import { sortBy, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import TableTemplate from '../../../../common/double-table/components/table-template';
import EmptyState from '../../../../common/empty-state-v2/components/empty-state';
import Helpers from '../../../../common/helpers';
import Icon from '../../../../common/icon/components/icon';
import RenderIf from '../../../../common/render-if/components/render-if';
import { statuses } from '../../constants/work-order-constants';
import { fields as WOFields, checklistTemplateFields as fields } from './constants/work-order-right-side-constants';

const tableConfig = [
  {
    title: 'ID',
    key: fields.id,
    class: 'cp-id',
  },
  {
    title: 'NAME',
    key: fields.name,
    type: fields.name,
    class: 'cp-name cp-template-name',
  },
  {
    key: fields.linkedToEquipment,
    type: fields.linkedToEquipment,
    class: 'linked-equipment',
  },
];

const WorkOrderSectionCPTemplates = ({ module, handleCustomAction, sectionAction, user, ...restProps }, { t }) => {
  const { editPermission: moduleEditPermission } = restProps;
  if (!module || !module.workOrderCPTemplates || !module.workOrderCPTemplates.length) {
    const showButtonForAddingCPT =
      (module[WOFields.status] === statuses.draft.value || module[WOFields.status] === statuses.approved.value || module[WOFields.status] === statuses.raised.value) &&
      Helpers.hasAccess({ user, visibleFor: moduleEditPermission });
    return (
      <>
        <EmptyState
          emptyStateText={t('WORK_ORDERS.NO_CP_TEMPLATES')}
          showButton={showButtonForAddingCPT}
          buttonAction={() => handleCustomAction(sectionAction)}
          buttonText={t('WORK_ORDER.ADD_CP_TEMPLATES')}
          transparent
        />
      </>
    );
  }

  const { openFillInChecklistModalData } = module;

  return (
    <div className="work-order-section-cp-templates">
      <RenderIf if={module && module.workOrderCPTemplates?.length > 0}>
        <div className="notification-WO-tab__table">
          <TableTemplate
            data={uniqBy(sortBy(module.workOrderCPTemplates, [fields.componentID]), fields.id)}
            handleFormatCells={(value, type, index, item) => {
              if (type === fields.name) {
                return (
                  <div className="checklist-name-container">
                    <p className="f-primary" title={value}>
                      {value}
                    </p>
                  </div>
                );
              } else if (type === fields.linkedToEquipment) {
                return (
                  value && (
                    <div className="linked-to-eq-container">
                      <Icon name="check" active handleHover={false} size="sm" />
                      <p className="f-primary">{t('WORK_ORDER.CP_TEMPLATES.LINKED_TO_COMPONENT')}</p>
                    </div>
                  )
                );
              }

              return Helpers.formatCell(value, type, index, item);
            }}
            showSearchBar={false}
            tableConfig={tableConfig}
            showInfo={false}
            onRowClick={row => openFillInChecklistModalData(row, true)}
            noDataText={t('WORK_ORDERS.NO_CP_TEMPLATES')}
          />
        </div>
      </RenderIf>
    </div>
  );
};

WorkOrderSectionCPTemplates.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default WorkOrderSectionCPTemplates;
