import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
//components
import Modal from '../../../../common/modal/components/modal';
import RenderIf from '../../../../common/render-if/components/render-if';
import ModuleHeader from '../left-toolbar/common/module-header';
import ManageGroupsModal from './components/manage-group-modal/manage-group-modal';
//constants
import { isEmpty } from 'lodash';
import routesConstants from '../../../../common/routes-constants';
import { detailsPages, modules } from '../../constants/constants';
import { createMeasurementLocation } from './actions/measurement-location-actions';
import MeasurementGroups from './components/measurement-groups';
import MeasurementLocations from './components/measurement-locations';
import MeasurementPoints from './components/measurement-points';
import CreateMeasurementPointModal from './components/measurement-points/create-measurement-point-modal';
import { fields, viewModuleHeaderProps, viewOptions } from './constants/constants';
import { DEFAULT_COLOR, formConstants } from './constants/measurement-location-constants';

import { MAX_TRUNCATE_LENGTH_PLACEHOLDER } from '../../../../common/constants';
import Helpers from '../../../../common/helpers';
import { setGenericNotification } from '../../../../common/notification/actions/action-creators';
import GenerateReportModal from '../right-toolbar/readings-and-gauges/components/modals/generate-report-modal';
import { setSelectedMeasurementLocation } from './actions/action-creators';
import { generateAndDownloadReport } from './actions/measurement-group-actions';
import './styles/readings-and-gauges.scss';

const ReadingsAndGauges = (props, { t }) => {
  const {
    handleActivePage,
    moduleActionsMenu,
    title,
    icon,
    backAction,
    viewer,
    location,
    isFullScreen,
    queryItem,
    projectID,
    user,
    defaultReadingGroupID,
    createMeasurementLocation,
    measurementLocationsFilter,
    selectedClusterElement,
    scrollToElement,
    elementsClustered,
    selectedMeasurementGroup,
    selectedMeasurementLocation,
    inspectionId,
    setSelectedMeasurementLocation,
    measurementLocationFormState,
    measurementPointFormState,
    generateAndDownloadReport,
    measurementLocationsClustered,
    measurementPointsClustered,
    setGenericNotification,
    router,
  } = props;
  const { query } = location;

  const view = useMemo(() => query[routesConstants.queryAlias.view] || viewOptions.group, [query]);
  const details = useMemo(() => query[routesConstants.queryAlias.details], [query]);

  const formStates = {
    [detailsPages.measurementLocation]: measurementLocationFormState,
    [detailsPages.measurementPoint]: measurementPointFormState,
  };
  const formHasUnsavedChanges = formStates[details]?.hasUnsavedChanges;

  const [path, setPath] = useState([{ Name: t('READINGS_AND_GAUGES.ALL_GROUPS'), [fields.returnToDefault]: true, queryParams: { type: modules.readingsAndGauges } }]);
  const [modalData, setModalData] = useState({ isOpen: false });
  const [prevQueryItem, setPrevQueryItem] = useState(null);

  /**
   * Navigates to the specified path and updates the path state.
   *
   * @param {object} pathItem - The current path object containing navigation details.
   * @param {object} path.queryParams - The query parameters of the path. Optional param
   * @param {string} path.queryParams.type - The type of the path.
   * @param {string} path.queryParams.selected_item - The currently selected path. Optional param
   * @param {object} path.queryParams.details - Additional details for the path. Optional param
   * @param {string} path.queryParams.view - The view associated with the path. Optional param
   * @param {string} newPathArray - The new path array to update to, if not passed it will push pathItem on top. Optional param
   */
  const navigateToPath = useCallback(
    (pathItem, newPathArray) => {
      if (isEmpty(pathItem)) {
        return;
      }

      let newPath = Object.assign([], path);
      const { queryParams } = pathItem;

      // Navigate to desired path
      if (queryParams && queryParams.type) {
        if (!queryParams.selected_item) {
          // Reset selected measurement location whenever selected_item is empty, which means neither mp or ml is selected
          setSelectedMeasurementLocation(null);
        }
        handleActivePage(queryParams.type, queryParams.selected_item || null, queryParams.details || null, queryParams.view || null);
      }

      // Set new path
      if (newPathArray) {
        // override the existing path
        setPath(newPathArray);
      } else {
        const { queryParams } = pathItem;
        if (!isEmpty(path) && path[path.length - 1].queryParams?.selected_item && queryParams.selected_item) {
          // only one query item with distinct type can be present in the path, and it should always come as a last item.
          newPath.pop();
        }
        setPath([...newPath, pathItem]);
      }
    },
    [handleActivePage, setSelectedMeasurementLocation, path]
  );

  const handleCreateNewLocation = useCallback(
    measurementGroupID => {
      createMeasurementLocation(
        DEFAULT_COLOR,
        t('READINGS_AND_GAUGES.MEASUREMENT_LOCATIONS.DEFAULT_NAME'),
        projectID,
        measurementGroupID || defaultReadingGroupID,
        measurementLocationsFilter,
        newData => {
          const newPathItem = {
            [fields.name]: newData[fields.name],
            queryParams: { type: modules.readingsAndGauges, selected_item: newData[fields.id], details: detailsPages.measurementLocation, view },
          };
          navigateToPath(newPathItem);
          setSelectedMeasurementLocation(newData);
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultReadingGroupID, projectID, createMeasurementLocation, measurementLocationsFilter, view, navigateToPath]
  );

  const handleOpenCreateMeasurementPointModal = useCallback(
    measurementLocation => {
      let modalData;
      const closeAction = () => {
        modalData = { isOpen: false };
        setModalData(modalData);
      };

      modalData = {
        isOpen: true,
        title: t('READINGS_AND_GAUGES.MEASUREMENT_POINT.CREATE_MODAL_TITLE'),
        CustomContent: dynamicProps => (
          <CreateMeasurementPointModal
            {...dynamicProps}
            inspectionId={inspectionId}
            projectID={projectID}
            measurementLocationID={measurementLocation ? measurementLocation[formConstants.fields.id] : null}
            navigateToPath={navigateToPath}
            closeAction={closeAction}
          />
        ),
        customClassName: 'modal-large',
        type: 'none',
        closeAction,
      };

      setModalData(modalData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inspectionId, projectID, navigateToPath]
  );

  const viewHeaderProps = useMemo(
    () =>
      viewModuleHeaderProps({
        user,
        handleCreateNewLocation,
        handleOpenCreateMeasurementPointModal,
        selectedMeasurementGroupId: selectedMeasurementGroup?.[fields.id],
      })?.[view] || {},
    [view, user, selectedMeasurementGroup, handleCreateNewLocation, handleOpenCreateMeasurementPointModal]
  );

  useEffect(() => {
    if (path.length > 1) {
      return;
    }
    if (view === viewOptions.location || view === viewOptions.location_and_points) {
      setPath([...path, { Name: t('READINGS_AND_GAUGES.ALL_ML'), queryParams: { type: modules.readingsAndGauges, selected_item: queryItem, details: detailsPages.measurementLocation, view } }]);
    } else if (view === viewOptions.points) {
      setPath([...path, { Name: t('READINGS_AND_GAUGES.ALL_MP'), queryParams: { type: modules.readingsAndGauges, selected_item: queryItem, details: detailsPages.measurementPoint, view } }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Responsible for handling specifc case when deselecting previously selected item from somewhere else in the app
    if (prevQueryItem !== queryItem) {
      // Selected item changed
      setPrevQueryItem(queryItem);
      if (prevQueryItem && !queryItem) {
        // Means previosly selected item has been deselected
        if (!isEmpty(path) && path[path.length - 1].queryParams?.selected_item) {
          // Remove if there is an actual selected item in the path, deslected from somewhere else, not using navigateToPath function
          let newPath = Object.assign([], path);
          newPath.pop();
          setSelectedMeasurementLocation(null);

          setPath(newPath);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryItem]);

  const handleManageGroupsClick = () => {
    setModalData({
      isOpen: true,
      type: '',
      CustomContent: dynamicProps => <ManageGroupsModal {...dynamicProps} />,
      closeAction: closeModal,
      title: t('READINGS_AND_GAUGES.MANAGE_GROUPS'),
      projectID,
      inspectionId,
      user,
    });
  };

  const generateAndDownloadReportHandler = data => {
    generateAndDownloadReport(
      data,
      () => {
        closeModal();
        // success generic notification
        setGenericNotification({ isDisplayed: true, type: 'success', text: t('GENERATE_AND_DOWNLOAD_REPORT.SUCCESS_NOTIFICATION'), icon: 'checkmark-outline', wrapperClassName: 'full-width' });
      },
      () => {
        closeModal();
        // error generic notification
        setGenericNotification({ isDisplayed: true, type: 'error', text: t('GENERATE_AND_DOWNLOAD_REPORT.ERROR_NOTIFICATION'), icon: 'info', wrapperClassName: 'full-width' });
      }
    );
  };

  const handleGenerateReportClick = () => {
    setModalData({
      isOpen: true,
      type: '',
      CustomContent: dynamicProps => <GenerateReportModal handleSubmit={data => generateAndDownloadReportHandler(data)} showDatesOnly={false} {...dynamicProps} />,
      closeAction: closeModal,
      title: t('INSPECTION_DETAILS_DOWNLOAD_REPORT'),
      projectID,
      inspectionId,
      customClassName: 'generate-report-modal',
    });
  };

  const closeModal = () => setModalData({ isOpen: false });

  const handleBackClick = () => {
    if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      const newPath = Object.assign([], path);
      newPath.pop();
      if (newPath[newPath.length - 1]) {
        navigateToPath(newPath[newPath.length - 1], newPath);
      }
    }
  };

  const handlePathClick = (_id, selectedPath) => {
    if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      const index = path.findIndex(element => element[formConstants.fields.name] === selectedPath[formConstants.fields.name]);
      let newPath = Object.assign([], path);

      if (index > -1) {
        // Return a new array with elements up to and including the found element
        newPath = newPath.slice(0, index + 1);
      }
      navigateToPath(selectedPath, newPath);
    }
  };

  const actionsMenu = [
    ...moduleActionsMenu,
    {
      title: 'READINGS_AND_GAUGES.MANAGE_GROUPS',
      action: handleManageGroupsClick,
    },
    {
      title: 'INSPECTION_DETAILS_DOWNLOAD_REPORT',
      action: handleGenerateReportClick,
    },
  ];

  return (
    <div className="readings-and-gauges">
      <ModuleHeader
        {...{
          title,
          icon,
          backAction,
          actionsMenu: actionsMenu,
          ...viewHeaderProps,
        }}
      />
      <RenderIf if={view === viewOptions.location}>
        <MeasurementLocations
          isFullScreen={isFullScreen}
          view={view}
          details={details}
          path={path}
          navigateToPath={navigateToPath}
          handleBackClick={handleBackClick}
          handlePathClick={handlePathClick}
          projectID={projectID}
          viewer={viewer}
          elementsClustered={measurementLocationsClustered}
          queryItem={queryItem}
          scrollToElement={scrollToElement}
          selectedClusterElement={selectedClusterElement}
          user={user}
          handleCreateNewLocation={handleCreateNewLocation}
        />
      </RenderIf>
      <RenderIf if={view === viewOptions.points}>
        <MeasurementPoints
          isFullScreen={isFullScreen}
          view={view}
          details={details}
          path={path}
          navigateToPath={navigateToPath}
          handleBackClick={handleBackClick}
          handlePathClick={handlePathClick}
          projectID={projectID}
          viewer={viewer}
          elementsClustered={measurementPointsClustered}
          queryItem={queryItem}
          scrollToElement={scrollToElement}
          selectedClusterElement={selectedClusterElement}
          user={user}
          handleOpenCreateMeasurementPointModal={handleOpenCreateMeasurementPointModal}
          router={router}
        />
      </RenderIf>
      <RenderIf if={view === viewOptions.group}>
        <MeasurementGroups
          isFullScreen={isFullScreen}
          view={view}
          details={details}
          path={path}
          navigateToPath={navigateToPath}
          handleBackClick={handleBackClick}
          handlePathClick={handlePathClick}
          projectID={projectID}
          viewer={viewer}
          user={user}
          handleManageGroupsClick={handleManageGroupsClick}
          showPath={false}
          elementsClustered={elementsClustered}
        />
      </RenderIf>
      <RenderIf if={view === viewOptions.location_and_points}>
        <div className="double-table">
          <div className="double-table__table">
            <MeasurementLocations
              searchPlaceholder={
                !isFullScreen
                  ? Helpers.truncateText(
                      t('READINGS_AND_GAUGES.GROUP.MEASUREMENT_LOCATION.SEARCH', {
                        groupName: selectedMeasurementGroup ? t(selectedMeasurementGroup[formConstants.fields.name]) : '',
                      }),
                      MAX_TRUNCATE_LENGTH_PLACEHOLDER
                    )
                  : t('READINGS_AND_GAUGES.GROUP.MEASUREMENT_LOCATION.SEARCH', {
                      groupName: selectedMeasurementGroup ? t(selectedMeasurementGroup[formConstants.fields.name]) : '',
                    })
              }
              isFullScreen={isFullScreen}
              view={view}
              details={details}
              path={path}
              navigateToPath={navigateToPath}
              handleBackClick={handleBackClick}
              handlePathClick={handlePathClick}
              projectID={projectID}
              viewer={viewer}
              elementsClustered={measurementLocationsClustered}
              queryItem={queryItem}
              scrollToElement={scrollToElement}
              selectedClusterElement={selectedClusterElement}
              selectedMeasurementLocation={selectedMeasurementLocation}
              selectedMeasurementGroupId={selectedMeasurementGroup?.[fields.id] || defaultReadingGroupID}
              user={user}
              handleCreateNewLocation={handleCreateNewLocation}
            />
          </div>
          <div className="double-table__table">
            <MeasurementPoints
              searchPlaceholder={
                !isFullScreen
                  ? Helpers.truncateText(
                      t('READINGS_AND_GAUGES.GROUP.MEASUREMENT_POINT.SEARCH', {
                        groupName: selectedMeasurementGroup ? t(selectedMeasurementGroup[formConstants.fields.name]) : '',
                      }),
                      MAX_TRUNCATE_LENGTH_PLACEHOLDER
                    )
                  : t('READINGS_AND_GAUGES.GROUP.MEASUREMENT_POINT.SEARCH', {
                      groupName: selectedMeasurementGroup ? t(selectedMeasurementGroup[formConstants.fields.name]) : '',
                    })
              }
              isFullScreen={isFullScreen}
              view={view}
              details={details}
              path={path}
              showPath={false}
              navigateToPath={navigateToPath}
              handleBackClick={handleBackClick}
              handlePathClick={handlePathClick}
              projectID={projectID}
              viewer={viewer}
              elementsClustered={measurementPointsClustered}
              queryItem={queryItem}
              scrollToElement={scrollToElement}
              selectedClusterElement={selectedClusterElement}
              selectedMeasurementLocation={selectedMeasurementLocation}
              user={user}
              handleOpenCreateMeasurementPointModal={handleOpenCreateMeasurementPointModal}
              router={router}
            />
          </div>
        </div>
      </RenderIf>
      <Modal {...modalData} />
    </div>
  );
};

ReadingsAndGauges.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  defaultReadingGroupID: state.projectDetailsReducer.DefaultReadingGroupID,
  measurementLocationsFilter: state.measurementLocationReducer.measurementLocationsFilter,
  selectedMeasurementGroup: state.measurementGroupReducer.selectedMeasurementGroup,
  selectedMeasurementLocation: state.measurementLocationReducer.selectedMeasurementLocation,
  measurementPointFormState: state.measurementPointReducer.measurementPointFormState,
  measurementLocationFormState: state.measurementLocationReducer.measurementLocationFormState,
});

const mapDispatchToProps = dispatch => ({
  createMeasurementLocation: (color, name, projectID, measurementGroupID, measurementLocationsFilter, callback) =>
    dispatch(createMeasurementLocation(color, name, projectID, measurementGroupID, measurementLocationsFilter, callback)),
  setSelectedMeasurementLocation: data => dispatch(setSelectedMeasurementLocation(data)),
  generateAndDownloadReport: (data, callback, errorCallback, loadingCallback) => dispatch(generateAndDownloadReport(data, callback, errorCallback, loadingCallback)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReadingsAndGauges));
