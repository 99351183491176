export default {
  FETCH_FOLDERS: '/project/document/hierarchy/list',
  FETCH_COMPONENT_FOLDERS: '/project/component/dms/hierarchy/list',
  FETCH_NOTIFICATION_FOLDERS: '/project/notification/dms/hierarchy/list',
  FETCH_OBSERVATION_FOLDERS: '/project/defect/dms/hierarchy/list',
  FETCH_WORKORDER_FOLDERS: '/project/workorder/dms/hierarchy/list',
  FETCH_WORKORDER_COMPLETION_FOLDERS: '/project/workorder/dms/completion/hierarchy/list',
  FETCH_CP_QUESTION_FOLDERS: '/project/checklists/question/dms/hierarchy/list',
  CREATE_FOLDER: '/project/document/hierarchy/add',
  FETCH_LABELS: '/project/document/labels/list',
  FETCH_FILES: '/project/document/files/list',
  FETCH_COMPONENT_FILES: '/project/component/dms/files/list',
  FETCH_NOTIFICATION_FILES: '/project/notification/dms/files/list',
  FETCH_OBSERVATION_FILES: '/project/defect/dms/files/list',
  FETCH_WORKORDER_FILES: '/project/workorder/dms/files/list',
  FETCH_WORKORDER_COMPLETION_FILES: '/project/workorder/dms/completion/files/list',
  FETCH_CP_QUESTION_FILES: '/project/checklists/question/dms/files/list',
  FETCH_FOLDER_HIERARCHY_LEVEL_DETAILS: '/project/document/hierarchy/details',
  FETCH_FILE_HIERARCHY_LEVEL_DETAILS: '/project/document/details',
  UPDATE_FOLDER_DETAILS: '/project/document/hierarchy/update',
  DELETE_DM_FOLDER: '/project/document/hierarchy/delete',
  DELETE_DMS_FILE: '/project/document/delete',
  MOVE_DMS_FILE: '/project/document/file/hierarchy/update',
  UPDATE_DMS_FILE: '/project/document/update',
  FETCH_STATUSES: '/project/document/statuses/get',
  FETCH_COMPONENTS_TO_LINK: '/inspection/component/lite-list',
  LINK_COMPONENTS_TO_FILE: '/project/document/file/component/link',
  ADD_LABEL_TO_DMS_FILE: '/project/document/label/add',
  REMOVE_LABEL_FROM_DMS_FILE: '/project/document/label/remove',
  CONFIRM_DMS_FILE_UPLOAD_IS_DONE: '/project/document/upload/done',
  CONFIRM_COMPONENT_DMS_FILE_UPLOAD_IS_DONE: '/inspection/component/dms/upload/done',
  CONFIRM_NOTIFICATION_DMS_FILE_UPLOAD_IS_DONE: '/inspection/notification/dms/upload/done',
  CONFIRM_OBSERVATION_DMS_FILE_UPLOAD_IS_DONE: '/inspection/defect/dms/upload/done',
  CONFIRM_WORKORDER_DMS_FILE_UPLOAD_IS_DONE: '/inspection/workorder/dms/upload/done',
  CONFIRM_WORKORDER_WIZARD_DMS_FILE_UPLOAD_IS_DONE: '/inspection/workorder/wizard/dms/upload/done',
  CONFIRM_CP_QUESTION_DMS_FILE_UPLOAD_IS_DONE: '/project/checklists/question/dms/upload/done',
  CONFIRM_WORKORDER_COMPLETION_DMS_FILE_UPLOAD_IS_DONE: '/inspection/workorder/dms/completion/upload/done',
  LINK_FILE_FROM_OBSERVATION: '/project/defect/dms/file/link',
  UNLINK_FILE_FROM_OBSERVATION: '/project/defect/dms/file/unlink',
  LINK_OBSERVATION_TO_FILE: '/project/document/file/defect/link',
  UNLINK_OBSERVATION_TO_FILE: '/project/document/file/defect/unlink',
  LINK_FILE_FROM_NOTIFICATION: '/project/notification/dms/file/link',
  UNLINK_FILE_FROM_NOTIFICATION: '/project/notification/dms/file/unlink',
  LINK_NOTIFICATION_TO_FILE: '/project/document/file/notification/link',
  UNLINK_NOTIFICATION_TO_FILE: '/project/document/file/notification/unlink',
  LINK_FILE_FROM_WORKORDER: '/project/workorders/dms/file/link',
  LINK_FILE_FROM_WORKORDER_COMPLETION: '/inspection/workorder/dms/completion/file/link',
  UNLINK_FILE_FROM_WORKORDER: '/project/workorders/dms/file/unlink',
  UNLINK_FILE_FROM_WORKORDER_COMPLETION: '/inspection/workorder/dms/completion/file/unlink',
  LINK_WORKORDER_TO_FILE: '/project/document/file/workorders/link',
  UNLINK_WORKORDER_TO_FILE: '/project/document/file/workorders/unlink',
  FETCH_DMS_DOCUMENT_VERSION_HISTORY: '/project/document/versions/list',
  SET_DMS_FILE_VERSION_TO_ACTIVE: '/project/document/version/activate',
  UPDATE_DOCUMENT_VERSION: '/project/document/version/update',
  DELETE_DMS_FILE_VERSION: '/project/document/version/delete',
  LINK_FILE_FROM_CHECKLIST_QUESTION: '/project/checklist/question/dms/file/link',
  UNLINK_FILE_FROM_CHECKLIST_QUESTION: '/project/checklist/question/dms/file/unlink',
  FETCH_NEW_VERSION_NUMBER_FOR_DOCUMENT: '/project/document/version/next',
};
