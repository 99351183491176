export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    color: 'Colour',
    description: 'Description',
    points: 'Geometry.coordinates',
    camPosition: 'CameraPosition.coordinates',
    createdDate: 'CreatedAt',
    createdBy: 'CreatedBy',
    createdByUser: 'CreatedByUser',
    status: 'Status',
    componentId: 'ComponentID',
    componentName: 'ComponentName',
    componentCode: 'ComponentCode',
    measurementGroupId: 'MeasurementGroupID',
    measurementGroup: 'MeasurementGroup',
    sortUp: 'SortUp',
    sortDown: 'SortDown',
  },
};

export const tabNames = {
  details: 'DETAILS',
  history: 'HISTORY',
};

export const textAreaMaxChars = 500;

export const toolbarItems = {
  [tabNames.details]: {
    name: tabNames.details,
    label: 'MEASUREMENT_LOCATION_FORM_TAB.DETAILS',
  },
  [tabNames.history]: {
    name: tabNames.history,
    label: 'MEASUREMENT_LOCATION_FORM_TAB.HISTORY',
  },
};

export const measurementPointTableConfig = [
  {
    title: 'ID',
    key: formConstants.fields.id,
    type: formConstants.fields.id,
    class: 'id-column',
  },
  {
    title: 'TABLE.DEFECT_NAME',
    key: formConstants.fields.name,
    type: formConstants.fields.name,
    class: 'name-column',
  },
  {
    title: '',
    key: formConstants.fields.sortUp,
    type: formConstants.fields.sortUp,
    class: 'sort-up-column',
  },
  {
    title: '',
    key: formConstants.fields.sortDown,
    type: formConstants.fields.sortDown,
    class: 'sort-down-column',
  },
];

export const filterProps = {
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  hasNext: 'HasNext',
  totalItems: 'TotalNumber',
  orderNumber: 'OrderNumber',
};

export const sortDirections = {
  asc: 'ASC',
  desc: 'DESC',
};

export const mpDefaultFilters = {
  [filterProps.searchText]: '',
  [filterProps.perPage]: 10,
  [filterProps.lastSeen]: 0,
  [filterProps.sortDirection]: sortDirections.asc,
  [filterProps.sortByColumn]: filterProps.orderNumber,
};
