import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TableTemplate from '../../../../../../common/double-table/components/table-template';
import EmptyState from '../../../../../../common/empty-state-v2/components/empty-state';
import Button from '../../../../../../common/form/components/button';
import Helpers from '../../../../../../common/helpers';
import Modal from '../../../../../../common/modal/components/modal';
import RenderIf from '../../../../../../common/render-if/components/render-if';
import { fetchMeasurementPointsForMeasurementLocation, updateOrderMeasurementPoints } from '../../../readings-and-gauges/actions/measurement-point-actions';
import CreateMeasurementPointModal from '../../../readings-and-gauges/components/measurement-points/create-measurement-point-modal';
import { formatMeasurementPointTableCells } from '../actions/table-formatting';
import { filterProps, formConstants, measurementPointTableConfig, mpDefaultFilters } from '../constants/measurement-location-constants';
import { reorderDirection } from '../constants/measurement-point-obeservations-constant';

const MeasurementPointsSection = (
  { projectID, handleActivePage, selectedMeasurementLocation, queryItem, fetchMeasurementPointsForMeasurementLocation, inspectionDetails, updateOrderMeasurementPoints, readonly, user },
  { t }
) => {
  const [modalData, setModalData] = useState({ isOpen: false });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({ MeasurementLocationID: queryItem, ...mpDefaultFilters });
  const inspectionID = inspectionDetails.InspectionID;

  const fetchMeasurementLocationPoints = useCallback(
    (filters, loadMore = false) => {
      const onSuccess = (items, newFilters) => {
        setLoading(false);
        setData(prevData => (loadMore ? [...prevData, ...items] : items));
        setFilters(prev => ({ ...prev, ...newFilters }));
        if (loadMore) {
          Helpers.scrollIntoView('ml-measurement-points-table', `row-${filters[filterProps.lastSeen]}`, -525);
        }
      };

      fetchMeasurementPointsForMeasurementLocation(filters, onSuccess, setFilters, null, setLoading);
    },
    [fetchMeasurementPointsForMeasurementLocation]
  );

  useEffect(() => {
    fetchMeasurementLocationPoints(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLoadMoreClick = () => {
    filters[filterProps.lastSeen] = data.length;
    fetchMeasurementLocationPoints(filters, true);
  };

  const handleOpenCreateMeasurementPointModal = useCallback(
    measurementLocation => {
      let modalData;
      const closeAction = () => {
        modalData = { isOpen: false };
        setModalData(modalData);
      };

      modalData = {
        isOpen: true,
        title: t('READINGS_AND_GAUGES.MEASUREMENT_POINT.CREATE_MODAL_TITLE'),
        CustomContent: dynamicProps => (
          <CreateMeasurementPointModal
            {...dynamicProps}
            inspectionId={inspectionID}
            projectID={projectID}
            measurementLocationID={measurementLocation[formConstants.fields.id]}
            handleActivePage={handleActivePage}
            closeAction={closeAction}
            refetchMeasurementPoints={() => fetchMeasurementLocationPoints(filters)}
            noRedirect
          />
        ),
        customClassName: 'modal-large',
        type: 'none',
        closeAction,
      };

      setModalData(modalData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [inspectionID, projectID, handleActivePage, fetchMeasurementLocationPoints]
  );

  const onReorderClick = (direction, index, id) => {
    if (direction === reorderDirection.up && index === 0) return;
    if (direction === reorderDirection.down && index === data.length - 1) return;

    const newRows = [...data];
    const newPosition = direction === reorderDirection.up ? index - 1 : index + 1;

    const successCallback = () => {
      [newRows[index], newRows[newPosition]] = [newRows[newPosition], newRows[index]];
      setData(newRows);
    };

    const errorCallback = error => {
      console.error('Reordering failed:', error);
    };

    // add 1 since index does not start from 0
    updateOrderMeasurementPoints(id, queryItem, newPosition + 1, successCallback, errorCallback);
  };

  const formatCells =
    (formatFunction, selectedMeasurementLocation) =>
    (
      value,
      type,
      searchInputValue,
      row,
      onDropdownClick,
      t,
      pointerDotID,
      user,
      onToggleClick,
      onDeleteRow,
      statusData,
      handleChangeStatus,
      onLinkFileClick,
      selectedItem,
      cell,
      hasLinkDMSFilePermissions,
      onRowClick,
      toggleModalAction,
      onReorderClick
    ) =>
      formatFunction(
        value,
        type,
        searchInputValue,
        row,
        onDropdownClick,
        t,
        pointerDotID,
        user,
        onToggleClick,
        onDeleteRow,
        statusData,
        handleChangeStatus,
        onLinkFileClick,
        selectedItem,
        cell,
        hasLinkDMSFilePermissions,
        onRowClick,
        toggleModalAction,
        onReorderClick,
        selectedMeasurementLocation
      );

  const handleFormatCells = formatCells(formatMeasurementPointTableCells, selectedMeasurementLocation);

  return (
    <div className="ml-measurement-points-section">
      <RenderIf if={data && data?.length > 0}>
        <div className="actions-container">
          <Button
            onClick={() => handleOpenCreateMeasurementPointModal(selectedMeasurementLocation)}
            text={t('READINGS_AND_GAUGES.MEASUREMENT_POINT.CREATE_MODAL_TITLE')}
            height="md"
            icon={'plus-circle'}
            type="button"
            variant="success-outline"
            disabled={readonly}
          />
        </div>
        <TableTemplate
          stickyHeader={false}
          showSearchBar={false}
          tableConfig={measurementPointTableConfig}
          filters={filters}
          data={data}
          onSortClick={() => null}
          loadMoreOnClick={onLoadMoreClick}
          handleFormatCells={handleFormatCells}
          isLoading={loading}
          selectedItem={null}
          TotalItems={filters[filterProps.totalItems]}
          sortDirection={filterProps.sortDirection}
          sortByColumn={filterProps.sortByColumn}
          resultsText={'MEASUREMENT_LOCATION_DETAILS.MEASUREMENT_POINTS.TOTAL'}
          hasNext={filters[filterProps.hasNext]}
          noDataText={t('DEFECT_DETAILS.NOTIFICATIONS_EMPTY_STATE')}
          customTableClass={'ml-measurement-points-table'}
          onRowClick={() => null}
          onReorderClick={onReorderClick}
          user={user}
        />
      </RenderIf>
      <RenderIf if={data && data?.length === 0}>
        <EmptyState
          emptyStateText={t('READINGS_AND_GAUGES.MEASUREMENT_POINTS.EMPTY_STATE.TITLE')}
          showButton={!readonly}
          buttonAction={() => handleOpenCreateMeasurementPointModal(selectedMeasurementLocation)}
          buttonText={t('READINGS_AND_GAUGES.MEASUREMENT_POINTS.EMPTY_STATE.ACTION_TEXT')}
          transparent
        />
      </RenderIf>
      <Modal {...modalData} />
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  fetchMeasurementPointsForMeasurementLocation: (filters, callback, filtersCallback, errorCallback, loadingCallback) =>
    dispatch(fetchMeasurementPointsForMeasurementLocation(filters, callback, filtersCallback, errorCallback, loadingCallback)),
  updateOrderMeasurementPoints: (MeasurementPointID, MeasurementLocationID, NewPosition, successCallback, errorCallback) =>
    dispatch(updateOrderMeasurementPoints(MeasurementPointID, MeasurementLocationID, NewPosition, successCallback, errorCallback)),
});

MeasurementPointsSection.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(MeasurementPointsSection);
