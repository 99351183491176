import { cloneDeep, each, get, isArray, isEmpty, map, some } from 'lodash';

import { DefaultDrawing, DrawingRequirements } from '../app/inspections/constants/default-drawing';

import workflowConstants from '../app/inspection-workflow/constants/inspection-workflow-constants';

import Helpers from './helpers';
import { highlightedOpacityLevel } from './pdf-viewer/constants/constants';

class DrawingHelpers {
  static extractImageDrawings = (imageDrawings, severityColors, pageNumber, componentFilter, canvasWidth, canvasHeight, scale = 2.5) => {
    let obj = null;
    let objects = [];
    let drawings = null;
    let drawing = null;
    let commonWidth = null;
    let commonHeight = null;
    // pull out drawings and define main object
    each(imageDrawings, (item, _index) => {
      drawings = item && item.Drawings;
      if (drawings) {
        each(drawings, (draw, _index) => {
          if (!draw) return;
          drawing = typeof draw.Drawing === 'string' ? JSON.parse(draw.Drawing) : draw.Drawing;
          // filters out the drawings that are not on the active PDF page
          if (!drawing || (drawing.objects && drawing.objects[0] && drawing.objects[0].pageNumber !== pageNumber)) return;
          drawing = !isNaN(item.Severity) && severityColors ? DrawingHelpers.adjustStrokeColor(drawing, item.Severity, severityColors) : drawing;
          commonWidth = canvasWidth ? canvasWidth : drawing.objects[0]?.canvasWidth || drawing.canvasWidth;
          commonHeight = canvasHeight ? canvasHeight : drawing.objects[0]?.canvasHeight || drawing.canvasHeight;
          drawing.objects[0].ID = draw.ID;
          obj = this.calculateImageDrawingsOffset(drawing, commonWidth, commonHeight, scale);
          if (!obj) {
            return;
          }
          objects = [...objects, !obj.objects.length ? obj.objects : obj.objects[0] || []];
        });
      }
    });

    return !isEmpty(objects) ? { objects } : null;
  };

  static calculateImagePointOffset = (top, left, targetResolution, currentResolution) => {
    let wfactor = (targetResolution.width / currentResolution.width).toFixed(2);
    let hfactor = (targetResolution.height / currentResolution.height).toFixed(2);

    return { left: left * wfactor, top: top * hfactor };
  };

  static getCenterDrawingPoint = drawing => {
    if (isEmpty(drawing)) {
      return { top: null, left: null };
    }
    const left = drawing.left + parseInt(drawing.width / 2) * drawing.scaleX;
    const top = drawing.top + parseInt(drawing.height / 2) * drawing.scaleY;
    if (top && left) {
      return { top, left };
    } else {
      return { top: null, left: null };
    }
  };

  static calculateImageDrawingsOffset = (_drawings, canvasWidth, canvasHeight, canvasScale = 1) => {
    let drawings = cloneDeep(_drawings);
    if (isEmpty(drawings)) return;

    const drawingObjects = drawings.objects && !isEmpty(drawings.objects) ? drawings.objects : drawings;

    const drawingCanvasWidth = drawings.objects[0]?.canvasWidth || drawings.canvasWidth;
    const drawingCanvasHeight = drawings.objects[0]?.canvasHeight || drawings.canvasHeight;

    const canWidth = drawingCanvasWidth ? drawingCanvasWidth.toFixed(2) : canvasWidth / canvasScale;
    const canHeight = drawingCanvasHeight ? drawingCanvasHeight.toFixed(2) : canvasHeight / canvasScale;
    const wfactor = canvasWidth / canWidth;
    const hfactor = canvasHeight / canHeight;

    const objects = map(drawingObjects, item => {
      let scaleX = item.scaleX;
      let scaleY = item.scaleY;
      let left = item.left;
      let top = item.top;
      let tempScaleX = scaleX * wfactor;
      let tempScaleY = scaleY * hfactor;
      let tempLeft = left * wfactor;
      let tempTop = top * hfactor;

      if (item.width < 1 || item.height < 1) {
        console.warn('Drawings object needs to have width and height grather then zero');
      }

      let tempItem = {
        scaleX: tempScaleX,
        scaleY: tempScaleY,
        left: tempLeft,
        top: tempTop,
        calculated: true,
      };
      return {
        ...item,
        ...tempItem,
      };
    });

    return { ...drawings.objects, objects };
  };

  static calculateDefectImageDrawingsOffset = (_drawings, canvasWidth, canvasHeight, includeDimensionsScale) => {
    let drawings = cloneDeep(_drawings);
    if (isEmpty(drawings)) return;
    else if (isEmpty(drawings.objects)) {
      console.warn('Drawings object array of objects cannot be empty, unable to calculate drawings offset');
      return;
    } else if (!drawings.canvasWidth || !drawings.canvasHeight) {
      console.warn('Drawings object needs to have canvasWidth and canvasHeight props, unable to calculate drawings offset');
      return;
    }
    const canWidth = drawings.canvasWidth.toFixed(2);
    const canHeight = drawings.canvasHeight.toFixed(2);
    const wfactor = canvasWidth / canWidth;
    const hfactor = canvasHeight / canHeight;

    const objects =
      map(drawings.objects, item => {
        let scaleX = item.scaleX;
        let scaleY = item.scaleY;
        let left = item.left;
        let top = item.top;
        let tempScaleX = scaleX * wfactor;
        let tempScaleY = scaleY * hfactor;
        let tempLeft = left * wfactor;
        let tempTop = top * hfactor;
        if (item.width < 1 || item.height < 1) {
          console.warn('Drawings object needs to have width and height grather then zero');
        }

        let tempItem = {
          scaleX: tempScaleX,
          scaleY: tempScaleY,
          left: tempLeft,
          top: tempTop,
          calculated: true,
        };

        if (includeDimensionsScale) {
          //dimensions calculation for checking is drawing big enough
          tempItem = { ...tempItem, width: item.width * tempScaleX, height: item.height * tempScaleY };
        }

        return {
          ...item,
          ...tempItem,
        };
      }) || [];

    return { ...drawings, objects };
  };

  static generateImagePointDrawing = (resolution, position) => {
    let drawing = cloneDeep(DefaultDrawing);
    // TODO: Reuse function calculateImagePointOffset to calc top and left offset
    const left = (position.X * drawing.canvasWidth) / resolution.Width;
    const top = (position.Y * drawing.canvasHeight) / resolution.Height;
    drawing.objects[0].left = left - parseInt(drawing.objects[0].width / 2) * drawing.objects[0].scaleX;
    drawing.objects[0].top = top - parseInt(drawing.objects[0].height / 2) * drawing.objects[0].scaleY;

    return drawing;
  };

  static isValid = drawing => {
    if (isEmpty(drawing) || !isArray(drawing.objects) || isEmpty(drawing.objects)) {
      return false;
    }

    //scale drawing widdth and height to see if drawing is required size
    const calculatedDrawing = this.calculateDefectImageDrawingsOffset(drawing, DrawingRequirements.canvasWidth, DrawingRequirements.canvasHeight, true);

    return !some(calculatedDrawing.objects, item => item.width < DrawingRequirements.width || item.height < DrawingRequirements.height);
  };

  static adjustStrokeColor = (draw, severity, pallete) => {
    let drawing = cloneDeep(draw);

    const severityColor = Helpers.fetchReviewedClass(severity);
    let color = '';
    switch (severityColor) {
      case workflowConstants.severity.green.color:
        color = pallete.severityGreen;
        break;
      case workflowConstants.severity.yellow.color:
        color = pallete.severityYellow;
        break;
      case workflowConstants.severity.orange.color:
        color = pallete.severityOrange;
        break;
      case workflowConstants.severity.red.color:
        color = pallete.severityRed;
        break;
      default:
        color = pallete.severityYellow;
    }

    if (!drawing) return;

    const res = map(drawing.objects, item => {
      item.stroke = color;
      return item;
    });

    return { ...drawing, objects: res };
  };

  static setDrawingSelectable = (draw, selectable) => {
    let drawing = cloneDeep(draw);

    const res = map(drawing.objects, item => {
      item.selectable = selectable;
      return item;
    });

    return { ...drawing, objects: res };
  };

  static getImagePointCalculation = (activeCameraHelper, currentCamera, sketchRef, drawings) => {
    if (!currentCamera || !activeCameraHelper) {
      return null;
    }

    const drawingObject = get(drawings, 'objects[0]');
    const { top, left } = this.getCenterDrawingPoint(drawingObject);

    const originalWidth = get(currentCamera, 'ImageResolution.Width');
    const originalHeight = get(currentCamera, 'ImageResolution.Height');
    const canvasWidth = get(sketchRef, '_container.offsetWidth');
    const canvasHeight = get(sketchRef, '_container.clientHeight');

    const imageResolution = { width: originalWidth, height: originalHeight };
    const canvasResolution = { width: canvasWidth, height: canvasHeight };

    return this.calculateImagePointOffset(top, left, imageResolution, canvasResolution);
  };

  static normalizeDrawings = components => {
    return (components || []).map(component => {
      if (isEmpty(component.Drawings)) {
        return {
          ...component,
          Drawings: [],
        };
      }

      let componentDrawings = get(component, 'Drawings');

      const updatedDrawings = componentDrawings
        .map(drawingItem => {
          const { Drawing } = drawingItem;

          if (!Drawing || isEmpty(Drawing)) {
            return null; // Skip this iteration
          }

          const parsedDrawing = JSON.parse(Drawing);
          const objects = get(JSON.parse(Drawing), 'objects') || {};
          objects.forEach(object => {
            if (object) {
              object.componentId = component.ID;
              object.uuid = object.uuid || Helpers.uuid4();
              object.selectable = false;
              object.opacity = highlightedOpacityLevel.notSelected;
              object.canvasWidth = parsedDrawing?.canvasWidth;
              object.canvasHeight = parsedDrawing?.canvasHeight;
            }
          });

          const updatedDrawing = {
            ID: drawingItem.ID,
            Drawing: `{"objects": ${JSON.stringify(objects)}}`,
          };

          return {
            ...drawingItem,
            ...updatedDrawing,
          };
        })
        .filter(updatedDrawing => updatedDrawing !== null);

      const updatedComponent = {
        ...component,
        Drawings: updatedDrawings,
        Severity: 0,
      };

      return updatedComponent;
    });
  };

  static findComponentByDrawingGuid = (components, clickedObj) => {
    for (let i = 0; i < components.length; i++) {
      const item = components[i];
      const { Drawings } = item;
      // iterate through Drawings of each component
      if (Drawings) {
        for (let j = 0; j < Drawings.length; j++) {
          const { Drawing } = Drawings[j];
          if (!isEmpty(Drawing)) {
            const parsedDrawing = JSON.parse(Drawing);
            const drawingObject = get(parsedDrawing, 'objects[0]');
            if (!drawingObject || !drawingObject.uuid) return;

            if (drawingObject.uuid === clickedObj.uuid) {
              return item;
            }
          }
        }
      }
    }

    return null;
  };
}

export default DrawingHelpers;
