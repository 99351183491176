import React from 'react';
import PropTypes from 'prop-types';

import Button from '../../../common/form/components/button';
import AddButton from '../../../common/add-button/components/add-button';
import Section from '../../../common/section/components/section';
import { defaultQuestionValidators } from '../../../common/question-components/constants/question-constants';
import { defaultSectionQuestionsName } from '../../../common/section/constants/section-constants';
import { FORMS } from '../../../common/constants';

const ChecklistsProceduresSecondStep = (
  {
    isLoading,
    isDisabled,
    addQuestionDisabled,
    sections,
    questionTypes,
    handleUpdateSection,
    handleAddQuestion,
    handleFieldChange,
    handleFieldDelete,
    handleFieldSort,
    handleAddSection,
    handleDeleteSection,
    handleBackButtonClick,
    handleFinishButtonClick,
    components,
    handleComponentSelected,
    handleWorkAreaAdd,
    handleUpdateTagLocation,
    handleWorkAreaUpdate,
    sectionsChanged,
    handleDeleteComponent,
    handleDeleteWorkArea,
    toggleAdditionalFields,
    handleAddQuestionOption,
    handleUpdateQuestionOption,
    handleDeleteQuestionOption,
    handleSectionSort,
    inspectionId,
    onDropAsset,
    handleDeleteSectionFile,
    sectionUpdateDebounce,
    questionUpdateDebounce,
    questionOptionUpdateDebounce,
    fetchQuestionFiles,
  },
  { t }
) => (
  <div className="checklists-procedures-management__container__second-step">
    {sections.map((section, index) => (
      <Section
        {...section}
        form={`${FORMS.sectionsForm}-checklist-procedure-template-${section.ID}`}
        key={`section-${section.ID}`}
        initialValues={section}
        sectionIndex={index}
        sectionsLength={sections.length}
        questions={section.SectionQuestions}
        sectionQuestionsName={defaultSectionQuestionsName}
        questionValidators={defaultQuestionValidators}
        questionTypes={questionTypes}
        handleSectionChange={handleUpdateSection}
        handleAddQuestion={handleAddQuestion}
        handleFieldDelete={handleFieldDelete}
        handleFieldSort={handleFieldSort}
        handleUpdateSection={handleUpdateSection}
        handleDeleteSection={handleDeleteSection}
        handleFieldChange={handleFieldChange}
        isExpandable
        deleteDisabled={sections.length <= 1}
        nonTranslatable
        components={components}
        handleComponentSelected={handleComponentSelected}
        handleWorkAreaAdd={handleWorkAreaAdd}
        handleUpdateTagLocation={handleUpdateTagLocation}
        handleWorkAreaUpdate={handleWorkAreaUpdate}
        sectionsChanged={sectionsChanged}
        handleDeleteComponent={handleDeleteComponent}
        handleDeleteWorkArea={handleDeleteWorkArea}
        toggleAdditionalFields={toggleAdditionalFields}
        handleAddQuestionOption={handleAddQuestionOption}
        handleUpdateQuestionOption={handleUpdateQuestionOption}
        handleDeleteQuestionOption={handleDeleteQuestionOption}
        handleSort={handleSectionSort}
        inspectionId={inspectionId}
        isLoading={isLoading}
        isDisabled={isDisabled}
        addQuestionDisabled={addQuestionDisabled}
        onDropAsset={onDropAsset}
        handleDeleteSectionFile={handleDeleteSectionFile}
        sections={sections}
        sectionUpdateDebounce={sectionUpdateDebounce}
        questionUpdateDebounce={questionUpdateDebounce}
        questionOptionUpdateDebounce={questionOptionUpdateDebounce}
        fetchQuestionFiles={fetchQuestionFiles}
        // Section expanded here is true as it was request by clients
        sectionExpanded
      />
    ))}
    <div className="checklists-procedures-management__container__second-step__add-button-wrapper">
      <AddButton disabled={isDisabled} onClick={handleAddSection}>
        {'SECTIONS.ADD_SECTION'}
      </AddButton>
    </div>
    <div className="checklists-procedures-management__container__second-step__buttons">
      <div className="checklists-procedures-management__container__second-step__buttons__wrapper">
        <Button
          variant="gray-outline"
          type="button"
          text={t('BACK')}
          width="sm"
          height="md"
          className="checklists-procedures-management__container__second-step__buttons__wrapper__btn"
          onClick={handleBackButtonClick}
        />
        <Button type="button" text={t('FINISH')} width="sm" height="md" onClick={handleFinishButtonClick} />
      </div>
    </div>
  </div>
);

ChecklistsProceduresSecondStep.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChecklistsProceduresSecondStep.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      SectionOrder: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      SectionQuestions: PropTypes.arrayOf(
        PropTypes.shape({
          ID: PropTypes.number,
          Name: PropTypes.string.isRequired,
          QuestionType: PropTypes.number,
          QuestionOrder: PropTypes.number,
          IsMandatory: PropTypes.bool,
          AllowMultipleAnswers: PropTypes.bool,
          EstimatedTime: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          CreatedAt: PropTypes.number,
        })
      ),
    })
  ),
  questionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      ChecklistItemType: PropTypes.string,
      ChecklistItemTypeName: PropTypes.string,
    })
  ),
  handleUpdateSection: PropTypes.func.isRequired,
  handleAddQuestion: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleFieldDelete: PropTypes.func.isRequired,
  handleFieldSort: PropTypes.func.isRequired,
  handleAddSection: PropTypes.func.isRequired,
  handleDeleteSection: PropTypes.func.isRequired,
  handleBackButtonClick: PropTypes.func.isRequired,
  handleFinishButtonClick: PropTypes.func.isRequired,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    })
  ),
  handleComponentSelected: PropTypes.func,
  sectionsChanged: PropTypes.bool,
  handleDeleteComponent: PropTypes.func.isRequired,
  toggleAdditionalFields: PropTypes.func.isRequired,
  handleAddQuestionOption: PropTypes.func.isRequired,
  handleUpdateQuestionOption: PropTypes.func.isRequired,
  handleDeleteQuestionOption: PropTypes.func.isRequired,
  handleSectionSort: PropTypes.func.isRequired,
  onDropAsset: PropTypes.func.isRequired,
  handleDeleteSectionFile: PropTypes.func.isRequired,
};

ChecklistsProceduresSecondStep.defaultProps = {
  isLoading: false,
  isDisabled: false,
  sections: [],
  questionTypes: [],
};

export default ChecklistsProceduresSecondStep;
