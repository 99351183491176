import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { find, isEmpty, map } from 'lodash';
import { FEATURES } from '../../../../common/constants';

import Modal from '../../../../common/modal/components/modal';
import ExternalUploadModal from './external-upload-modal';
import UploadModal from './upload-modal';

import Loader from '../../../../common/global-loader/components/simple-loader';
import Tab from '../../../../common/tabs/component/tab';
import Tabs from '../../../../common/tabs/component/tabs';
import LinkedImagesSliderSlider from '../../../start-workflow/components/linked-images-slider';
import ComponentForm from './component-form';
import ComponentHistory from './component-history';
import ComponentPDF from './component-pdf';

import { ReactComponent as PDFIcon } from '../../../../common/assets/pdf-icon.svg';
import { validate } from './validators/component-validator';

import { change, updateSyncWarnings } from 'redux-form';
import CommentsTab from '../../../../common/comments-tab/components/comments-tab';
import { commentFields } from '../../../../common/comments-tab/constants/constants';
import { FORMS } from '../../../../common/constants';
import DrawingHelpers from '../../../../common/drawing-helpers';
import Helpers from '../../../../common/helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import WorkTabs from '../../../../common/work-tab/components/work-tab';
import ActionModal from '../../../document-management/components/modals/action-modal/action-modal';
import { DMSCategoriesModules } from '../../../document-management/constants/constants';
import { setPdfComponents } from '../../../pdf-tag/actions/action-creators';
import { getPdfComponentsAndChangePageBySelectedComponent, getPdfComponentsForPopup } from '../../../pdf-tag/actions/pdf-tag-actions';
import { componentFields, componentFilterValues } from '../../../pdf-tag/constants/constants';
import { fetchDMSCategories } from '../../../project/actions/project-actions';
import DeleteComponentModal from '../../../start-workflow/components/delete-component-modal';
import orientationConstants from '../../../start-workflow/constants/orientation-constants';
import { getComponentDMSFilesUploaded, getComponentFilesUploaded } from '../../../upload/actions/external-upload-actions';
import { setSingleUploadItem, setUploadItems, uploadAsset } from '../../../upload/actions/upload-actions';
import { setComponentFormState, setComponentLocationEditing, setComponentsFilters, setUnsavedChangesDirty } from '../../actions/action-creators';
import {
  addComponentComment,
  closeInspectionModal,
  deleteComponentComment,
  deleteComponentFile,
  deleteDefect,
  fetchCommentUsersAndTeams,
  fetchComponentComments,
  getComponentLinkedDefects,
  getDefectDetails,
  maximizeInspectionModal,
  minimizeInspectionModal,
  openAsSeparatePageInspectionModal,
  toggleDeleteDefectModal,
  toggleInspectionModal,
  updateComponentProperties,
} from '../../actions/inspection-actions';
import { DefaultComponent, filterParams, formConstants, preventedFieldsPriorComponentIsCreated, tabNames, toolbarItems, workTabs } from '../../constants/component-constants';
import { measurementTypes, modules } from '../../constants/constants';
import { supportedMimeTypes } from '../../constants/upload-constants';
import { isDefaultComponent } from '../../helpers/inspection-helper';
import '../../styles/component-details.scss';
import '../../styles/component-files-modal.scss';
import CriticalEquipmentInfo from './common/critical-equipment-info';
import ModuleHeader from './common/module-header';

class ComponentDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalData: {
        isOpen: false,
      },
      deleteModalData: {
        isOpen: false,
      },
      activeToolbarItem: toolbarItems[tabNames.details].name,
      selectedComponent: {},
      commentsList: [],
      commentsLoading: false,
      addCommentLoading: false,
      commentUsersList: [],
      commentTeamsList: [],
      commentUsersAndTeamsLoading: false,
      elementAlreadyFocused: false,
      isSelectedComponentDefaultComponent: false,
    };
  }

  componentDidMount() {
    const { fetchDMSCategories, projectId, queryItem } = this.props;
    fetchDMSCategories(projectId, DMSCategoriesModules.components);

    if (!queryItem || queryItem < 0) {
      this.setState({ elementAlreadyFocused: true });
      return;
    }

    this.fetchData(tabNames.details);
  }
  componentDidUpdate = prevProps => {
    const { queryItem, viewer, inspectionDetails, setComponentLocationEditing, handleActivePage } = this.props;
    const { selectedComponent } = this.state;

    // check if the selected equipment is the default equipment, and if so - reroute to Equipment module
    if (!isEmpty(selectedComponent) && inspectionDetails && handleActivePage) {
      const isSelectedComponentDefaultComponent = this.isCurrentEquipmentDefault();
      if (isSelectedComponentDefaultComponent) {
        this.setState({ selectedComponent: {}, isSelectedComponentDefaultComponent: true }); // reset the state so that re-rendering is ommited after the handleActivePage
        handleActivePage(modules.components); // re-route to Equipment module
        return; // stop further execution of the code
      }
    }
    // reset state when Create new component is clicked
    if (prevProps.queryItem !== queryItem && queryItem && queryItem < 0) {
      setComponentLocationEditing(false);
      this.setState({ selectedComponent: {} });
    }
    if (queryItem && queryItem > 0 && (prevProps.queryItem !== queryItem || viewer !== prevProps.viewer)) {
      this.fetchData(tabNames.details);
    }
  };

  isCurrentEquipmentDefault = () => {
    const { inspectionDetails } = this.props;
    const { selectedComponent } = this.state;
    const { DefaultComponent } = inspectionDetails;
    const inspectionDefaultComponentId = DefaultComponent;
    return isDefaultComponent(selectedComponent, inspectionDefaultComponentId);
  };

  getComponentLocation = () => {
    const { componentsClustered, queryItem } = this.props;
    const selectedComponentClustered = find(componentsClustered, item => item[formConstants.fields.id] === queryItem);
    return Helpers.getModuleLocationObject(selectedComponentClustered);
  };

  fetchData = tabName => {
    const { elementAlreadyFocused } = this.state;
    const { getDefectDetails, queryItem, isPdfScreen, viewer, setComponentLocationEditing, setUnsavedChangesDirty } = this.props;

    const componentDetails = { ID: queryItem, [formConstants.fields.systemType]: measurementTypes.component };
    // sets the newly created defect as temp to handle rest of the comments logic in getDefectDetails method
    this.setState({ activeToolbarItem: tabName });
    if (queryItem < 1) componentDetails[componentFields.isTemp] = true;
    if (tabName === tabNames.details) {
      setUnsavedChangesDirty(false);
      setComponentLocationEditing(false);
      getDefectDetails(
        componentDetails,
        c => {
          this.setState({ selectedComponent: c });
          this.getUploadedFiles();
          if (c?.Geometry?.coordinates?.[0] && c?.CameraPosition?.coordinates && viewer && !elementAlreadyFocused) {
            viewer.zoomToPosition({ x: c.CameraPosition.coordinates[0], y: c.CameraPosition.coordinates[1], z: c.CameraPosition.coordinates[2] }, c.Geometry.coordinates, 500);
            this.setState({ elementAlreadyFocused: true });
          }
        },
        {},
        true,
        !isPdfScreen
      );
    }
  };

  getUploadedFiles = () => {
    const { getComponentDMSFilesUploaded, queryItem } = this.props;
    if (queryItem && queryItem > 0) {
      getComponentDMSFilesUploaded(queryItem);
    }
  };

  handleOnChange = (values, _b, c, oldValues) => {
    const { updateSyncWarnings, setComponentFormState, setUnsavedChangesDirty } = this.props;
    setComponentFormState({ hasUnsavedChanges: c.dirty });
    setUnsavedChangesDirty(c.dirty);
    // Checks if old value of critical is changed, due to pristine being true when toggle is returned to first state and then not calling API
    const isCriticalChanged = oldValues?.[formConstants.fields.critical] && oldValues?.[formConstants.fields.critical] !== values?.[formConstants.fields.critical];
    const isCodeChanged = oldValues?.[formConstants.fields.code] && oldValues?.[formConstants.fields.code] !== values?.[formConstants.fields.code];
    if (!c.pristine || isCriticalChanged || isCodeChanged) {
      updateSyncWarnings(FORMS.componentForm, {});
    }
  };

  submitForm = values => {
    const { requestInProgress } = this.props;
    if (requestInProgress) return;
    const errors = validate(values);
    const isValid = isEmpty(errors);
    const { updateDefect, createComponent, isSavingComponent, defaultComponent, inspectionId, updateComponentProperties, formUnsavedCustomProps, setComponentFormState, setUnsavedChangesDirty } =
      this.props;
    const componentDetails = values[componentFields.componentId] && values[componentFields.componentId] > 0 ? values : defaultComponent;
    const { comments, ...defectValues } = componentDetails;

    if (isValid && !isSavingComponent && (!componentDetails[componentFields.componentId] || componentDetails[componentFields.componentId] === -2)) {
      const defaultComponent = DefaultComponent();
      createComponent({ ...defectValues, ...defaultComponent, [componentFields.name]: values.Name, [componentFields.componentType]: values.Type, [componentFields.inspectionId]: inspectionId });
    } else {
      const { Geometry, ...otherValues } = defectValues;
      const preparedCustomProps = formUnsavedCustomProps.map(prop => {
        if (typeof prop[formConstants.fields.id] === 'string') {
          delete prop[formConstants.fields.id];
        }
        return prop;
      });
      setUnsavedChangesDirty(false);
      setComponentFormState({ requestInProgress: true });
      updateDefect(otherValues);
      updateComponentProperties(preparedCustomProps, () => {
        setComponentFormState({ hasUnsavedChanges: false, requestInProgress: false, hasUnsavedCustomProps: false, unsavedCustomProps: [] });
      });
      this.setSelectedComponentData(componentDetails);
    }
  };

  openUploadModal = (onLocalUpload, uploadGroup) => {
    const { openExternalUploadModal } = this;
    const { defect } = this.props;
    const { selectedComponent } = this.state;

    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: true,
        CustomContent: () => (
          <UploadModal
            closeAction={this.closeUploadModal}
            onDrop={files => {
              onLocalUpload(files, selectedComponent || defect);
            }}
            onExternalUpload={() => openExternalUploadModal(uploadGroup)}
            supportedMimeTypes={supportedMimeTypes}
            className="project-dropzone"
            defect={defect}
          />
        ),
        type: 'none',
        confirmAction: this.closeUploadModal,
        closeAction: this.closeUploadModal,
      },
    }));
  };

  openExternalUploadModal = uploadGroup => {
    const { defect } = this.props;
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: true,
        CustomContent: () => <ExternalUploadModal closeAction={this.closeUploadModal} defect={defect} uploadGroup={uploadGroup} />,
        type: 'none',
        closeAction: this.closeUploadModal,
      },
    }));
  };

  handleInputDisabled = type => {
    let content = '';

    switch (type) {
      case preventedFieldsPriorComponentIsCreated.upload:
        content = 'CREATE_COMPONENT.FILL_OTHER_FIELDS';
        break;
      case preventedFieldsPriorComponentIsCreated.customFields:
        content = 'CREATE_COMPONENT.FILL_OTHER_FIELDS_CUSTOM_PROP';
        break;
      default:
        content = 'CREATE_COMPONENT.FILL_OTHER_FIELDS_CUSTOM_PROP';
        break;
    }

    const { t } = this.context;
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: true,
        content: t(content),
        type: 'ok',
        closeAction: this.closeUploadModal,
        confirmAction: this.closeUploadModal,
      },
    }));
  };

  closeUploadModal = () => {
    const { setUploadItems } = this.props;
    setUploadItems(null);
    this.getUploadedFiles();
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: false,
      },
    }));
  };

  closeDeleteFileModal = () => {
    this.setState({ deleteModalData: { isOpen: false }, modalData: { isOpen: false } });
  };

  openDeleteFileModal = (fileID, fileName, categoryID) => {
    const { t } = this.context;
    const { deleteFile, queryItem } = this.props;
    const deleteModalData = {
      isOpen: true,
      firstParagraph: t('COMPONENT_DETAILS.DELETE_FILE_DESC', { fileName }),
      title: t('COMPONENT_DETAILS.DELETE_FILE_TITLE'),
      type: '',
      confirmButtonText: t('UNLINK FILE'),
      closeButtonText: t('CANCEL'),
      customConfirmAction: () => deleteFile(fileID, queryItem, this.closeDeleteFileModal, categoryID),
      customCloseAction: this.closeDeleteFileModal,
      customClassName: 'work-areas-action__delete',
      CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
    };

    this.setState({ deleteModalData });
  };

  openImagesModal = (selectedImage, linkedImages) => {
    const { setOrientation, user } = this.props;
    const { selectedComponent } = this.state;
    let index = 0;
    linkedImages = map(linkedImages, (item, i) => {
      item.src = Helpers.getUploadImageSource(item.FileName, item.URL);
      if (selectedImage.FileID === item.FileID) {
        index = i;
      }
      return item;
    });

    this.setState(prevState => ({
      modalData: {
        ...prevState.modalData,
        customClassName: 'linked-images-slider',
        CustomContent: () => (
          <LinkedImagesSliderSlider
            linkedImages={linkedImages}
            currentSlideIndex={index}
            setOrientation={setOrientation}
            disabledOrientations={[orientationConstants.orientation.chart]}
            isPreviewModal={true}
            openDeleteFileModal={this.openDeleteFileModal}
            hasUnlinkAccess={{
              user,
              id: selectedComponent[formConstants.fields.createdById],
              ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.components].create.name,
              visibleFor: [PERMISSIONS[PERMISSION_TYPES.components].edit.name],
            }}
          />
        ),
        isOpen: true,
        type: 'none',
        closeAction: () => this.setState({ modalData: { isOpen: false } }),
      },
    }));
  };

  selectComponentFromDrawing = (clickedObj, elementAlreadyFocused) => {
    const { activeTab, handleActivePage, pdfComponents } = this.props;
    const comp = DrawingHelpers.findComponentByDrawingGuid(pdfComponents, clickedObj);

    if (isEmpty(comp) || activeTab !== modules.components) {
      // if object is empty or active tab is not component
      return;
    }

    //redirect
    this.setState({ elementAlreadyFocused: false });

    handleActivePage(modules.components, comp[componentFields.componentId]);
  };

  openFile = (file, imageFiles) => {
    const { t } = this.context;
    const { selectedComponent } = this.state;
    const {
      toggleInspectionModal,
      inspectionModalData,
      inspectionId,
      getPdfComponentsForPopup,
      elementAlreadyFocused,
      isPdfScreen,
      setPdfComponents,
      closeInspectionModal,
      minimizeInspectionModal,
      maximizeInspectionModal,
      underlyingFileId,
      openAsSeparatePageInspectionModal,
      getPdfComponentsAndChangePageBySelectedComponent,
    } = this.props;

    if (file.isPDF) {
      getPdfComponentsForPopup(
        inspectionId,
        file.FileID,
        // sets default filters and pageNumber to 1
        {
          SearchText: '',
          ComponentFilter: componentFilterValues.all,
        },
        1,
        selectedComponent[formConstants.fields.id],
        d => {
          const confirmedDrawings = (d || []).filter(cDrawing => cDrawing[componentFields.confirmed]);
          setPdfComponents(confirmedDrawings);
          // open regular modal for tools since inspectionModal works only on MODULES level, but not on Tools modules
          // to check if we are on Tools part of the app we use isPdfScreen property
          if (isPdfScreen) {
            this.setState(prevState => ({
              modalData: {
                ...prevState.modalData,
                isOpen: true,
                title: t('PDF_VIEWER.MODAL.TITLE'),
                customClassName: 'modal-large',
                closeAction: () => {
                  /**
                   * re-fetch components for the underlying PDF so as to update drawings on the PDF viewer
                   * as they are overwritten by the data from the file that is opened
                   */
                  getPdfComponentsForPopup(
                    inspectionId,
                    // fetching the drawings for underlying opened PDF
                    underlyingFileId || file.FileID,
                    // sets default filters and pageNumber to 1
                    {
                      SearchText: '',
                      ComponentFilter: componentFilterValues.all,
                    },
                    1,
                    selectedComponent ? selectedComponent[componentFields.componentId] : null,
                    selectedComponent[formConstants.fields.id],
                    newDrawings => setPdfComponents(newDrawings)
                  );
                  this.setState({ modalData: { isOpen: false } });
                },
                type: '',
                CustomContent: props => (
                  <ComponentPDF
                    file={file}
                    selectComponent={clickedObj => this.selectComponentFromDrawing(clickedObj, elementAlreadyFocused)}
                    rightCollapsed={true}
                    inspectionId={inspectionId}
                    // when PDF opened in popup from module details or DMS, we show only confirmed tags/drawings
                    showOnlyConfirmedDrawings={true}
                    {...props}
                  />
                ),
              },
            }));
          } else {
            getPdfComponentsAndChangePageBySelectedComponent(
              inspectionId,
              file.FileID,
              {
                SearchText: '',
                ComponentFilter: componentFilterValues.all,
              },
              selectedComponent,
              true,
              () => {
                toggleInspectionModal({
                  ...inspectionModalData,
                  isOpen: true,
                  isMinimized: false,
                  FileIcon: props => <PDFIcon height={18} {...props} />,
                  fileName: file.name,
                  title: t('PDF_VIEWER.MODAL.TITLE'),
                  customClassName: 'modal-large',
                  type: '',
                  closeAction: closeInspectionModal,
                  minimizeAction: () =>
                    getPdfComponentsAndChangePageBySelectedComponent(
                      inspectionId,
                      file.FileID,
                      {
                        SearchText: '',
                        ComponentFilter: componentFilterValues.all,
                      },
                      selectedComponent,
                      true,
                      () => {
                        minimizeInspectionModal();
                      }
                    ),
                  maximizeAction: () =>
                    getPdfComponentsAndChangePageBySelectedComponent(
                      inspectionId,
                      file.FileID,
                      {
                        SearchText: '',
                        ComponentFilter: componentFilterValues.all,
                      },
                      selectedComponent,
                      true,
                      () => {
                        maximizeInspectionModal();
                      }
                    ),
                  separatePageAction: () =>
                    getPdfComponentsAndChangePageBySelectedComponent(
                      inspectionId,
                      file.FileID,
                      {
                        SearchText: '',
                        ComponentFilter: componentFilterValues.all,
                      },
                      selectedComponent,
                      true,
                      () => {
                        openAsSeparatePageInspectionModal();
                      }
                    ),
                  CustomContent: props => (
                    <ComponentPDF
                      file={file}
                      selectComponent={clickedObj => this.selectComponentFromDrawing(clickedObj, elementAlreadyFocused)}
                      rightCollapsed={true}
                      // when PDF opened in popup from module details or DMS, we show only confirmed tags/drawings
                      showOnlyConfirmedDrawings={true}
                      {...props}
                    />
                  ),
                });
              }
            );
          }
        }
      );
    } else if (file.isImage) {
      const images = isEmpty(imageFiles) ? [file] : imageFiles;
      this.openImagesModal(file, images);
    } else {
      Helpers.getFileExtensionAndDownload(file);
    }
  };

  fetchComponentCommentsHandler = () => {
    const { fetchComponentComments, queryItem } = this.props;

    // do not fetch comments if component has no ID (fix for create new component)
    if (!queryItem || queryItem < 0) return;

    const onSuccessFetch = newState => {
      this.setState({ commentsList: newState.commentsList, commentsLoading: newState.commentsLoading });

      if (newState.commentsList) {
        Helpers.scrollIntoView('comments-list-wrapper', `comment-${newState.commentsList.length - 1}`, 0);
      }
    };

    fetchComponentComments({ [commentFields.componentId]: queryItem }, onSuccessFetch);
  };

  addComponentCommentHandler = (commentValue, commentTags, resetCommentInput, scrollIntoView) => {
    const { addComponentComment, queryItem, inspectionId, projectId } = this.props;
    const commentParams = { InspectionID: inspectionId, ProjectID: projectId, [commentFields.tags]: commentTags, [commentFields.moduleItemID]: queryItem, Comment: commentValue };
    addComponentComment(
      commentParams,
      () => this.fetchComponentCommentsHandler(),
      loading => this.setState({ addCommentLoading: loading })
    );
    resetCommentInput();
    scrollIntoView();
  };

  deleteComponentCommentHandler = comment => {
    const { deleteComponentComment, queryItem } = this.props;
    const commentParams = { [commentFields.moduleCommentID]: queryItem, CommentID: comment[commentFields.id] };
    deleteComponentComment(
      commentParams,
      () => this.fetchComponentCommentsHandler(),
      loading => this.setState({ addCommentLoading: loading })
    );
  };

  searchUserAndTeamsHandler = searchTerm => {
    const { fetchCommentUsersAndTeams } = this.props;
    fetchCommentUsersAndTeams(
      searchTerm,
      (usersList, teamsList) => this.setState({ commentUsersList: usersList, commentTeamsList: teamsList }),
      loading =>
        this.setState({
          commentUsersAndTeamsLoading: loading,
        })
    );
  };

  setSelectedComponentData = selectedComponent => this.setState({ selectedComponent });

  openDeleteComponentModal = () => {
    const { t } = this.context;
    const { getComponentLinkedDefects, toggleDeleteModal, deleteDefect, handleActivePage, componentFilters, setComponentsFilters, customDeleteCallback } = this.props;
    const {
      selectedComponent,
      selectedComponent: { ID },
    } = this.state;

    const deleteComponentCallback = () => {
      setComponentsFilters({ ...componentFilters, [filterParams.totalItems]: componentFilters[filterParams.totalItems] - 1 });
      // custom delete callback is for the pdf tag details screen,
      // also it helps to clear the element from array on left hand side table on the pdf screen
      customDeleteCallback && customDeleteCallback({ ComponentID: ID });
    };

    //if defect is not created in DB just delete id
    if (!ID || ID < 0) {
      deleteDefect({ ComponentID: selectedComponent[componentFields.componentId], SystemType: selectedComponent.SystemType }, deleteComponentCallback, true);
    } else {
      //fetch affected defects
      getComponentLinkedDefects(ID, componentLinkedDefects => {
        const deleteComponentModalData = {
          isOpen: true,
          CustomContent: () => (
            <DeleteComponentModal
              componentLinkedDefects={componentLinkedDefects}
              deleteText={t('DELETE_COMPONENT_MODAL.MAIN_TEXT')}
              deleteTextWithDefects={t('DELETE_COMPONENT_MODAL.TEXT_WITH_DEFECTS')}
            />
          ),
          type: 'yes-no',
          title: t('DELETE_COMPONENT_MODAL.TILTE'),
          closeAction: this.closeDeleteDefectModal,
          confirmAction: () =>
            deleteDefect({ ComponentID: selectedComponent[componentFields.componentId], SystemType: selectedComponent.SystemType }, () => {
              handleActivePage && handleActivePage(modules.components);
              deleteComponentCallback();
            }),
        };

        toggleDeleteModal(deleteComponentModalData);
      });
    }
  };

  closeDeleteDefectModal = () => {
    const { toggleDeleteModal } = this.props;
    const modalData = {
      isOpen: false,
    };
    toggleDeleteModal(modalData);
  };

  handleCustomPropertyUpdate = property => {
    const { formUnsavedCustomProps, setComponentFormState } = this.props;
    const newUnsavedCustomProps = formUnsavedCustomProps || [];
    const positionOfProperty = newUnsavedCustomProps.findIndex(prop => prop[formConstants.fields.id] === property[formConstants.fields.id]);
    if (newUnsavedCustomProps[positionOfProperty]) {
      newUnsavedCustomProps[positionOfProperty] = property;
    } else {
      newUnsavedCustomProps.push(property);
    }
    setComponentFormState({ unsavedCustomProps: newUnsavedCustomProps, hasUnsavedCustomProps: true });
  };

  render() {
    const {
      modalData,
      deleteModalData,
      activeToolbarItem,
      selectedComponent,
      commentsList,
      commentsLoading,
      addCommentLoading,
      commentUsersList,
      commentTeamsList,
      commentUsersAndTeamsLoading,
      isSelectedComponentDefaultComponent,
    } = this.state;
    const {
      deleteDefectModalData,
      uploadInProgress,
      objectToolClick,
      duplicateComponent,
      customComponent,
      showGeometryWarning,
      handleActivePage,
      componentDetailsLoading,
      viewer,
      user,
      changeField,
      customDeleteCallback,
      inspectionId,
      queryItem,
      setComponentsFilters,
      componentFilters,
      toggleElement,
      formHasUnsavedChanges,
      setComponentFormState,
      componentsClustered,
    } = this.props;
    const { openUploadModal, openFile } = this;

    const menuOptions = [
      {
        title: 'COMPONENT_DETAILS.DUPLICATE_COMPONENT',
        action: () =>
          duplicateComponent(selectedComponent[componentFields.componentId], id => {
            handleActivePage(modules.components, id);
            setComponentsFilters({ ...componentFilters, [filterParams.totalItems]: componentFilters[filterParams.totalItems] + 1 });
          }),
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.components].edit.name,
          ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.components].create.name,
          id: selectedComponent[formConstants.fields.createdByUserID],
        },
      },
      {
        title: 'COMPONENT_DETAILS.FORM_DELETE_TEXT',
        action: this.openDeleteComponentModal,
        access: {
          visibleFor: PERMISSIONS[PERMISSION_TYPES.components].delete.name,
        },
        separator: true,
        isHighlighted: true,
      },
    ];

    if (componentDetailsLoading) {
      return <Loader isLoading={true} />;
    }

    const selectedComponentClustered = find(componentsClustered, item => item[formConstants.fields.id] === queryItem);

    return (
      <div className="component-details">
        <Tabs
          defaultTabKey={activeToolbarItem}
          navigationClassName="component-details__tabs"
          onChange={this.fetchData}
          // TODO: menuOptions={isSelectedComponentDefaultComponent ? restOfTheCode} most likely can be removed, since we reroute from this component if true
          tabsHeader={activeToolbarItem === tabNames.details ? <ModuleHeader id={queryItem} menuOptions={isSelectedComponentDefaultComponent ? [] : menuOptions} /> : null}
          formHasUnsavedChanges={formHasUnsavedChanges}
          setFormUnsavedChanges={setComponentFormState}
        >
          <Tab title={toolbarItems[tabNames.details].label} tabKey={tabNames.details}>
            <>
              {selectedComponent[componentFields.critical] && <CriticalEquipmentInfo title={'CRITICAL_EQUIPMENT.TITLE'} paragraph={'CRITICAL_EQUIPMENT_COMPONENTS.PARAGRAPH'} />}
              {customComponent && customComponent()}
              <ComponentForm
                showGeometryWarning={showGeometryWarning}
                onChange={this.handleOnChange}
                openUploadModal={openUploadModal}
                handleInputDisabled={this.handleInputDisabled}
                objectToolClick={objectToolClick}
                selectedComponent={selectedComponent}
                openFile={openFile}
                viewer={viewer}
                initialValues={selectedComponent}
                handleActivePage={handleActivePage}
                changeField={changeField}
                customDeleteCallback={customDeleteCallback}
                openDeleteFileModal={this.openDeleteFileModal}
                onSubmit={this.submitForm}
                handleCustomPropertyUpdate={this.handleCustomPropertyUpdate}
                locationObject={{
                  ...Helpers.getModuleLocationObject({
                    ...selectedComponent,
                    Geometry: selectedComponentClustered?.Geometry || selectedComponent?.Geometry,
                    CameraPosition: selectedComponentClustered?.CameraPosition || selectedComponent?.CameraPosition,
                  }),
                  visible: selectedComponentClustered?.visible || false,
                }}
                isDefaultComponent={isSelectedComponentDefaultComponent} // TODO: this most likely can be removed, since we reroute from this component if true
                toggleElement={toggleElement}
              />
              <Modal {...deleteDefectModalData} />
            </>
          </Tab>
          <Tab title={toolbarItems[tabNames.work].label} tabKey={tabNames.work} visible={FEATURES.components.tabs.work.visible}>
            <WorkTabs tabs={workTabs} selectedComponent={selectedComponent} inspectionId={inspectionId} queryItem={queryItem} />
          </Tab>
          <Tab title={toolbarItems[tabNames.comments].label} tabKey={tabNames.comments}>
            <CommentsTab
              commentsList={commentsList}
              commentsLoading={commentsLoading}
              addCommentLoading={addCommentLoading}
              fetchCommentsList={this.fetchComponentCommentsHandler}
              onAddCommentClick={this.addComponentCommentHandler}
              onDeleteCommentClick={this.deleteComponentCommentHandler}
              fetchCommentUsersAndTeams={this.searchUserAndTeamsHandler}
              commentUsersList={commentUsersList}
              commentTeamsList={commentTeamsList}
              commentUsersAndTeamsLoading={commentUsersAndTeamsLoading}
              user={user}
              addCommentPermission={PERMISSIONS[PERMISSION_TYPES.components].addComment.name}
              addCommentDisabled={!selectedComponent[formConstants.fields.id] || selectedComponent[formConstants.fields.id] < 1}
            />
          </Tab>
          {FEATURES.modulesHistory?.visible && (
            <Tab title={toolbarItems[tabNames.history].label} tabKey={tabNames.history}>
              <ComponentHistory componentID={selectedComponent[formConstants.fields.id]} />
            </Tab>
          )}
        </Tabs>

        <Modal {...modalData} modalDisabled={uploadInProgress} />
        <Modal {...deleteModalData} />
      </div>
    );
  }
}
ComponentDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

ComponentDetails.defaultProps = {
  showGeometryWarning: true,
  isPdfScreen: false,
};

const mapStateToProps = state => ({
  deleteDefectModalData: state.inspectionReducer.delDefectModalData,
  uploadInProgress: state.uploadReducer.uploadInProgress,
  inspectionModalData: state.inspectionReducer.inspectionModalData,
  isSavingComponent: state.inspectionReducer.isSavingComponent,
  activeTab: state.inspectionReducer.activeLeftSidebar,
  componentDetailsLoading: state.inspectionReducer.componentDetailsLoading,
  user: state.userReducer,
  pdfComponents: state.pdfTagReducer.pdfComponents,
  pdfPageNumber: state.pdfTagReducer.pdfPageNumber,
  componentFilters: state.inspectionReducer.componentsFilter,
  formUnsavedCustomProps: state.inspectionReducer.componentFormState.unsavedCustomProps,
  requestInProgress: state.inspectionReducer.componentFormState.requestInProgress,
  componentsClustered: state.inspectionReducer.componentsClustered,
  inspectionDetails: state.inspectionReducer.inspectionDetails,
  formHasUnsavedChanges: state.inspectionReducer.componentFormState.hasUnsavedChanges,
});

const mapDispatchToProps = dispatch => ({
  setUploadItems: files => dispatch(setUploadItems(files)),
  getComponentFilesUploaded: id => dispatch(getComponentFilesUploaded(id)),
  getComponentDMSFilesUploaded: id => dispatch(getComponentDMSFilesUploaded(id)),
  toggleInspectionModal: data => dispatch(toggleInspectionModal(data)),
  closeInspectionModal: () => dispatch(closeInspectionModal()),
  minimizeInspectionModal: () => dispatch(minimizeInspectionModal()),
  maximizeInspectionModal: () => dispatch(maximizeInspectionModal()),
  getPdfComponentsAndChangePageBySelectedComponent: (inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback) =>
    dispatch(getPdfComponentsAndChangePageBySelectedComponent(inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback)),
  openAsSeparatePageInspectionModal: () => dispatch(openAsSeparatePageInspectionModal()),
  getPdfComponentsForPopup: (inspectionID, fileId, filter, pageNumber, compId, callback) => dispatch(getPdfComponentsForPopup(inspectionID, fileId, filter, pageNumber, compId, callback)),
  getDefectDetails: (element, callback, legacyProps, includeLoader, saveItem) => dispatch(getDefectDetails(element, callback, legacyProps, includeLoader, saveItem)),
  setSingleUploadItem: (progress, index) => dispatch(setSingleUploadItem(progress, index)),
  uploadAsset: (file, IDs, callbackFunction, index) => dispatch(uploadAsset(file, IDs, callbackFunction, index)),
  updateSyncWarnings: (form, warnings) => dispatch(updateSyncWarnings(form, warnings)),
  fetchComponentComments: (params, callback) => dispatch(fetchComponentComments(params, callback)),
  addComponentComment: (params, dataCallback, loadingCallback) => dispatch(addComponentComment(params, dataCallback, loadingCallback)),
  deleteComponentComment: (params, dataCallback, loadingCallback) => dispatch(deleteComponentComment(params, dataCallback, loadingCallback)),
  fetchCommentUsersAndTeams: (searchTerm, dataCallback, loadingCallback) => dispatch(fetchCommentUsersAndTeams(searchTerm, dataCallback, loadingCallback)),
  changeField: (fieldName, value) => dispatch(change(FORMS.componentForm, fieldName, value)),
  setComponentLocationEditing: val => dispatch(setComponentLocationEditing(val)),
  setPdfComponents: selectedModuleItemDrawings => dispatch(setPdfComponents(selectedModuleItemDrawings)),
  deleteFile: (fileID, componentID, callback, categoryID) => dispatch(deleteComponentFile(fileID, componentID, callback, categoryID)),
  fetchDMSCategories: (projectId, moduleName, callback) => dispatch(fetchDMSCategories(projectId, moduleName, callback)),
  setComponentsFilters: filters => dispatch(setComponentsFilters(filters)),
  getComponentLinkedDefects: (id, callback) => dispatch(getComponentLinkedDefects(id, callback)),
  toggleDeleteModal: data => dispatch(toggleDeleteDefectModal(data)),
  deleteDefect: (data, callback, deleteOnlyInternal) => dispatch(deleteDefect(data, callback, deleteOnlyInternal)),
  setComponentFormState: state => dispatch(setComponentFormState(state)),
  updateComponentProperties: (props, callback) => dispatch(updateComponentProperties(props, callback)),
  setUnsavedChangesDirty: data => dispatch(setUnsavedChangesDirty(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComponentDetails);
