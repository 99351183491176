import { find, isEmpty } from 'lodash';

import { formFields } from '../constants/section-constants';
import { defaultOptionsFieldName, defaultQuestionName, questionOptionsFields } from '../../question-components/constants/question-constants';

export const validate = (values, props) => {
  let errors = {};
  const sectionQuestionsName = props?.sectionQuestionsName;
  const questionValidators = props?.questionValidators;
  const sections = props?.sections;

  errors = { ...errors, ...validateSection(values, sections) };

  if (values[sectionQuestionsName] && questionValidators) {
    const questionErrors = [];

    values[sectionQuestionsName].forEach((member, index) => {
      const error = validateQuestion(member, props);
      questionErrors[index] = error;
    });

    if (questionErrors.length) {
      errors[sectionQuestionsName] = questionErrors;
    }
  }

  return errors;
};

export const validateSection = (values, sections) => {
  const errors = {};
  const { name } = formFields;

  if (!values[name.name]) {
    errors[name.name] = name.errorRequired;
  }

  if (values[name.name] && values[name.name].length < name.minChars) {
    errors[name.name] = name.errorMinChars;
  }

  if (values[name.name] && values[name.name].length > name.maxChars) {
    errors[name.name] = name.errorMaxChars;
  }

  if (values[name.name] && sections && sections.length > 0) {
    const section = find(sections, item => {
      return item.Locked && item[name.name].toLowerCase().trim() === values[name.name].toLowerCase().trim();
    });

    if (!isEmpty(section)) {
      errors[name.name] = name.errorExistingName;
    }
  }

  return errors;
};

export const validateQuestion = (value, props) => {
  const errors = {};
  const questionValidators = props?.questionValidators;
  const questionId = props?.question?.ID;
  const options = props?.question?.QuestionOptions;
  const isSingleCheck = props?.isSingleCheck;

  for (let key in questionValidators) {
    const defaultFieldName = `${defaultQuestionName}-${key}-${questionId}`;
    const fieldName = isSingleCheck ? key : defaultFieldName;

    if (questionValidators[key].required && questionValidators[key].errorRequired && (!value || !value[fieldName])) {
      errors[defaultFieldName] = questionValidators[key].errorRequired;
    }

    if (questionValidators[key].minChars && questionValidators[key].errorMinChars && value && value[fieldName] && value[fieldName].length < questionValidators[key].minChars) {
      errors[defaultFieldName] = questionValidators[key].errorMinChars;
    }

    if (questionValidators[key].maxChars && questionValidators[key].errorMaxChars && value && value[fieldName] && value[fieldName].length > questionValidators[key].maxChars) {
      errors[defaultFieldName] = questionValidators[key].errorMaxChars;
    }

    if (options && options.length > 0) {
      for (let i = 0; i < options.length; i++) {
        const defaultOptionName = `${defaultOptionsFieldName}-${questionOptionsFields.name.name}-${options[i].ID}`;
        const optionName = isSingleCheck ? questionOptionsFields.name.name : defaultOptionName;

        if (!value[optionName]) {
          errors[defaultOptionName] = questionOptionsFields.name.errorRequired;
        }

        if (value[optionName] && questionOptionsFields.name.maxChars && value[optionName].length && value[optionName].length > questionOptionsFields.name.maxChars) {
          errors[optionName] = questionOptionsFields.name.errorMaxChars;
        }
      }
    }
  }

  return errors;
};

export const questionsValidator = (values, props) => {
  const { questionValidators, sectionQuestions } = props;
  let errors = {};

  if (sectionQuestions && sectionQuestions.length > 0) {
    for (let i = 0; i < sectionQuestions.length; i++) {
      errors = { ...errors, ...validateQuestion(values, { questionValidators, question: sectionQuestions[i] }) };
    }
  }

  return errors;
};
