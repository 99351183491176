import { remove, findIndex, cloneDeep, isEmpty, pick, get } from 'lodash';

import checklistsProceduresApi from '../../../api/checklists-procedures-api/actions';
import inspectionApi from '../../../api/inspection/actions';
import { fields } from '../constants/checklists-procedures-constants';
import { additionalFields, questionOptionsFields } from '../../../common/question-components/constants/question-constants';
import { formatQuestionTypes } from '../../../common/question-components/helpers/question-field-helpers';
import { timeToMilliseconds } from '../../../common/section/helpers/section-helpers';
import { getSectionAndQuestionDetails, formatSections } from '../helpers/checklists-procedures-management-helpers';
import { fields as customPropertiesConstants } from '../../../common/advanced-filter-custom-property/constants/constants';
import ReducerHelpers from '../../../common/reducer-helpers';
import Helpers from '../../../common/helpers';
import { setChecklistQuestionFilesUploaded } from '../../upload/actions/action-creators';
import genericFileImage from '../../inspections/assets/component-generic-file.svg';

export const createChecklistProcedure = (values, callback) => {
  return async () => {
    try {
      const res = await checklistsProceduresApi.createChecklistProcedure(values);
      const data = res?.data?.Data;

      callback(false, true, data?.ID);
    } catch (e) {
      callback(false, false);
    }
  };
};

export const fetchChecklistsProcedures = (filters, checklists, callback, loadMore = false, advancedFilter = {}) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await checklistsProceduresApi.fetchChecklistsProcedures({ ...filters, ...advancedFilter });
      const data = res?.data?.Data;

      if (data?.ChecklistAndProcedureTemplates) {
        callback({
          isLoading: false,
          checklists: loadMore ? [...checklists, ...data.ChecklistAndProcedureTemplates] : data.ChecklistAndProcedureTemplates,
          filters: { ...filters, HasNext: data.HasNext || false, LastSeen: data.LastSeen, TotalItems: data.TotalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
    }
  };
};

export const deleteChecklistProcedure = (data, checklists, callback) => {
  return async () => {
    try {
      callback(true);
      await checklistsProceduresApi.deleteChecklistProcedure(data);

      remove(checklists, { [fields.id]: data.ChecklistAndProcedureTemplateID });
      callback(false, true, null, checklists, true);
    } catch (e) {
      callback(false);
    }
  };
};

export const fetchChecklistProcedure = (id, callback) => {
  return async () => {
    try {
      callback(true);
      const res = await checklistsProceduresApi.fetchChecklistProcedure(id);
      const data = res?.data?.Data;

      callback(false, data);
    } catch (e) {
      callback(false);
    }
  };
};

export const updateChecklistProcedure = (data, callback, checklists) => {
  return async () => {
    try {
      callback(true);
      await checklistsProceduresApi.updateChecklistProcedure(data);

      if (checklists) {
        const index = findIndex(checklists, { [fields.id]: data[fields.id] });

        if (index > -1) {
          checklists[index] = data;
        }
      }

      callback(false, true, data[fields.id], checklists);
    } catch (e) {
      callback(false);
    }
  };
};

export const changeChecklistProcedureStatus = (data, callback, checklists, isChangeToLiveStatus) => {
  return async () => {
    try {
      callback(true);

      if (isChangeToLiveStatus) {
        await checklistsProceduresApi.changeChecklistProcedureStatus(data);
      } else {
        await checklistsProceduresApi.changeChecklistProcedureStatusDraft(data);
      }

      if (checklists) {
        const index = findIndex(checklists, { [fields.id]: data.ChecklistAndProcedureTemplateID });

        if (index > -1) {
          const checklist = { ...checklists[index], [fields.status]: data[fields.status] };
          checklists[index] = checklist;
        }
      }

      callback(false, true, null, checklists);
    } catch (e) {
      callback(false);
    }
  };
};

export const updateSection = (data, sections, callback) => {
  return async () => {
    try {
      await checklistsProceduresApi.updateSection(data);

      const index = findIndex(sections, { ID: data.ID });

      if (index > -1) {
        const section = Object.assign({}, sections[index]);
        section[fields.name] = data[fields.name];
        sections.splice(index, 1, section);
      }

      // isLoading, sections, isSectionChanged, toggleAddQuestionButton
      callback(false, sections, false, true);
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchChecklistProcedureSections = (data, callback, questionId, areAdditionalFieldsExpanded) => {
  return async () => {
    try {
      callback(true);
      const res = await checklistsProceduresApi.fetchChecklistProcedureSections(data);
      let sections = res?.data?.Data;

      if (sections) {
        sections = await formatSections(sections, questionId, areAdditionalFieldsExpanded);
        callback(false, sections, true);
      }
    } catch (e) {
      callback(false);
      console.error(e);
    }
  };
};

export const addSectionQuestion = (sectionId, sections, callback) => {
  return async () => {
    try {
      callback(true);
      const res = await checklistsProceduresApi.addSectionQuestion(sectionId);
      const question = res?.data?.Data;

      if (question) {
        const index = findIndex(sections, { ID: sectionId });

        if (index > -1) {
          sections[index].SectionQuestions.push(question);
        }
      }

      callback(false, sections);
    } catch (e) {
      callback(false);
      console.error(e);
    }
  };
};

export const addSection = (checklistProcedureId, sections, callback) => {
  return async () => {
    try {
      callback(true);
      const res = await checklistsProceduresApi.addSection(checklistProcedureId);
      const section = res?.data?.Data;

      if (section) {
        sections.push(section);
      }

      callback(false, sections);
    } catch (e) {
      callback(false);
      console.error(e);
    }
  };
};

export const deleteSection = (sectionId, sections, callback) => {
  return async () => {
    try {
      callback(true);
      await checklistsProceduresApi.deleteSection(sectionId);

      remove(sections, { [fields.id]: sectionId });

      callback(false, sections);
    } catch (e) {
      callback(false);
      console.error(e);
    }
  };
};

export const updateSectionQuestion = (question, sections, callback, field, skipReinitialize) => {
  return async () => {
    try {
      const data = await getSectionAndQuestionDetails(question[fields.id], sections);
      if (data?.question) {
        if (typeof question[additionalFields.estimatedTime.name] === 'string') {
          question[additionalFields.estimatedTime.name] = timeToMilliseconds(question[additionalFields.estimatedTime.name]);
        }

        const values = Object.assign({}, pick(data.question, [fields.id, fields.name, fields.description, fields.questionType, fields.isMandatory, fields.allowMultipleAnswers, fields.estimatedTime]));
        values[field.name] = question[field.name];
        values.ChecklistItemTypeID = question.QuestionType;

        const res = await checklistsProceduresApi.updateSectionQuestion(values);
        const updatedQuestion = {
          ...data.question,
          ...pick(res?.data?.Data, [fields.id, fields.name, fields.description, fields.questionType, fields.questionOptions, fields.isMandatory, fields.allowMultipleAnswers, fields.estimatedTime]),
        };

        if (updatedQuestion) {
          // TODO: Find a solution where we don't need to do this since this reinitializes the form and can override the inserted value in the meantime
          sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, updatedQuestion);

          if (!skipReinitialize) {
            sections[data.sectionIndex].changed = !sections[data.sectionIndex].changed;
          }
        }
      }
      // isLoading, sections, isSectionChanged, toggleAddQuestionButton
      callback(true, sections, !skipReinitialize, true);
    } catch (e) {
      callback(false);
      console.error(e);
    }
  };
};

export const deleteSectionQuestion = (sectionQuestionId, callback, sections, index) => {
  return async () => {
    try {
      callback(true);
      await checklistsProceduresApi.deleteSectionQuestion(sectionQuestionId);

      const data = await getSectionAndQuestionDetails(sectionQuestionId, sections);

      if (data?.question) {
        sections[data.sectionIndex].changed = !sections[data.sectionIndex].changed;
        sections[data.sectionIndex].SectionQuestions.splice(index, 1);

        callback(false, sections);
      } else {
        callback(false);
      }
    } catch (e) {
      callback(false);
      console.error(e);
    }
  };
};

export const fetchQuestionTypes = (t, callback) => {
  return async () => {
    try {
      callback(true);
      const res = await checklistsProceduresApi.fetchQuestionTypes();
      let questionTypes = res?.data?.Data;

      if (questionTypes) {
        questionTypes = await formatQuestionTypes(questionTypes, 'ID', 'ChecklistItemType', t);
      }

      callback(false, questionTypes);
    } catch (e) {
      callback(false);
    }
  };
};

export const fetchComponents = (data, callback) => {
  return async () => {
    try {
      const res = await inspectionApi.getInspectionComponentsLite(data);
      const components = res?.data?.Data?.Components;

      callback(components);
    } catch (e) {
      console.error(e);
    }
  };
};

export const addQuestionComponent = (questionId, components, sections, callback) => {
  return async () => {
    try {
      callback(true);
      const data = await getSectionAndQuestionDetails(questionId, sections);

      if (data?.question) {
        const componentIds = components.map(component => component.ID);
        const res = await checklistsProceduresApi.addQuestionComponent({ SectionQuestionID: questionId, ComponentIDs: componentIds });
        const { Data } = res.data;
        // Remap the array so that we use correct ID to delete the component
        const addedQuestionComponents = components.map(component => {
          const componentWithNewIDIndex = Data.findIndex(newComponent => component.ComponentID === newComponent.ComponentID);
          return {
            ...component,
            ID: Data[componentWithNewIDIndex].ID,
          };
        });

        const newQuestion = { ...data.question, QuestionsComponents: [...(data.question.QuestionsComponents || []).concat(addedQuestionComponents)] };
        sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, newQuestion);
      }

      callback(false, sections, true);
    } catch (e) {
      callback(false);
    }
  };
};

export const deleteQuestionComponent = (questionId, componentId, sections, callback) => {
  return async () => {
    try {
      callback(true);
      const data = await getSectionAndQuestionDetails(questionId, sections);

      if (data?.question) {
        await checklistsProceduresApi.deleteQuestionComponent({ SectionQuestionID: questionId, SectionQuestionComponentID: componentId });

        const newQuestion = { ...data.question, QuestionsComponents: ReducerHelpers.removeItemByProp(data.question.QuestionsComponents, { ID: componentId }, 'ID') };
        sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, newQuestion);
      }

      callback(false, sections, true);
    } catch (e) {
      callback(false);
    }
  };
};

export const addQuestionWorkArea = (questionId, workArea, sections, callback) => {
  return async () => {
    try {
      callback(true);
      const data = await getSectionAndQuestionDetails(questionId, sections);

      let newSections = cloneDeep(sections);
      if (data?.question) {
        const res = await checklistsProceduresApi.addQuestionWorkArea({ SectionQuestionID: questionId, ...workArea, ModelDetails: JSON.stringify(workArea.ModelDetails) });
        let { Data } = res.data;
        Data = { ...Data, ModelDetails: JSON.parse(isEmpty(Data.ModelDetails) ? 'null' : Data.ModelDetails) };
        const newQuestion = { ...data.question, QuestionAreas: [...(data.question.QuestionAreas || []), Data] };
        newSections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, newQuestion);
      }

      callback(false, newSections, true);
    } catch (e) {
      callback(false);
    }
  };
};

export const deleteQuestionWorkArea = (questionId, workArea, sections, callback) => {
  return async () => {
    try {
      callback(true);
      const data = await getSectionAndQuestionDetails(questionId, sections);

      if (data?.question) {
        await checklistsProceduresApi.deleteQuestionWorkArea({ SectionQuestionID: questionId, SectionQuestionAreaID: workArea.ID });

        const newQuestion = { ...data.question, QuestionAreas: ReducerHelpers.removeItemByProp(data.question.QuestionAreas, workArea, 'ID') };
        sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, newQuestion);
      }

      callback(false, sections, true);
    } catch (e) {
      callback(false);
    }
  };
};

export const updateQuestionWorkArea = (questionId, workArea, sections, callback) => {
  return async () => {
    try {
      callback(true);
      const data = await getSectionAndQuestionDetails(questionId, sections);

      if (data?.question) {
        const res = await checklistsProceduresApi.updateQuestionWorkArea({
          QuestionSectionAreaID: workArea.ID,
          ...workArea,
          ModelDetails: JSON.stringify(workArea.ModelDetails),
        });
        let { Data } = res.data;
        Data = { ...Data, ModelDetails: JSON.parse(isEmpty(Data.ModelDetails) ? 'null' : Data.ModelDetails) };

        const newQuestion = { ...data.question, QuestionAreas: ReducerHelpers.updateItemInListByProp(data.question.QuestionAreas, Data, 'ID') };
        sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, newQuestion);
      }

      callback(false, sections, true);
    } catch (e) {
      callback(false);
    }
  };
};

export const addQuestionOption = (questionId, sections, callback) => {
  return async () => {
    try {
      callback(true);
      const data = await getSectionAndQuestionDetails(questionId, sections);

      if (data?.question) {
        const res = await checklistsProceduresApi.addQuestionOption({ SectionQuestionID: questionId });
        const option = res?.data?.Data;

        sections[data.sectionIndex].changed = !sections[data.sectionIndex].changed;
        data.question.QuestionOptions.push(option);
        sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, data.question);
      }

      callback(false, sections);
    } catch (e) {
      callback(false);
    }
  };
};

export const updateQuestionOption = (values, sections, callback) => {
  return async () => {
    try {
      callback(true);
      const data = await getSectionAndQuestionDetails(values.SectionQuestionID, sections);

      if (data.question) {
        await checklistsProceduresApi.updateQuestionOption(values);

        const optionIndex = findIndex(data.question.QuestionOptions, { ID: values.ID });

        if (optionIndex > -1) {
          data.question.QuestionOptions.splice(optionIndex, 1, values);
          sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, data.question);
          sections[data.sectionIndex].changed = !sections[data.sectionIndex].changed;
        }

        callback(false, sections);
      }
    } catch (e) {
      console.error(e);
      callback(false);
    }
  };
};

export const deleteQuestionOption = (index, option, sections, callback) => {
  return async () => {
    try {
      callback(true);

      if (option) {
        const { sectionQuestionId, id } = questionOptionsFields;
        const data = await getSectionAndQuestionDetails(option[sectionQuestionId.name], sections);

        if (data?.question) {
          await checklistsProceduresApi.deleteQuestionOption({ SectionQuestionID: option[sectionQuestionId.name], SectionQuestionOptionID: option[id.name] });

          sections[data.sectionIndex].changed = !sections[data.sectionIndex].changed;
          data.question.QuestionOptions.splice(index, 1);
          sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, data.question);
        }
      }

      callback(false, sections);
    } catch (e) {
      callback(false);
    }
  };
};

export const sortSections = (templateId, currentIndex, newIndex, sections, callback) => {
  return async () => {
    try {
      callback(true);
      const currentSection = Object.assign({}, sections[currentIndex]);
      const newSection = Object.assign({}, sections[newIndex]);

      if (currentSection && newSection) {
        const data = {
          TemplateID: templateId,
          SectionID: currentSection[fields.id],
          NewPosition: newSection.SectionOrder,
        };

        await checklistsProceduresApi.orderSections(data);

        const currentOrder = currentSection.SectionOrder;
        currentSection.SectionOrder = newSection.SectionOrder;
        newSection.SectionOrder = currentOrder;

        sections.splice(currentIndex, 1, newSection);
        sections.splice(newIndex, 1, currentSection);

        callback(false, sections, true);
      }
    } catch (e) {
      callback(false);
    }
  };
};

export const sortQuestions = (sectionId, currentIndex, newIndex, sections, callback) => {
  return async () => {
    try {
      callback(true);
      const index = findIndex(sections, { [fields.id]: sectionId });

      if (index > -1) {
        const section = Object.assign({}, sections[index]);
        const currentQuestion = Object.assign({}, section.SectionQuestions[currentIndex]);
        const newQuestion = Object.assign({}, section.SectionQuestions[newIndex]);

        if (currentQuestion && newQuestion) {
          const data = {
            SectionQuestionID: currentQuestion[fields.id],
            NewPosition: newQuestion.QuestionOrder,
          };

          await checklistsProceduresApi.orderSectionQuestions(data);

          const currentOrder = currentQuestion.QuestionOrder;
          currentQuestion.QuestionOrder = newQuestion.QuestionOrder;
          newQuestion.QuestionOrder = currentOrder;

          section.SectionQuestions.splice(currentIndex, 1, newQuestion);
          section.SectionQuestions.splice(newIndex, 1, currentQuestion);

          sections[index].changed = !sections[index].changed;
          sections.splice(index, 1, section);

          callback(false, sections, true);
        }
      }
    } catch (e) {
      callback(false);
    }
  };
};

export const copyChecklistProcedure = (templateId, callback) => {
  return async () => {
    try {
      const res = await checklistsProceduresApi.copyChecklistProcedure({ ChecklistAndProcedureTemplateID: templateId });
      const checklist = res?.data?.Data;

      if (checklist && typeof callback === 'function') {
        callback(checklist.ID);
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const deleteSectionQuestionFile = (questionId, fileId, sections, callback) => {
  return async () => {
    try {
      callback(true);
      const data = await getSectionAndQuestionDetails(questionId, sections);

      if (data?.question) {
        await checklistsProceduresApi.deleteSectionQuestionFile({ SectionQuestionID: questionId, FileID: fileId });

        remove(data.question.QuestionFiles, { FileID: fileId });

        sections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, data.question);
        sections[data.sectionIndex].changed = !sections[data.sectionIndex].changed;
      }

      callback(false, sections, true);
    } catch (error) {
      callback(false);
      console.error(error);
    }
  };
};

export const getChecklistProceduresPropertyNames = (value, projectId, callback) => {
  return async () => {
    if (!projectId) return;
    const res = await checklistsProceduresApi.getChecklistProceduresCustomPropertyNames({ ProjectID: projectId, SearchText: value });
    const { Data } = res.data;
    if (Data && Data.PropertyNames) {
      callback && callback(Data.PropertyNames);
    }
  };
};

export const getChecklistProceduresProperties = (ChecklistTemplateID, callback) => {
  return async () => {
    try {
      const res = await checklistsProceduresApi.getChecklistProceduresCustomProperties({ ChecklistTemplateID });
      const { Data } = res.data;
      if (Data) {
        callback && callback(Data);
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const addCustomProperties = (propertiesToAdd, ChecklistTemplateID, callback) => {
  return async () => {
    try {
      if (isEmpty(propertiesToAdd)) {
        return;
      }
      const res = await checklistsProceduresApi.checklistProceduresCustomPropertiesAdd({ Properties: propertiesToAdd.map(p => ({ ...p, ChecklistTemplateID })) });
      const { Data } = res.data;
      if (Data) {
        callback && callback(Data);
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateCustomProperty = (property, ChecklistTemplateID, callback) => {
  return async () => {
    try {
      if (!property?.[customPropertiesConstants.id]) {
        return;
      }
      const res = await checklistsProceduresApi.checklistProceduresCustomPropertiesUpdate({ Property: { ChecklistTemplateID, ...property } });
      const { Data } = res.data;
      if (Data) {
        callback && callback(Data);
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteCustomProperty = (property, ChecklistTemplateID, callback) => {
  return async () => {
    try {
      if (!property?.[customPropertiesConstants.id]) {
        return;
      }
      await checklistsProceduresApi.checklistProceduresCustomPropertiesDelete({ ChecklistTemplateID, PropertyID: property[customPropertiesConstants.id] });
      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateQuestionTagLocation = (questionId, location, sections, callback) => {
  return async () => {
    try {
      callback(true);
      const data = await getSectionAndQuestionDetails(questionId, sections);

      let newSections = cloneDeep(sections);
      const newGeometry = location?.Geometry;
      const CameraPosition = location?.CameraPosition;
      if (data?.question && !isEmpty(newGeometry)) {
        await checklistsProceduresApi.addQuestionTagLocation({ ID: questionId, Geometry: newGeometry, CameraPosition });
        const newQuestion = { ...data.question, Geometry: { ...data.question.Geometry, ...newGeometry }, CameraPosition };
        newSections[data.sectionIndex].SectionQuestions.splice(data.questionIndex, 1, newQuestion);
      }

      callback(false, newSections, true);
    } catch (e) {
      callback(false);
    }
  };
};

export const generatePDF = (checklist_template_id, callback) => {
  return async () => {
    try {
      const res = await checklistsProceduresApi.generatePDF([{ checklist_template_id }]);
      const fileName = res.request.getResponseHeader('content-disposition').split('filename=')[1].split(';')[0];

      const file = res?.data;
      file.name = fileName || null;
      if (file) {
        Helpers.downloadPDFFile(file);
      }

      callback && callback();
    } catch (e) {
      console.error(e);
      callback && callback();
    }
  };
};

export const getChecklistQuestionDMSFilesUploaded = question_id => {
  return async dispatch => {
    try {
      const res = await checklistsProceduresApi.fetchChecklistQuestionFiles([{ question_id }]);
      let Data = get(res, 'data.Data');

      if (Data) {
        Data = Helpers.mapExternalFilesForModal(Data, genericFileImage);
        dispatch(setChecklistQuestionFilesUploaded(Data, question_id));
      } else {
        dispatch(setChecklistQuestionFilesUploaded({}, question_id));
      }
    } catch (e) {
      console.error(e);
      dispatch(setChecklistQuestionFilesUploaded({}, question_id));
    }
  };
};
