import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { debounce, get, isEmpty } from 'lodash';

import { setNodesLoading } from '../actions/action-creators';
import { savePointRelatedImages } from '../../../start-workflow/actions/start-workflow-actions';
import { selectAllCameraPositions, deselectAllCameraPositions, selectAllCameraThumbnails, deselectAllCameraThumbnails } from '../../../start-workflow/actions/action-creators';

import { sceneSettings, formConstants, userSettings, inspectionPresets, presetConstants, navigationModeOptions } from '../../constants/inspection-settings';
import { placements } from '../../constants/constants';
import { defaultPotreId } from '../constants/point-images-contstants';

import CollapsibleToolbar from '../../components/collapsible-toolbar';
import Defects from '../../../start-workflow/components/defects';
import CamerasWorkflow from '../../../start-workflow/components/cameras';
import CamerasInspection from '../../components/left-toolbar/cameras';
import ScreenToolbox from '../../components/toolbox/screen-toolbox';
import InspectionSettings from '../../components/right-toolbar/inspection-settings';
import ExternalImagesModal from '../../../start-workflow/components/external-images-modal';
import Modal from '../../../../common/modal/components/modal';
import ObjectToolbox from '../../components/toolbox/object-toolbox';

import { ReactComponent as CamVisibleIcon } from '../assets/all_cameras_visible.svg';
import { ReactComponent as CamInvisibleIcon } from '../assets/all_cameras_invisible.svg';
import { ReactComponent as ThumbnailVisibleIcon } from '../assets/all_thumbnail_visible.svg';
import { ReactComponent as ThumbnailInvisibleIcon } from '../assets/all_thumbnail_invisible.svg';
import { AMAZON_IMAGE_SIZES, LENGTH_UNIT, POTREE_STATS_VISIBLE } from '../../../../common/constants';
import { setImages360Ref, setIs3DViewModeActive } from '../../actions/action-creators';

class View extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toolbarCollapsed: false,
      modalData: {
        isOpen: false,
      },
    };
    this.configureSceneDebounced = debounce(this.configureScene, 100);
  }

  clearScene() {
    const { viewer, createViewerInstance, potreeId, setImages360Ref } = this.props;
    const { Potree } = window;
    if (!viewer) return;

    for (let pointCloud of viewer.scene.pointclouds) {
      pointCloud.visible = false;
      viewer.scene.scene.remove(pointCloud);
      if (pointCloud.pcoGeometry.root.dispose) {
        pointCloud.pcoGeometry.root.dispose();
      }
      pointCloud.material.dispose();
      pointCloud = undefined;
    }
    while (viewer.scene.pointclouds.length > 0) {
      viewer.scene.scenePointCloud.remove(viewer.scene.pointclouds[0]);
      viewer.scene.pointclouds.pop();
    }

    if (Potree.lru) Potree.lru.freeMemory();

    document.getElementById(potreeId).innerHTML = '';
    createViewerInstance(null);
    setImages360Ref(null);
  }

  componentWillUnmount() {
    this.clearScene();
  }

  toggleToolbar = () => {
    this.setState(({ toolbarCollapsed }) => ({ toolbarCollapsed: !toolbarCollapsed }));
  };

  changePointMaterial = (pointMaterial, viewer) => {
    if (viewer && viewer.scene.pointclouds[0]) {
      viewer.scene.pointclouds.forEach(pointcloud => {
        pointcloud.material.activeAttributeName = pointMaterial.value;
      });
    }
  };

  configurePointcloud = (pointcloud, viewer) => {
    const { inspectionSettings } = this.props;
    const { Potree } = window;
    if (inspectionSettings[formConstants.fields.pointSize]) pointcloud.material.size = inspectionSettings[formConstants.fields.pointSize];
    if (inspectionSettings[formConstants.fields.PointSizing]) pointcloud.material.pointSizeType = Potree.PointSizeType[inspectionSettings[formConstants.fields.PointSizing].value];
    if (inspectionSettings[formConstants.fields.PointType]) this.changePointMaterial(inspectionSettings[formConstants.fields.PointType], viewer);
    if (inspectionSettings[formConstants.fields.Opacity]) pointcloud.material.opacity = inspectionSettings[formConstants.fields.Opacity];
    if (inspectionSettings[formConstants.fields.Quality]) pointcloud.material.shape = Potree.PointShape[inspectionSettings[formConstants.fields.Quality].value];
  };

  configureScene = viewer => {
    const {
      inspectionSettings,
      user: { Setting360View },
      createViewerInstance,
    } = this.props;
    if (!viewer) return;

    // Set length unit
    viewer.setLengthUnitAndDisplayUnit('m', LENGTH_UNIT);

    if (inspectionSettings[formConstants.fields.maxPoints]) viewer.setPointBudget(inspectionSettings[formConstants.fields.maxPoints] * 1000 * 1000);
    if (inspectionSettings[formConstants.fields.color]) viewer.setBackground(inspectionSettings[formConstants.fields.color]);
    if (inspectionSettings[formConstants.fields.FOV]) viewer.setFOV(inspectionSettings[formConstants.fields.FOV]);
    if (inspectionSettings[formConstants.fields.EDL]) viewer.setEDLEnabled(inspectionSettings[formConstants.fields.EDL]);
    if (inspectionSettings[formConstants.fields.EDLRadius]) viewer.setEDLRadius(inspectionSettings[formConstants.fields.EDLRadius]);
    if (inspectionSettings[formConstants.fields.EDLStrength]) viewer.setEDLStrength(inspectionSettings[formConstants.fields.EDLStrength]);
    if (inspectionSettings[formConstants.fields.minNodeSize]) viewer.setMinNodeSize(inspectionSettings[formConstants.fields.minNodeSize]);
    if (inspectionSettings[formConstants.fields.HighQuality]) viewer.useHQ = inspectionSettings[formConstants.fields.HighQuality];

    const cameraPosition = get(inspectionSettings, formConstants.fields.cameraPosition);
    if (cameraPosition) {
      viewer.zoomToPosition(
        {
          x: cameraPosition[0],
          y: cameraPosition[1],
          z: cameraPosition[2],
        },
        null,
        500
      );
    } else {
      viewer.fitToScreen();
    }

    // Point cloud object needed
    if (viewer.scene.pointclouds[0]) {
      viewer.scene.pointclouds.forEach(pointcloud => {
        this.configurePointcloud(pointcloud);
      });
    }

    // User settings
    if (!isEmpty(Setting360View)) {
      if (viewer) {
        const modelQuality =
          Setting360View[userSettings.fields.modelQuality] && inspectionPresets[Setting360View[userSettings.fields.modelQuality]]
            ? Setting360View[userSettings.fields.modelQuality]
            : presetConstants._hd;
        const presetToLoad = inspectionPresets[modelQuality];
        viewer.setPointBudget(presetToLoad[formConstants.fields.maxPoints] * 1000 * 1000);
        viewer.useHQ = presetToLoad[formConstants.fields.HighQuality];
        if (Setting360View[userSettings.fields.navigationMode] === navigationModeOptions.viewer.value) {
          // Viewer controls
          viewer.setControls(viewer.fpControls);
        } else {
          // Pan controls
          viewer.setControls(viewer.earthControls);
        }

        viewer.fpControls.lockElevation = Setting360View[userSettings.fields.lockZAxis];
        if (Setting360View[userSettings.fields.sensitivity]) {
          viewer.setZoomSpeed(Setting360View[userSettings.fields.sensitivity]);
        }
        if (Setting360View[userSettings.fields.speedOfMovement]) {
          viewer.setMoveSpeed(Setting360View[userSettings.fields.speedOfMovement]);
        }
      }
    }
    createViewerInstance(viewer);
  };

  loadPointClouds = async (viewer, pointcloudUrls) => {
    const { Potree } = window;

    let promises = [];

    pointcloudUrls.forEach(async (url, index) => {
      promises.push(Potree.loadPointCloud(url, `view-${index}`));
    });
    const results = await Promise.allSettled(promises);

    results.forEach(result => {
      if (result.status === 'rejected' || !result.value) {
        console.warn('Failed to load pointcloud: ', result.reason);
        return;
      }

      let pointcloud = result.value.pointcloud;
      let material = pointcloud.material;
      viewer.scene.addPointCloud(pointcloud);
      material.activeAttributeName = 'rgba'; // any Potree.PointColorType.XXXX
      material.size = 1;
      material.pointSizeType = Potree.PointSizeType.ADAPTIVE;
      material.shape = Potree.PointShape.SQUARE;
      pointcloud.updateMatrixWorld();
    });

    this.configureSceneDebounced(viewer);
  };

  componentDidMount() {
    const { inspectionDetails, inspectionSettings, createViewerInstance, potreeId, progressBaseId } = this.props;
    const { Potree } = window;
    const pointcloudUrls = get(inspectionDetails, 'PointCloudURLs');

    if (!isEmpty(pointcloudUrls)) {
      document.getElementById(potreeId).innerHTML = '';
      createViewerInstance(null);
      const viewer = new Potree.Viewer(document.getElementById(potreeId), { progressBaseId });
      // Set default values for the viewer
      viewer.setFOV(60);
      viewer.setPointBudget(1 * 1000 * 1000);
      viewer.setEDLEnabled(false);
      viewer.setBackground(inspectionSettings.BackgroundColour || sceneSettings.defaultBgColor.hexCode);
      viewer.setDescription(``);
      viewer.loadSettingsFromURL();
      viewer.toggleStatsVisibility(POTREE_STATS_VISIBLE);
      this.loadPointClouds(viewer, pointcloudUrls);
    }
  }

  componentDidUpdate(prevProps) {
    const { inspectionDetails, inspectionSettings, createViewerInstance, potreeId, progressBaseId } = this.props;
    const { Potree } = window;
    const pointcloudUrls = get(inspectionDetails, 'PointCloudURLs'),
      inspectionID = get(inspectionDetails, 'InspectionID'),
      previnspectionID = get(prevProps.inspectionDetails, 'InspectionID');

    if (inspectionID !== previnspectionID && !isEmpty(pointcloudUrls)) {
      document.getElementById(potreeId).innerHTML = '';
      createViewerInstance(null);
      const viewer = new Potree.Viewer(document.getElementById(potreeId), { progressBaseId });
      // Set default values for the viewer
      viewer.setFOV(60);
      viewer.setPointBudget(1 * 1000 * 1000);
      viewer.setEDLEnabled(false);
      viewer.setBackground(inspectionSettings.BackgroundColour || sceneSettings.defaultBgColor.hexCode);
      viewer.setDescription(``);
      viewer.loadSettingsFromURL();
      viewer.toggleStatsVisibility(POTREE_STATS_VISIBLE);

      this.loadPointClouds(viewer, pointcloudUrls);
    }
  }

  openSettingsModal = () => {
    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: true,
        CustomContent: () => <InspectionSettings isWorkflow={true} />,
        type: 'none',
        closeAction: this.closeModal,
        customClassName: 'inspection-settings-modal',
      },
    }));
  };

  saveImageDrawings = files => {
    const { saveExternalFiles, selectedDefect, currentImage } = this.props;
    if (isEmpty(files)) {
      this.closeModal();
    } else {
      saveExternalFiles(files, selectedDefect, currentImage, this.closeModal);
    }
  };

  openRelatedImagesModal = () => {
    const { pointImages } = this.props;

    this.setState(prevProps => ({
      modalData: {
        ...prevProps.modalData,
        isOpen: true,
        CustomContent: () => <ExternalImagesModal externalFiles={pointImages} saveExternalFiles={this.saveImageDrawings} imageType={AMAZON_IMAGE_SIZES.small.name} />,
        type: 'none',
        closeAction: this.closeModal,
      },
    }));
  };

  closeModal = () => {
    this.setState({
      modalData: {
        isOpen: false,
      },
    });
  };

  renderToggleCamera = () => {
    const { t } = this.context;
    const { cameraPositionVisible, cameraThumbnailVisible, selectAllCameras, deselectAllCameras, selectAllCameraThumbnails, deselectAllCameraThumbnails, activeCameraHelper } = this.props;

    const cameraToggle = !cameraPositionVisible ? (
      <CamInvisibleIcon width={20} height={20} onClick={selectAllCameras} className="svg-primary-g stroke-primary-g camera-action" title={t('CAMERA_HIDDEN_ICON.TITLE')} />
    ) : (
      <CamVisibleIcon width={20} height={20} onClick={deselectAllCameras} className="svg-primary-g stroke-primary-g camera-action" title={t('CAMERA_VISIBLE_ICON.TITLE')} />
    );

    const cameraThumbnailToggle = !cameraThumbnailVisible ? (
      <ThumbnailInvisibleIcon
        width={20}
        height={20}
        onClick={() => {
          if (cameraPositionVisible) {
            selectAllCameraThumbnails();
          }
        }}
        className="svg-primary-g stroke-primary-g camera-action"
        title={t('CAMERA_THUMBNAIL_HIDDEN_ICON.TITLE')}
      />
    ) : (
      <ThumbnailVisibleIcon
        width={20}
        height={20}
        onClick={() => {
          if (cameraThumbnailVisible) {
            deselectAllCameraThumbnails();
          }
        }}
        className="svg-primary-g stroke-primary-g camera-action"
        title={t('CAMERA_THUMBNAIL_VISIBLE_ICON.TITLE')}
      />
    );

    return (
      <div className="workflow__camera-actions">
        {activeCameraHelper && (
          <>
            <p
              onClick={() => {
                if (activeCameraHelper.selectCamera) activeCameraHelper.selectCamera();
              }}
              className="f-secondary-green noselect camera-action"
            >
              {t('START_WORKFLOW.RESET_CAMERA_POSITION')}
            </p>
            <p className="f-secondary-dark noselect separator">|</p>
          </>
        )}
        {cameraToggle}
        <p className="f-secondary-dark noselect separator">|</p>
        {cameraThumbnailToggle}
      </div>
    );
  };

  render() {
    const {
      isWorkflow,
      parentRef,
      viewer,
      potreeId,
      preSelectedDefect,
      activeTool,
      handleObjectToolClick,
      inspectionSettings,
      inspectionDetails,
      setInspectionDetails,
      showObjectToolbox,
      showScreenToolbox,
      progressBaseId,
      currentInsertion,
      images360,
      is3DViewModeActive,
      setIs3DViewModeActive,
      openInstructionsModal,
      openSettings,
      show360,
    } = this.props;
    const { toolbarCollapsed, modalData } = this.state;
    const { t } = this.context;

    return (
      <div ref={parentRef} className={`inspection${isWorkflow ? ' workflow' : ''}`}>
        {isWorkflow ? (
          <Fragment>
            <div className="workflow__header">
              <p className="f-primary">{t('START_WORKFLOW.3D_VIEW_HEADER_TEXT')}</p>
              {this.renderToggleCamera()}
            </div>
            <div style={{ zIndex: 2 }} id={progressBaseId} className="progress">
              <div id={`${progressBaseId}-bar`} className="progress__bar" />
              <p id={`${progressBaseId}-text`} className="progress__text f-primary" />
            </div>
            <ScreenToolbox openSettingsModal={this.openSettingsModal} viewer={viewer} />

            <CollapsibleToolbar className="workflow__toolbar" collapsed={toolbarCollapsed} toggleToolbar={this.toggleToolbar} placement={placements.bottom}>
              <Defects viewer={viewer} preSelectedDefect={preSelectedDefect} />
              <CamerasWorkflow viewer={viewer} />
            </CollapsibleToolbar>
            <Modal {...modalData} />
          </Fragment>
        ) : (
          <Fragment>
            <div style={{ zIndex: 2 }} id={progressBaseId} className="progress">
              <div id={`${progressBaseId}-bar`} className="progress__bar" />
              <p id={`${progressBaseId}-text`} className="progress__text f-primary" />
            </div>
            {showObjectToolbox && <ObjectToolbox activeTool={activeTool} currentInsertion={currentInsertion} handleClick={handleObjectToolClick} />}
            {showScreenToolbox && (
              <ScreenToolbox
                inspectionSettings={inspectionSettings}
                inspectionDetails={inspectionDetails}
                viewer={viewer}
                setInspectionDetails={setInspectionDetails}
                openSettings={openSettings}
                images360={images360}
                openHelp={openInstructionsModal}
                is3DViewModeActive={is3DViewModeActive}
                setIs3DViewModeActive={setIs3DViewModeActive}
                showHelp={openInstructionsModal}
                show360={show360}
              />
            )}
            <CamerasInspection viewer={viewer} />
          </Fragment>
        )}

        <div id={potreeId} className={`potree-area`} />
        {/* <div id="potree_sidebar_container" /> */}
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  return {
    inspectionDetails: state.inspectionReducer.inspectionDetails,
    inspectionSettings: state.inspectionReducer.inspectionSettings,
    cameraPositionVisible: state.startWorkflowReducer.cameraPositionVisible,
    cameraThumbnailVisible: state.startWorkflowReducer.cameraThumbnailVisible,
    activeCameraHelper: state.startWorkflowReducer.activeCameraHelper,
    selectedDefect: state.startWorkflowReducer.selectedDefect,
    currentImage: state.startWorkflowReducer.currentImage,
    pointImages: state.potreeReducer.pointImages,
    progressBaseId: `${props.potreeId}-pc-progress`,
    user: state.userReducer,
    images360: state.inspectionReducer.images360Ref,
    is3DViewModeActive: state.inspectionReducer.is3DViewModeActive,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    setNodesLoading: data => dispatch(setNodesLoading(data)),
    selectAllCameras: () => dispatch(selectAllCameraPositions()),
    deselectAllCameras: () => dispatch(deselectAllCameraPositions()),
    selectAllCameraThumbnails: () => dispatch(selectAllCameraThumbnails()),
    deselectAllCameraThumbnails: () => dispatch(deselectAllCameraThumbnails()),
    saveExternalFiles: (files, defect, currentImage, callback) => dispatch(savePointRelatedImages(defect, currentImage, files, callback)),
    setImages360Ref: data => dispatch(setImages360Ref(data)),
    setIs3DViewModeActive: data => dispatch(setIs3DViewModeActive(data)),
  };
};
View.defaultProps = {
  potreeId: defaultPotreId,
  getCameraPosition: () => null,
  openInstructionsModal: undefined,
};
View.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(View);
