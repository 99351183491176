import { find, findIndex, orderBy } from 'lodash';
import { updateSyncErrors } from 'redux-form';
import documentManagementAPI from '../../../api/document-management-api/actions';
import pdfTagApi from '../../../api/pdf-tag/actions';
import { FORMS } from '../../../common/constants';
import DrawingHelpers from '../../../common/drawing-helpers';
import Helpers from '../../../common/helpers';
import { dispatchErrorModalWired } from '../../../common/modal/actions/modal-actions';
import { updateElementDetails } from '../../inspections/actions/action-creators';
import { setPdfComponents, setPdfPopupComponentsLoading } from '../../pdf-tag/actions/action-creators';
import { componentFields, componentFilterValues } from '../../pdf-tag/constants/constants';
import { setDMSLabels, setDMSLabelsLoading, setSelectedDMSFileLabels, setVersionHistory, setVersionHistoryLoading } from '../constants/action-creators';
import { fields, folderFormFields } from '../constants/constants';

export const fetchDMFolders = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await documentManagementAPI.fetchDMFolders(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchComponentDMFolders = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await documentManagementAPI.fetchComponentDMFolders(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchNotificationDMFolders = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await documentManagementAPI.fetchNotificationDMFolders(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchObservationDMFolders = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await documentManagementAPI.fetchObservationDMFolders(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchWorkorderDMFolders = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await documentManagementAPI.fetchWorkorderDMFolders(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchWorkorderCompletionDMFolders = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await documentManagementAPI.fetchWorkorderCompletionDMFolders(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchCPQuestionDMFolders = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await documentManagementAPI.fetchCPQuestionDMFolders(filters);
      const { Data } = res?.data;
      const { Items, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Items, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const createDMSFolder = (data, dataCallback, modalCallback) => {
  return async dispatch => {
    try {
      const res = await documentManagementAPI.createDMFolder(data);
      const { Data } = res?.data;
      modalCallback && typeof modalCallback === 'function' && modalCallback();
      dataCallback && typeof dataCallback === 'function' && dataCallback(Data);
    } catch (e) {
      const errCode = Helpers.getErrorCode(e);
      const err = Helpers.getErrorContent(e);
      if (!isNaN(errCode) && [6103].indexOf(errCode) > -1) {
        // show custom errors
        dispatch(updateSyncErrors(FORMS.createNewFolderForm, { [folderFormFields.name.name]: err }));
      } else {
        // present non custom handled errors in popup
        dispatchErrorModalWired(true, e);
      }
      console.error(e);
    }
  };
};

export const fetchDMLabels = (ProjectID, searchText = '', dataCallback, loadingCallback) => {
  return async dispatch => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      dispatch(setDMSLabelsLoading(true));
      const res = await documentManagementAPI.fetchDMLabels({ ProjectID, SearchText: searchText });
      let { Data } = res?.data;
      Data = orderBy(Data || [], 'Name', 'asc');
      // dispatch save to reducer the labels so they can be used in labels popup
      dispatch(setDMSLabels(Data));
      dataCallback && typeof dataCallback === 'function' && dataCallback(Data);
      isLoadingCallbackValid && loadingCallback(false);
      dispatch(setDMSLabelsLoading(false));
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
      dispatch(setDMSLabelsLoading(false));
    }
  };
};

export const fetchDMFiles = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await documentManagementAPI.fetchDMFiles(filters);
      const { Data } = res?.data;
      const { Files, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Files, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchComponentDMFiles = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await documentManagementAPI.fetchComponentDMFiles(filters);
      const { Data } = res?.data;
      const { Files, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Files, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchNotificationDMFiles = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await documentManagementAPI.fetchNotificationDMFiles(filters);
      const { Data } = res?.data;
      const { Files, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Files, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchObservationDMFiles = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await documentManagementAPI.fetchObservationDMFiles(filters);
      const { Data } = res?.data;
      const { Files, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Files, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchWorkorderDMFiles = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await documentManagementAPI.fetchWorkorderDMFiles(filters);
      const { Data } = res?.data;
      const { Files, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Files, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchWorkorderCompletionDMFiles = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await documentManagementAPI.fetchWorkorderCompletionDMFiles(filters);
      const { Data } = res?.data;
      const { Files, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Files, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchCPQuestionDMFiles = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      const res = await documentManagementAPI.fetchCPQuestionDMFiles(filters);
      const { Data } = res?.data;
      const { Files, ...rest } = Data;
      dataCallback && typeof dataCallback === 'function' && dataCallback(Files, { ...filters, ...rest });
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const fetchDMFolderDetails = (hierarchy_id, callback, actionType, isPropertyAdd = false) => {
  return async () => {
    try {
      callback({ isRightSideLoading: !isPropertyAdd, toolbarCollapsed: false });
      const res = await documentManagementAPI.fetchDMFolderDetails([{ hierarchy_id }]);
      const level = res?.data?.Data;
      const callbackObj = isPropertyAdd
        ? { selectedItemData: level, selectedItemDataProperties: level?.Properties, selectedItem: hierarchy_id, persistedSelectedItem: hierarchy_id }
        : {
            isRightSideLoading: false,
            selectedItemData: level,
            selectedItemDataProperties: level?.Properties,
            selectedItem: hierarchy_id,
            persistedSelectedItem: hierarchy_id,
            // isRightSideDisabled: actionType === itemActionTypes.view,
            isRightSideDisabled: false,
          };
      callback && callback(callbackObj);
    } catch (e) {
      console.error(e);
      callback({ isRightSideLoading: false, toolbarCollapsed: true });
    }
  };
};

export const fetchDMFileDetails = (file_id, callback, actionType, isPropertyAdd = false, isVersionRefresh = false) => {
  return async dispatch => {
    try {
      !isVersionRefresh && callback({ isRightSideLoading: !isPropertyAdd, toolbarCollapsed: false });
      const res = await documentManagementAPI.fetchDMFileDetails([{ file_id }]);
      let file = res?.data?.Data;
      file = Helpers.mapExternalFilesForModal([file])[0];
      if (isVersionRefresh) {
        callback && callback(file);
      } else {
        const callbackObj = isPropertyAdd
          ? { selectedItemData: file, selectedItemDataProperties: file?.Properties, selectedItem: file_id, persistedSelectedItem: file_id }
          : {
              isRightSideLoading: false,
              selectedItemData: file,
              selectedItemDataProperties: file?.Properties,
              selectedItem: file_id,
              persistedSelectedItem: file_id,
              // isRightSideDisabled: actionType === itemActionTypes.view,
              isRightSideDisabled: false,
            };
        const { Labels } = file;
        dispatch(setSelectedDMSFileLabels(Labels || []));
        callback && callback(callbackObj);
      }
    } catch (e) {
      console.error(e);
      callback({ isRightSideLoading: false, toolbarCollapsed: true });
    }
  };
};

export const updateDMFolderDetails = (data, folderData, hierarchyCallback, detailsCallback) => {
  return async dispatch => {
    try {
      await documentManagementAPI.updateDMFolderDetails(data);

      hierarchyCallback &&
        hierarchyCallback({
          folderData: Object.assign([], folderData, { [findIndex(folderData, { ID: data[fields.id] })]: data }),
        });
      detailsCallback &&
        detailsCallback({
          selectedItemData: data,
          selectedItem: data[fields.id],
        });
    } catch (e) {
      const errCode = Helpers.getErrorCode(e);
      const err = Helpers.getErrorContent(e);
      if (!isNaN(errCode) && [6103].indexOf(errCode) > -1) {
        // show custom errors
        dispatch(updateSyncErrors(FORMS.DMSFolderHierarchyLevel, { [folderFormFields.name.name]: err }));
      } else {
        // present non custom handled errors in popup
        dispatchErrorModalWired(true, e);
      }
      console.error(e);
    }
  };
};

export const deleteDMFolder = (data, callback) => {
  return async () => {
    try {
      await documentManagementAPI.deleteDMFolder(data);
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteDMFile = (data, callback) => {
  return async () => {
    try {
      await documentManagementAPI.deleteDMFile(data);
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const moveDMSFile = (data, callback) => {
  return async () => {
    try {
      await documentManagementAPI.moveDMSFile(data);
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchDMSStatuses = callback => {
  return async () => {
    try {
      const res = await documentManagementAPI.fetchDMSStatuses();
      const { Data } = res?.data;
      callback && typeof callback === 'function' && callback(Data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateDMSFile = (data, callback) => {
  return async () => {
    try {
      await documentManagementAPI.updateDMSFile(data);
      callback && typeof callback === 'function' && callback(data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchComponentsToLink = (filters, callback, loadingCallback) => {
  return async () => {
    try {
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(true);
      const res = await documentManagementAPI.fetchComponentsToLink(filters);
      const { Data } = res?.data;
      const { Components, ...rest } = Data;
      callback && typeof callback === 'function' && callback(Components, { ...filters, ...rest });
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    } catch (e) {
      console.error(e);
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    }
  };
};

export const linkComponentsToFile = (data, callback) => {
  return async () => {
    try {
      const res = await documentManagementAPI.linkComponentsToFile(data);
      const { Data } = res?.data;
      const { Components } = Data;
      callback && typeof callback === 'function' && callback(Components);
    } catch (e) {
      console.error(e);
    }
  };
};

export const addLabelToDMSFile = (newAddedLabels, sourceId, labelId, callback) => {
  return async dispatch => {
    try {
      await documentManagementAPI.addLabelToDMSFile({ SourceID: sourceId, LabelID: labelId });
      // dispatch save to reducer the labels so they can be used in labels popup
      dispatch(setSelectedDMSFileLabels(newAddedLabels));
      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const removeLabelFromDMSFile = (newAddedLabels, sourceId, labelId, callback) => {
  return async dispatch => {
    try {
      await documentManagementAPI.removeLabelFromDMSFile({ SourceID: sourceId, LabelID: labelId });
      // dispatch save to reducer the labels so they can be used in labels popup
      dispatch(setSelectedDMSFileLabels(newAddedLabels));
      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};
export const getPdfComponentDrawings = (
  InspectionID,
  FileID,
  filter = {
    SearchText: '',
    ComponentFilter: componentFilterValues.all,
  },
  PageNumber = 1,
  componentID,
  callback = null
) => {
  return async dispatch => {
    dispatch(setPdfPopupComponentsLoading(true));
    try {
      const res = await pdfTagApi.getPdfComponents({
        InspectionID,
        FileID,
        ...filter,
        PageNumber,
      });
      const { TaggedComponents } = res.data.Data;
      let normalizedComponents = [];
      try {
        normalizedComponents = DrawingHelpers.normalizeDrawings(TaggedComponents);
      } catch (e) {
        console.warn('corrupted drawings');
      }
      if (componentID) {
        // updates selected component with latest info in DB
        const selectedComp = find(normalizedComponents, { [componentFields.componentId]: componentID });
        dispatch(updateElementDetails({ ...selectedComp }));
      }
      dispatch(setPdfComponents(normalizedComponents || []));
      callback && callback(normalizedComponents);
      dispatch(setPdfPopupComponentsLoading(false));
    } catch (err) {
      callback && callback([]);
      dispatch(setPdfPopupComponentsLoading(false));
    }
  };
};

export const confirmDMSFileUploadIsDone = (FileID, IsActiveVersion, callback) => {
  return async () => {
    try {
      await documentManagementAPI.confirmDMSFileUploadIsDone({ FileID, IsActiveVersion });
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const confirmComponentDMSFileUploadIsDone = (FileID, IsActiveVersion, callback) => {
  return async () => {
    try {
      await documentManagementAPI.confirmComponentDMSFileUploadIsDone({ FileID, IsActiveVersion });
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const confirmNotificationDMSFileUploadIsDone = (FileID, IsActiveVersion, callback) => {
  return async () => {
    try {
      await documentManagementAPI.confirmNotificationDMSFileUploadIsDone({ FileID, IsActiveVersion });
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const confirmObservationDMSFileUploadIsDone = (FileID, IsActiveVersion, callback) => {
  return async () => {
    try {
      await documentManagementAPI.confirmObservationDMSFileUploadIsDone({ FileID, IsActiveVersion });
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const confirmWorkorderDMSFileUploadIsDone = (FileID, IsActiveVersion, callback) => {
  return async () => {
    try {
      await documentManagementAPI.confirmWorkorderDMSFileUploadIsDone({ FileID, IsActiveVersion });
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const confirmWorkorderWizardDMSFileUploadIsDone = (FileID, IsActiveVersion, callback) => {
  return async () => {
    try {
      await documentManagementAPI.confirmWorkorderWizardDMSFileUploadIsDone({ FileID, IsActiveVersion });
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const confirmCPQuestionDMSFileUploadIsDone = (FileID, IsActiveVersion, callback) => {
  return async () => {
    try {
      await documentManagementAPI.confirmCPQuestionDMSFileUploadIsDone({ FileID, IsActiveVersion });
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const confirmWorkorderCompletionDMSFileUploadIsDone = (FileID, IsActiveVersion, callback) => {
  return async () => {
    try {
      await documentManagementAPI.confirmWorkorderCompletionDMSFileUploadIsDone({ FileID, IsActiveVersion });
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const linkDMSFileFromObservation = (defectId, files, callback) => {
  return async () => {
    try {
      await documentManagementAPI.linkDMSFileFromObservation({ DefectID: defectId, Files: files });
      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const unlinkDMSFileFromObservation = (sourceId, defectId, categoryID, callback, modalCallback) => {
  return async () => {
    try {
      await documentManagementAPI.unlinkDMSFileFromObservation({ SourceID: sourceId, DefectID: defectId, CategoryID: categoryID });
      callback && callback();
      modalCallback && modalCallback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const linkObservationToFile = (data, callback) => {
  return async () => {
    try {
      const res = await documentManagementAPI.linkObservationToFile(data);
      const { Data } = res?.data;
      const { Defects } = Data;
      callback && typeof callback === 'function' && callback(Defects);
    } catch (e) {
      console.error(e);
    }
  };
};

export const unlinkObservationToFile = (data, callback) => {
  return async () => {
    try {
      const res = await documentManagementAPI.unlinkObservationToFile(data);
      const { Data } = res?.data;
      const { Defects } = Data;
      callback && typeof callback === 'function' && callback(Defects);
    } catch (e) {
      console.error(e);
    }
  };
};

export const linkDMSFileFromNotification = (notificationId, files, callback) => {
  return async () => {
    try {
      await documentManagementAPI.linkDMSFileFromNotification({ NotificationID: notificationId, Files: files });
      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const unlinkDMSFileFromNotification = (sourceId, notificationId, categoryID, callback, modalCallback) => {
  return async () => {
    try {
      await documentManagementAPI.unlinkDMSFileFromNotification({ SourceID: sourceId, NotificationID: notificationId, CategoryID: categoryID });
      callback && callback();
      modalCallback && modalCallback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const linkNotificationToFile = (data, callback) => {
  return async () => {
    try {
      const res = await documentManagementAPI.linkNotificationToFile(data);
      const { Data } = res?.data;
      const { Notifications } = Data;
      callback && typeof callback === 'function' && callback(Notifications);
    } catch (e) {
      console.error(e);
    }
  };
};

export const unlinkNotificationToFile = (data, callback) => {
  return async () => {
    try {
      const res = await documentManagementAPI.unlinkNotificationToFile(data);
      const { Data } = res?.data;
      const { Notifications } = Data;
      callback && typeof callback === 'function' && callback(Notifications);
    } catch (e) {
      console.error(e);
    }
  };
};

export const linkDMSFileFromWorkorder = (workorderId, files, callback) => {
  return async () => {
    try {
      await documentManagementAPI.linkDMSFileFromWorkorder({ WorkorderID: workorderId, Files: files });
      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const linkDMSFileFromWorkorderCompletion = (workorderId, files, callback) => {
  return async () => {
    try {
      await documentManagementAPI.linkDMSFileFromWorkorderCompletion({ WorkOrderID: workorderId, Files: files });
      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const unlinkDMSFileFromWorkorder = (sourceId, workorderId, categoryID, callback, modalCallback) => {
  return async () => {
    try {
      await documentManagementAPI.unlinkDMSFileFromWorkorder({ SourceID: sourceId, WorkorderID: workorderId, CategoryID: categoryID });
      callback && callback();
      modalCallback && modalCallback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const unlinkDMSFileFromWorkorderCompletion = (sourceId, workorderId, categoryID, callback, modalCallback) => {
  return async () => {
    try {
      await documentManagementAPI.unlinkDMSFileFromWorkorderCompletion({ SourceID: sourceId, WorkOrderID: workorderId, CategoryID: categoryID });
      callback && callback();
      modalCallback && modalCallback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const linkWorkorderToFile = (data, callback) => {
  return async () => {
    try {
      const res = await documentManagementAPI.linkWorkorderToFile(data);
      const { Data } = res?.data;
      const { Workorders } = Data;
      callback && typeof callback === 'function' && callback(Workorders);
    } catch (e) {
      console.error(e);
    }
  };
};

export const unlinkWorkorderToFile = (data, callback) => {
  return async () => {
    try {
      const res = await documentManagementAPI.unlinkWorkorderToFile(data);
      const { Data } = res?.data;
      const { Workorders } = Data;
      callback && typeof callback === 'function' && callback(Workorders);
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchVersionHistoryForDocument = data => {
  return async dispatch => {
    try {
      dispatch(setVersionHistoryLoading(true));
      const res = await documentManagementAPI.fetchDMSDocumentVersionHistory(data);
      const { Data } = res?.data;
      dispatch(setVersionHistory(Data));
      dispatch(setVersionHistoryLoading(false));
    } catch (e) {
      dispatch(setVersionHistoryLoading(false));
      console.error(e);
    }
  };
};

export const setFileVersionAsActive = (data, callback) => {
  return async () => {
    try {
      await documentManagementAPI.setFileVersionAsActive(data);
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateDocumentVersion = (data, selectedDocument, callback) => {
  return async () => {
    try {
      await documentManagementAPI.updateDocumentVersion(data);
      callback && typeof callback === 'function' && callback(data, selectedDocument);
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteDMSFileVersion = (data, callback) => {
  return async () => {
    try {
      await documentManagementAPI.deleteDMSFileVersion(data);
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const linkDMSFileFromChecklistQuestion = (questionId, files, callback) => {
  return async () => {
    try {
      await documentManagementAPI.linkDMSFileFromChecklistQuestion({ QuestionID: questionId, Files: files });
      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const unlinkDMSFileFromChecklistQuestion = (sourceId, questionId, categoryID, callback, modalCallback) => {
  return async () => {
    try {
      await documentManagementAPI.unlinkDMSFileFromChecklistQuestion({ SourceID: sourceId, QuestionID: questionId, CategoryID: categoryID });
      callback && callback();
      modalCallback && modalCallback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchNewVersionNumberForDocument = (sourceID, callback) => {
  return async () => {
    try {
      const res = await documentManagementAPI.fetchNewVersionNumberForDocument(sourceID);
      const { NextVersion } = res?.data?.Data;
      if (NextVersion < 0) return;
      callback && typeof callback === 'function' && callback(NextVersion);
    } catch (e) {
      console.error(e);
    }
  };
};
