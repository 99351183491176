import React from 'react';
import PropTypes from 'prop-types';

import BasicImage from '../../../../../common/image-components/components/basic-image';
import RenderIf from '../../../../../common/render-if/components/render-if';
import { questionFields } from '../../right-toolbar/isolation-certificate-details/constants/change-status-constants';
import { millisecondsToTime } from '../../../../../common/section/helpers/section-helpers';

const SectionQuestionDetails = ({ question, expandImages, hideAdditionalDetails }, { t }) => {
  const getImages = (images, imagesProp) => {
    let selectedImages = Object.assign([], images);

    if (selectedImages && selectedImages.length) {
      if (selectedImages.length > 3 && !question[imagesProp]) {
        selectedImages = selectedImages.slice(0, 3);
      }

      return (
        <div className="screenshots-container">
          {selectedImages.map((item, index) => (
            <div className={`screenshot-image ${(index + 1) % 3 === 0 ? '' : 'screenshot-image-with-padding'}`}>
              <BasicImage src={item.URL} alt={`question-screenshot-${question[questionFields.id]}-${index}`} />
              <p className="f-primary bold screenshot-name">{t(item.FileName)}</p>
            </div>
          ))}
          <RenderIf if={images.length > 3}>
            <p className="show-more-text f-secondary-green bold" onClick={() => expandImages(imagesProp)}>
              {question[imagesProp] ? t('ISOLATION_CERTIFICATE_STATUS.IMAGES_VIEW_LESS') : t('ISOLATION_CERTIFICATE_STATUS.IMAGES_VIEW_MORE', { number: images.length - 3 })}
            </p>
          </RenderIf>
        </div>
      );
    } else {
      return <p className="f-primary">{'-'}</p>;
    }
  };

  return (
    <div className="question-details">
      <p className="f-secondary-dark bold label-separator">{t('ISOLATION_CERTIFICATE_STATUS.QUESTION_DESCRIPTION')}</p>
      <p className="f-primary">{question[questionFields.description] || '-'}</p>
      <RenderIf if={!hideAdditionalDetails}>
        <div className="details-item-aligned details-section-separator">
          <div>
            <p className="f-secondary-dark bold label-separator">{t('ISOLATION_CERTIFICATE_STATUS.QUESTION_COMPONENTS')}</p>
            <RenderIf if={question?.[questionFields.components]}>
              {question?.[questionFields.components] &&
                question?.[questionFields.components].length &&
                question?.[questionFields.components].map(item => <p className="f-primary bold label-separator">{item.Name}</p>)}
            </RenderIf>
            <RenderIf if={!question?.[questionFields.components]}>
              <p className="f-primary">{'-'}</p>
            </RenderIf>
          </div>
          <div className="moved-right">
            <p className="f-secondary-dark bold label-separator">{t('ISOLATION_CERTIFICATE_STATUS.QUESTION_ESTIMATED_TIME')}</p>
            <p className="f-primary">{question?.[questionFields.estimatedTime] ? millisecondsToTime(question?.[questionFields.estimatedTime]) : '-'}</p>
          </div>
        </div>
        <div className="details-section-separator">
          <p className="f-secondary-dark bold label-separator">{t('ISOLATION_CERTIFICATE_STATUS.WORK_AREAS_COMPONENTS')}</p>
          <RenderIf if={question?.[questionFields.areas]}>
            <>
              <p className="f-secondary-dark work-area-name label-separator">{t('ISOLATION_CERTIFICATE_STATUS.WORK_AREAS_NAME')}</p>
              {question?.[questionFields.areas] &&
                question?.[questionFields.areas].length &&
                question?.[questionFields.areas].map((item, index) => (
                  <div className={`work-area-container ${question[questionFields.areas].length - 1 < index ? 'work-area-container-with-border' : ''}`}>
                    <p className="f-primary bold label-separator">{item.Name}</p>
                  </div>
                ))}
            </>
          </RenderIf>
          <RenderIf if={!question?.[questionFields.areas]}>
            <p className="f-primary">{'-'}</p>
          </RenderIf>
        </div>
        <div className="details-section-separator details-section-separator-with-border">
          <p className="f-secondary-dark bold label-separator">{t('ISOLATION_CERTIFICATE_STATUS.QUESTION_SCREENSHOTS')}</p>
          {getImages(question?.[questionFields.screenshots], questionFields.screenshotsExpanded)}
        </div>
        <div className="details-section-separator details-section-separator-with-border">
          <p className="f-secondary-dark bold label-separator">{t('ISOLATION_CERTIFICATE_STATUS.QUESTION_IMAGES')}</p>
          {getImages(question?.[questionFields.files], questionFields.imagesExpanded)}
        </div>
      </RenderIf>
    </div>
  );
};

SectionQuestionDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

SectionQuestionDetails.propTypes = {
  question: PropTypes.shape({
    ID: PropTypes.number,
    Name: PropTypes.string.isRequired,
    QuestionType: PropTypes.number,
    QuestionOrder: PropTypes.number,
    IsMandatory: PropTypes.bool,
    AllowMultipleAnswers: PropTypes.bool,
    EstimatedTime: PropTypes.number,
    CreatedAt: PropTypes.number,
    SectionID: PropTypes.number,
  }),
  expandImages: PropTypes.func.isRequired,
  hideAdditionalDetails: PropTypes.bool,
};

export default SectionQuestionDetails;
