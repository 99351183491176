import { formConstants, textAreaMaxChars } from '../../../constants/component-constants';

const componentFieldMaxCharLength = 150;
const componentFieldMinCharLength = 3;

export const validate = values => {
  const errors = {};

  if (!values[formConstants.fields.name] || values[formConstants.fields.name].length < componentFieldMinCharLength) {
    errors[formConstants.fields.name] = {
      string: 'DEFECT_FORM.ERROR.NAME_LENGTH_MIN',
      params: { systemType: 'Equipment' },
    };
  }
  if (values[formConstants.fields.name] && values[formConstants.fields.name].length > componentFieldMaxCharLength) {
    errors[formConstants.fields.name] = {
      string: 'DEFECT_FORM.ERROR.NAME_LENGTH_MAX',
      params: { systemType: 'Equipment' },
    };
  }
  if (values[formConstants.fields.code] && values[formConstants.fields.code].length > componentFieldMaxCharLength) {
    errors[formConstants.fields.code] = {
      string: 'DEFECT_FORM.ERROR.CODE_LENGTH_MAX',
      params: { systemType: 'Equipment' },
    };
  }
  if (values[formConstants.fields.manufacturer] && values[formConstants.fields.manufacturer].length > componentFieldMaxCharLength) {
    errors[formConstants.fields.manufacturer] = {
      string: 'DEFECT_FORM.ERROR.MANUFACTURER_LENGTH_MAX',
      params: { systemType: 'Equipment' },
    };
  }
  if (values[formConstants.fields.material] && values[formConstants.fields.material].length > componentFieldMaxCharLength) {
    errors[formConstants.fields.material] = {
      string: 'DEFECT_FORM.ERROR.MATERIALS_LENGTH_MAX',
      params: { systemType: 'Equipment' },
    };
  }
  if (values[formConstants.fields.asset] && values[formConstants.fields.asset].length > componentFieldMaxCharLength) {
    errors[formConstants.fields.asset] = {
      string: 'DEFECT_FORM.ERROR.ASSET_TYPE_LENGTH_MAX',
      params: { systemType: 'Equipment' },
    };
  }
  if (values[formConstants.fields.componentType] && values[formConstants.fields.componentType].length > componentFieldMaxCharLength) {
    errors[formConstants.fields.componentType] = {
      string: 'DEFECT_FORM.ERROR.TYPE_LENGTH_MAX',
      params: { systemType: 'Equipment' },
    };
  }
  if (!values[formConstants.fields.componentType] || values[formConstants.fields.componentType].length < componentFieldMinCharLength) {
    errors[formConstants.fields.componentType] = {
      string: 'DEFECT_FORM.ERROR.NAME_LENGTH_MIN',
      params: { systemType: 'Equipment type' },
    };
  }

  if (values[formConstants.fields.componentSubType] && values[formConstants.fields.componentSubType].length > componentFieldMaxCharLength) {
    errors[formConstants.fields.componentSubType] = {
      string: 'DEFECT_FORM.ERROR.SUBTYPE_LENGTH_MAX',
      params: { systemType: 'Equipment' },
    };
  }

  if (values[formConstants.fields.location] && values[formConstants.fields.location].length > componentFieldMaxCharLength) {
    errors[formConstants.fields.location] = {
      string: 'DEFECT_FORM.ERROR.LOCATION_LENGTH_MAX',
      params: { systemType: 'Equipment' },
    };
  }

  if (values[formConstants.fields.description] && values[formConstants.fields.description].length > textAreaMaxChars) {
    errors[formConstants.fields.description] = 'COMPONENT_FORM.ERROR.COMPONENT_DESCRIPTION_LENGTH';
  }

  return errors;
};
