import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import '../styles/stepper.scss';
import Icon from '../../icon/components/icon';

class Stepper extends Component {
  isActive = (activeStep, index) => {
    return Math.floor(activeStep) >= index;
  };

  getColumns = () => {
    const { stepperData } = this.props;

    return '1fr '.repeat(stepperData.length);
  };

  renderHeaderContent = activeStep => {
    const { t } = this.context;
    const { stepperData } = this.props;
    const foundIndex = stepperData.findIndex(item => Math.floor(activeStep) === parseInt(item.stepValue, 10));
    if (foundIndex > -1) {
      return (
        <>
          <p className="f-primary">{`${foundIndex + 1}. ${t(stepperData[foundIndex].name)}`}</p>

          <label>
            <Icon name="info" handleHover={false} size="xs" />
            {t('STEPPER.STEP_DESCRIPTION', { current: foundIndex + 1, last: stepperData.length })}
          </label>
        </>
      );
    } else {
      return null;
    }
  };

  render() {
    const { stepperData, activeStep } = this.props;

    return (
      <div className="stepper">
        <div className="stepper__header">{this.renderHeaderContent(activeStep)}</div>
        <div className="stepper__steps" style={{ gridTemplateColumns: this.getColumns() }}>
          {stepperData.map((item, index) => {
            const isActive = this.isActive(activeStep, item.stepValue);

            return (
              <Fragment key={index}>
                <div className={`step ${isActive ? 'active' : ''}`} />
              </Fragment>
            );
          })}
        </div>
      </div>
    );
  }
}

Stepper.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default Stepper;
