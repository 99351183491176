import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import ExpandableSection from '../../expandable-section/components/expandable-section';
import TimePicker from '../../form/components/time-picker';
import AddButton from '../../add-button/components/add-button';
import Icon from '../../icon/components/icon';
import { ComponentsDisplay, ComponentPicker, QuestionField } from '../../question-components/components';
import { additionalFields, defaultQuestionName } from '../../question-components/constants/question-constants';
import { isEmpty } from 'lodash';
import EditModuleItemFilesModal from '../../../app/inspections/components/right-toolbar/common/edit-module-item-files-modal';
import { modules } from '../../../app/inspections/constants/constants';
import Helpers from '../../helpers';

const AdditionalFields = (
  {
    hideAdditionalFields,
    member,
    questions,
    questionIndex,
    sectionId,
    components,
    onFieldChange,
    handleComponentSelected,
    handleDeleteComponent,
    // handleManageWorkAreas,
    handleAdd3DPosition,
    handleComponentPicker3D,
    handleComponentPickerPDF,
    handleComponentPickerCH,
    handleScreenshotFrom3D,
    isDisabled,
    projectDMSCategories,
    fetchQuestionFiles,
  },
  { t }
) => {
  const { description, estimatedTime } = additionalFields;
  const question = questions[questionIndex];
  const questionScreenshots = useMemo(() => Helpers.mapExternalFilesForModal(question?.QuestionsScreenshots), [question]);

  if (hideAdditionalFields || !question) {
    return null;
  }

  return (
    <div className="additional-fields-container">
      <ExpandableSection
        CustomComponent={({ isExpanded }) => (
          <div className="additional-fields-container__title-wrapper">
            <p className="f-secondary-green">{t('SECTIONS.ADDITIONAL_FIELDS')}</p>
            <Icon size="sm" name={isExpanded ? 'chevron-up' : 'chevron-down'} />
          </div>
        )}
        className="expanded-section"
        onExpand={isExpanded => isExpanded && fetchQuestionFiles(question.ID)}
        hideExpandAction
      >
        <div className="additional-fields-container__content">
          <QuestionField
            member={member}
            name={`${defaultQuestionName}-${description.name}-${question.ID}`}
            label={description.label}
            placeholder={description.placeholder}
            type={description.type}
            id={`${description.name}-${sectionId}-${questionIndex}`}
            sectionId={sectionId}
            onChange={(_e, value) => onFieldChange(value, Object.assign({}, questions[questionIndex]), description, questionIndex, true)}
            maxChars={description.maxChars}
            fullWidth
            disabled={isDisabled}
          />
          <div className="additional-fields-container__content__picker-wrapper">
            {!isDisabled && (
              <ComponentPicker
                components={components}
                questionId={question.ID}
                handleComponentSelected={handleComponentSelected}
                handleComponentPicker3D={() => handleComponentPicker3D(question)}
                handleComponentPickerPDF={() => handleComponentPickerPDF(question)}
                handleComponentPickerCH={() => handleComponentPickerCH(question)}
                selectedComponents={question.QuestionsComponents}
                withRightPadding
                style={{ width: '100%' }}
              />
            )}
          </div>
          <ComponentsDisplay components={components} selectedComponents={question?.QuestionsComponents} featureId={question?.ID} handleDeleteComponent={!isDisabled ? handleDeleteComponent : null} />
          <div className="bottom-spacing">
            <Field
              id={`${defaultQuestionName}-${estimatedTime.name}-${question.ID}`}
              name={`${defaultQuestionName}-${estimatedTime.name}-${question.ID}`}
              onChange={(_e, value) => onFieldChange(value, Object.assign({}, questions[questionIndex]), estimatedTime, questionIndex, true)}
              component={TimePicker}
              label={t(estimatedTime.label)}
              type="text"
              size="lg"
              disabled={isDisabled}
            />
          </div>
          <p className="f-primary bottom-spacing">{t('ADDITIONAL_FIELDS.3D_LOCATION')}</p>
          <AddButton className="bottom-spacing" onClick={() => handleAdd3DPosition(question)}>
            {isEmpty(question.Geometry) || isEmpty(question.Geometry.coordinates) ? 'ADDITIONAL_FIELDS.TAG_3D_LOCATION' : 'ADDITIONAL_FIELDS.SHOW_3D_LOCATION'}
          </AddButton>
          <div className="additional-fields-container__content__files">
            <EditModuleItemFilesModal projectDMSCategories={projectDMSCategories} selectedModuleItem={question} moduleType={modules.checklistQuestion} disabled={isDisabled} />
          </div>
          {/* <AddButton className="bottom-spacing" onClick={() => handleManageWorkAreas(question)}>
            {'ADDITIONAL_FIELDS.MANAGE_WORK_AREAS'}
          </AddButton> */}
          <div className=" component-files-modal">
            <div className="category-wrapper">
              <h3 className="title">{t('SCREENSHOT_FILES')}</h3>
              {questionScreenshots?.length > 0 && (
                <div className="category-files-wrapper">
                  <div className="header">
                    <p>{t('TABLE.COMPANY_NAME')}</p>
                  </div>
                  {questionScreenshots.map((file, index) => {
                    return (
                      <div className="row" key={file.FileID}>
                        <Icon name={'image'} size="sm" active />
                        <span title={Helpers.decodeIfStringEncoded(file.FileName)} className="title" onClick={() => handleScreenshotFrom3D(question, index)}>
                          {Helpers.decodeIfStringEncoded(file.FileName || file.real_name)}
                        </span>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <AddButton className="bottom-spacing" onClick={() => handleScreenshotFrom3D(question)}>
            {'ADDITIONAL_FIELDS.TAKE_SCREENSHOT_FROM_3D'}
          </AddButton>
        </div>
      </ExpandableSection>
    </div>
  );
};

AdditionalFields.contextTypes = {
  t: PropTypes.func.isRequired,
};

AdditionalFields.propTypes = {
  hideAdditionalFields: PropTypes.bool,
  member: PropTypes.string,
  questionIndex: PropTypes.number.isRequired,
  sectionId: PropTypes.number.isRequired,
  onFieldChange: PropTypes.func.isRequired,
  components: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
    })
  ),
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
    })
  ),
  handleComponentSelected: PropTypes.func,
  handleDeleteComponent: PropTypes.func,
  toggleUploadModal: PropTypes.func,
};

AdditionalFields.defaultProps = {
  member: '',
  components: [],
  isExpanded: false,
};

export default AdditionalFields;
