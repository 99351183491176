import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const getChecklists = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_CHECKLISTS}`, data);
};

const getChecklistTypes = async data => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_CHECKLIST_TYPES}`);
};

const getChecklistDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_CHECKLIST_DETAILS}${generateQueryParams(params)}`);
};

const createChecklist = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_CHECKLIST}`, data);
};

const deleteChecklist = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_CHECKLIST}`, data);
};

const updateChecklist = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_CHECKLIST}`, data);
};

const addChecklistItem = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_CHECKLIST_ITEM}`, data);
};

const deleteChecklistItem = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_CHECKLIST_ITEM}`, data);
};
const updateChecklistItem = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_CHECKLIST_ITEM}`, data);
};

const getRegimes = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_REGIMES}`, data);
};
const getRegimeDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_REGIME_DETAILS}${generateQueryParams(params)}`);
};

const createRegime = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_REGIME}`, data);
};
const duplicateRegime = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DUPLICATE_REGIME}`, data);
};

const updateRegime = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_REGIME}`, data);
};

const getRegimeComponents = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_REGIME_COMPONENTS}`, data);
};

const addComponentToRegime = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_COMPONENT_TO_REGIME}`, data);
};

const removeComponentFromRegime = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.REMOVE_COMPONENT_FROM_REGIME}`, data);
};

const getRegimeFiles = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_REGIME_FILES}`, params);
};

const unlinkRegimeFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_REGIME_FILE}`, params);
};

const getRegimeChecklists = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_REGIME_CHECKLISTS}`, params);
};

const addChecklistToTheRegime = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_CHECKLIST_TO_THE_REGIME}`, params);
};

const deleteRegimeChecklist = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_CHECKLIST_FROM_REGIME}`, params);
};

const getIntervalList = async () => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_INTERVAL_LIST}`);
};

const updateInterval = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_INTERVAL}`, params);
};

const confirmRegime = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CONFIRM_REGIME}`, params);
};

const deleteRegime = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_REGIME}`, params);
};

const changeStatus = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_REGIME_STATUS}`, params);
};

const getRegimeTasks = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_REGIME_TASKS}`, params);
};

const saveWorkOrderChecklistTemplate = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SAVE_REGIME_CHECKLISTS}`, params);
};

const getRegimeChecklistTemplateQuestions = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_REGIME_CHECKLIST_TEMPLATE_QUESTIONS}`, params);
};

const generateAndDownloadRegimeChecklistTemplatePDF = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GENERATE_REGIME_CHECKLIST_TEMPLATE_PDF}${generateQueryParams(params)}`, { responseType: 'blob' });
};

export default {
  getChecklists,
  getChecklistTypes,
  getChecklistDetails,
  createChecklist,
  updateChecklist,
  deleteChecklist,
  addChecklistItem,
  deleteChecklistItem,
  updateChecklistItem,
  getRegimes,
  getRegimeDetails,
  createRegime,
  updateRegime,
  getRegimeComponents,
  getRegimeFiles,
  unlinkRegimeFile,
  addComponentToRegime,
  removeComponentFromRegime,
  getRegimeChecklists,
  addChecklistToTheRegime,
  deleteRegimeChecklist,
  duplicateRegime,
  getIntervalList,
  updateInterval,
  confirmRegime,
  deleteRegime,
  changeStatus,
  getRegimeTasks,
  saveWorkOrderChecklistTemplate,
  getRegimeChecklistTemplateQuestions,
  generateAndDownloadRegimeChecklistTemplatePDF,
};
