export default {
  GET_WORK_ORDERS: '/inspection/workorder/list',
  GET_WORK_ORDERS_LITE: '/inspection/workorder/lite-list',
  GET_WORK_ORDER_DETAILS: '/inspection/workorder/details',
  GET_WORKORDER_TYPES: '/inspection/workorder/types/list',
  CREATE_WORK_ORDER: '/inspection/workorder/create',
  UPDATE_WORKORDER: '/inspection/workorder/update',
  DELETE_WORKORDER: '/inspection/workorder/delete',
  GET_WORKORDER_COMPONENTS: '/inspection/workorder/component/list',
  ADD_WORKORDER_COMPONENT: '/inspection/workorder/component/add',
  DELETE_WORKORDER_COMPONENT: '/inspection/workorder/component/delete',
  GET_WORKORDER_ASSIGNEES: '/inspection/workorder/assignee/list',
  SEARCH_WORKORDER_ASSIGNEE: '/inspection/workorder/assignment/search',
  SEARCH_WORKORDER_CONTRIBUTORS: '/inspection/workorder/contributor/search',
  ADD_WORKORDER_ASSIGNEE: '/inspection/workorder/assignment/add',
  DELETE_WORKORDER_ASSIGNEE: '/inspection/workorder/assignment/delete',
  GET_WORKORDER_CONTRIBUTORS: '/inspection/workorder/contributors/list',
  ADD_WORKORDER_CONTRIBUTOR: '/inspection/workorder/contributor/add',
  DELETE_WORKORDER_CONTRIBUTOR: '/inspection/workorder/contributor/delete',
  GET_WORKORDER_CHECKLISTS: '/inspection/workorder/checklist/list',
  ADD_WORKORDER_CHECKLIST: '/inspection/workorder/checklist/add',
  DELETE_WORKORDER_CHECKLIST: '/inspection/workorder/checklist/delete',
  GET_WORKORDER_AREAS: '/inspection/workorder/workarea/list',
  ADD_WORKORDER_AREA: '/inspection/workorder/workarea/add',
  UPDATE_WORKORDER_AREA: '/inspection/workorder/workarea/update',
  DELETE_WORKORDER_AREA: '/inspection/workorder/workarea/delete',
  GET_WORKORDER_FILES: '/inspection/workorder/files/list',
  DELETE_WORKORDER_FILE: '/inspection/workorder/file/unlink',
  GET_WORK_ORDER_CHECKLIST_QUESTIONS: '/inspection/workorder/checklist/details',
  UPDATE_CHECKLIST_ANSWER: '/inspection/workorder/answer/add',
  GET_WORKORDER_PERMITS: '/project/permit/list',
  GENERATE_PDF: '/inspection/workorder/pdf',
  CHANGE_WORK_ORDER_STATUS: '/inspection/workorder/status',
  CHANGE_WORK_ORDER_STATUS_COMPLETED: '/inspection/workorder/status/completed',
  CHANGE_WORK_ORDER_ON_HOLD: '/inspection/workorder/onhold',
  ARCHIVE_WORK_ORDER: '/inspection/workorder/archive',
  GET_PERMITS: '/inspection/workorder/permit/list',
  SEARCH_PERMITS: '/inspection/workorder/permit/search',
  ADD_WORK_ORDER_PERMIT: '/inspection/workorder/permit/add',
  DELETE_WORK_ORDER_PERMIT: '/inspection/workorder/permit/delete',
  GET_WORK_ORDER_COMPLETION_FILES: '/inspection/workorder/dms/completion/files/list',
  DELETE_WORK_ORDER_COMPLETION_FILE: '/inspection/workorder/completion/file/unlink',
  GET_WORK_ORDER_COMPLETIONS_DETAILS: '/inspection/workorder/completion/get',
  POST_WORK_ORDER_COMPLETIONS_DETAILS: '/inspection/workorder/completion/update',
  GET_WORK_ORDER_STATUS_FLOWS: '/inspection/workorder/status/flow',
  POST_WORK_ORDER_PERMIT: '/inspection/workorder/permit/create',
  GET_WORK_ORDER_CUSTOM_PROPERTIES: '/properties/workorder/get',
  FETCH_WORKORDER_COMMENTS: '/inspection/workorder/comment/list',
  ADD_WORKORDER_COMMENT: '/inspection/workorder/comment/add',
  DELETE_WORKORDER_COMMENT: '/inspection/workorder/comment/delete',
  GET_WORKORDER_CUSTOM_PROPERTY_NAMES: '/properties/workorder/names',
  SEARCH_CONTRIBUTORS: '/inspection/workorder/assignments/list',
  GET_CP_TEMPLATES: '/inspection/workorder/checklist/template/list',
  SEARCH_CP_TEMPLATES: '/inspection/workorder/checklist/template/search',
  ADD_WORK_ORDER_CP_TEMPLATE: '/inspection/workorder/checklist/template/add',
  DELETE_WORK_ORDER_CP_TEMPLATE: '/inspection/workorder/checklist/template/delete',
  SUBMIT_WORK_ORDER_CHECKLIST: '/inspection/workorder/checklist/submit',
  GET_WORK_ORDER_CHECKLIST_TEMPLATE_QUESTIONS: '/inspection/workorder/checklist/template/sections/list',
  GENERATE_WORKORDER_CHECKLIST_TEMPLATE_PDF: '/inspection/workorder/checklist/pdf',
  GENERATE_WORKORDER_CHECKLIST_PDF: '/inspection/workorder/checklist/copy/pdf',
  FETCH_CHECKLISTS_PROCEDURES_TEMPLATES_FOR_CREATE_WO: '/inspection/workorder/create/checklist/template/list',
  FETCH_CHECKLISTS_PROCEDURES_TEMPLATES_FOR_WO: '/inspection/workorder/create/checklist/template/list',
  SAVE_WORK_ORDER_CHECKLIST_TEMPLATE: '/inspection/workorder/checklist/template/save',
  FETCH_WORK_ORDER_OBSERVATIONS: '/inspection/workorder/defects/list',
  CREATE_WORK_ORDER_OBSERVATION: '/inspection/workorder/defect/create',
  FETCH_OBSERVATIONS_FOR_WORK_ORDERS: '/inspection/workorder/defects/search',
  LINK_OBSERVATION_TO_WORK_ORDER: '/inspection/workorder/link/defect',
  UNLINK_OBSERVATION_TO_WORK_ORDER: '/inspection/workorder/unlink/defect',
  WORK_ORDER_UPDATE_GEOMETRY: '/inspection/workorder/geometry/update',
  WORK_ORDER_ADDITIONAL_SUBTYPES: '/inspection/workorder/additional/subtypes/list',
};
