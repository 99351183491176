import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ReactComponent as SearchIcon } from '../assets/search-icon.svg';

import Loader from '../../global-loader/components/simple-loader';
import Icon from '../../icon/components/icon';
import Portal from '../../portal/components/portal';
import RenderIf from '../../render-if/components/render-if';
import '../styles/search-input.scss';

// TODO: refactor to functional component
class SearchInput extends Component {
  constructor(props) {
    super(props);
    const { keepDropdownVisible } = props;
    this.state = {
      dropdownVisible: false,
      keepDropdownVisible: keepDropdownVisible || false,
    };
    this.node = React.createRef();
    this.inputRef = React.createRef();
  }

  handleClickOutside = e => {
    const { shouldRenderPortal, portalProps } = this.props;
    if (this.node && this.node.current && this.node.current.contains(e.target)) {
      // element is clicked no need to call click outside handler function
      return;
    }
    if (shouldRenderPortal && portalProps) {
      const portalElement = document.getElementById(portalProps.id);

      // element is clicked inside a portal, return the function or element that triggered the event  is the parent node of the portal elemen
      if (portalElement?.contains(e.target) || portalElement?.parentNode === e.target) return;
    }

    this.setState({ dropdownVisible: false, keepDropdownVisible: false });
  };

  componentDidMount() {
    const { includeDropdown } = this.props;
    if (includeDropdown) {
      document.addEventListener('click', this.handleClickOutside);
    }
  }

  componentWillUnmount() {
    const { includeDropdown } = this.props;
    if (includeDropdown) {
      document.removeEventListener('click', this.handleClickOutside);
    }
  }

  render() {
    const { t } = this.context;
    const { dropdownVisible, keepDropdownVisible } = this.state;
    const {
      includeDropdown,
      items,
      renderItem,
      emptyStateLabel,
      charLimitation,
      onFocus,
      stripped,
      wrapperClass,
      onInputFocus,
      label,
      labelClass,
      isDropdownDataLoading,
      getDropdownItemKey,
      shouldRenderPortal,
      portalProps,
      isRequired,
      grayLabel,
      showSearchIcon = true,
      ...restProps
    } = this.props;

    const isCharLimited = !isNaN(charLimitation) ? this.inputRef?.current?.value?.length < charLimitation : false;

    return (
      <div className="search-wrapper">
        {label && (
          <label className={`search-wrapper__label ${labelClass}`}>
            {`${t(label)}${isRequired ? '*' : ''}`}
            {grayLabel && <span className="search-wrapper__gray-label"> {grayLabel}</span>}
          </label>
        )}{' '}
        <div className={`search ${stripped ? 'search-input-stripped' : ''} ${wrapperClass || ''}`} ref={this.node}>
          <div className="search-wrapper__input">
            {showSearchIcon && <SearchIcon className="search__icon search-input-stripped__icon" />}
            <input
              {...restProps}
              onFocus={() => {
                this.setState({ dropdownVisible: true });
                if (typeof onInputFocus === 'function') {
                  onInputFocus();
                }
              }}
              type="text"
              className="search__input search-input-stripped__input"
              ref={this.inputRef}
              data-cy="search-input"
            />
            {includeDropdown && (
              <Icon
                name="chevron-down"
                className="dropdown-icon"
                handleHover={true}
                size="sm"
                onClick={() => {
                  const { dropdownVisible } = this.state;
                  // toggle the dropdown visible, as per requirements of: https://hybirdclarity.atlassian.net/browse/CLR-7243
                  this.setState({ dropdownVisible: !dropdownVisible });
                  if (typeof onInputFocus === 'function') {
                    onInputFocus();
                  }
                }}
              />
            )}
          </div>

          <RenderIf if={shouldRenderPortal && portalProps}>
            <Portal shouldRender={true} {...portalProps}>
              {includeDropdown && (dropdownVisible || keepDropdownVisible) && !isCharLimited && (
                <div className="items-dropdown">
                  {items?.length > 0 &&
                    items.map((item, index) => (
                      <div key={index} className="item" data-cy="search-item">
                        {renderItem ? renderItem(item, index) : null}
                      </div>
                    ))}
                  {items.length === 0 && (
                    <>
                      <RenderIf if={isDropdownDataLoading}>
                        <Loader isLoading={isDropdownDataLoading} />
                      </RenderIf>
                      <RenderIf if={!isDropdownDataLoading}>
                        <div className="empty-state">
                          <p className="f-primary">{t(emptyStateLabel)}</p>
                        </div>
                      </RenderIf>
                    </>
                  )}
                </div>
              )}
            </Portal>
          </RenderIf>

          <RenderIf if={!shouldRenderPortal && !portalProps}>
            {includeDropdown && (dropdownVisible || keepDropdownVisible) && !isCharLimited && (
              <div className="items-dropdown">
                {items?.length > 0 &&
                  items.map((item, index) => (
                    <div key={index} className="item" data-cy="search-item">
                      {renderItem ? renderItem(item, index) : null}
                    </div>
                  ))}
                {items.length === 0 && (
                  <>
                    <RenderIf if={isDropdownDataLoading}>
                      <Loader isLoading={isDropdownDataLoading} />
                    </RenderIf>
                    <RenderIf if={!isDropdownDataLoading}>
                      <div className="empty-state">
                        <p className="f-primary">{t(emptyStateLabel)}</p>
                      </div>
                    </RenderIf>
                  </>
                )}
              </div>
            )}
          </RenderIf>
        </div>
      </div>
    );
  }
}

SearchInput.contextTypes = {
  t: PropTypes.func.isRequired,
};

SearchInput.defaultProps = {
  includeDropdown: false,
  items: [],
  renderItem: null,
  emptyStateLabel: 'SEARCH_INPUT.EMPTY_STATE',
  charLimitation: null,
  stripped: false,
  keepDropdownVisible: false,
  focusInput: false,
  isDropdownDataLoading: false,
  shouldRenderPortal: false,
  portalProps: null,
};

export default SearchInput;
