export default {
  GET_SIGNED_URL: '/upload/get_signed_url',
  UPLOAD_DONE: '/upload/done',
  EXTERNAL_FILES_UPLOAD: '/project/files',
  SAVE_EXTERNAL_DEFECT_FILES: '/defect/link_to_project_files',
  SAVE_EXTERNAL_COMPONENT_FILES: '/component/link_to_project_files',
  GET_COMPONENT_FILES_UPLOADED: '/inspection/component/files/list',
  GET_DEFECT_FILES_UPLOADED: '/inspection/defect/files/list',
  GET_MEASUREMENT_FILES_UPLOADED: '/inspection/measurement/files/list',
  GET_NDT_MEASUREMENT_FILES_UPLOADED: '/inspection/ndt/measurement/files/list',
  GET_NDT_MEASUREMENT_POINT_FILES_UPLOADED: '/inspection/ndt/measures/files/list',
  UPLOAD_INSPECTON_FILE: '/inspection/file/upload/get_signed_url',
  UPLOAD_DEFECT_FILE: '/inspection/defect/file/upload/get_signed_url',
  UPLOAD_MEASUREMENT_FILE: '/inspection/measurement/upload/get_signed_url',
  UPLOAD_COMPONENT_FILE: '/inspection/component/upload/get_signed_url',
  UPLOAD_NDT_MEASUREMENT_FILE: '/inspection/ndt/measurement/upload',
  UPLOAD_NDT_MEASUREMENT_POINT_FILE: '/inspection/ndt/measure/upload',
  UPLOAD_PDF_TAGGING_FILE: '/inspection/pdf/tagging/upload/get_signed_url',
  UPLOAD_CONTRACTOR_FILE: '/project/contractor/upload/get_signed_url',
  UPLOAD_ISOLATION_TEMPLATE_FILE: '/project/isolation/template/upload/get_signed_url',
  UPLOAD_CHECKLIST_PROCEDURE_QUESTION_FILE: '/project/checklists/section/question/upload/get_signed_url',
  UPLOAD_PERMIT_TEMPLATE_FILE: '/project/permit/template/upload/get_signed_url',
  UPLOAD_ISOLATION_COMPONENT_FILE: '/project/isolation/certificate/upload/get_signed_url',
  UPLOAD_WORK_ORDER_FILE: '/inspection/workorder/completion/upload/get_signed_url',
  UPLOAD_MAINTENANCE_REGIME_FILE: '/inspection/maintenance/regime/upload/get_signed_url',
  UPLOAD_PERMIT_FILES: '/project/permit/upload/get_signed_url',
  UPLOAD_DM_FILE: '/project/document/upload',
  UPLOAD_DM_FILE_FROM_INSPECTION: '/inspection/component/dms/upload',
  GET_COMPONENT_DMS_FILES_UPLOADED: '/project/document/component/files/list',
  UPLOAD_DM_FILE_FROM_DEFECT: '/inspection/defect/dms/upload',
  UPLOAD_DM_FILE_FROM_NOTIFICATION: '/inspection/notification/dms/upload',
  UPLOAD_DM_FILE_FROM_WORKORDER: '/inspection/workorder/dms/upload',
  UPLOAD_DM_FILE_FROM_CHECKLIST_QUESTION: '/project/checklists/question/dms/upload/get_signed_url',
  UPLOAD_DM_FILE_FROM_WORKORDER_COMPLETION: '/inspection/workorder/dms/completion/upload/get_signed_url',
  UPLOAD_DM_FILE_FROM_WORKORDER_WIZARD: '/inspection/workorder/wizard/dms/upload',
};
