import { measurementTypes } from '../../../constants/constants';

export const formConstants = {
  fields: {
    id: 'ID',
    name: 'Name',
    color: 'Colour',
    description: 'Description',
    points: 'Geometry.coordinates',
    camPosition: 'CameraPosition.coordinates',
  },
};

export const filterParams = {
  searchText: 'SearchText',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  totalItems: 'TotalNumber',
  hasNext: 'HasNext',
  projectID: 'ProjectID',
  MLwithAlarms: 'MeasurementLocationsWithAlarms',
};

export const sortDirections = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const defaultFilter = {
  [filterParams.searchText]: '',
  [filterParams.lastSeen]: 0,
  [filterParams.perPage]: 20,
  [filterParams.totalItems]: 0,
  [filterParams.hasNext]: false,
  [filterParams.sortDirection]: sortDirections.DESC,
  [filterParams.sortByColumn]: formConstants.fields.id,
};

export const DefaultMeasurementLocation = camPos => ({
  Name: 'New Measurement Location',
  SystemType: measurementTypes.rgMeasurementLocation,
  Geometry: {
    type: 'Point',
    coordinates: [],
  },
  CameraPosition: camPos || {
    coordinates: [0, 0, 0],
  },
});

export const DEFAULT_COLOR = 'green';
