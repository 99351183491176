import PropTypes from 'prop-types';
import React from 'react';
import Autosuggest from 'react-autosuggest';

import { debounce, isEmpty } from 'lodash';

import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { ReactComponent as CloseIcon } from '../../../assets/close-icon.svg';
import '../styles/autocomplete.scss';
import '../styles/basic-input.scss';

// This component is used only within the Component Form, in the Materials section. Serves as a wrapper for <Autosuggest />

// TODO: lift state up
class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: '',
    };
    this.fetchRequestedDebounce = debounce(this.handleSuggestionsFetchRequested, 500);
  }

  componentWillUnmount() {
    this.fetchRequestedDebounce.cancel();
  }

  handleEnterPress = event => {
    if (this.state.value.trim() !== '') {
      this.onSuggestionSelected(event, { suggestion: { label: this.state.value } });
    }
  };

  handleOnBlur = event => {
    if (event.target.value.trim() !== '') {
      this.onSuggestionSelected(event, { suggestionValue: event.target.value });
    }
  };

  handleBackspacePressed = _event => {
    const {
      input: { onChange, value },
    } = this.props;
    if (this.state.value === '' && !isEmpty(value)) {
      // delete last element
      const newValues = [...value];
      newValues.pop();
      onChange(newValues);
    }
  };

  removeElementByIndex = index => {
    const {
      input: { onChange, value },
    } = this.props;
    const newValues = [...value];
    newValues.splice(index, 1);
    onChange(newValues);
  };

  renderInput = inputProps => {
    const { ref, placeholder, disabled, ...other } = inputProps;
    const {
      input: { value },
      // maxChars,
    } = this.props;
    return (
      <div className={`inspection-input__input-wrapper border`}>
        <div className="multiselect">
          {!isEmpty(value) &&
            value.map((el, index) => (
              <div key={index} className={`${disabled ? 'multiselect__disabled' : ''} multiselect__tag`} onClick={() => this.removeElementByIndex(index)}>
                <span className="tag-text f-primary">{el}</span>
                <CloseIcon className="tag-remove svg-primary" width={8} height={8} />
              </div>
            ))}
          <input
            {...other}
            ref={ref}
            type={'text'}
            disabled={disabled}
            // max={maxChars} // TODO: to improve in the future
            placeholder={placeholder || ''}
            onKeyDown={e => {
              if (e.key === 'Backspace') {
                this.handleBackspacePressed(e);
              }
              if (e.key === 'Enter') {
                this.handleEnterPress(e);
              }
              if (other.onKeyDown) other.onKeyDown(e);
            }}
            className={'input f-primary'}
            onBlur={this.handleOnBlur}
          />
        </div>
      </div>
    );
  };

  renderSuggestion = (suggestion, { query }) => {
    const matches = match(suggestion.label, query);
    const parts = parse(suggestion.label, matches);

    return (
      <div>
        {parts.map((part, index) => {
          return part.highlight ? (
            <span className="f-secondary-green" key={String(index)}>
              {part.text}
            </span>
          ) : (
            <span className="f-primary" key={String(index)}>
              {part.text}
            </span>
          );
        })}
      </div>
    );
  };

  renderSuggestionsContainer = ({ containerProps, children }) => {
    return (
      <div className="suggestion-container" {...containerProps}>
        {children}
      </div>
    );
  };

  getSuggestionValue = suggestion => {
    return suggestion.label;
  };

  getSuggestions = value => {
    const { fetchSuggestions, meta, maxChars } = this.props;
    const inputValue = value.trim().toLowerCase();
    // disables fetch if there are errors or inputValue.length exceeds maxChars allowed (150)
    if (!meta.error && maxChars && inputValue.length < maxChars) {
      fetchSuggestions(inputValue);
    }
  };

  handleSuggestionsFetchRequested = ({ value, _reason }) => {
    this.getSuggestions(value);
  };

  handleSuggestionsClearRequested = () => {
    const { clearSuggestions } = this.props;
    clearSuggestions();
  };

  onSuggestionSelected = (_, { suggestion }) => {
    // if the suggestion is empty return the func, since this function is called twice on one click
    // and the first call is the value that is entered not the one that should be presented for that we use sugesstion.label
    if (!suggestion) return;
    const {
      input: { onChange, value },
    } = this.props;
    if (!isEmpty(value) && value.findIndex(el => el.toLowerCase() === suggestion?.label.toLowerCase()) !== -1) {
      this.setState({ value: '' });
      return;
    }

    const newValue = [...value, suggestion?.label];
    onChange(newValue);
    this.setState({ value: '' });
  };

  render() {
    const { placeholder, meta, id, label, labelClass, isHidden, suggestions, disabled } = this.props;
    const { t } = this.context;

    return (
      <div className={`inspection-input ${isHidden ? 'hidden-field' : ''}`}>
        {label && (
          <label className={`inspection-input__label ${labelClass || 'f-secondary-dark'}`} htmlFor={id}>
            {label}
          </label>
        )}
        <Autosuggest
          renderInputComponent={this.renderInput}
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.fetchRequestedDebounce}
          onSuggestionsClearRequested={this.handleSuggestionsClearRequested}
          onSuggestionSelected={this.onSuggestionSelected}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          inputProps={{
            placeholder,
            disabled,
            value: this.state.value,
            onChange: (_event, { newValue }) => this.setState({ value: newValue }),
          }}
        />
        <div className={`error-message${meta.error ? '--visible' : ''}`}>{meta.error && t(meta.error.string, meta.error.params)}</div>
      </div>
    );
  }
}
MultiSelect.contextTypes = {
  t: PropTypes.func.isRequired,
};

MultiSelect.propTypes = {
  suggestions: PropTypes.array.isRequired,
  fetchSuggestions: PropTypes.func.isRequired,
  clearSuggestions: PropTypes.func.isRequired,
};

export default MultiSelect;
