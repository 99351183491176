import PropTypes from 'prop-types';
import React from 'react';
import { Field } from 'redux-form';

import Icon from '../../icon/components/icon';
import { questionTypes } from '../constants/question-constants';

import '../styles/question-field.scss';

const QuestionField = (
  {
    sectionId,
    member,
    name,
    label,
    placeholder,
    type,
    value,
    wrapperClassName,
    className,
    types,
    isInline,
    fullWidth,
    withRightPadding,
    withLeftPadding,
    style,
    labelTranslationObj,
    placeholderTranslationObj,
    isLocked,
    isRequired,
    ...rest
  },
  { t }
) => {
  const question = types[type];

  if (!question) {
    return null;
  }

  if (question.isStatic) {
    return (
      <p className={`f-primary ${className}`} {...rest}>
        {value}
      </p>
    );
  }

  return (
    <div
      className={`question-field ${wrapperClassName} ${fullWidth ? 'full-width' : ''} ${isInline ? 'inline' : ''} ${withRightPadding ? 'with-right-padding' : ''} ${
        withLeftPadding ? 'with-left-padding' : ''
      }`}
      style={{ ...(question.style || {}), ...style }}
    >
      <Field
        isRequired={isRequired}
        name={`${member ? `${member}.` : ''}${name}`}
        placeholder={`${t(placeholder, placeholderTranslationObj)}${isRequired && '*'}`}
        component={question.component}
        className={className}
        label={t(label, labelTranslationObj)}
        size="lg"
        {...(question.props || {})}
        {...rest}
      />
      {isLocked && <Icon name="lock" size="xs" className={`f-primary question-field__locked-icon ${withRightPadding ? 'question-field__locked-icon-with-padding' : ''}`} />}
    </div>
  );
};

QuestionField.contextTypes = {
  t: PropTypes.func.isRequired,
};

QuestionField.propTypes = {
  sectionId: PropTypes.number,
  member: PropTypes.string, // If it's part of the array field, pass the member prop
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  wrapperClassName: PropTypes.string,
  className: PropTypes.string,
  types: PropTypes.shape({
    value: PropTypes.string,
    component: PropTypes.oneOfType([PropTypes.element, PropTypes.func, PropTypes.elementType]),
    props: PropTypes.object,
    isStatic: PropTypes.bool,
  }),
  isInline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  withRightPadding: PropTypes.bool,
  withLeftPadding: PropTypes.bool,
  style: PropTypes.object,
  labelTranslationObj: PropTypes.object,
  placeholderTranslationObj: PropTypes.object,
  isLocked: PropTypes.bool,
};

QuestionField.defaultProps = {
  member: '',
  name: '',
  placeholder: '',
  label: '',
  type: '',
  value: '',
  wrapperClassName: '',
  className: '',
  types: questionTypes,
  isInline: false,
  fullWidth: false,
  withRightPadding: false,
  withLeftPadding: false,
  style: {},
  labelTranslationObj: {},
  placeholderTranslationObj: {},
  isLocked: false,
};

export default QuestionField;
