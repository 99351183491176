import notificationsApi from '../../../api/notifications/actions';
import regimeApi from '../../../api/regime/actions';
import teamsApi from '../../../api/teams/actions';
import uploadApi from '../../../api/upload/actions';
import workOrdersApi from '../../../api/work-orders/actions';

import { findIndex, get, isEmpty, map, remove, uniqBy } from 'lodash';

import { notificationModalCustom } from '../../../common/modal/actions/modal-actions';
import {
  amendNotificationWorkOrders,
  amendWorkOrderArea,
  amendWorkOrderCPTemplate,
  amendWorkOrderChecklist,
  amendWorkOrderComponent,
  amendWorkOrderContractors,
  amendWorkOrderContributor,
  amendWorkOrderPermit,
  amendWorkOrders,
  handleDeleteWorkorderModal,
  handleRemoveWorkorderByProp,
  handleUpdateWorkorderByProp,
  removeFileBySection,
  removeWorkOrderArea,
  removeWorkOrderChecklist,
  removeWorkOrderComponent,
  removeWorkOrderContractors,
  removeWorkOrderContributor,
  removeWorkOrderPermit,
  setWorkOrderAreas,
  setWorkOrderCPTemplates,
  setWorkOrderChecklists,
  setWorkOrderCompletionFiles,
  setWorkOrderComponents,
  setWorkOrderContractors,
  setWorkOrderContributors,
  setWorkOrderDetailsLoading,
  setWorkOrderFiles,
  setWorkOrderFilters,
  setWorkOrderObservations,
  setWorkOrderPaging,
  setWorkOrderPermits,
  setWorkOrderProperties,
  setWorkOrders,
  setWorkOrdersLoading,
  updateCountField,
  updateWorkOrderAreaData,
  updateWorkOrderChecklist,
} from './action-creators';

import { filterFields, statuses, workAreaConstants } from '../constants/work-order-constants';

import genericFileImage from '../../inspections/assets/component-generic-file.svg';

import { formConstants as checklistFormConstants } from '../../../app/maintenance-regime/constants/checklist-constants';
import Helpers from '../../../common/helpers';
import { participantTypes } from '../../../common/user-team/constants/constants';
import { assigneeFields, checklistFields, checklistStatuses, componentFields, formConstants as workorderConstants } from '../components/work-order/constants/constants';
import { checklistTemplateFields, fields as workOrderFields } from '../components/work-order/constants/work-order-right-side-constants';
import { measurementTypes } from '../constants/constants';
import elementActions from '../constants/element-actions';
import { formConstants as workOrderForm } from '../constants/work-order-constants';

export const getWorkOrders = (InspectionID, params, loadMore, successCallback, isLite = false, errorCallback = null) => {
  return async dispatch => {
    if (!InspectionID) {
      return;
    }
    try {
      dispatch(setWorkOrdersLoading(true));
      const action = isLite ? workOrdersApi.getWorkOrdersLite : workOrdersApi.getWorkOrders;

      if (loadMore || params[filterFields.statusFilter]) {
        delete params[filterFields.includedIDs];
      }

      const res = await action({ InspectionID, ...params });
      const { Data } = res?.data;
      const { Items, ...restProps } = Data;

      if (loadMore) {
        dispatch(amendWorkOrders(Items || []));
      } else {
        dispatch(setWorkOrders(Items || []));
      }

      dispatch(setWorkOrderFilters(Object.assign(params, restProps)));

      successCallback && successCallback(Items, restProps);
      dispatch(setWorkOrdersLoading(false));
    } catch (err) {
      dispatch(setWorkOrdersLoading(false));
      errorCallback && errorCallback(err);
    }
  };
};

export const getWorkorderDetails = (workorderId, includeLoader = true, callback) => {
  return async dispatch => {
    try {
      includeLoader && dispatch(setWorkOrderDetailsLoading(true));

      let res = await workOrdersApi.getWorkorderDetails([{ workorder_id: workorderId }]);
      const { Data } = res.data;

      if (Data) {
        Data[workOrderFields.startDate] = Data[workOrderFields.startDate] ? new Date(Data[workOrderFields.startDate] * 1000) : null;
        Data[workOrderFields.endDate] = Data[workOrderFields.endDate] ? new Date(Data[workOrderFields.endDate] * 1000) : null;
        Data[workOrderFields.dueDate] = Data[workOrderFields.dueDate] ? new Date(Data[workOrderFields.dueDate] * 1000) : null;
      }

      callback && callback(Data);
      includeLoader && dispatch(setWorkOrderDetailsLoading(false));
    } catch (err) {
      includeLoader && dispatch(setWorkOrderDetailsLoading(false));
    }
  };
};

export const getWorkorderTypes = successCallback => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.getWorkorderTypes();
      successCallback(res.data.Data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const getAdditionalWorkorderTypes = successCallback => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.fetchAdditionalWorkOrderSubtypes();
      const { Data } = res.data;
      successCallback(Data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateWorkorder = (data, callbackSuccess = () => null) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;

      await workOrdersApi.updateWorkorder(data);
      dispatch(handleUpdateWorkorderByProp(data));
      callbackSuccess();
    } catch (e) {
      console.error(e);
    }
  };
};

export const removeWorkorder = (data, callback) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      dispatch(setWorkOrdersLoading(true));

      await workOrdersApi.deleteWorkorder({ WorkOrderID: data[workorderConstants.fields.id] });
      dispatch(handleRemoveWorkorderByProp(data));
      callback && callback();
      dispatch(
        handleDeleteWorkorderModal({
          isOpen: false,
        })
      );

      dispatch(setWorkOrdersLoading(false));
    } catch (e) {
      dispatch(setWorkOrdersLoading(false));
      dispatch(
        handleDeleteWorkorderModal({
          isOpen: false,
        })
      );
      console.error(e);
    }
  };
};

export const getNotificationsByType = (InspectionID, params, successCallback) => {
  return async dispatch => {
    try {
      const res = await notificationsApi.getNotifications({ InspectionID, ...params });
      const { Items } = res.data;

      successCallback(Items);
    } catch (e) {
      console.error(e);
    }
  };
};

export const getComponentsByNotificationIds = (notificationIds, successCallback) => {
  return async dispatch => {
    try {
      if (isEmpty(notificationIds)) {
        successCallback([]);
        throw new Error('Notification ids are empty');
      }
      const res = await notificationsApi.getComponentsByNotificationIds({ NotificationIDs: notificationIds });
      const { Data } = res.data;

      successCallback(Data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const getChecklistsByNoitificationIds = (notificationIds, successCallback) => {
  return async dispatch => {
    try {
      if (isEmpty(notificationIds)) {
        successCallback([]);
        throw new Error('Notification ids are empty');
      }
      const res = await notificationsApi.getChecklistsByNotificationIds({ NotificationIDs: notificationIds });
      const { Data } = res.data;

      successCallback(Data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const searchComponents = (inspection_id, searchText, successCallback, otherParams) => {
  return async dispatch => {
    try {
      const { requestActions } = elementActions[measurementTypes.component];
      const res = await requestActions.getInspectionElementsLite({ ...{ InspectionID: parseInt(inspection_id), SearchText: searchText }, ...(otherParams || {}) });
      const { Data } = res.data;
      let components = Object.assign([], Data.Components);
      components.map(component => {
        return { ...component, [workOrderFields.componentID]: component[workOrderFields.id], [workOrderFields.componentName]: component[workOrderFields.name] };
      });

      successCallback(components);
    } catch (error) {
      console.error(error);
    }
  };
};

export const searchChecklists = (params, successAction = () => null) => {
  return async dispatch => {
    try {
      const res = await regimeApi.getChecklists({ ...params, PerPage: 1000 });
      const { ChecklistTemplates } = res.data.Data;

      successAction(ChecklistTemplates || []);
    } catch (error) {}
  };
};

export const getChecklistDetails = (checklist_template_id, successCallback) => {
  return async dispatch => {
    try {
      const res = await regimeApi.getChecklistDetails([{ checklist_template_id }]);
      const { ChecklistTemplateItems } = res.data;

      successCallback(ChecklistTemplateItems);
    } catch (error) {}
  };
};

export const getFilesByNotifications = (ids, successCallback = () => null) => {
  return async dispatch => {
    try {
      if (isEmpty(ids)) {
        successCallback({});
      }
      const res = await notificationsApi.getFilesByNotificationIds({ NotificationIDs: ids });
      const { Data } = res.data;
      successCallback(Data);
    } catch (error) {
      console.error(error);
    }
  };
};

export const singleItemUploadDone = (url, setSingleItem) => {
  return async dispatch => {
    const res = await uploadApi.setUploadDone({ Key: Helpers.getKeyFromUrl(url) });
    const data = res.data.Data;
    setSingleItem && setSingleItem(data);
  };
};

export const searchUsersAndTeams = (params, callback) => {
  return async dispatch => {
    const res = await teamsApi.searchAssignees(params);

    let { Data } = res.data;

    callback && callback(Data);
  };
};

export const searchWorkOrderContributors = (params, callback) => {
  return async dispatch => {
    const res = await workOrdersApi.searchWorkOrderContributors(params);

    let { Data } = res.data;

    callback && callback(Data);
  };
};

export const searchWorkOrderAssignees = (params, callback) => {
  return async dispatch => {
    const res = await workOrdersApi.searchWorkOrderAssignees(params);

    let { Data } = res.data;

    callback && callback(Data);
  };
};

export const createWorkOrder = (data, successCallback, destroyFormCallback) => {
  return async (dispatch, getState) => {
    try {
      const res = await workOrdersApi.createWorkOrder(data);
      const workOrders = res?.data?.Data || [];
      successCallback && successCallback(workOrders);
      const pagingObject = Object.assign({}, getState().workOrderReducer?.pagingObject);
      pagingObject[filterFields.totalItems] = pagingObject[filterFields.totalItems] + workOrders.length;

      dispatch(amendWorkOrders(workOrders));
      dispatch(amendNotificationWorkOrders(workOrders));
      dispatch(setWorkOrderPaging(pagingObject));

      dispatch(notificationModalCustom(true, 'CREATE_WORK_ORDER.SUCCESS_MESSAGE', 'CREATE_WORK_ORDER.SUCCESS_MESSAGE_TITLE'));
      destroyFormCallback && typeof destroyFormCallback === 'function' && destroyFormCallback();
    } catch (e) {
      console.error(e);
    }
  };
};

// Components
export const getWorkOrderComponents = (workOrderId, params, loadMore, successCallback) => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.getWorkOrderComponents({ WorkOrderID: workOrderId, ...params });
      const { Data, ...restProps } = res.data;
      let components = Object.assign([], Data);
      components = components.map(component => {
        return { ...component, [workOrderFields.componentID]: component[workOrderFields.id], [workOrderFields.componentName]: component[workOrderFields.name] };
      });

      if (loadMore) {
        dispatch(amendWorkOrderComponent(components));
      } else {
        dispatch(setWorkOrderComponents(components));
      }

      successCallback && successCallback(restProps);
    } catch (err) {
      console.error(err);
    }
  };
};

export const addWorkOrderComponent = (workorderId, data) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;

      const res = await workOrdersApi.addWorkorderComponent({ WorkOrderID: workorderId, ComponentID: data[componentFields.id] });
      const { Data } = res.data;
      const component = Object.assign({}, Data);
      component[workOrderFields.componentID] = component[workOrderFields.id];
      component[workOrderFields.componentName] = component[workOrderFields.name];

      dispatch(amendWorkOrderComponent(component));
      dispatch(updateCountField({ [workAreaConstants.fields.id]: workorderId }, workAreaConstants.fields.id, workOrderForm.fields.componentsNumber, 1));
    } catch (e) {
      console.error(e);
    }
  };
};

export const removeWorkorderComponent = (workorderId, data, callback) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;

      await workOrdersApi.deleteWorkorderComponent({ WorkOrderID: workorderId, ComponentID: data[componentFields.id] });
      dispatch(removeWorkOrderComponent(data));

      dispatch(updateCountField({ [workAreaConstants.fields.id]: workorderId }, workAreaConstants.fields.id, workOrderForm.fields.componentsNumber, -1));

      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const getWorkOrderContributors = (workOrderId, params, loadMore, successCallback) => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.getWorkOrderContributors({ WorkOrderID: workOrderId, ...params });
      const { Data, ...restProps } = res.data;

      if (loadMore) {
        dispatch(amendWorkOrderContributor(Data || []));
      } else {
        dispatch(setWorkOrderContributors(Data || []));
      }

      successCallback && successCallback(restProps);
    } catch (err) {
      console.error(err);
    }
  };
};

export const addWorkOrderContributor = (workorderId, projectId, inspectionId, data) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      await workOrdersApi.addWorkOrderContributor({
        WorkOrderID: workorderId,
        ProjectID: projectId,
        InspectionID: inspectionId,
        AssigneeID: data[assigneeFields.id],
        AssigneeType: data[assigneeFields.assigneeType] || data[assigneeFields.type],
        ParticipantType: data[assigneeFields.participantType],
      });

      dispatch(amendWorkOrderContributor(data));
      if (data[assigneeFields.participantType] === participantTypes.assignee) {
        dispatch(updateCountField({ [workAreaConstants.fields.id]: workorderId }, workAreaConstants.fields.id, workOrderForm.fields.assigneesNumber, 1));
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const removeWorkorderContributor = (workorderId, projectId, inspectionId, data, callback) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;

      await workOrdersApi.deleteWorkorderContributor({
        WorkOrderID: workorderId,
        ProjectID: projectId,
        InspectionID: inspectionId,
        AssigneeID: data[assigneeFields.id],
        AssigneeType: data[assigneeFields.type] || data[assigneeFields.assigneeType],
        ParticipantType: data[assigneeFields.participantType],
      });
      dispatch(removeWorkOrderContributor(data));

      if (data[assigneeFields.participantType] === participantTypes.assignee) {
        dispatch(updateCountField({ [workAreaConstants.fields.id]: workorderId }, workAreaConstants.fields.id, workOrderForm.fields.assigneesNumber, -1));
      }

      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const getWorkOrderContractors = (workOrderId, params, loadMore, successCallback) => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.getWorkOrderAssignees({ WorkOrderID: workOrderId, ...params });
      const { Data, ...restProps } = res.data;

      if (loadMore) {
        dispatch(amendWorkOrderContractors(Data || []));
      } else {
        dispatch(setWorkOrderContractors(Data || []));
      }

      successCallback && successCallback(restProps);
    } catch (err) {
      console.error(err);
    }
  };
};

export const addWorkOrderContractors = (workorderId, data) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      await workOrdersApi.addWorkorderAssignee({ WorkOrderID: workorderId, AssigneeID: data[assigneeFields.id], AssigneeType: data[assigneeFields.type] });

      dispatch(amendWorkOrderContractors(data));
      dispatch(updateCountField({ [workAreaConstants.fields.id]: workorderId }, workAreaConstants.fields.id, workOrderForm.fields.assigneesNumber, 1));
    } catch (e) {
      console.error(e);
    }
  };
};

export const removeWorkorderContractors = (workorderId, data, callback) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;

      await workOrdersApi.deleteWorkorderAssignee({ WorkOrderID: workorderId, AssigneeID: data[assigneeFields.id], AssigneeType: data[assigneeFields.type] });
      dispatch(removeWorkOrderContractors(data));

      dispatch(updateCountField({ [workAreaConstants.fields.id]: workorderId }, workAreaConstants.fields.id, workOrderForm.fields.assigneesNumber, -1));

      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const getWorkOrderChecklists = (workOrderId, params, loadMore, successCallback) => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.getWorkOrderChecklists({ WorkOrderID: workOrderId, ...params });
      const { Data, ...restProps } = res.data;

      if (loadMore) {
        dispatch(amendWorkOrderChecklist(Data || []));
      } else {
        dispatch(setWorkOrderChecklists(Data || []));
      }

      successCallback && successCallback(restProps);
    } catch (err) {
      console.error(err);
    }
  };
};

export const addWorkOrderChecklist = (workorderId, data) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      const res = await workOrdersApi.addWorkorderChecklist({ WorkOrderID: workorderId, ChecklistID: data[checklistFormConstants.fields.id] });
      const { Data } = res.data;

      dispatch(amendWorkOrderChecklist({ ...Data, [checklistFormConstants.fields.status]: checklistStatuses.new }));
      dispatch(updateCountField({ [workAreaConstants.fields.id]: workorderId }, workAreaConstants.fields.id, workOrderForm.fields.checklistsNumber, 1));
    } catch (e) {
      console.error(e);
    }
  };
};

export const getWorkOrderChecklistQuestions = (WorkOrderChecklistID, successCallback, failCallback) => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.getWorkOrderChecklistQuestions({ WorkOrderChecklistID });
      const { Data } = res.data;
      successCallback && successCallback(Data);
    } catch (e) {
      console.error(e);
      failCallback && failCallback(e);
    }
  };
};

export const getWorkOrderChecklistTemplateQuestions = (ChecklistAndProcedureTemplateID, successCallback, failCallback) => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.getWorkOrderChecklistTemplateQuestions({ ChecklistAndProcedureTemplateID });
      const { Data } = res.data;
      successCallback && successCallback(Data);
    } catch (e) {
      console.error(e);
      failCallback && failCallback(e);
    }
  };
};

export const updateChecklistAnswer = (data, callback) => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.updateChecklistAnswer(data);
      const { Data } = res.data;
      delete Data?.status;
      // update work order in list
      dispatch(updateWorkOrderChecklist({ ID: data.ModuleChecklistID, ...Data }));

      callback && callback(Data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const removeWorkorderChecklist = (workorderId, data, callback) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      await workOrdersApi.deleteWorkorderChecklist({ WorkOrderID: workorderId, WorkOrderChecklistID: data[checklistFormConstants.fields.id] });

      dispatch(removeWorkOrderChecklist(data));

      dispatch(updateCountField({ [workAreaConstants.fields.id]: workorderId }, workAreaConstants.fields.id, workOrderForm.fields.checklistsNumber, -1));
      //reduce number from table
      if (data[checklistFormConstants.fields.status] === checklistStatuses.completed) {
        dispatch(updateCountField({ [workAreaConstants.fields.id]: workorderId }, workAreaConstants.fields.id, workOrderForm.fields.filledChecklistsNumber, -1));
      }

      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const getWorkOrderAreas = (workOrderId, params, loadMore, successCallback) => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.getWorkOrderAreas({ WorkOrderID: workOrderId, ...params });
      const { Data, ...restProps } = res.data;

      if (loadMore) {
        dispatch(amendWorkOrderArea(Data || []));
      } else {
        dispatch(setWorkOrderAreas(Data || []));
      }

      successCallback && successCallback({ workAreas: Data, restProps });
    } catch (err) {
      console.error(err);
    }
  };
};

export const addWorkOrderArea = (workorderId, data) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;

      const res = await workOrdersApi.addWorkorderArea({ WorkOrderID: workorderId, ...data });
      const { Data } = res.data;
      dispatch(amendWorkOrderArea(Data));
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateWorkOrderArea = (workorderId, data, dataCallback = () => null) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;

      const res = await workOrdersApi.updateWorkOrderArea({ WorkOrderID: workorderId, ...data });
      const { Data } = res.data;
      dispatch(updateWorkOrderAreaData(Data));
      dataCallback && typeof dataCallback === 'function' && dataCallback(Data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const removeWorkorderArea = (workorderId, data) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;

      await workOrdersApi.deleteWorkorderArea({ WorkOrderID: workorderId, ID: data[workAreaConstants.fields.id] });
      dispatch(removeWorkOrderArea(data));
    } catch (e) {
      console.error(e);
    }
  };
};

export const getWorkOrderFiles = (workOrderId, successCallback) => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.getWorkOrderFiles([{ workorder_id: workOrderId }]);
      const completionFilesResponse = await workOrdersApi.getWorkOrderCompletionFiles([{ workorder_id: workOrderId }]);
      let completionFiles = completionFilesResponse?.data?.Data;

      let { Data } = res.data;
      Data = await mapFiles(Data, false);

      completionFiles = await mapFiles(completionFiles, true);

      for (let key in Data) {
        Data[key] = uniqBy((Data[key] || []).concat(completionFiles[key] || []), workOrderFields.fileId);
      }

      dispatch(setWorkOrderFiles(Data || {}));

      successCallback && successCallback();
    } catch (err) {
      console.error(err);
    }
  };
};

export const deleteWOFile = (WorkOrderID, FileID, section, successCallback, isCompletionFile) => {
  return async dispatch => {
    try {
      if (isCompletionFile) {
        await workOrdersApi.deleteWorkOrderCompletionFile({ FileID, WorkOrderID });
      } else {
        await workOrdersApi.deleteWorkOrderFile({ FileID, WorkOrderID });
      }

      dispatch(removeFileBySection({ FileID }, section));

      successCallback && successCallback();
    } catch (err) {
      console.error(err);
    }
  };
};

export const getWorkOrderPermits = (params, callback) => {
  return async dispatch => {
    const res = await workOrdersApi.getWorkOrderPermits(params);

    let { Data } = res.data;

    callback && callback(Data?.Items);
  };
};

export const generateAndDownloadPDF = (workOrderId, additionalParams, callback) => {
  return async () => {
    try {
      const res = await workOrdersApi.generatePDF([{ workorder_id: workOrderId }, ...additionalParams]);
      const fileName = res.request.getResponseHeader('content-disposition').split('filename=')[1].split(';')[0];

      const file = res?.data;
      file.name = fileName || null;
      if (file) {
        Helpers.downloadPDFFile(file);
      }

      callback && callback();
    } catch (e) {
      console.error(e);
      callback && callback();
    }
  };
};

export const changeWorkOrderStatus = (data, filters, workOrders, callback) => {
  return async dispatch => {
    try {
      if (data.Status === statuses.completed.value) {
        await workOrdersApi.changeWorkOrderStatusCompleted(data);
      } else {
        await workOrdersApi.changeWorkOrderStatus(data);
      }

      workOrders = await formatWorkOrdersOnStatusChange(workOrders, filters, data);

      dispatch(setWorkOrders(workOrders));
      callback(true);
    } catch (e) {
      console.error(e);
      callback();
    }
  };
};

export const changeWorkOrderOnHold = (data, callback) => {
  return async () => {
    try {
      await workOrdersApi.changeWorkOrderOnHold(data);

      callback(true);
    } catch (e) {
      console.error(e);
      callback();
    }
  };
};

export const archiveWorkOrder = (data, callback) => {
  return async () => {
    try {
      await workOrdersApi.archiveWorkOrder(data);

      callback(true);
    } catch (e) {
      console.error(e);
      callback();
    }
  };
};

export const deleteWorkOrder = (data, workOrders, pagingObject, callback) => {
  return async dispatch => {
    try {
      await workOrdersApi.deleteWorkorder(data);

      remove(workOrders, { [workOrderFields.id]: data[workOrderFields.workOrderID] });
      pagingObject[filterFields.totalItems] = pagingObject[filterFields.totalItems] - 1;
      pagingObject[filterFields.lastSeen] = pagingObject[filterFields.lastSeen] - 1;
      dispatch(setWorkOrders(workOrders));
      dispatch(setWorkOrderPaging(pagingObject));
      callback(true);
    } catch (e) {
      console.error(e);
      callback();
    }
  };
};

export const getPermits = (workOrderId, loadMore, callback) => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.getPermits([{ workorder_id: workOrderId }]);
      const { Data, ...restProps } = res.data;

      if (loadMore) {
        dispatch(amendWorkOrderPermit(Data || []));
      } else {
        dispatch(setWorkOrderPermits(Data || []));
      }

      callback && callback(restProps);
    } catch (e) {
      console.error(e);
    }
  };
};

export const searchPermits = (data, callback) => {
  return async () => {
    try {
      const res = await workOrdersApi.searchPermits(data);
      const permits = res?.data?.Data;

      callback(permits);
    } catch (e) {
      console.error(e);
    }
  };
};

export const addWorkOrderPermit = (workorderId, data) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;
      await workOrdersApi.addWorkOrderPermit({ WorkOrderID: workorderId, PermitID: data[assigneeFields.id] });
      dispatch(amendWorkOrderPermit(data));
      dispatch(updateCountField({ [workAreaConstants.fields.id]: workorderId }, workAreaConstants.fields.id, workOrderForm.fields.permitsNumber, 1));
    } catch (e) {
      console.error(e);
    }
  };
};

export const deleteWorkOrderPermit = (workorderId, data, callback) => {
  return async dispatch => {
    try {
      if (isEmpty(data)) return;

      await workOrdersApi.deleteWorkOrderPermit({ WorkOrderID: workorderId, PermitID: data[assigneeFields.id] });
      dispatch(removeWorkOrderPermit(data));
      dispatch(updateCountField({ [workAreaConstants.fields.id]: workorderId }, workAreaConstants.fields.id, workOrderForm.fields.assigneesNumber, -1));

      callback && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

const formatWorkOrdersOnStatusChange = async (workOrders, filters, data) => {
  const index = findIndex(workOrders, { [workOrderFields.id]: data[workOrderFields.id] });

  if (index > -1) {
    // if the new status of the certificate is one of the selected for the filters, or it's show all, just change it,
    // else, remove it since now it shouldn't be in the list
    if (!filters[filterFields.statusFilter] || !filters[filterFields.statusFilter].length || filters[filterFields.statusFilter].indexOf(data[workOrderFields.status]) > -1) {
      const workOrder = Object.assign({}, workOrders[index]);
      workOrder[workOrderFields.status] = data[workOrderFields.status];
      workOrders.splice(index, 1, workOrder);
    } else {
      workOrders.splice(index, 1);
    }
  }

  return workOrders;
};

const mapFiles = (data, isCompletionFile) => {
  Object.keys(data).map(key => {
    data[key] = Helpers.mapExternalFilesForModal(data[key], genericFileImage, isCompletionFile);
    data[key] = map(data[key], item => {
      let otherData = {};
      if (item.Drawings) {
        let Drawings = null;
        try {
          Drawings = JSON.parse(item.Drawings);
        } catch (err) {
          console.warn("can't parse drawings");
        }
        if (Drawings)
          otherData = {
            Drawings,
          };
      }

      return {
        ...item,
        ...otherData,
      };
    });

    return data[key];
  });

  return data;
};

export const getWorkOrderCompletionDetails = (workOrderID, callback) => {
  return async () => {
    try {
      const res = await workOrdersApi.getWorkOrderCompletionDetails([{ workorder_id: workOrderID }]);

      callback && callback(res?.data?.Data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const getWorkOrderCompletionDetailsFiles = (workOrderID, callback) => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.getWorkOrderCompletionFiles([{ workorder_id: workOrderID }]);
      let Data = get(res, 'data.Data');

      if (Data) {
        Data = Helpers.mapExternalFilesForModal(Data, genericFileImage);
        dispatch(setWorkOrderCompletionFiles(Data));
      } else {
        dispatch(setWorkOrderCompletionFiles([]));
      }
    } catch (e) {
      console.error(e);
      dispatch(setWorkOrderCompletionFiles([]));
    }
  };
};
export const updateWorkOrderCompletionDetails = (workOrderID, values) => {
  return async () => {
    try {
      await workOrdersApi.updateWorkOrderCompletionDetails({ ID: workOrderID, ...values });
    } catch (e) {
      console.error(e);
    }
  };
};
export const deleteWOCompletionFile = (WorkOrderID, FileID, successCallback) => {
  return async () => {
    try {
      await workOrdersApi.deleteWorkOrderCompletionFile({ FileID, WorkOrderID });

      successCallback && successCallback();
    } catch (err) {
      console.error(err);
    }
  };
};

export const getWorkOrderStatusFlows = (workOrderID, callback) => {
  return async () => {
    try {
      const res = await workOrdersApi.getWorkOrderStatusFlows([{ workorder_id: workOrderID }]);

      callback && callback(res?.data?.Data);
    } catch (e) {
      console.error(e);
    }
  };
};

export const createWorkOrderPermit = (data, workorderId, includeLoader = true, callback) => {
  return async dispatch => {
    try {
      await workOrdersApi.createWorkOrderPermit(data);

      dispatch(getPermits(workorderId, false));
      if (typeof callback === 'function') {
        callback();
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const getWorkOrderCustomProperties = WorkOrderID => {
  return async dispatch => {
    try {
      // this check is due to getWorkOrderCustomProperties triggering on load of page
      if (!WorkOrderID) return;
      dispatch(setWorkOrderProperties([]));
      const res = await workOrdersApi.getWorkOrderCustomProperties({ WorkOrderID });
      const { Properties } = res.data.Data;
      dispatch(setWorkOrderProperties(Properties || []));
    } catch (e) {
      console.error(e);
    }
  };
};

// workorder comments
export const fetchWorkOrderComments = (params, callback) => {
  return async () => {
    try {
      callback({
        commentsLoading: true,
      });
      const res = await workOrdersApi.fetchWorkOrderComments([params]);
      const { Data } = res?.data;
      callback({
        commentsList: Data,
        commentsLoading: false,
      });
    } catch (e) {
      callback({
        commentsLoading: false,
      });
      console.error(e);
    }
  };
};

export const addWorkOrderComment = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await workOrdersApi.addWorkOrderComment(filters);
      dataCallback && typeof dataCallback === 'function' && dataCallback();
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const deleteWorkOrderComment = (filters, dataCallback, loadingCallback) => {
  return async () => {
    const isLoadingCallbackValid = loadingCallback && typeof loadingCallback === 'function';
    try {
      isLoadingCallbackValid && loadingCallback(true);
      await workOrdersApi.deleteWorkOrderComment(filters);
      dataCallback && typeof dataCallback === 'function' && dataCallback();
      isLoadingCallbackValid && loadingCallback(false);
    } catch (e) {
      console.error(e);
      isLoadingCallbackValid && loadingCallback(false);
    }
  };
};

export const searchContributors = (params, callback) => {
  return async dispatch => {
    const res = await workOrdersApi.searchContributors(params);

    let { Data } = res.data;

    callback && callback(Data);
  };
};

// C&P Templates

export const getCPTemplates = (workOrderId, loadMore, callback) => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.getCPTemplates({ WorkOrderID: workOrderId });
      const { Data, ...restProps } = res.data;

      if (loadMore) {
        dispatch(amendWorkOrderCPTemplate(Data || []));
      } else {
        dispatch(setWorkOrderCPTemplates(Data || []));
      }

      callback && callback(restProps);
    } catch (e) {
      console.error(e);
    }
  };
};

export const saveCPTemplates = (WorkOrderID, data, successCallback, failCallback) => {
  return async () => {
    try {
      await workOrdersApi.saveWorkOrderChecklistTemplate({ WorkOrderID, ...data });

      successCallback && successCallback();
    } catch (e) {
      console.error(e);
      failCallback && failCallback(e);
    }
  };
};

export const submitChecklist = (ChecklistID, WorkOrderID, successCallback, failCallback) => {
  return async (dispatch, getState) => {
    try {
      const user = getState().userReducer;
      await workOrdersApi.submitWorkOrderChecklist({ ChecklistID, WorkOrderID });
      dispatch(
        updateWorkOrderChecklist({ ID: ChecklistID, [checklistFields.isSubmitted]: true, [checklistTemplateFields.submittedAt]: Date.now() / 1000, [checklistTemplateFields.submittedBy]: user.Name })
      );

      successCallback && successCallback();
    } catch (e) {
      console.error(e);
      failCallback && failCallback(e);
    }
  };
};

export const generateAndDownloadChecklistPDF = (workorder_id, workorder_checklist_id, callback) => {
  return async () => {
    try {
      const res = await workOrdersApi.generateAndDownloadChecklistPDF([{ workorder_id }, { workorder_checklist_id }]);
      const fileName = res.request.getResponseHeader('content-disposition').split('filename=')[1].split(';')[0];

      const file = res?.data;
      file.name = fileName || null;
      if (file) {
        Helpers.downloadPDFFile(file);
      }

      callback();
    } catch (e) {
      console.error(e);
      callback();
    }
  };
};

export const generateAndDownloadPDFChecklistTemplatePDF = (checklist_template_id, callback) => {
  return async () => {
    try {
      const res = await workOrdersApi.generateAndDownloadPDFChecklistTemplatePDF([{ checklist_template_id }]);
      const fileName = res.request.getResponseHeader('content-disposition').split('filename=')[1].split(';')[0];

      const file = res?.data;
      file.name = fileName || null;
      if (file) {
        Helpers.downloadPDFFile(file);
      }

      callback && callback();
    } catch (e) {
      console.error(e);
      callback && callback();
    }
  };
};

export const getChecklistTemplatesForCreateWO = (filters, checklists, callback, loadMore = false, advancedFilter = {}) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await workOrdersApi.fetchChecklistTemplatesForCreateWO({ ...filters, ...advancedFilter });
      const data = res?.data?.Data;

      if (data?.[workorderConstants.fields.checklistAndProcedureTemplates]) {
        callback({
          isLoading: false,
          checklists: loadMore ? [...checklists, ...data[workorderConstants.fields.checklistAndProcedureTemplates]] : data[workorderConstants.fields.checklistAndProcedureTemplates],
          filters: { ...filters, HasNext: data.HasNext || false, LastSeen: data.LastSeen, TotalItems: data.TotalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
    }
  };
};

export const getChecklistTemplatesForWorkOrder = (filters, checklists, callback, loadMore = false, advancedFilter = {}) => {
  return async () => {
    try {
      callback({ isLoading: true });
      const res = await workOrdersApi.fetchChecklistTemplatesForWorkOrder({ ...filters, ...advancedFilter });
      const data = res?.data?.Data;

      if (data?.ChecklistAndProcedureTemplates) {
        callback({
          isLoading: false,
          checklists: loadMore ? [...checklists, ...data.ChecklistAndProcedureTemplates] : data.ChecklistAndProcedureTemplates,
          filters: { ...filters, HasNext: data.HasNext || false, LastSeen: data.LastSeen, TotalItems: data.TotalItems },
        });
      } else {
        callback({ isLoading: false });
      }
    } catch (e) {
      callback({ isLoading: false });
    }
  };
};

export const fetchWorkOrderObservations = WorkOrderID => {
  return async dispatch => {
    try {
      const res = await workOrdersApi.fetchWorkOrderObservations({ WorkOrderID });
      const { Data } = res.data;
      dispatch(setWorkOrderObservations(Data));
    } catch (e) {
      console.error(e);
    }
  };
};

export const createWorkOrderObservation = (data, callback) => {
  return async () => {
    try {
      await workOrdersApi.createWorkOrderObservation(data);
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const fetchObservationsForWorkOrders = (WorkOrderID, InspectionID, SearchText, pagingFilters, callback, loadingCallback) => {
  return async () => {
    try {
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(true);
      const res = await workOrdersApi.fetchObservationsForWorkOrders({ WorkOrderID, InspectionID, SearchText, ...pagingFilters });
      const { Data } = res.data;
      const { WorkOrderDefectItem, ...newPagingFilters } = Data;
      callback && typeof callback === 'function' && callback(WorkOrderDefectItem, newPagingFilters);
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    } catch (e) {
      console.error(e);
      loadingCallback && typeof loadingCallback === 'function' && loadingCallback(false);
    }
  };
};

export const linkObservationToWorkOrder = (DefectID, WorkOrderID, callback) => {
  return async () => {
    try {
      await workOrdersApi.linkObservationToWorkOrder({ WorkOrderID, DefectID });
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const unlinkObservationToWorkOrder = (DefectID, WorkOrderID, callback) => {
  return async () => {
    try {
      await workOrdersApi.unlinkObservationToWorkOrder({ WorkOrderID, DefectID });
      callback && typeof callback === 'function' && callback();
    } catch (e) {
      console.error(e);
    }
  };
};

export const getChecklistTemplatesForWorkOrderAdvancedFilters = (filters, callback) => {
  return async () => {
    try {
      const res = await workOrdersApi.fetchChecklistTemplatesForWorkOrder(filters);
      const { Data } = res?.data;
      if (Data?.[workorderConstants.fields.checklistAndProcedureTemplates]) {
        callback(Data[workorderConstants.fields.checklistAndProcedureTemplates] || []);
      }
    } catch (e) {
      console.error(e);
    }
  };
};

export const updateWorkOrderGeometry = (data, successCallback) => {
  return async () => {
    try {
      await workOrdersApi.updateWorkOrderGeometry(data);
      successCallback && successCallback();
    } catch (e) {
      console.error(e);
    }
  };
};
