import { findIndex, isEmpty, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Checkbox from '../../form/components/checkbox';
import SearchInput from '../../input/components/search-input';
import RenderIf from '../../render-if/components/render-if';
import AddedUsersTeamsList from './added-users-teams-list';

import { assigneesTypes } from '../../../app/members/constants/team-constants';
import { userTeamFields } from '../constants/constants';

import '../styles/select-users-teams-multiple.scss';

const SelectUsersTeamsMultiple = (
  { usersAndTeams, addedUsersAndTeams, handleUsersAndTeamsSearch, addAssignee, removeAssignee, closeConfirmModal, searchLabelText, showAddedUsersTeamsList = true },
  { t }
) => {
  const [sortedUsersAndTeams, setSortedUsersAndTeams] = useState([]);
  const [firstTeamIndex, setFirstTeamIndex] = useState(-1);
  const [displayedItems, setDisplayedItems] = useState([]);

  const placeholder = isEmpty(addedUsersAndTeams)
    ? t('PROJECT.SEARCH.PLACEHOLDER')
    : addedUsersAndTeams.length === 1
    ? addedUsersAndTeams[0]?.[userTeamFields.name]
    : t('MULTIPLE', { numberOfItems: addedUsersAndTeams.length });
  const handleUserAssigned = (item, index, isSelected) => {
    if (isSelected) {
      removeAssignee(item, index);
    } else {
      addAssignee(item, index);
    }
  };

  //  TODO: Find a way without using useEffect
  useEffect(() => {
    if (!isEmpty(usersAndTeams)) {
      const sortedArray = orderBy(usersAndTeams, [userTeamFields.type, 'AssigneeType'], ['desc', 'desc']);
      setSortedUsersAndTeams(sortedArray);
      setFirstTeamIndex(sortedArray.findIndex(it => it[userTeamFields.type] === assigneesTypes.team || it.AssigneeType === assigneesTypes.team));
    } else {
      setSortedUsersAndTeams([]);
      setFirstTeamIndex(-1);
    }
  }, [usersAndTeams]);

  useEffect(() => {
    const addedItems =
      addedUsersAndTeams != null
        ? addedUsersAndTeams.map(addedItem => ({
            ...addedItem,
            isSelected: true,
          }))
        : [];

    const otherItems = sortedUsersAndTeams.filter(
      item => !addedUsersAndTeams?.some(addedItem => `${item[userTeamFields.type]}_${item[userTeamFields.id]}` === `${addedItem[userTeamFields.type]}_${addedItem[userTeamFields.id]}`)
    );

    const combinedItems = [...addedItems, ...otherItems];

    setDisplayedItems(combinedItems);
  }, [sortedUsersAndTeams, addedUsersAndTeams]);

  return (
    <div className="select-users-teams-container">
      <div className="input-with-dropdown">
        <label className="search-input-label">{t(searchLabelText)}</label>
        <SearchInput
          onChange={handleUsersAndTeamsSearch}
          placeholder={placeholder}
          includeDropdown={true}
          items={displayedItems}
          emptyStateLabel={'CREATE_WORKORDER_FORM.USERS_TEAMS_DROPDOWN_EMPTY_STATE'}
          renderItem={(item, index) => {
            const selectedIndex = findIndex(addedUsersAndTeams, i => `${item[userTeamFields.type]}_${item[userTeamFields.id]}` === `${i[userTeamFields.type]}_${i[userTeamFields.id]}`);
            const isSelected = item.isSelected || selectedIndex > -1;

            return (
              <div key={`${item[userTeamFields.type]}_${item[userTeamFields.id]}`}>
                {addedUsersAndTeams?.length > 0 && index === 0 && <p className="f-secondary-dark heading">{t('ADDED')}</p>}
                {index === addedUsersAndTeams?.length && <p className="f-secondary-dark heading">{t('SELECT_USERS_TEAMS.USERS_HEADING_TITLE')}</p>}
                {index === firstTeamIndex && <p className="f-secondary-dark heading">{t('SELECT_USERS_TEAMS.TEAMS_HEADING_TITLE')}</p>}
                <div
                  className={`dropdown-render-item-container ${isSelected ? 'selected' : ''}`}
                  key={`users-teams-assignee-${item[userTeamFields.id]}`}
                  onClick={() => handleUserAssigned(item, selectedIndex, isSelected)}
                >
                  <div className="user-checkbox-wrapper">
                    <div>
                      <Checkbox meta={{}} input={{ value: isSelected }} />
                    </div>
                    <div>
                      <p className="f-primary light-bold">{item[userTeamFields.name]}</p>
                      <RenderIf if={item[userTeamFields.type] === assigneesTypes.user || item.AssigneeType === assigneesTypes.user}>
                        <label className="">{item[userTeamFields.email]}</label>
                      </RenderIf>
                      <RenderIf if={item[userTeamFields.type] === assigneesTypes.team || item.AssigneeType === assigneesTypes.team}>
                        <label className="">{t('SELECT_USERS_TEAMS.MEMBERS_COUNT', { count: item[userTeamFields.membersCount] })}</label>
                      </RenderIf>
                    </div>
                  </div>
                </div>
              </div>
            );
          }}
        />
      </div>
      <RenderIf if={showAddedUsersTeamsList}>
        <AddedUsersTeamsList addedUsersAndTeams={addedUsersAndTeams} removeAssignee={removeAssignee} closeConfirmModal={closeConfirmModal} />
      </RenderIf>
    </div>
  );
};

SelectUsersTeamsMultiple.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default SelectUsersTeamsMultiple;
