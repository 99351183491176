const supportedErrorsContent = [
  1000, 1001, 1003, 1106, 1110, 1111, 1112, 1113, 1114, 1115, 1116, 1117, 1122, 1123, 1124, 1137, 1127, 1172, 1173, 1174, 1175, 1184, 1185, 2008, 2054, 3105, 3251, 3257, 1002, 1010, 1011, 1100, 1101,
  1102, 1103, 1104, 1105, 1107, 1108, 1109, 1118, 1119, 1120, 1121, 1125, 1126, 1128, 1129, 1130, 1131, 1132, 1133, 1134, 1135, 1136, 1138, 1139, 1140, 1141, 1142, 1143, 1144, 1145, 1146, 1147, 1148,
  1149, 1150, 1151, 1152, 1153, 1154, 1155, 1156, 1157, 1158, 1159, 1160, 1161, 1162, 1163, 1164, 1165, 1166, 1167, 1168, 1169, 1170, 1171, 1176, 1177, 1178, 1179, 1180, 1181, 1182, 1183, 1186, 1187,
  1188, 1190, 1191, 1192, 1193, 1194, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027,
  2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050, 2051, 2052, 2053, 2055, 2056, 2057, 2058, 2059, 2060, 2061,
  2062, 2063, 2064, 2065, 2066, 2067, 2068, 2074, 2076, 2077, 2078, 2083, 2084, 3000, 3001, 3002, 3003, 3004, 3005, 3006, 3007, 3008, 3009, 3010, 3011, 3012, 3013, 3014, 3015, 3016, 3017, 3018, 3019,
  3020, 3022, 3023, 3024, 3025, 3026, 3027, 3028, 3029, 3030, 3031, 3032, 3033, 3100, 3101, 3102, 3103, 3104, 3107, 3112, 3113, 3114, 3200, 3201, 3202, 3203, 3204, 3205, 3206, 3207, 3208, 3209, 3210,
  3211, 3212, 3213, 3215, 3216, 3250, 3252, 3255, 3256, 3270, 3271, 3272, 3273, 3281, 3282, 3283, 3284, 3300, 3301, 3302, 3303, 3304, 3401, 3402, 3403, 3405, 3406, 3407, 3408, 3499, 3500, 3501, 3502,
  3503, 3504, 3505, 3506, 3507, 3508, 3509, 3510, 3511, 3512, 3513, 3514, 3600, 3601, 3602, 3603, 3604, 3605, 3606, 3607, 3700, 3701, 3702, 3703, 3704, 3705, 3706, 3707, 3708, 3709, 3710, 3711, 3712,
  3713, 3714, 3716, 3800, 3801, 3802, 3803, 3034, 3258, 3259, 3715, 3717, 3718, 3804, 5000, 5001, 5002, 5003, 5004, 5005, 5006, 5007, 5008, 5009, 5010, 5011, 5012, 5017, 5501, 5502, 5013, 3260, 3261,
  3262, 3263, 3035, 3037, 3038, 5650, 5800, 5801, 5802, 5803, 5804, 5805, 5806, 5807, 5808, 5809, 5810, 5901, 5902, 5811, 5812, 5014, 5015, 5016, 5600, 5601, 5602, 5813, 6000, 6001, 6002, 6003, 6004,
  6005, 6006, 6007, 6008, 6100, 6101, 6102, 6103, 6104, 6105, 6106, 6107, 6108, 6109, 6110, 6111, 6112, 6113, 6114, 6115, 6116, 6117, 6131, 2089, 3036, 3214, 5651, 6118, 6119, 6120, 6121, 6122, 6123,
  6124, 6125, 6126, 6127, 6128, 6129, 6130, 6200, 6201, 6202, 6300, 6132, 6503, 6506, 6514, 6543,
];
const supportedAwsErrors = [1];
const genericMessage = 'ERROR_MODAL.GENERIC_MESSAGE';
const genericTitle = 'ERROR_MODAL.TITLE';
const modalOpenedClass = 'modal-opened';

const modalTypeConstants = {
  error: 'error',
  info: 'info',
  warning: 'warning',
};

const statusChangeNoteModalConstants = {
  comment: {
    name: 'Comment',
    label: 'STATUS_CHANGE_NOTE_MODAL.COMMENT_LABEL',
    maxChars: 500,
  },
};

export { genericMessage, genericTitle, modalOpenedClass, modalTypeConstants, statusChangeNoteModalConstants, supportedAwsErrors, supportedErrorsContent };
