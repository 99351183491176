import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty, values, find } from 'lodash';

import SectionTitle from '../right-toolbar/common/section-title';
import WorkOrderSectionChecklistQuestionsForm from './work-order-section-checklist-questions-form';
import ExpandableSection from '../../../../common/expandable-section/components/expandable-section';
import SectionEmptyState from '../right-toolbar/common/section-empty-state';
import ProgressBar from '../../../../common/progress-bar/components/progress-bar';
import Icon from '../../../../common/icon/components/icon';
import Loader from '../../../../common/global-loader/components/simple-loader';
import QuestionTagLocation from '../../../../common/question-tag-location/components/question-tag-location';
import Button from '../../../../common/form/components/button';
import Modal from '../../../../common/modal/components/modal';

import Helpers from '../../../../common/helpers';
import { moduleSectionQuestionFields, moduleSectionsFields } from '../../constants/constants';
import { questionTypes } from '../../../../common/question-components/constants/question-constants';
import { fields as workOrderField } from './constants/work-order-right-side-constants';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';

import '../../styles/module-sections.scss';
import '../../styles/work-order-section-checklist-details.scss';
import { validate } from './validators/work-order-questions-form-validator';
import { connect } from 'react-redux';
import { setWorkOrderCPSections } from '../../actions/action-creators';
import { statuses } from '../../constants/work-order-constants';

const WorkOrderSectionChecklistDetails = (
  {
    isSectionExpanded,
    sectionTitle = moduleSectionsFields.sectionSecondaryName,
    module,
    handleAnswerQuestion,
    finalStatus = '',
    user,
    skipOrdering,
    severityColors,
    // showEmptyVersion prop handles for C&P template to display EMPTY version of the template which is non-fillable
    showEmptyVersion,
    showProgressBar,
    checklistId,
    disabledForStatuses,
    inspectionId,
    handleSubmitChecklist,
    getChecklistQuestions,
    handleCancelChecklist,
    workOrderCPSections,
    setWorkOrderCPSections,
    handleFileOpen,
    categoryPropName,
    filesPropName,
  },
  { t }
) => {
  const [checklistDetails, setChecklistDetails] = useState({ Sections: [], Properties: [] });
  const [questionsAnswered, setQuestionAnswered] = useState(null);
  const [questionsTotal, setQuestionTotal] = useState(null);
  const [isPropertiesExpanded, setIsPropertiesExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [modalData, setModalData] = useState({ isOpen: false });

  useEffect(() => {
    setIsLoading(true);
    // reset WO CP sections
    setWorkOrderCPSections([]);
    getChecklistQuestions(
      checklist => {
        setChecklistDetails(checklist);
        setIsSubmitted(checklist[workOrderField.isSubmitted]);
        setQuestionAnswered(checklist?.[workOrderField.questionsAnswered] || 0);
        setQuestionTotal(checklist?.[workOrderField.questionsTotal] || 0);

        setIsLoading(false);
      },
      () => setIsLoading(false)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklistId]);

  const customProperties = useMemo(() => {
    if (isEmpty(checklistDetails?.Properties)) {
      return [];
    }
    if (isPropertiesExpanded) {
      return checklistDetails?.Properties;
    } else {
      // Show only first 4 properties
      return checklistDetails?.Properties.slice(0, 4);
    }
  }, [checklistDetails, isPropertiesExpanded]);

  const showSubmitBtn = useMemo(() => {
    return !showEmptyVersion && !isSubmitted;
  }, [showEmptyVersion, isSubmitted]);

  const hasInvalidSection = useMemo(() => {
    return (workOrderCPSections || []).some(section => section.SectionInvalid);
  }, [workOrderCPSections]);

  if (isLoading) {
    return (
      <div className="sections-container">
        <div className="sections-container__loader-wrapper">
          <Loader isLoading={true} />
        </div>
      </div>
    );
  }

  if (!checklistDetails.Sections || !checklistDetails.Sections.length) {
    return null;
  }

  const getCustomSectionFormInitialValues = section => {
    const types = values(questionTypes);
    const initialValues = {};

    // we return initialValues as {} if showEmptyVersion is true (all answers should be blank)
    if (showEmptyVersion) return initialValues;
    const sectionQuestions = Object.assign([], section[moduleSectionsFields.questions]);

    for (let i = 0; i < sectionQuestions.length; i++) {
      const type = find(types, { valueId: sectionQuestions[i][moduleSectionQuestionFields.type] });

      if (!isEmpty(type)) {
        const name = `${type.fieldName}-${sectionQuestions[i][moduleSectionQuestionFields.id]}`;
        const value =
          sectionQuestions[i][moduleSectionQuestionFields.answers] && sectionQuestions[i][moduleSectionQuestionFields.answers].length > 0
            ? sectionQuestions[i][moduleSectionQuestionFields.answers][0][type.fieldName]
            : null;

        // if the question type is "MULTI_ANSWER", we need to loop through available questions options
        // to determine is the question answered to pre-populate the field in the form
        if (type.valueId === questionTypes.MULTI_ANSWER.valueId) {
          const questionOptions = Object.assign([], sectionQuestions[i][moduleSectionQuestionFields.questionOptions]);

          for (let j = 0; j < questionOptions.length; j++) {
            if (questionOptions[j][moduleSectionQuestionFields.isAnswer]) {
              initialValues[`${name}-${questionOptions[j][moduleSectionQuestionFields.id]}`] = questionOptions[j][moduleSectionQuestionFields.id];
            }
          }
        } else if (type.valueId === questionTypes.CHECKBOX.valueId) {
          initialValues[`${name}-yes`] = value && typeof value === 'boolean' ? 'true' : null;
          initialValues[`${name}-no`] = !value && typeof value === 'boolean' ? 'false' : null;
        } else if (type.valueId === questionTypes.DATE.valueId) {
          initialValues[name] = value ? Helpers.getDateFromUnix(value) : null;
        } else {
          initialValues[name] = value;
        }
      }
    }

    return initialValues;
  };

  // determine is section disabled for editing
  const isSectionDisabled = disabled => {
    // showEmptyVersion prop handles for C&P template to display EMPTY version of the template which is non-fillable/editable
    let isDisabled = module[moduleSectionsFields.archived] || disabled || showEmptyVersion || false;

    // if the module is in final status, for example isolation certificate is in
    // status "DE_ISOLATED", section is disabled for editing
    if (module[moduleSectionsFields.status] === finalStatus) {
      isDisabled = true;
    }

    isDisabled =
      isDisabled ||
      !Helpers.hasAccess({
        user,
        visibleFor: [PERMISSIONS[PERMISSION_TYPES.workOrders].fillInCP.name],
      });

    if (module[moduleSectionsFields.status] && disabledForStatuses && disabledForStatuses.length) {
      isDisabled = isDisabled || disabledForStatuses.indexOf(module[moduleSectionsFields.status]) > -1;
    }

    return isDisabled;
  };

  const hasEditPermission = () => {
    return Helpers.hasAccess({
      user,
      visibleFor: [PERMISSIONS[PERMISSION_TYPES.workOrders].fillInCP.name],
    });
  };

  const onAnswerQuestionSuccess = data => {
    setQuestionAnswered(data?.[workOrderField.questionsAnswered] || 0);
    setQuestionTotal(data?.[workOrderField.questionsTotal] || 0);
  };

  const open3DTagLocationModal = question => {
    const closeAction = () => {
      setModalData({ isOpen: false });
    };

    setModalData({
      isOpen: true,
      CustomContent: dynamicProps => (
        <QuestionTagLocation {...dynamicProps} question={question} inspectionId={inspectionId} handleUpdateLocation={() => null} closeAction={closeAction} viewOnly={true} />
      ),
      customClassName: 'modal-no-max-height modal-large',
      type: 'none',
      closeAction,
    });
  };

  const renderSectionContent = section => {
    if (!section) {
      return null;
    }
    if (section[moduleSectionsFields.questions]) {
      return (
        <WorkOrderSectionChecklistQuestionsForm
          form={`module-sections-form-${section[moduleSectionsFields.id]}`}
          handleFieldChange={vals => handleAnswerQuestion(vals, onAnswerQuestionSuccess)}
          questions={section[moduleSectionsFields.questions]}
          moduleId={module[moduleSectionsFields.id]}
          initialValues={getCustomSectionFormInitialValues(section)}
          disabled={isSectionDisabled(section[moduleSectionsFields.disabled]) || isSubmitted || submitting}
          handle3DTagLocationClick={open3DTagLocationModal}
          handleFileOpen={handleFileOpen}
          categoryPropName={categoryPropName}
          filesPropName={filesPropName}
          {...section}
        />
      );
    }

    return <SectionEmptyState />;
  };

  const onSubmit = () => {
    setSubmitting(true);
    handleSubmitChecklist(
      () => {
        setSubmitting(false);
        setIsSubmitted(true);
      },
      () => setSubmitting(false)
    );
  };

  /**
   * explicitly and deliberately setting it like this
   */
  const workOrderHasInvalidSubmitCPStatus =
    module[moduleSectionsFields.status] !== statuses.inProgress.value &&
    module[moduleSectionsFields.status] !== statuses.completed.value &&
    module[moduleSectionsFields.status] !== statuses.closed.value;

  return (
    <div className="work-order-section-checklist-details">
      <div className="sections-container">
        {showProgressBar && typeof questionsAnswered === 'number' && typeof questionsTotal === 'number' && (
          <div className="questions-answered-progress">
            <div className="questions-answered-progress-header">
              <p className="f-secondary-dark light-bold">{t('PROGRESS')}</p>
              <p className="f-primary">
                {isSubmitted ? (
                  <div className="questions-answered-progress-header__submitted-wrapper">
                    <Icon size="xs" name="checkmark" active />
                    <p className="f-primary">{t('SUBMITTED')}</p>
                  </div>
                ) : (
                  <>
                    <span>{questionsAnswered}</span>/<span>{questionsTotal}</span> {t('ANSWERED')}
                  </>
                )}
              </p>
            </div>
            <ProgressBar height={'4px'} percentages={[Helpers.getPercentage(questionsAnswered, questionsTotal)]} colors={[severityColors?.secondaryThemeColor]} />
          </div>
        )}
        {!isEmpty(customProperties) && (
          <div className="sections-container__custom-properties">
            <div className="sections-container__custom-properties-header">
              <p className="f-secondary-dark light-bold">{t('CUSTOM_PROPERTIES')}</p>
              <Icon name={isPropertiesExpanded ? 'chevron-up' : 'chevron-down'} onClick={() => setIsPropertiesExpanded(!isPropertiesExpanded)} className="toggle-action toggle-action-expand" />
            </div>
            <div className="sections-container__custom-properties-content">
              {customProperties.map(property => (
                <div className="sections-container__custom-properties-item">
                  <p className="f-secondary-dark label" title={property?.Name}>
                    {property?.Name}
                  </p>
                  <p className="f-primary">{property?.Value}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {checklistDetails?.Sections.map((section, index) => {
          return (
            <div className="sections-item" key={`section-details-item-${section[moduleSectionsFields.id]}`}>
              <ExpandableSection
                CustomComponent={SectionTitle}
                className="expanded-section"
                customExpanded={isSectionExpanded}
                initiallyExpanded={index === 0}
                hideExpandAction
                customComponentProps={{
                  title: section[sectionTitle] || section[moduleSectionsFields.sectionName],
                  number: index + 1,
                  titleTranslatable: section.translatableTitle,
                  showError: section[moduleSectionsFields.showError],
                  isSectionExpanded: isSectionExpanded || section[moduleSectionsFields.expanded],
                  module,
                  section,
                  questions: section[moduleSectionsFields.questions],
                  formName: `module-sections-form-${section[moduleSectionsFields.id]}`,
                  sectionAction: section[moduleSectionsFields.sectionAction],
                  disabled: isSectionDisabled(section[moduleSectionsFields.disabled]) || !hasEditPermission(),
                  icon: section.icon,
                  iconClass: section.iconClass,
                  skipOrdering,
                  isChecklistProcedureTemplate: true,
                  validate: validate,
                }}
              >
                <div
                  className="section-content-item"
                  id={`module-section-content-${
                    section.translatableTitle ? t(section[sectionTitle] || section[moduleSectionsFields.sectionName]) : section[sectionTitle] || section[moduleSectionsFields.sectionName]
                  }`}
                >
                  {renderSectionContent(section)}
                </div>
              </ExpandableSection>
            </div>
          );
        })}
      </div>
      <div className={`buttons ${showSubmitBtn ? 'buttons--with-submit-btn' : ''}`}>
        <Button type="button" variant="gray-outline" height="md" width="sm" text={t('CANCEL')} onClick={handleCancelChecklist} />
        {showSubmitBtn && (
          <Button
            disabled={submitting || !hasEditPermission() || hasInvalidSection || workOrderHasInvalidSubmitCPStatus}
            type="button"
            className="buttons__save-btn"
            height="md"
            width="sm"
            variant="success"
            text={t('SUBMIT')}
            onClick={onSubmit}
          />
        )}
      </div>
      <Modal {...modalData} />
    </div>
  );
};

WorkOrderSectionChecklistDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

WorkOrderSectionChecklistDetails.propTypes = {
  isSectionExpanded: PropTypes.bool,
  sectionTitle: PropTypes.string,
  module: PropTypes.shape({
    ID: PropTypes.number,
    Name: PropTypes.string,
    Archived: PropTypes.bool,
    StartDate: PropTypes.number,
    EndDate: PropTypes.number,
    Status: PropTypes.string,
    TemplateName: PropTypes.string,
  }),
  handleSectionAction: PropTypes.func,
  handleAnswerQuestion: PropTypes.func,
  finalStatus: PropTypes.string,
  moduleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  customAction: PropTypes.string,
  skipOrdering: PropTypes.bool,
  showEmptyVersion: PropTypes.bool,
  showProgressBar: PropTypes.bool,
  checklistId: PropTypes.number.isRequired,
  inspectionId: PropTypes.number.isRequired,
  handleSubmitChecklist: PropTypes.func.isRequired,
  getChecklistQuestions: PropTypes.func.isRequired,
  handleCancelChecklist: PropTypes.func.isRequired,
  categoryPropName: PropTypes.string,
  filesPropName: PropTypes.string,
};

WorkOrderSectionChecklistDetails.defaultProps = {
  showEmptyVersion: false,
  showProgressBar: true,
  categoryPropName: 'Group',
  filesPropName: 'QuestionFiles',
};

const mapStateToProps = state => ({
  workOrderCPSections: state.workOrderReducer.workOrderCPSections,
});

const mapDispatchToProps = dispatch => ({
  setWorkOrderCPSections: data => dispatch(setWorkOrderCPSections(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkOrderSectionChecklistDetails);
