import { API_BASE_URL } from '../../common/constants';
import { Api } from '../../core/api';
import { generateQueryParams } from '../helpers/helper';
import routesConstants from './routes';

const getWorkOrders = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_WORK_ORDERS}`, data);
};

const getWorkOrdersLite = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_WORK_ORDERS_LITE}`, data);
};

const getWorkorderDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_WORK_ORDER_DETAILS}${generateQueryParams(params)}`);
};

const getWorkorderTypes = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_WORKORDER_TYPES}`);
};

const createWorkOrder = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_WORK_ORDER}`, data);
};

const updateWorkorder = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_WORKORDER}`, data);
};

const deleteWorkorder = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_WORKORDER}`, data);
};

const getWorkOrderComponents = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_WORKORDER_COMPONENTS}`, data);
};

const addWorkorderComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_WORKORDER_COMPONENT}`, data);
};

const deleteWorkorderComponent = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_WORKORDER_COMPONENT}`, data);
};

const searchWorkOrderContributors = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SEARCH_WORKORDER_CONTRIBUTORS}`, data);
};

const searchWorkOrderAssignees = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SEARCH_WORKORDER_ASSIGNEE}`, data);
};

const getWorkOrderAssignees = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_WORKORDER_ASSIGNEES}`, data);
};

const getWorkOrderContributors = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_WORKORDER_CONTRIBUTORS}`, data);
};

const addWorkorderAssignee = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_WORKORDER_ASSIGNEE}`, data);
};

const deleteWorkorderAssignee = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_WORKORDER_ASSIGNEE}`, data);
};

const addWorkOrderContributor = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_WORKORDER_CONTRIBUTOR}`, data);
};

const deleteWorkorderContributor = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_WORKORDER_CONTRIBUTOR}`, data);
};

const getWorkOrderChecklists = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_WORKORDER_CHECKLISTS}`, data);
};

const addWorkorderChecklist = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_WORKORDER_CHECKLIST}`, data);
};

const deleteWorkorderChecklist = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_WORKORDER_CHECKLIST}`, data);
};

const getWorkOrderAreas = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_WORKORDER_AREAS}`, data);
};

const addWorkorderArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_WORKORDER_AREA}`, data);
};

const updateWorkOrderArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_WORKORDER_AREA}`, data);
};

const deleteWorkorderArea = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_WORKORDER_AREA}`, data);
};

const getWorkOrderFiles = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_WORKORDER_FILES}${generateQueryParams(params)}`);
};

const deleteWorkOrderFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_WORKORDER_FILE}`, data);
};

const getWorkOrderChecklistQuestions = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_WORK_ORDER_CHECKLIST_QUESTIONS}`, data);
};

const getWorkOrderChecklistTemplateQuestions = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_WORK_ORDER_CHECKLIST_TEMPLATE_QUESTIONS}`, data);
};

const updateChecklistAnswer = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPDATE_CHECKLIST_ANSWER}`, data);
};

const getWorkOrderPermits = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_WORKORDER_PERMITS}`, data);
};

const generatePDF = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GENERATE_PDF}${generateQueryParams(params)}`, { responseType: 'blob' });
};

const changeWorkOrderStatus = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_WORK_ORDER_STATUS}`, data);
};

const changeWorkOrderStatusCompleted = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_WORK_ORDER_STATUS_COMPLETED}`, data);
};

const changeWorkOrderOnHold = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CHANGE_WORK_ORDER_ON_HOLD}`, data);
};

const archiveWorkOrder = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ARCHIVE_WORK_ORDER}`, data);
};

const getPermits = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_PERMITS}${generateQueryParams(params)}`);
};

const searchPermits = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SEARCH_PERMITS}`, data);
};

const addWorkOrderPermit = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_WORK_ORDER_PERMIT}`, data);
};

const deleteWorkOrderPermit = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_WORK_ORDER_PERMIT}`, data);
};

const getWorkOrderCompletionFiles = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_WORK_ORDER_COMPLETION_FILES}${generateQueryParams(params)}`);
};

const deleteWorkOrderCompletionFile = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_WORK_ORDER_COMPLETION_FILE}`, data);
};

const getWorkOrderCompletionDetails = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_WORK_ORDER_COMPLETIONS_DETAILS}${generateQueryParams(params)}`);
};

const updateWorkOrderCompletionDetails = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.POST_WORK_ORDER_COMPLETIONS_DETAILS}`, data);
};

const getWorkOrderStatusFlows = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_WORK_ORDER_STATUS_FLOWS}${generateQueryParams(params)}`);
};

const createWorkOrderPermit = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.POST_WORK_ORDER_PERMIT}`, data);
};

const getWorkOrderCustomProperties = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_WORK_ORDER_CUSTOM_PROPERTIES}`, data);
};

export const fetchWorkOrderComments = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.FETCH_WORKORDER_COMMENTS}${generateQueryParams(params)}`);
};

export const addWorkOrderComment = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_WORKORDER_COMMENT}`, params);
};

export const deleteWorkOrderComment = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_WORKORDER_COMMENT}`, params);
};

const getWorkorderCustomPropertyNames = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_WORKORDER_CUSTOM_PROPERTY_NAMES}`, data);
};

const searchContributors = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SEARCH_CONTRIBUTORS}`, data);
};

const getCPTemplates = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_CP_TEMPLATES}`, data);
};

const searchCPTemplates = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SEARCH_CP_TEMPLATES}`, data);
};

const addWorkOrderCPTemplate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.ADD_WORK_ORDER_CP_TEMPLATE}`, data);
};

const deleteWorkOrderCPTemplate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.DELETE_WORK_ORDER_CP_TEMPLATE}`, data);
};

const submitWorkOrderChecklist = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SUBMIT_WORK_ORDER_CHECKLIST}`, data);
};

const generateAndDownloadPDFChecklistTemplatePDF = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GENERATE_WORKORDER_CHECKLIST_TEMPLATE_PDF}${generateQueryParams(params)}`, { responseType: 'blob' });
};

const generateAndDownloadChecklistPDF = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GENERATE_WORKORDER_CHECKLIST_PDF}${generateQueryParams(params)}`, { responseType: 'blob' });
};

const fetchChecklistTemplatesForCreateWO = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_CHECKLISTS_PROCEDURES_TEMPLATES_FOR_CREATE_WO}`, data);
};

const fetchChecklistTemplatesForWorkOrder = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_CHECKLISTS_PROCEDURES_TEMPLATES_FOR_WO}`, data);
};

const saveWorkOrderChecklistTemplate = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SAVE_WORK_ORDER_CHECKLIST_TEMPLATE}`, data);
};

const fetchWorkOrderObservations = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_WORK_ORDER_OBSERVATIONS}`, data);
};

const createWorkOrderObservation = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.CREATE_WORK_ORDER_OBSERVATION}`, data);
};

const fetchObservationsForWorkOrders = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.FETCH_OBSERVATIONS_FOR_WORK_ORDERS}`, data);
};

const linkObservationToWorkOrder = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.LINK_OBSERVATION_TO_WORK_ORDER}`, data);
};

const unlinkObservationToWorkOrder = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UNLINK_OBSERVATION_TO_WORK_ORDER}`, data);
};

const updateWorkOrderGeometry = async data => {
  return await Api.post(`${API_BASE_URL}${routesConstants.WORK_ORDER_UPDATE_GEOMETRY}`, data);
};

const fetchAdditionalWorkOrderSubtypes = async data => {
  return await Api.get(`${API_BASE_URL}${routesConstants.WORK_ORDER_ADDITIONAL_SUBTYPES}`, data);
};

export default {
  getWorkOrders,
  getWorkOrdersLite,
  getWorkorderDetails,
  getWorkorderTypes,
  createWorkOrder,
  updateWorkorder,
  deleteWorkorder,
  getWorkOrderComponents,
  addWorkorderComponent,
  deleteWorkorderComponent,
  searchWorkOrderContributors,
  getWorkOrderAssignees,
  addWorkorderAssignee,
  deleteWorkorderAssignee,
  getWorkOrderChecklists,
  addWorkorderChecklist,
  deleteWorkorderChecklist,
  getWorkOrderAreas,
  addWorkorderArea,
  updateWorkOrderArea,
  deleteWorkorderArea,
  getWorkOrderFiles,
  deleteWorkOrderFile,
  getWorkOrderChecklistQuestions,
  updateChecklistAnswer,
  getWorkOrderPermits,
  generatePDF,
  changeWorkOrderStatus,
  changeWorkOrderStatusCompleted,
  changeWorkOrderOnHold,
  archiveWorkOrder,
  getPermits,
  searchPermits,
  addWorkOrderPermit,
  deleteWorkOrderPermit,
  getWorkOrderCompletionFiles,
  deleteWorkOrderCompletionFile,
  getWorkOrderCompletionDetails,
  updateWorkOrderCompletionDetails,
  getWorkOrderStatusFlows,
  createWorkOrderPermit,
  getWorkOrderCustomProperties,
  fetchWorkOrderComments,
  addWorkOrderComment,
  deleteWorkOrderComment,
  getWorkOrderContributors,
  addWorkOrderContributor,
  deleteWorkorderContributor,
  searchWorkOrderAssignees,
  getWorkorderCustomPropertyNames,
  searchContributors,
  getCPTemplates,
  searchCPTemplates,
  addWorkOrderCPTemplate,
  deleteWorkOrderCPTemplate,
  submitWorkOrderChecklist,
  getWorkOrderChecklistTemplateQuestions,
  generateAndDownloadPDFChecklistTemplatePDF,
  generateAndDownloadChecklistPDF,
  fetchChecklistTemplatesForCreateWO,
  fetchChecklistTemplatesForWorkOrder,
  saveWorkOrderChecklistTemplate,
  fetchWorkOrderObservations,
  createWorkOrderObservation,
  fetchObservationsForWorkOrders,
  linkObservationToWorkOrder,
  unlinkObservationToWorkOrder,
  updateWorkOrderGeometry,
  fetchAdditionalWorkOrderSubtypes,
};
