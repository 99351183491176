import { dropRight, findIndex, some } from 'lodash';
import Helpers from '../../../common/helpers';
import { uploadType } from '../../upload/constants/constants';
import { fields, fileFormFields, fileTypesOptions, filterProps, sortDirection, statuses, uploadedFileValidation } from '../constants/constants';
//import { history } from '../../../core/routes';

const onSort = (SortByColumn, filters, apiCall, additionalFilters = {}) => {
  const newFilters = {
    ...filters,
    ...additionalFilters,
    [filterProps.sortByColumn]: SortByColumn,
    [filterProps.sortDirection]: filters[filterProps.sortDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
    [filterProps.lastSeen]: 0,
  };
  apiCall && typeof apiCall === 'function' && apiCall(newFilters);
};

const onSearch = (SearchText, filters, apiCall, additionalFilters = {}) => {
  const newFilters = {
    ...filters,
    ...additionalFilters,
    [filterProps.lastSeen]: 0,
    SearchText,
  };
  apiCall && typeof apiCall === 'function' && apiCall(newFilters);
};

const onFolderRowClick = (row, path, filters, defaultFilters, apiCall, pathCallback, searchCallback, currentLevelCallback, additionalFilters = {}) => {
  // to be implemented once BE supports deep linking
  //   history.push({
  //     ...location,
  //     query: { ...query, folder_id: row?.ID },
  //   });
  if (!path || (path && path.length < 1)) return;
  const newPath = row?.PathItems ? [path[0], ...row.PathItems] : [...path, { Name: row?.Name, ID: row?.ID }];
  const newFilters = {
    ...filters,
    ...additionalFilters,
    [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
    [filterProps.searchText]: defaultFilters[filterProps.searchText],
    [filterProps.parentID]: typeof row?.ID === 'number' ? row.ID : null,
    [filterProps.rootID]: newPath[1]?.ID,
  };
  // apiCall: loadMore = false, resetSelectedItem = true
  apiCall && typeof apiCall === 'function' && apiCall(newFilters, false, true);
  pathCallback && typeof pathCallback === 'function' && pathCallback(newPath);
  searchCallback && typeof searchCallback === 'function' && searchCallback(defaultFilters[filterProps.searchText]);
  currentLevelCallback && typeof currentLevelCallback === 'function' && currentLevelCallback(row?.ID);
};

const onPathBackClick = (path, filters, defaultFilters, apiCall, pathCallback, searchCallback, currentLevelCallback, folderRef, additionalFilters = {}) => {
  const newPath = Object.assign([], path);
  newPath.pop();
  const newFilters = {
    ...filters,
    ...additionalFilters,
    [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
    [filterProps.searchText]: defaultFilters[filterProps.searchText],
    [filterProps.parentID]: newPath[newPath.length - 1].ID,
  };

  if (folderRef) folderRef.current = newFilters[filterProps.parentID];
  // apiCall: loadMore = false, resetSelectedItem = true
  apiCall && typeof apiCall === 'function' && apiCall(newFilters, false, true);
  pathCallback && typeof pathCallback === 'function' && pathCallback(newPath);
  searchCallback && typeof searchCallback === 'function' && searchCallback(defaultFilters[filterProps.searchText]);
  currentLevelCallback && typeof currentLevelCallback === 'function' && currentLevelCallback(newFilters[filterProps.parentID]);
};

const onPathClick = (ID, path, filters, defaultFilters, apiCall, pathCallback, searchCallback, currentLevelCallback, additionalFilters = {}) => {
  let newPath = Object.assign([], path);
  newPath = ID ? dropRight(newPath, newPath.length - 1 - findIndex(newPath, { ID })) : [newPath[0]];
  const newFilters = {
    ...filters,
    ...additionalFilters,
    [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
    [filterProps.searchText]: defaultFilters[filterProps.searchText],
    [filterProps.parentID]: ID,
  };

  // apiCall: loadMore = false, resetSelectedItem = true
  apiCall && typeof apiCall === 'function' && apiCall(newFilters, false, true);
  pathCallback && typeof pathCallback === 'function' && pathCallback(newPath);
  searchCallback && typeof searchCallback === 'function' && searchCallback(defaultFilters[filterProps.searchText]);
  currentLevelCallback && typeof currentLevelCallback === 'function' && currentLevelCallback(newFilters[filterProps.parentID]);
};

const onFolderChangeFetchFiles = (row, path, filters, defaultFilters, apiCall, searchCallback, additionalFilters = {}) => {
  if (!path || (path && path.length < 1)) return;
  const newPath = row?.PathItems ? [path[0], ...row.PathItems] : [...path, { Name: row?.Name, ID: row?.ID }];
  const newFilters = {
    ...filters,
    ...additionalFilters,
    [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
    [filterProps.searchText]: defaultFilters[filterProps.searchText],
    [filterProps.parentID]: typeof row?.ID === 'number' ? row.ID : null,
    [filterProps.rootID]: newPath[1]?.ID,
  };
  apiCall && typeof apiCall === 'function' && apiCall(newFilters);
  searchCallback && typeof searchCallback === 'function' && searchCallback(defaultFilters[filterProps.searchText]);
};

const onPathBackFetchFiles = (path, filters, defaultFilters, apiCall, searchCallback, additionalFilters = {}) => {
  const newPath = Object.assign([], path);
  newPath.pop();

  const newFilters = {
    ...filters,
    ...additionalFilters,
    [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
    [filterProps.searchText]: defaultFilters[filterProps.searchText],
    [filterProps.parentID]: newPath[newPath.length - 1].ID,
  };

  apiCall && typeof apiCall === 'function' && apiCall(newFilters);
  searchCallback && typeof searchCallback === 'function' && searchCallback(defaultFilters[filterProps.searchText]);
};

const onPathClickFetchFiles = (ID, filters, defaultFilters, apiCall, searchCallback, additionalFilters = {}) => {
  const newFilters = {
    ...filters,
    ...additionalFilters,
    [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
    [filterProps.searchText]: defaultFilters[filterProps.searchText],
    [filterProps.parentID]: ID,
  };

  apiCall && typeof apiCall === 'function' && apiCall(newFilters);
  searchCallback && typeof searchCallback === 'function' && searchCallback(defaultFilters[filterProps.searchText]);
};

const getStatuses = statusData => {
  const statusesObj = {};
  if (!statusData) return statusesObj;
  statusData.forEach(status => {
    statusesObj[status] = statuses[status];
  });
  return statusesObj;
};

// this one will become obsolete once we fully migrate to notification stacking via invalidUploadedFiles method
export const isUploadedDMSFileValid = items => {
  const hasLargeFiles = some(items, item => item.size > uploadedFileValidation.bytes);

  if (hasLargeFiles) {
    return false;
  }
  return true;
};

export const isUploadedDMSFileNameValid = items => {
  const hasLargeFileNames = some(items, item => item.name.length > fileFormFields.fileName.maxChars);

  if (hasLargeFileNames) {
    return false;
  }
  return true;
};

export const invalidUploadedFiles = files => files.filter(file => file.size > uploadedFileValidation.bytes);

// reusing this as a helper for DMS file upload, used it also on EditModuleItemFilesModal component
export const onDMSDropAsset = async (files, successCallback, fileUploadCallback, type = uploadType.dmFiles) => {
  const dmFileUploadType = type;
  let validFiles = files.filter(file => file.size <= uploadedFileValidation.bytes);

  if (!validFiles || validFiles.length === 0) {
    return;
  }

  const tmp_files = await Promise.all(
    validFiles.map(async (file, index) => {
      const fileExtensionIndex = file.name.lastIndexOf('.');
      const fileExtension = file.name.substring(fileExtensionIndex + 1, file.name.length);

      const tmp_file = {
        type: Helpers.getFileType(file[fields.type]),
        [fields.fileType]: fileExtension,
        mimeType: file[fields.type],
        title: '',
        description: '',
        size: file.size,
        [fields.fileSizeKB]: Helpers.formatBytes(file.size),
        real_name: Helpers.formatFileName(file.name),
        name: Helpers.formatFileName(file.name),
        [fields.status]: 'LIVE',
        index: index,
        [fields.smartDrawing]: false,
        realFile: file,
      };

      if (fileExtension === fileTypesOptions.pdf) {
        try {
          const count = !file[fields.numberOfPages] ? await Helpers.getFileNumberOfPages(file) : file[fields.numberOfPages];
          file[fields.numberOfPages] = count;
        } catch (error) {
          console.error(`Error processing PDF file '${file.name}':`, error);
          // Optionally, you can mark the file as invalid here or handle it in any other way.
          // For example, you can set a flag or remove it from further processing.
          // Continue processing other files instead of returning.
        }
      }

      // TODO: in the future introduce notification that file is corrupted and therefore won't be uploaded
      // if(file[fields.numberOfPages] < 1) return;
      file.timeStamp = Date.now();
      file.real_name = tmp_file.real_name;
      file.uploadType = dmFileUploadType;

      return { ...tmp_file, ...file };
    })
  );

  successCallback && successCallback(tmp_files);
  fileUploadCallback && fileUploadCallback(tmp_files);
};

const getCategories = (categories, t) => {
  return categories.map(category => {
    return { ...category, Name: t(category[fields.name]) };
  });
};

/**
 * The function `getDirtyFormFields` compares two sets of form values and returns an array of fields
 * that have changed.
 * @param prevFormValues - The `prevFormValues` parameter in the `getDirtyFormFields` function refers
 * to the previous values of a form before any changes were made.
 * @param newFormValues - newFormValues is an object containing the updated values of a form, where the
 * keys represent the form field names and the values represent the new values entered by the user.
 * @returns The function `getDirtyFormFields` returns an array of field names that have different
 * values between the `prevFormValues` and `newFormValues` objects.
 */
const getDirtyFormFields = (prevFormValues, newFormValues) => {
  const dirtyFields = Object.keys(prevFormValues).filter(field => prevFormValues[field] !== newFormValues[field]);
  return dirtyFields;
};

// filtering categories on the FE side
const filterCategoriesByName = (searchText, stateCategoriesList, initialCategoriesList, setStateCallback) => {
  if (searchText === '' || !stateCategoriesList || stateCategoriesList?.length === 0) {
    setStateCallback(initialCategoriesList);
  } else {
    const matchedCategories = initialCategoriesList.filter(c => c[fields.name].toLowerCase().includes(searchText.toLowerCase()));
    setStateCallback(matchedCategories);
  }
};

const updateSelectedCategoriesHelper = (selectedCategory, initialSelectedCategories, defaultDMSCategoryIDBackup, assignUncategorised = false, t) => {
  let updatedSelectedCategories = [...initialSelectedCategories];
  const selectedCategoryIndex = updatedSelectedCategories.findIndex(cat => cat[fields.id] === selectedCategory[fields.id]);

  if (selectedCategoryIndex > -1) {
    updatedSelectedCategories.splice(selectedCategoryIndex, 1);
  } else {
    updatedSelectedCategories.push(selectedCategory);
  }

  const defaultCategoryIndex = updatedSelectedCategories.findIndex(cat => cat[fields.id] === defaultDMSCategoryIDBackup);
  if (defaultCategoryIndex > -1 && updatedSelectedCategories.length > 1) {
    if (defaultCategoryIndex === updatedSelectedCategories.length - 1) {
      updatedSelectedCategories = [{ [fields.id]: defaultDMSCategoryIDBackup, [fields.name]: t('NO_CATEGORY') }];
    } else {
      updatedSelectedCategories.splice(defaultCategoryIndex, 1);
    }
  } else if (updatedSelectedCategories.length === 0 && assignUncategorised) {
    // makes sure that 'Uncategorised' category always has to be assigned to a component, if there are no other categories assigned
    updatedSelectedCategories.push({ [fields.id]: defaultDMSCategoryIDBackup, [fields.name]: t('NO_CATEGORY') });
  }

  return updatedSelectedCategories;
};

const handleCategorySelect = (category, initialSelectedCategories, defaultDMSCategoryIDBackup, setStateCallback, t) => {
  const updatedSelectedCategories = updateSelectedCategoriesHelper(category, initialSelectedCategories, defaultDMSCategoryIDBackup, true, t);
  setStateCallback(updatedSelectedCategories);
};

const handleComponentCategorySelect = (index, category, componentSelectedCategories, selectedComponents, defaultDMSCategoryIDBackup, setSelectedComponentsCallback, t) => {
  const updatedSelectedCategories = updateSelectedCategoriesHelper(category, componentSelectedCategories, defaultDMSCategoryIDBackup, false, t);
  const newSelectedComponents = Object.assign([], selectedComponents);
  const newValueIDs = updatedSelectedCategories.map(cat => cat[fields.id]);

  newSelectedComponents[index] = { ...newSelectedComponents[index], [fields.categoriesID]: newValueIDs, [fields.componentSelectedCategories]: updatedSelectedCategories };
  setSelectedComponentsCallback(newSelectedComponents);
};

// field added in case some other field needs to be checked
const getSearchInputValue = (selectedCategories, t, field = null) =>
  selectedCategories?.length === 1
    ? selectedCategories[0][fields.name] || selectedCategories[0][field]
    : selectedCategories?.length > 1
    ? t('MULTIPLE', { numberOfItems: selectedCategories.length })
    : t('SEARCH');

export default {
  onSort,
  onSearch,
  onFolderRowClick,
  onPathBackClick,
  onPathClick,
  onFolderChangeFetchFiles,
  onPathBackFetchFiles,
  onPathClickFetchFiles,
  getStatuses,
  getCategories,
  getDirtyFormFields,
  filterCategoriesByName,
  handleCategorySelect,
  getSearchInputValue,
  handleComponentCategorySelect,
};
