import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../../../../../common/icon/components/icon';
import { connect } from 'react-redux';
import { modules } from '../../../../inspections/constants/constants';
import Helpers from '../../../../../common/helpers';
import { fields } from '../../../constants/constants';
import { isEmpty } from 'lodash';

const ChooseDMSLocationRightSide = (
  {
    initialValues,
    onUnlinkFileFromSelectedModuleItem,
    moduleItemType,
    defectFiles,
    componentFiles,
    notificationFiles,
    workorderFiles,
    workOrderCompletionFiles,
    checklistQuestionFiles,
    selectedModuleItem,
    hasUnlinkDMSFilePermissions,
  },
  { t }
) => {
  const filesMap = {
    [modules.defects]: defectFiles,
    [modules.components]: componentFiles,
    [modules.notifications]: notificationFiles,
    [modules.workorders]: workorderFiles,
    [modules.checklistQuestion]: !isEmpty(selectedModuleItem) ? checklistQuestionFiles[selectedModuleItem[fields.id]] : null,
    [modules.workorderCompletion]: workOrderCompletionFiles,
  };

  const selectedModuleItemFiles = !isEmpty(filesMap[moduleItemType]) ? filesMap[moduleItemType] : [];
  const selectedCategoryLinkedFiles = selectedModuleItemFiles?.length > 0 && selectedModuleItemFiles.filter(cf => cf[fields.categoryId] === initialValues[fields.id]);

  return (
    <div className="change-hierarchy-details-section">
      <div className="change-hierarchy-details-section__title">
        <div className="change-hierarchy-details-section__title__text">
          <h5 className="f-primary bold">{t('ADDED_DOCUMENTS')}</h5>
        </div>
      </div>
      {(!selectedCategoryLinkedFiles || selectedCategoryLinkedFiles?.length === 0) && <div className="empty-state">{t('NO_DOCUMENTS_YET')}</div>}
      {selectedCategoryLinkedFiles?.length > 0 && (
        <div className="category-files-wrapper">
          <div className="header">
            <p>{t('TABLE.COMPANY_NAME')}</p>
          </div>
          {selectedCategoryLinkedFiles.map(file => {
            return (
              <div className="row" key={file[fields.fileId]} title={Helpers.decodeIfStringEncoded(file[fields.fileName] || file[fields.realName])}>
                <p className="title three-dot-text">{Helpers.decodeIfStringEncoded(file[fields.fileName] || file[fields.realName])}</p>
                <Icon
                  name="close"
                  size="sm"
                  disabled={!hasUnlinkDMSFilePermissions}
                  handleHover={hasUnlinkDMSFilePermissions}
                  className={`${!hasUnlinkDMSFilePermissions ? '' : 'action'}`}
                  onClick={hasUnlinkDMSFilePermissions ? () => onUnlinkFileFromSelectedModuleItem(file) : null}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

ChooseDMSLocationRightSide.contextTypes = {
  t: PropTypes.func.isRequired,
};

ChooseDMSLocationRightSide.defaultProps = {
  moduleItemType: PropTypes.string,
};

const mapStateToProps = state => ({
  defectFiles: state.uploadReducer.defectFiles,
  componentFiles: state.uploadReducer.componentFiles,
  notificationFiles: state.uploadReducer.notificationFiles,
  workorderFiles: state.uploadReducer.workorderFiles,
  checklistQuestionFiles: state.uploadReducer.checklistQuestionFiles,
  workOrderCompletionFiles: state.workOrderReducer.workOrderCompletionFiles,
});

export default connect(mapStateToProps, null)(ChooseDMSLocationRightSide);
