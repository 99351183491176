import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../common/permissions-constants';
import { detailsPages, modules, viewQueryPropTypes } from '../../../constants/constants';
import { formatGroupTableCells, formatLocationTableCells, formatPointTableCells } from '../actions/table-formatting';

export const viewOptions = viewQueryPropTypes[modules.readingsAndGauges];

export const fields = {
  id: 'ID',
  name: 'Name',
  icon: 'Icon',
  createdAt: 'CreatedAt',
  createdBy: 'CreatedBy',
  createdByUser: 'CreatedByUser',
  toggle: 'toggle',
  alarm: 'alarm',
  returnToDefault: 'returnToDefault',
  searchText: 'SearchText',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  projectID: 'ProjectID',
  inspectionID: 'InspectionID',
  hasNext: 'HasNext',
  totalNumber: 'TotalNumber',
  editAction: 'editAction',
  deleteAction: 'deleteAction',
  changeGroupAction: 'changeGroupAction',
  isActive: 'isActive',
  password: 'Password',
  addReading: 'AddReading',
  alarmStatusColor: 'AlarmStatusColour',
  alarmStatusName: 'AlarmStatusName',
  color: 'Colour',
  readingsAndGaugesGroupID: 'ReadingsAndGaugesGroupIDs',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  componentIDs: 'ComponentIDs',
  hasAlarm: 'HasAlarm',
  measurementLocationID: 'MeasurementLocationID',
  measurementUnits: 'MeasurementUnits',
  measurementPointIDs: 'MeasurementPointIDs',
  includeAnalytics: 'IncludeAnalytics',
  unit: 'Unit',
  measurementPointTriggeredAlarms: 'MeasurementPointTriggeredAlarms',
};

export const getGroupTableConfig = (isFullScreen, isModal = false) => {
  const tableConfig = [
    {
      title: 'READINGS_AND_GAUGES.GROUP_NAME',
      key: fields.name,
      type: fields.name,
      class: 'name-column',
      isSort: true,
    },
    {
      title: '',
      key: fields.toggle,
      type: fields.toggle,
      class: 'toggle-column',
    },
  ];
  if (isFullScreen) {
    tableConfig.pop();
    tableConfig.push(
      {
        title: 'READINGS_AND_GAUGES.CREATED_AT',
        key: fields.createdAt,
        type: fields.createdAt,
        class: 'created-at-column',
        isSort: true,
      },
      {
        title: 'READINGS_AND_GAUGES.CREATED_BY',
        key: fields.createdByUser,
        type: fields.createdByUser,
        class: 'created-by-column',
        isSort: true,
      }
    );
  }

  if (isModal) {
    tableConfig.push(
      {
        title: '',
        key: fields.editAction,
        type: fields.editAction,
        class: 'action-column',
      },
      {
        title: '',
        key: fields.deleteAction,
        type: fields.deleteAction,
        class: 'action-column',
      }
    );
  }
  return tableConfig;
};

export const getLocationTableConfig = isFullScreen => {
  const tableConfig = [
    {
      title: 'READINGS_AND_GAUGES.ID',
      key: fields.id,
      type: fields.id,
      class: 'id-column',
      isSort: true,
    },
    {
      title: 'READINGS_AND_GAUGES.MEASUREMENT_LOCATION_NAME',
      key: fields.name,
      type: fields.name,
      class: 'name-column',
      isSort: true,
    },
    {
      title: '',
      key: fields.alarmStatusColor,
      type: fields.alarmStatusColor,
      class: 'alarm-column',
      isSort: false,
    },
    {
      title: '',
      key: fields.toggle,
      type: fields.toggle,
      class: 'toggle-column',
    },
  ];
  if (isFullScreen) {
    tableConfig.splice(-2, 2);
    tableConfig.push(
      {
        title: 'READINGS_AND_GAUGES.CREATED_AT',
        key: fields.createdAt,
        type: fields.createdAt,
        class: 'created-at-column',
        isSort: true,
      },
      {
        title: 'READINGS_AND_GAUGES.CREATED_BY',
        key: fields.createdByUser,
        type: fields.createdByUser,
        class: 'created-by-column',
        isSort: true,
      },
      {
        title: '',
        key: fields.alarmStatusColor,
        type: fields.alarmStatusColor,
        class: 'alarm-column',
        isSort: false,
      }
    );
  }
  return tableConfig;
};

export const getPointTableConfig = isFullScreen => {
  const tableConfig = [
    {
      title: 'READINGS_AND_GAUGES.ID',
      key: fields.id,
      type: fields.id,
      class: 'id-column',
      isSort: true,
    },
    {
      title: 'READINGS_AND_GAUGES.MEASUREMENT_POINT_NAME',
      key: fields.name,
      type: fields.name,
      class: 'name-column',
      isSort: true,
    },
    {
      title: '',
      key: fields.alarmStatusColor,
      type: fields.alarmStatusColor,
      class: 'alarm-column',
      isSort: false,
    },
    {
      title: '',
      key: fields.addReading,
      type: fields.addReading,
      class: 'add-reading-column',
    },
    {
      title: '',
      key: fields.toggle,
      type: fields.toggle,
      class: 'toggle-column',
    },
  ];
  if (isFullScreen) {
    tableConfig.splice(-3, 3);
    tableConfig.push(
      {
        title: 'READINGS_AND_GAUGES.CREATED_AT',
        key: fields.createdAt,
        type: fields.createdAt,
        class: 'created-at-column',
        isSort: true,
      },
      {
        title: 'READINGS_AND_GAUGES.CREATED_BY',
        key: fields.createdByUser,
        type: fields.createdByUser,
        class: 'created-by-column',
        isSort: true,
      },
      {
        title: '',
        key: fields.alarmStatusColor,
        type: fields.alarmStatusColor,
        class: 'alarm-column',
        isSort: false,
      },
      {
        title: '',
        key: fields.addReading,
        type: fields.addReading,
        class: 'add-reading-column',
      }
    );
  }
  return tableConfig;
};

export const getSingleRnGTableConfig = {
  [viewOptions.group]: getGroupTableConfig,
  [viewOptions.location]: getLocationTableConfig,
  [viewOptions.points]: getPointTableConfig,
};

export const getTableFormat = {
  [viewOptions.group]: formatGroupTableCells,
  [viewOptions.location]: formatLocationTableCells,
  [viewOptions.points]: formatPointTableCells,
};

export const sortDirections = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const defaultFilters = {
  [fields.searchText]: '',
  [fields.perPage]: 20,
  [fields.lastSeen]: 0,
  [fields.sortDirection]: sortDirections.ASC,
  [fields.sortByColumn]: fields.name,
};

export const defaultUnitsFilters = {
  [fields.searchText]: '',
  [fields.perPage]: 20,
  [fields.lastSeen]: 0,
  [fields.sortDirection]: sortDirections.ASC,
  [fields.sortByColumn]: fields.name,
};

export const manageGroupsDefaultFilters = {
  [fields.searchText]: '',
  [fields.perPage]: 20,
  [fields.lastSeen]: 0,
  [fields.sortDirection]: sortDirections.DESC,
  [fields.sortByColumn]: fields.createdAt,
};

export const groupActionTypes = {
  edit: 'edit',
  delete: 'delete',
};

export const groupDeleteActionTypes = {
  deleteAndMove: 'deleteAndMove',
  deleteAll: 'deleteAll',
};

export const viewModuleHeaderProps = ({ user, handleCreateNewLocation, selectedMeasurementGroupId }) => ({
  [viewOptions.location]: {
    actionButtontext: 'CREATE_BUTTON_TEXT.NEW',
    handleActionButtonClick: handleCreateNewLocation,
    actionButtonProps: {
      user,
      visibleFor: PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].locationCreate.name,
    },
  },
  [viewOptions.location_and_points]: {
    actionButtontext: 'CREATE_BUTTON_TEXT.NEW',
    handleActionButtonClick: selectedMeasurementGroupId ? () => handleCreateNewLocation(selectedMeasurementGroupId) : null,
    actionButtonProps: {
      user,
      visibleFor: PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].locationCreate.name,
    },
  },
});

export const detailsPerView = {
  [viewOptions.location]: detailsPages.measurementLocation,
  [viewOptions.points]: detailsPages.measurementPoint,
  [viewOptions.location_and_points]: detailsPages.measurementLocation,
};

export const changeGroupTableConfig = [
  {
    title: 'READINGS_AND_GAUGES.GROUP_NAME',
    key: fields.name,
    type: fields.name,
    class: 'name-column',
    isSort: true,
  },
  {
    title: 'READINGS_AND_GAUGES.CREATED_AT',
    key: fields.createdAt,
    type: fields.createdAt,
    class: 'created-at-column',
    isSort: true,
  },
  {
    title: 'READINGS_AND_GAUGES.CREATED_BY',
    key: fields.createdByUser,
    type: fields.createdByUser,
    class: 'created-by-column',
    isSort: true,
  },
  {
    title: '',
    key: fields.changeGroupAction,
    type: fields.changeGroupAction,
    class: 'action-column',
  },
];

export const deleteWithModalFields = {
  field: {
    id: 'ConfirmationCheck',
    name: 'ConfirmationCheck',
    label: 'MEASUREMENT_LOCATION.MODAL.CONFIRM_DELETE.LABEL',
    placeholder: 'MEASUREMENT_LOCATION.MODAL.CONFIRM_DELETE.LABEL',
    required: 'ERROR_MODAL.6506',
  },
};

export const MIN_GROUP_CHARACTERS = 3;
export const MAX_GROUP_CHARACTERS = 50;
