import routesConstants from './routes';
import { Api } from '../../core/api';
import { API_BASE_URL } from '../../common/constants';
import { generateQueryParams } from '../helpers/helper';

const getSignedUrl = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.GET_SIGNED_URL}`, params);
};

const uploadInspectionFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_INSPECTON_FILE}`, params);
};

const uploadDefectFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_DEFECT_FILE}`, params);
};

const uploadMeasurementFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_MEASUREMENT_FILE}`, params);
};

const uploadComponentFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_COMPONENT_FILE}`, params);
};

const uploadNDTMeasurementFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_NDT_MEASUREMENT_FILE}`, params);
};

const uploadNDTMeasurementPointFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_NDT_MEASUREMENT_POINT_FILE}`, params);
};

const uploadPdfTaggingFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_PDF_TAGGING_FILE}`, params);
};

const uploadContractorFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_CONTRACTOR_FILE}`, params);
};

const uploadIsolationTemplateFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_ISOLATION_TEMPLATE_FILE}`, params);
};

const uploadChecklistProcedureQuestionFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_CHECKLIST_PROCEDURE_QUESTION_FILE}`, params);
};

const uploadPermitTemplateFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_PERMIT_TEMPLATE_FILE}`, params);
};

const uploadIsolationComponentFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_ISOLATION_COMPONENT_FILE}`, params);
};

const uploadWorkOrderFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_WORK_ORDER_FILE}`, params);
};

const uploadMaintenanceRegimeFile = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_MAINTENANCE_REGIME_FILE}`, params);
};

const uploadPermitFiles = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_PERMIT_FILES}`, params);
};

const uploadDMFiles = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_DM_FILE}`, params);
};

const uploadDMFilesFromInspection = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_DM_FILE_FROM_INSPECTION}`, params);
};

const uploadDMFilesFromDefect = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_DM_FILE_FROM_DEFECT}`, params);
};

const uploadDMFilesFromNotification = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_DM_FILE_FROM_NOTIFICATION}`, params);
};

const uploadDMFilesFromWorkorder = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_DM_FILE_FROM_WORKORDER}`, params);
};

const uploadDMFilesFromWorkorderWizard = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_DM_FILE_FROM_WORKORDER_WIZARD}`, params);
};

const uploadDMFilesFromWorkorderCompletion = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_DM_FILE_FROM_WORKORDER_COMPLETION}`, params);
};

const upload = (url, data, onUploadProgress, contentType, needLoader, headers) => {
  return Api.put(url, data, { onUploadProgress, needLoader: needLoader || false }, { 'Content-Type': contentType, ...headers });
};

const setUploadDone = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_DONE}`, params);
};

const getFilesExternalUpload = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.EXTERNAL_FILES_UPLOAD}`, params);
};

const getComponentFilesUploaded = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_COMPONENT_FILES_UPLOADED}${generateQueryParams(params)}`);
};

const getComponentDMSFilesUploaded = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_COMPONENT_DMS_FILES_UPLOADED}${generateQueryParams(params)}`);
};

const getDefectFilesUploaded = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_DEFECT_FILES_UPLOADED}${generateQueryParams(params)}`);
};

const getMeasurementFilesUploaded = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_MEASUREMENT_FILES_UPLOADED}${generateQueryParams(params)}`);
};

const getNDTMeasurementFilesUploaded = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_MEASUREMENT_FILES_UPLOADED}${generateQueryParams(params)}`);
};

const getNDTMeasurementPointFilesUploaded = async params => {
  return await Api.get(`${API_BASE_URL}${routesConstants.GET_NDT_MEASUREMENT_POINT_FILES_UPLOADED}${generateQueryParams(params)}`);
};

const saveExternalFiles = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SAVE_EXTERNAL_DEFECT_FILES}`, params);
};
const saveExternalComponentFiles = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.SAVE_EXTERNAL_COMPONENT_FILES}`, params);
};

const uploadDMFilesFromChecklistQuestion = async params => {
  return await Api.post(`${API_BASE_URL}${routesConstants.UPLOAD_DM_FILE_FROM_CHECKLIST_QUESTION}`, params);
};

export default {
  getSignedUrl,
  upload,
  setUploadDone,
  getFilesExternalUpload,
  getComponentFilesUploaded,
  getComponentDMSFilesUploaded,
  getDefectFilesUploaded,
  getMeasurementFilesUploaded,
  saveExternalFiles,
  saveExternalComponentFiles,
  getNDTMeasurementFilesUploaded,
  getNDTMeasurementPointFilesUploaded,
  uploadInspectionFile,
  uploadDefectFile,
  uploadMeasurementFile,
  uploadComponentFile,
  uploadNDTMeasurementFile,
  uploadNDTMeasurementPointFile,
  uploadPdfTaggingFile,
  uploadContractorFile,
  uploadIsolationTemplateFile,
  uploadChecklistProcedureQuestionFile,
  uploadPermitTemplateFile,
  uploadIsolationComponentFile,
  uploadWorkOrderFile,
  uploadMaintenanceRegimeFile,
  uploadPermitFiles,
  uploadDMFiles,
  uploadDMFilesFromInspection,
  uploadDMFilesFromDefect,
  uploadDMFilesFromNotification,
  uploadDMFilesFromWorkorder,
  uploadDMFilesFromWorkorderWizard,
  uploadDMFilesFromChecklistQuestion,
  uploadDMFilesFromWorkorderCompletion,
};
