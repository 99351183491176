import CheckBox from '../../form/components/checkbox';
import DatePicker from '../../form/components/date-picker';
import CustomInput from '../../form/components/input';
import CustomSelect from '../../form/components/select';
import Textarea from '../../form/components/text-area';

export const questionTypes = {
  TEXT: {
    value: 'TEXT',
    valueId: 3,
    text: 'SECTIONS.TEXT_FIELD',
    component: CustomInput,
    isSelectable: true,
    props: {
      type: 'text',
      maxChars: 500,
    },
    maxChars: 500,
    fieldName: 'AnswerText',
  },
  TEXT_LONG: {
    value: 'TEXT',
    valueId: 3,
    text: 'SECTIONS.TEXT_FIELD',
    component: Textarea,
    isSelectable: true,
    props: {
      type: 'text',
      maxChars: 500,
    },
    maxChars: 500,
    fieldName: 'AnswerText',
  },
  NUMBER: {
    value: 'NUMBER',
    valueId: 1,
    text: 'SECTIONS.NUMBER_FIELD',
    component: CustomInput,
    isSelectable: true,
    props: {
      type: 'number',
    },
    fieldName: 'AnswerNumber',
  },
  TEXTAREA: {
    value: 'TEXTAREA',
    component: Textarea,
    isSelectable: false,
  },
  DATE: {
    value: 'DATE',
    valueId: 6,
    text: 'SECTIONS.DATE_FIELD',
    component: DatePicker,
    isSelectable: true,
    fieldName: 'AnswerDate',
  },
  CHECKBOX: {
    value: 'CHECKBOX',
    valueId: 2,
    text: 'SECTIONS.CHECKBOX_FIELD',
    component: CheckBox,
    isSelectable: true,
    fieldName: 'AnswerCheckbox',
  },
  SELECT: {
    value: 'SELECT',
    component: CustomSelect,
    text: 'SECTIONS.MULTI_SELECT_FIELD',
    isSelectable: false,
  },
  MULTI_ANSWER: {
    value: 'MULTI_ANSWER',
    valueId: 5,
    text: 'SECTIONS.MULTI_SELECT_FIELD',
    isSelectable: true,
    fieldName: 'AnswerOptionIDs',
  },
  NO_ANSWER: {
    value: 'NO_ANSWER',
    valueId: 4,
    text: 'SECTIONS.NO_ANSWER_FIELD',
    isSelectable: true,
    isStatic: true,
  },
  SIGNATURE: {
    valueId: 7,
    assigneeName: 'Signature of Assignee',
    witnessName: 'Signature of Witness',
    assignee: 'Assignee',
  },
};

export const defaultQuestion = {
  name: {
    name: 'Name',
    label: 'SECTIONS.QUESTION_NAME_LABEL',
    placeholder: 'SECTIONS.QUESTION_NAME_PLACEHOLDER',
    errorRequired: 'SECTIONS.QUESTION_NAME_REQUIRED',
    errorMinChars: 'SECTIONS.QUESTION_NAME_MIN_CHARS',
    errorMaxChars: 'SECTIONS.QUESTION_NAME_MAX_CHARS',
    minChars: 3,
    maxChars: 100,
    type: questionTypes.TEXT.value,
  },
  questionType: {
    name: 'QuestionType',
    alternativeName: 'ChecklistItemTypeID',
    label: 'SECTIONS.QUESTION_TYPE_LABEL',
    type: questionTypes.MULTI_ANSWER.value,
  },
  isMandatory: {
    name: 'IsMandatory',
    label: 'SECTIONS.IS_MANDATORY_LABEL',
    type: questionTypes.CHECKBOX.value,
  },
  allowMultipleAnswers: {
    name: 'AllowMultipleAnswers',
    label: 'SECTIONS.ALLOW_MULTIPLE_ANSWERS_LABEL',
    type: questionTypes.CHECKBOX.value,
  },
};

export const additionalFields = {
  description: {
    name: 'Description',
    label: 'SECTIONS.QUESTION_DESCRIPTION_LABEL',
    placeholder: 'SECTIONS.QUESTION_DESCRIPTION_PLACEHOLDER',
    type: questionTypes.TEXTAREA.value,
    maxChars: 2000,
    maxCharsError: 'SECTIONS.QUESTION_DESCRIPTION_MAX_CHARS_ERROR',
  },
  component: {
    name: 'Name',
    label: 'SECTIONS.QUESTION_COMPONENT_LABEL',
  },
  components: {
    name: 'QuestionsComponents',
    label: 'SECTIONS.QUESTION_COMPONENT_LABEL',
  },
  estimatedTime: {
    name: 'EstimatedTime',
    label: 'SECTIONS.QUESTION_ESTIMATED_TIME_LABEL',
    placeholder: 'SECTIONS.QUESTION_ESTIMATED_TIME_PLACEHOLDER',
    type: questionTypes.DATE.value,
  },
  geometry: {
    name: 'Geometry',
    label: 'SECTIONS.TAG_LOCATION_LABEL',
  },
  files: {
    name: 'QuestionDMSFiles',
  },
};

export const questionOptionsFields = {
  name: {
    name: 'Name',
    label: 'SECTIONS.QUESTION_OPTION_NAME_LABEL',
    placeholder: 'SECTIONS.QUESTION_OPTION_NAME_PLACEHOLDER',
    errorRequired: 'SECTIONS.QUESTION_OPTION_NAME_REQUIRED',
    errorMaxChars: 'SECTIONS.QUESTION_OPTION_NAME_MAX_CHARS',
    type: questionTypes.TEXT.value,
    maxChars: 50,
  },
  sectionQuestionId: {
    name: 'SectionQuestionID',
  },
  id: {
    name: 'ID',
  },
};

export const defaultQuestionValidators = {
  [defaultQuestion.name.name]: {
    required: true,
    errorRequired: defaultQuestion.name.errorRequired,
    minChars: defaultQuestion.name.minChars,
    errorMinChars: defaultQuestion.name.errorMinChars,
    maxChars: defaultQuestion.name.maxChars,
    errorMaxChars: defaultQuestion.name.errorMaxChars,
  },
  [additionalFields.description.name]: {
    maxChars: additionalFields.description.maxChars,
    errorMaxChars: additionalFields.description.maxCharsError,
  },
};

export const defaultSelectKeys = {
  valueKey: 'value',
  textKey: 'text',
};

export const componentsPickerSelectKeys = {
  valueKey: 'ID',
  textKey: 'Name',
  codeKey: 'Code',
  typeKey: 'Type',
};

export const componentFields = {
  id: 'ID',
  name: 'Name',
  code: 'Code',
  asset: 'AssetType',
  componentType: 'Type',
  componentSubType: 'SubType',
  createdBy: 'CreatedBy',
};

export const componentDetailsDisplayProps = [
  {
    name: componentFields.name,
  },
  {
    name: componentFields.code,
  },
  {
    name: componentFields.asset,
  },
  {
    name: componentFields.componentType,
  },
  {
    name: componentFields.componentSubType,
  },
  {
    name: componentFields.createdBy,
  },
];

export const defaultQuestionName = 'SectionQuestion';

export const defaultOptionsFieldName = 'QuestionOptions';
