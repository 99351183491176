export const tabNames = {
  details: 'DETAILS',
  comments: 'COMMENTS',
  completion: 'COMPLETION',
  flow: 'FLOW',
};

export const toolbarItems = {
  [tabNames.details]: {
    name: tabNames.details,
    label: 'WORK_ORDER.TAB_DETAILS',
  },
  [tabNames.comments]: {
    name: tabNames.comments,
    label: 'COMPONENET_FORM_TAB.COMMENTS',
  },
  [tabNames.completion]: {
    name: tabNames.completion,
    label: 'WORK_ORDER.TAB_COMPLETION',
    formDisabled: false,
  },
  [tabNames.flow]: {
    name: tabNames.flow,
    label: 'WORK_ORDER.TAB_FLOW',
  },
};

export const fields = {
  id: 'ID',
  archived: 'Archived',
  status: 'Status',
  onHold: 'OnHold',
  name: 'Name',
  subType: 'SubType',
  additionalSubType: 'AdditionalSubType',
  type: 'Type',
  description: 'Description',
  toolsRequired: 'ToolsRequired',
  severity: 'Severity',
  startDate: 'StartDate',
  endDate: 'DueDate',
  dueDate: 'ExpectedDueDate',
  estimatedWrenchTime: 'EstimatedWrenchTime',
  fileId: 'FileID',
  fileName: 'FileName',
  workCompleted: 'WorkCompleted',
  recommendations: 'Recommendations',
  actualEndTime: 'ActualEndDate',
  actualWrenchTime: 'ActualWrenchTime',
  files: 'Files',
  workOrderID: 'WorkOrderID',
  checklistTemplateID: 'ChecklistTemplateID',
  archive: 'Archive',
  searchText: 'SearchText',
  permitId: 'PermitID',
  createdBy: 'CreatedByID',
  componentID: 'ComponentID',
  componentName: 'ComponentName',
  color: 'Colour',
  isCompletionFile: 'isCompletionFile',
  signedBy: 'SignedByUser',
  signedAt: 'SignedAt',
  commentSectionId: 'CommentSectionID',
  customProperties: 'customProperties',
  externalID: 'ExternalID',
  questionsAnswered: 'QuestionsAnswered',
  questionsTotal: 'QuestionsTotal',
  isSubmitted: 'IsSubmitted',
  hasUnansweredMandatoryQuestions: 'HasUnansweredMandatoryQuestions',
  cameraPosition: 'CameraPosition',
  coordinates: 'coordinates',
  inspectionID: 'InspectionID',
  components: 'Components',
  mainType: 'MainType',
  critical: 'Critical',
};

export const completedFormFields = {
  workCompleted: {
    id: 'work-order-completed-note',
    name: fields.workCompleted,
    label: 'WORK_ORDER.WORK_COMPLETED_LABEL',
    placeholder: 'WORK_ORDER.WORK_COMPLETED_LABEL',
    maxChars: 500,
    errorRequired: 'WORK_ORDER.WORK_COMPLETED_ERROR_REQUIRED',
    errorMaxChars: 'WORK_ORDER.WORK_COMPLETED_ERROR_MAX_CHARS',
  },
  recommendations: {
    id: 'work-order-recommendations',
    name: fields.recommendations,
    label: 'WORK_ORDER.RECOMMENDATIONS_LABEL',
    placeholder: 'WORK_ORDER.RECOMMENDATIONS_LABEL',
    maxChars: 500,
    errorMaxChars: 'WORK_ORDER.RECOMMENDATIONS_MAX_CHARS',
  },
  actualEndTime: {
    id: 'work-order-actual-end-time',
    name: fields.actualEndTime,
    label: 'WORK_ORDER.ACTUAL_END_DATE_LABEL',
    placeholder: 'WORK_ORDER.ACTUAL_END_DATE_LABEL',
    errorRequired: 'WORK_ORDER.ACTUAL_END_DATE_ERROR_REQUIRED',
    errorMinDate: 'WORK_ORDER.ACTUAL_END_DATE_MIN',
  },
  actualWrenchTime: {
    id: 'work-order-actual-wrench-time',
    name: fields.actualWrenchTime,
    label: 'WORK_ORDER.ACTUAL_WRENCH_TIME_LABEL',
    placeholder: 'WORK_ORDER.ACTUAL_WRENCH_TIME_LABEL',
  },
};
export const CompletionFilesSupportedMimeTypes = {
  images: {
    'image/*': ['.jpeg', '.jpg', '.png', '.svg', '.tiff', '.gif'],
  },
  otherFiles: {
    'application/pdf': ['.pdf'],
    'application/zip': ['.zip'],
  },
};

export const checklistTemplateFields = {
  id: 'ID',
  name: 'Name',
  linkedToEquipment: 'LinkedToEquipment',
  component: 'Component',
  componentID: 'ComponentID',
  isSubmitted: 'IsSubmitted',
  questionsAnswered: 'QuestionsAnswered',
  questionsTotal: 'QuestionsTotal',
  submittedAt: 'SubmittedAt',
  submittedBy: 'SubmittedBy',
};
