import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { Field } from 'redux-form';

import CustomCheckBox from '../../../../../../common/form/components/custom-checkbox';
import CustomInput from '../../../../../../common/form/components/input';
import SearchInput from '../../../../../../common/input/components/search-input';
import LoadMore from '../../../../../../common/load-more/components/load-more';
import RenderIf from '../../../../../../common/render-if/components/render-if';
import ComponentPickerWithList from '../../../common/component-picker/component-picker-with-list';
import { defaultUnitsFilters, fields } from '../../constants/constants';
import { formConstants } from '../../constants/measurement-point-constants';
import '../../styles/measurement-point-details-form.scss';

const MeasurementPointDetailsFormFields = (
  { isPreviewMode, fetchComponents, inspectionId, projectID, selectedComponents, onComponentSelect, componentPickerAdditionalOptions, fetchUnits, changeField, formValues },
  { t }
) => {
  const [unitsFilters, setUnitFilters] = useState(defaultUnitsFilters);
  const [measurementUnits, setMeasurementUnits] = useState([]);

  const fetchUnitsData = useCallback(
    (filters, loadMore = false) => {
      fetchUnits(
        { ...filters, [fields.projectID]: projectID },
        newData => {
          const updatedUnits = loadMore ? [...measurementUnits, ...newData] : newData;
          setMeasurementUnits(updatedUnits);
        },
        setUnitFilters
      );
    },
    [measurementUnits, fetchUnits, projectID]
  );

  /* eslint-disable react-hooks/exhaustive-deps */
  const searchUnitsTextChanged = useCallback(
    debounce(searchText => fetchUnitsData({ ...defaultUnitsFilters, [fields.lastSeen]: 0, [fields.searchText]: searchText }), 300),
    [fetchUnitsData]
  );

  const handleSearchInputChange = e => {
    const searchText = e.target.value;
    searchUnitsTextChanged(searchText);
    changeField(formConstants.fields.unit, searchText);
  };

  const handleUnitSelect = unit => {
    const unitValue = unit[fields.unit];
    const currentUnitValue = formValues[formConstants.fields.unit];

    if (currentUnitValue === unitValue) {
      changeField(formConstants.fields.unit, '');
    } else {
      changeField(formConstants.fields.unit, unitValue);
    }
  };

  const handleLoadMoreClick = () => fetchUnitsData(unitsFilters, true);

  const handleOnBlur = e => {
    const value = e.target.value;
    const currentUnitValue = formValues[formConstants.fields.unit];

    changeField(formConstants.fields.unit, value || currentUnitValue || '');
  };

  useEffect(() => {
    fetchUnitsData(defaultUnitsFilters);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <div className="measurement-point-details-form">
      <div className="double">
        <div className="split-input">
          <Field
            id={formConstants.fields.name}
            name={formConstants.fields.name}
            component={CustomInput}
            disabled={isPreviewMode}
            placeholder={t('MEASUREMENT_POINT_DETAILS.FORM_NAME')}
            label={t('MEASUREMENT_POINT_DETAILS.FORM_NAME')}
            type="text"
            size="lg"
            additionalClassName="text-transform-none"
            labelClass="text-transform-none"
            isRequired
          />
        </div>
      </div>
      <div className="double">
        <div className="split-input unit-input">
          <SearchInput
            wrapperClass={formValues?.[formConstants.fields.unit] ? 'highlight-placeholder' : ''}
            placeholder={formValues?.[formConstants.fields.unit] || t('MEASUREMENT_POINT_DETAILS.FORM_UNIT')}
            id={formConstants.fields.unit}
            name={formConstants.fields.unit}
            disabled={isPreviewMode}
            label={t('MEASUREMENT_POINT_DETAILS.FORM_UNIT')}
            type="text"
            size="lg"
            value={formValues?.[formConstants.fields.unit]}
            additionalClassName="text-transform-none"
            labelClass="text-transform-none"
            onChange={handleSearchInputChange}
            onBlur={handleOnBlur}
            includeDropdown
            items={measurementUnits}
            emptyStateLabel="NO_MEASUREMENT_UNIT_FOUND"
            isDropdownDataLoading={false}
            shouldRenderPortal={false}
            autocomplete={'off'}
            portalProps={false}
            renderItem={(measurementUnit, index) => {
              const isSelected = formValues?.[formConstants.fields.unit] === measurementUnit[fields.unit];
              return (
                <>
                  <div
                    className="group-item-wrapper"
                    onClick={e => {
                      e.preventDefault();
                      handleUnitSelect(measurementUnit);
                    }}
                    key={`measurement-unit-${index}`}
                  >
                    <CustomCheckBox
                      meta={{}}
                      input={{
                        value: isSelected,
                        onChange: () => handleUnitSelect(measurementUnit),
                      }}
                      id={`row-${index}`}
                    />
                    <p id={`row-${index}`} className="pointer">
                      {measurementUnit[fields.unit]}
                    </p>
                  </div>
                  <RenderIf if={measurementUnits.length - index === 1}>
                    <LoadMore
                      loaded={measurementUnits.length}
                      total={unitsFilters.TotalNumber}
                      onlyText={!unitsFilters.HasNext}
                      totalPosition="center"
                      label="LOAD_MORE"
                      showButton
                      showTotalItems
                      disabled={false}
                      buttonVariant="success-outline"
                      onClick={handleLoadMoreClick}
                      buttonWidth="sm"
                    />
                  </RenderIf>
                </>
              );
            }}
          />
        </div>
        <div className="split-input">
          <Field
            id={formConstants.fields.expectedRange}
            name={formConstants.fields.expectedRange}
            component={CustomInput}
            disabled={isPreviewMode}
            placeholder={t('MEASUREMENT_POINT_DETAILS.FORM_EXPECTED_RANGE')}
            label={t('MEASUREMENT_POINT_DETAILS.FORM_EXPECTED_RANGE')}
            type="text"
            size="lg"
            additionalClassName="text-transform-none"
            labelClass="text-transform-none"
          />
        </div>
      </div>
      <ComponentPickerWithList
        fetchComponents={fetchComponents}
        inspectionId={inspectionId}
        selectedComponents={selectedComponents}
        onComponentSelect={onComponentSelect}
        disabled={isPreviewMode}
        additionalOptions={componentPickerAdditionalOptions}
      />
    </div>
  );
};

MeasurementPointDetailsFormFields.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MeasurementPointDetailsFormFields;
