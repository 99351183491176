import { debounce, find, get, isEmpty, isObject, map, pick, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import Modal from '../../../../common/modal/components/modal';
import Tab from '../../../../common/tabs/component/tab';
import Tabs from '../../../../common/tabs/component/tabs';
import DefectHistory from './defect-history';

import { AMAZON_IMAGE_SIZES, FEATURES, FORMS } from '../../../../common/constants';
import { measurementTypes, modules } from '../../constants/constants';
import { assigneeFields, defectStatus, formConstants, observationTypeFields, tabNames, toolbarItems } from '../../constants/defect-constants';

import ExternalImagesModal from '../../../start-workflow/components/external-images-modal';

import CommentsTab from '../../../../common/comments-tab/components/comments-tab';
import { commentFields } from '../../../../common/comments-tab/constants/constants';
import Loader from '../../../../common/global-loader/components/simple-loader';
import Helpers from '../../../../common/helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../common/permissions-constants';
import { savePointRelatedImages } from '../../../start-workflow/actions/start-workflow-actions';
import DefectFormWrapper from './defect-form-wrapper';

import { notificationModalCustom } from '../../../../common/modal/actions/modal-actions';
import { changeLinkedImagesColor } from '../../../upload/actions/external-upload-actions';
import {
  handleDefectRelatedComponentsVisible,
  setCreateNotificationFormSubmitting,
  setDefectLocationEditing,
  setElementDetails,
  setObservationFormState,
  setUnsavedChangesDirty,
} from '../../actions/action-creators';
import {
  addObservationComment,
  closeInspectionModal,
  deleteDefect,
  deleteObservationComment,
  fetchCommentUsersAndTeams,
  fetchObservationComments,
  fetchObservationWorkOrders,
  getDefectDetails,
  getDefectRelatedImages,
  getInspectionElementsByType,
  getObservationNotifications,
  getObservationTypes,
  handleEditDrawing,
  maximizeInspectionModal,
  minimizeInspectionModal,
  openAsSeparatePageInspectionModal,
  toggleDeleteDefectModal,
  toggleInspectionModal,
  unlinkObservationComponent,
  updateDefect,
  updateObservationComponent,
  updateObservationProperties,
} from '../../actions/inspection-actions';
import { createNotificationFromObservation, fetchDetailsForCreationOfNotification } from '../../actions/notification-actions';

import { ReactComponent as PDFIcon } from '../../../../common/assets/pdf-icon.svg';
import DrawingHelpers from '../../../../common/drawing-helpers';
import AddContributors from '../../../../common/user-team/components/add-contributors';
import { participantTypes } from '../../../../common/user-team/constants/constants';
import { unlinkDMSFileFromObservation } from '../../../document-management/actions/dm-api-calls';
import { setPdfComponents } from '../../../pdf-tag/actions/action-creators';
import { changePDFPage, getPdfComponentsForPopup } from '../../../pdf-tag/actions/pdf-tag-actions';
import { componentFields, componentFilterValues } from '../../../pdf-tag/constants/constants';
import LinkedImagesSliderSlider from '../../../start-workflow/components/linked-images-slider';
import orientationConstants from '../../../start-workflow/constants/orientation-constants';
import { preventedFieldsPriorComponentIsCreated } from '../../constants/component-constants';
import {
  addObservationContributor,
  deleteObservationContributor,
  getObservationContributors,
  getObservationDMSFilesUploaded,
  searchObservationContributors,
} from '../observations/actions/observations-api-calls';
import ModuleHeader from './common/module-header';
import ComponentPDF from './component-pdf';
import DefectNotifications from './defect-details/components/defect-notifications';
import DefectNotificationCreationModal from './defect-details/components/defect-notifications-creation-modal';
import { defectNotificationFields } from './defect-details/constants/constants';

const DefectDetails = (
  {
    queryItem,
    getInspectionElementsByType,
    updateDefect,
    getDefectRelatedImages,
    getObservationTypes,
    getDefectDetails,
    getObservationNotifications,
    updateObservationComponent,
    fetchCommentUsersAndTeams,
    fetchObservationComments,
    addObservationComment,
    deleteObservationComment,
    viewer,
    createNotification,
    deleteDefectModalData,
    user,
    inspectionDetails,
    inspectionID,
    projectID,
    componentsVisible,
    changeField,
    defectFiles,
    openDownloadReportModalSingleDefect,
    observationTypes,
    observationNotifications,
    handleActivePage,
    objectToolClick,
    showGeometryWarning,
    defectDetailsLoading,
    savePointRelatedImages,
    getObservationDMSFilesUploaded,
    handleEditDrawing,
    handleDefectRelatedComponentsVisible,
    setDefectLocationEditing,
    setElementDetails,
    notificationModalCustom,
    toggleInspectionModal,
    inspectionModalData,
    getPdfComponentsForPopup,
    activeTab,
    isPdfScreen,
    setPdfComponents,
    components,
    getObservationContributors,
    searchObservationContributors,
    addObservationContributor,
    deleteObservationContributor,
    contributors,
    unlinkObservationComponent,
    closeInspectionModal,
    minimizeInspectionModal,
    maximizeInspectionModal,
    unlinkDMSFileFromObservation,
    openAsSeparatePageInspectionModal,
    changePDFPage,
    fetchDetailsForCreationOfNotification,
    toggleDeleteModal,
    deleteDefect,
    setObservationFormState,
    observationFormState,
    updateObservationProperties,
    observationNotificationsLoading,
    setCreateNotificationFormSubmitting,
    observationWorkOrders,
    fetchObservationWorkOrders,
    defectsClustered,
    setUnsavedChangesDirty,
    toggleElement,
  },
  { t }
) => {
  const [modalData, setModalData] = useState({
    isOpen: false,
  });
  const [deleteFileModalData, setDeleteFileModalData] = useState({
    isOpen: false,
  });
  const [modalRelatedImagesData, setModalRelatedImagesData] = useState({
    isOpen: false,
  });
  const [activeToolbarItem] = useState(toolbarItems[tabNames.details].name);
  const [defect, setDefect] = useState({});
  const [commentsList, setCommentsList] = useState([]);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [addCommentLoading, setAddCommentLoading] = useState(false);
  const [usersAndTeamsComments, setUsersAndTeamsComments] = useState({ commentUsersList: [], commentTeamsList: [] });
  const [commentUsersAndTeamsLoading, setCommentUsersAndTeamsLoading] = useState(false);
  const [inspectionComponents, setInspectionComponents] = useState([]);
  const [elementAlreadyFocused, setElementAlreadyFocused] = useState(false);
  const [prevQueryItem, setPrevQueryItem] = useState(null);
  const [prevViewer, setPrevViewer] = useState(null);
  const [assignees, setAssignees] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [assigneesModal, setAssigneesModal] = useState({ isOpen: false });
  const [isLoadingNotificationDetails, setIsLoadingNotificationDetails] = useState(false);

  const userCanCreateNewNotifications = useMemo(() => {
    return Helpers.hasAccess({ user, visibleFor: PERMISSIONS[PERMISSION_TYPES.notifications].create.name });
  }, [user]);

  // This component was declared before so useCallback so it can be used in the hook
  const handleObservationComponentChange = (defectID, componentID) => {
    if (componentID) {
      updateObservationComponent(defectID, componentID);
    } else {
      unlinkObservationComponent(defectID);
      changeField(formConstants.fields.componentID, inspectionDetails.DefaultComponent);
    }
  };

  const submitForm = (values, forceFetch, customCallback = () => null) => {
    const { requestInProgress } = observationFormState;
    if (requestInProgress) return;
    const { Geometry, ...defectValues } = values;
    const { unsavedCustomProps } = observationFormState;
    let callback = customCallback;

    // check if the force fetch is true
    if (forceFetch) {
      callback = () =>
        getDefectDetails(
          values[formConstants.fields.id],
          defect => {
            setDefect(defect);
            // add custom callback after the new defect has been set
            customCallback();
          },
          false
        );
    }
    // Set the main type to be string and not object
    defectValues[formConstants.fields.mainType] = isObject(defectValues[formConstants.fields.mainType])
      ? defectValues[formConstants.fields.mainType][observationTypeFields.value]
      : defectValues[formConstants.fields.mainType];

    setObservationFormState({ requestInProgress: true });
    // The request in progress is set here so it prevents the user from sending multiple request
    // In case he decides to click 10 time save button
    // TODO: Look in to solution with debounce, can it be done without requestInProgress?
    const preparedCustomProps = unsavedCustomProps.map(prop => {
      if (typeof prop[formConstants.fields.id] === 'string') {
        delete prop[formConstants.fields.id];
      }
      return prop;
    });

    updateDefect(defectValues, true, () => {
      callback();
      updateObservationProperties(preparedCustomProps, () => {
        setUnsavedChangesDirty(false);
        setObservationFormState({ hasUnsavedChanges: false, requestInProgress: false, hasUnsavedCustomProps: false, unsavedCustomProps: [] });
      });
    });
  };

  const formChangeDebounce = (_values, _b, c) => {
    setObservationFormState({ hasUnsavedChanges: c?.dirty });
    setUnsavedChangesDirty(c?.dirty);
  };

  useEffect(() => {
    if (!queryItem || queryItem < 0) {
      setElementAlreadyFocused(true);
      return;
    }

    fetchData(tabNames.details);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryItem]);

  useEffect(() => {
    if (queryItem && queryItem > 0) {
      if (queryItem < 0) {
        setDefectLocationEditing(false);
        // Reset defect state when queryItem is less than 0
        setDefect({});
      } else if (queryItem !== prevQueryItem || viewer !== prevViewer) {
        // Fetch data when queryItem changes or viewer changes
        fetchData(tabNames.details);
      }
    }

    // Update previous queryItem and viewer
    setPrevQueryItem(queryItem);
    setPrevViewer(viewer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryItem, viewer]);

  const fetchInspectionComponents = async searchText => {
    const res = await getInspectionElementsByType(inspectionID, measurementTypes.component, searchText, true);
    setInspectionComponents(res);
  };

  const closeModalAction = () => setModalData({ isOpen: false });

  const handleSearchContributors = (searchText, participantType) => {
    searchObservationContributors(
      {
        SearchText: searchText || '',
        DefectID: queryItem,
        ProjectID: projectID,
        ParticipantType: participantType,
      },
      users => {
        if (participantType === participantTypes.assignee) {
          const extendedAssignees = [...users, { [formConstants.fields.id]: -2, [formConstants.fields.name]: 'None', [formConstants.fields.type]: 'USER' }];
          setAssignees(extendedAssignees);
        } else {
          setCollaborators(users);
        }
      }
    );
  };

  const handleAddObservationContributor = user => {
    addObservationContributor(queryItem, projectID, inspectionID, user);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleAddObservationContributorDebounce = useCallback(debounce(handleAddObservationContributor, 200), [queryItem, assignees, collaborators]);

  const handleRemoveObservationContributor = (user, callback = () => null) => {
    deleteObservationContributor(queryItem, projectID, inspectionID, user, () => {
      if (user[assigneeFields.participantType] === participantTypes.assignee) {
        const updatedUser = { ...user, Type: user.AssigneeType };
        setAssignees(uniqBy([...assignees, updatedUser], 'ID'));
      } else {
        setCollaborators(uniqBy([...collaborators, user], 'ID'));
      }
      callback();
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRemoveObservationContributorDebounce = useCallback(debounce(handleRemoveObservationContributor, 200), [queryItem, assignees, collaborators]);

  const fetchData = tabName => {
    if (tabName === tabNames.details) {
      // resets the state, in case the state is not reset after navigating via the unsaved changes popup
      setObservationFormState({ hasUnsavedCustomProps: false, unsavedCustomProps: [], hasUnsavedChanges: false });

      setUnsavedChangesDirty(false);
      setDefectLocationEditing(false);
      getDefectDetails(queryItem, defect => {
        setDefect(defect);
        getObservationDMSFilesUploaded(queryItem);
        handleDefectRelatedComponentsVisible(false);
        getObservationContributors(queryItem);
        fetchObservationWorkOrders(queryItem);

        fetchInspectionComponents('');
        if (isEmpty(observationTypes)) {
          getObservationTypes();
        }
        if (defect?.Geometry?.coordinates?.[0] && defect?.CameraPosition?.coordinates && viewer && !elementAlreadyFocused) {
          // we can use here Geometry and Camera position from defect object since it is freshly fetched but usually we need to use it from clustered elements, they are always up to date
          viewer.zoomToPosition({ x: defect.CameraPosition.coordinates[0], y: defect.CameraPosition.coordinates[1], z: defect.CameraPosition.coordinates[2] }, defect.Geometry.coordinates, 500);
          setElementAlreadyFocused(true);
        }
      });
    } else if (tabName === tabNames.notifications) {
      getObservationNotifications(queryItem);
    }
  };

  const openAssigneesModal = () => {
    handleSearchContributors('', participantTypes.assignee);
    handleSearchContributors('', participantTypes.collaborator);

    setAssigneesModal({
      ...assigneesModal,
      title: t('WORK_ORDER.EDIT_ASSIGNEES'),
      customClassName: 'work-order-default-modal create-work-order-modal',
      CustomContent: dynamicProps => (
        <AddContributors
          assigneeLabel="CHOOSE_WORK_ORDER_NOTIFICATION.ADD_ASSIGNEES"
          collaboratorLabel="CHOOSE_WORK_ORDER_NOTIFICATION.ADD_COLLABORATORS"
          customClassName="work-order-form"
          {...dynamicProps}
        />
      ),
      isOpen: true,
      type: 'none',
      confirmAction: () => setAssigneesModal({ isOpen: false }),
      closeAction: () => setAssigneesModal({ isOpen: false }),
    });
  };

  const openConfirmationModal = (title, content, confirmAction) => {
    setModalData({
      isOpen: true,
      type: 'yes-no',
      content: content,
      title: title,
      confirmAction: () => {
        confirmAction();
        setModalData({ isOpen: false });
      },
      closeAction: closeModalAction,
    });
  };

  const getRelatedImages = () => {
    const coords = get(defect, 'Geometry.coordinates[0]');
    if (!isEmpty(coords)) {
      // start loading
      openRelatedImagesModal([], true);
      getDefectRelatedImages(inspectionID, coords, () => null);
    }
  };

  const openRelatedImagesModal = _alreadySelectedImages => {
    const coords = get(defect, 'Geometry.coordinates[0]');
    setModalRelatedImagesData(_prevProps => ({
      isOpen: true,
      CustomContent: () => (
        <ExternalImagesModal
          className={'workflow-external'}
          saveExternalFiles={images => {
            savePointRelatedImages(defect, null, images, closeRelatedImagesModal);
          }}
          imageType={AMAZON_IMAGE_SIZES.small.name}
          loadWithDrawings={true}
          includeLoader={true}
          includePaging={true}
          cols={2}
          title={'START_WORKFLOW.REALTED_POINTS_IMAGES_MODAL_TITLE'}
          refetchData={type => getDefectRelatedImages(inspectionID, coords, () => null, type)}
        />
      ),
      customClassName: 'modal-no-max-height',
      type: 'none',
      closeAction: closeRelatedImagesModal,
    }));
  };

  const closeRelatedImagesModal = () => {
    setModalRelatedImagesData({
      isOpen: false,
    });
    getDefectFiles();
  };

  const openDeleteFileModal = (fileID, fileName, categoryID) => {
    const modalData = {
      isOpen: true,
      content: t('COMPONENT_DETAILS.DELETE_FILE_DESC', { fileName }),
      title: t('COMPONENT_DETAILS.DELETE_FILE_TITLE'),
      type: 'yes-no',
      confirmAction: () =>
        unlinkDMSFileFromObservation(
          fileID,
          queryItem,
          categoryID,
          () => getObservationDMSFilesUploaded(queryItem),
          () => {
            setDeleteFileModalData({ isOpen: false });
            setModalData({ isOpen: false });
          }
        ),
      closeAction: () => setDeleteFileModalData({ isOpen: false }),
      customClassName: 'modal-small',
    };

    setDeleteFileModalData(modalData);
  };

  const selectComponentFromDrawing = (clickedObj, elementAlreadyFocused) => {
    const comp = DrawingHelpers.findComponentByDrawingGuid(components, clickedObj);

    if (isEmpty(comp) || activeTab !== modules.defects) {
      // if object is empty or active tab is not component
      return;
    }

    //redirect
    setElementAlreadyFocused && setElementAlreadyFocused(false);
    handleActivePage(modules.components, comp[formConstants.fields.id]);
  };

  const openImagesModal = (selectedImage, linkedImages) => {
    let index = 0;
    linkedImages = map(linkedImages, (item, i) => {
      item.src = Helpers.getUploadImageSource(item.FileName, item.URL);
      if (selectedImage.FileID === item.FileID) {
        index = i;
      }
      return item;
    });

    setModalData(prevProps => ({
      ...prevProps.modalData,
      customClassName: 'linked-images-slider defects-gallery',
      CustomContent: () => (
        <LinkedImagesSliderSlider
          linkedImages={linkedImages}
          currentSlideIndex={index}
          setOrientation={() => null}
          disabledOrientations={[orientationConstants.orientation.chart]}
          isPreviewModal={true}
          openDeleteFileModal={openDeleteFileModal}
          hasUnlinkAccess={{
            user,
            id: defect[formConstants.fields.createdById],
            ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.observations].create.name,
            visibleFor: [PERMISSIONS[PERMISSION_TYPES.observations].edit.name],
          }}
        />
      ),
      isOpen: true,
      type: 'none',
      closeAction: closeModalAction,
    }));
  };

  // TODO: redundant code as in component-details. Requires refactoring
  const openFile = (file, imageFiles) => {
    if (file.isPDF) {
      getPdfComponentsForPopup(
        inspectionID,
        file.FileID,
        // sets default filters and pageNumber to 1
        {
          SearchText: '',
          ComponentFilter: componentFilterValues.all,
        },
        1,
        defect[formConstants.fields.id],
        d => {
          const confirmedDrawings = (d || []).filter(cDrawing => cDrawing[componentFields.confirmed]);
          setPdfComponents(confirmedDrawings);
          // open regular modal for tools since inspectionModal works only on MODULES level, but not on Tools modules
          // to check if we are on Tools part of the app we use isPdfScreen property
          // TODO: figure out if isPdfScreen is needed
          if (isPdfScreen) {
            setModalData(prevProps => ({
              ...prevProps.modalData,
              isOpen: true,
              title: t('PDF_VIEWER.MODAL.TITLE'),
              customClassName: 'modal-large',
              closeAction: () => {
                setPdfComponents([]);
                setModalData({ isOpen: false });
              },
              CustomContent: dynamicProps => (
                <ComponentPDF
                  {...dynamicProps}
                  file={file}
                  selectComponent={clickedObj => selectComponentFromDrawing(clickedObj, elementAlreadyFocused)}
                  rightCollapsed={true}
                  inspectionId={inspectionID}
                  // when PDF opened in popup from module details or DMS, we show only confirmed tags/drawings
                  showOnlyConfirmedDrawings={true}
                />
              ),
            }));
          } else {
            toggleInspectionModal({
              ...inspectionModalData,
              isOpen: true,
              FileIcon: props => <PDFIcon height={18} {...props} />,
              fileName: file.name,
              title: t('PDF_VIEWER.MODAL.TITLE'),
              customClassName: 'modal-large',
              closeAction: closeInspectionModal,
              minimizeAction: () =>
                changePDFPage(
                  1, // pdfPageNumber set to 1 because component-pdf on unmount sets pdfPageNumber to 1 in reducer
                  inspectionID,
                  file.FileID,
                  {
                    SearchText: '',
                    ComponentFilter: componentFilterValues.all,
                  },
                  null,
                  true,
                  () => {
                    minimizeInspectionModal();
                  }
                ),
              maximizeAction: () =>
                changePDFPage(
                  1, // pdfPageNumber set to 1 because component-pdf on unmount sets pdfPageNumber to 1 in reducer
                  inspectionID,
                  file.FileID,
                  {
                    SearchText: '',
                    ComponentFilter: componentFilterValues.all,
                  },
                  null,
                  true,
                  () => {
                    maximizeInspectionModal();
                  }
                ),
              separatePageAction: () =>
                changePDFPage(
                  1, // pdfPageNumber set to 1 because component-pdf on unmount sets pdfPageNumber to 1 in reducer
                  inspectionID,
                  file.FileID,
                  {
                    SearchText: '',
                    ComponentFilter: componentFilterValues.all,
                  },
                  null,
                  true,
                  () => {
                    openAsSeparatePageInspectionModal();
                  }
                ),
              CustomContent: dynamicProps => (
                <ComponentPDF
                  {...dynamicProps}
                  file={file}
                  selectComponent={clickedObj => selectComponentFromDrawing(clickedObj, elementAlreadyFocused)}
                  rightCollapsed={true}
                  // when PDF opened in popup from module details or DMS, we show only confirmed tags/drawings
                  showOnlyConfirmedDrawings={true}
                />
              ),
            });
          }
        }
      );
    } else if (file.isImage) {
      const images = isEmpty(imageFiles) ? [file] : imageFiles;
      openImagesModal(file, images);
    } else {
      Helpers.getFileExtensionAndDownload(file);
    }
  };

  const getDefectFiles = () => {
    const defectID = get(defect, 'ID');
    if (!defectID) return;
    getObservationDMSFilesUploaded(defectID);
  };

  const prepareNotificationConfigurationForRequest = data => {
    const filteredData = pick(data, [defectNotificationFields.name, defectNotificationFields.severity]);
    filteredData[defectNotificationFields.properties] = (data[defectNotificationFields.properties] || []).map(prop => {
      return {
        [defectNotificationFields.name]: prop[defectNotificationFields.name],
        [defectNotificationFields.value]: isObject(prop[defectNotificationFields.value])
          ? prop[defectNotificationFields.value]?.[defectNotificationFields.value]
          : prop[defectNotificationFields.value],
      };
    });

    return filteredData;
  };

  const createNotificationHandler = () => {
    const defectID = get(defect, 'ID');

    // denies ability to add multiple notifications on quick clicking
    if (isLoadingNotificationDetails) return;
    setIsLoadingNotificationDetails(true);

    fetchDetailsForCreationOfNotification(projectID, defectID, configuration => {
      const { IsPopupEnabled, ...values } = configuration;
      if (IsPopupEnabled) {
        setModalData({
          isOpen: true,
          type: '',
          title: t('DEFECT_DETAILS.CREATE_NOTIFICATION'),
          CustomContent: dynamicProps => {
            return <DefectNotificationCreationModal notificationData={values} {...dynamicProps} />;
          },
          closeAction: closeModalAction,
          customCloseAction: closeModalAction,
          handleSubmit: data => {
            onNotificationCreateClick(defectID, data, IsPopupEnabled);
          },
        });
      } else {
        onNotificationCreateClick(defectID, configuration, IsPopupEnabled);
      }
      setIsLoadingNotificationDetails(false);
    });
  };

  const onNotificationCreateClick = (defectID, configuration, IsPopupEnabled) => {
    // sets create notification button submitting to true, so the user cannot create/click multiple times
    setCreateNotificationFormSubmitting(true);
    const configurationToSend = Object.assign({}, { ...prepareNotificationConfigurationForRequest(configuration), IsPopupEnabled });
    // send notification
    createNotification(
      inspectionID,
      defectID,
      configurationToSend,
      () => {
        // close create modal if the pop up is enabled
        if (IsPopupEnabled) {
          closeModalAction();
        }
        notificationModalCustom(true, 'DEFECT_DETAILS_MODAL.NOTIFICATION_CREATED_TEXT', 'DEFECT_DETAILS_MODAL.NOTIFICATION_CREATED_TITLE');
        setElementDetails({ defect: { ...defect, [formConstants.fields.status]: defectStatus.actioned } });
        setTimeout(() => {
          getObservationNotifications(defectID);
        }, 300);
        // sets create notification button submitting to false, so the user is able to create/click notifications
        setCreateNotificationFormSubmitting(false);
      },
      // even if error occurs, sets create notification button submitting to false, so the user is able to create/click notifications
      () => setCreateNotificationFormSubmitting(false)
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCreateNotificationDebounce = useCallback(debounce(createNotificationHandler, 200), [createNotificationHandler, queryItem]);

  const fetchObservationCommentsHandler = () => {
    const onSuccessFetch = newState => {
      setCommentsList(newState.commentsList);
      setCommentsLoading(newState.commentsLoading);

      if (newState.commentsList) {
        Helpers.scrollIntoView('comments-list-wrapper', `comment-${newState.commentsList.length - 1}`, 0);
      }
    };

    fetchObservationComments({ [commentFields.defectId]: queryItem }, onSuccessFetch);
  };

  const addObservationCommentHandler = (commentValue, commentTags, resetCommentInput, scrollIntoView) => {
    const commentParams = { InspectionID: inspectionID, ProjectID: projectID, [commentFields.tags]: commentTags, [commentFields.moduleItemID]: queryItem, Comment: commentValue };
    addObservationComment(
      commentParams,
      () => fetchObservationCommentsHandler(),
      loading => setAddCommentLoading(loading)
    );
    resetCommentInput();
    scrollIntoView();
  };

  const deleteObservationCommentHandler = comment => {
    const commentParams = { [commentFields.moduleCommentID]: queryItem, CommentID: comment[commentFields.id] };
    deleteObservationComment(
      commentParams,
      () => fetchObservationCommentsHandler(false),
      loading => setAddCommentLoading(loading)
    );
  };

  const searchUserAndTeamsHandler = searchTerm => {
    fetchCommentUsersAndTeams(
      searchTerm,
      (usersList, teamsList) => setUsersAndTeamsComments({ commentUsersList: usersList, commentTeamsList: teamsList }),
      loading => setCommentUsersAndTeamsLoading(loading)
    );
  };

  const handleInputDisabled = type => {
    let content = '';

    switch (type) {
      case preventedFieldsPriorComponentIsCreated.upload:
        content = 'CREATE_COMPONENT.FILL_OTHER_FIELDS';
        break;
      case preventedFieldsPriorComponentIsCreated.customFields:
        content = 'CREATE_COMPONENT.FILL_OTHER_FIELDS_CUSTOM_PROP';
        break;
      default:
        content = 'CREATE_COMPONENT.FILL_OTHER_FIELDS_CUSTOM_PROP';
        break;
    }

    setModalData(prevModalData => ({
      ...prevModalData,
      isOpen: true,
      content: t(content),
      type: 'ok',
      closeAction: closeModalAction,
      confirmAction: closeModalAction,
    }));
  };

  if (defectDetailsLoading || !inspectionDetails) {
    return <Loader isLoading={true} />;
  }

  const menuOptions = [
    {
      title: 'DEFECT_DETAILS.DOWNLOAD_REPORT',
      action: () => openDownloadReportModalSingleDefect(defect),
    },
    {
      title: 'DEFECT_DETAILS.FORM_DELETE',
      isHighlighted: true,
      separator: true,
      access: {
        visibleFor: PERMISSIONS[PERMISSION_TYPES.observations].delete.name,
      },
      action: () => openDeleteDefectModal(),
    },
  ];

  const openDeleteDefectModal = () => {
    const modalData = {
      isOpen: true,
      content: t('DELETE_DEFECT_MODAL.DESC', { defectName: defect[formConstants.fields.name] }),
      type: 'yes-no',
      confirmAction: () =>
        deleteDefect({ DefectID: defect[formConstants.fields.id], SystemType: defect[formConstants.fields.systemType] }, () => {
          handleActivePage(modules.defects);
        }),
      closeAction: closeDeleteDefectModal,
      customClassName: 'modal-medium',
    };
    toggleDeleteModal(modalData);
  };

  const closeDeleteDefectModal = () => {
    const modalData = {
      isOpen: false,
    };
    toggleDeleteModal(modalData);
  };

  const selectedDefect = find(defectsClustered, item => item[formConstants.fields.id] === queryItem);

  return (
    <div className="defect-details">
      <Tabs
        defaultTabKey={activeToolbarItem}
        navigationClassName="component-details__tabs"
        onChange={fetchData}
        tabsHeader={<ModuleHeader id={queryItem} menuOptions={menuOptions} />}
        formHasUnsavedChanges={observationFormState?.hasUnsavedChanges}
        setFormUnsavedChanges={setObservationFormState}
      >
        <Tab title={toolbarItems[tabNames.details].label} tabKey={tabNames.details}>
          <DefectFormWrapper
            changeLinkedImagesColor={changeLinkedImagesColor}
            inspectionDetails={inspectionDetails}
            projectID={projectID}
            inspectionID={inspectionID}
            handleDefectRelatedComponentsVisible={handleDefectRelatedComponentsVisible}
            componentsVisible={componentsVisible}
            changeField={changeField}
            defectFiles={defectFiles}
            openConfirmationModal={openConfirmationModal}
            openDownloadReportModalSingleDefect={openDownloadReportModalSingleDefect}
            observationTypes={observationTypes}
            observationNotifications={observationNotifications}
            handleActivePage={handleActivePage}
            defect={defect}
            handleEditDrawing={handleEditDrawing}
            objectToolClick={objectToolClick}
            openRelatedImagesModal={getRelatedImages}
            getDefectFiles={getDefectFiles}
            formChangeDebounce={formChangeDebounce}
            fetchInspectionComponents={fetchInspectionComponents}
            inspectionComponents={inspectionComponents}
            showGeometryWarning={showGeometryWarning}
            handleInputDisabled={handleInputDisabled}
            openFile={openFile}
            openAssigneesModal={openAssigneesModal}
            addedAssignees={contributors.Assignees}
            addedCollaborators={contributors.Collaborators}
            submitForm={submitForm}
            setDefect={setDefect}
            formHasUnsavedChanges={observationFormState?.hasUnsavedChanges || observationFormState?.hasUnsavedCustomProps || observationFormState?.requestInProgress}
            handleObservationComponentChange={handleObservationComponentChange}
            observationWorkOrders={observationWorkOrders}
            locationObject={{
              ...Helpers.getModuleLocationObject({
                ...selectedDefect,
                Geometry: selectedDefect?.Geometry || selectedDefect?.Geometry,
                CameraPosition: selectedDefect?.CameraPosition || selectedDefect?.CameraPosition,
              }),
              visible: selectedDefect?.visible || false,
            }}
            toggleElement={toggleElement}
          />
        </Tab>
        <Tab title={toolbarItems[tabNames.notifications].label} tabKey={tabNames.notifications} visible={FEATURES.notifications?.visible}>
          <DefectNotifications
            notifications={observationNotifications}
            onCreateNotificationClick={handleCreateNotificationDebounce}
            handleActivePage={handleActivePage}
            isCreateButtonDisabled={!userCanCreateNewNotifications}
            isLoading={observationNotificationsLoading}
            isLoadingNotificationDetails={isLoadingNotificationDetails}
          />
        </Tab>
        <Tab title={toolbarItems[tabNames.comments].label} tabKey={tabNames.comments}>
          <CommentsTab
            commentsList={commentsList}
            commentsLoading={commentsLoading}
            addCommentLoading={addCommentLoading}
            fetchCommentsList={fetchObservationCommentsHandler}
            onAddCommentClick={addObservationCommentHandler}
            onDeleteCommentClick={deleteObservationCommentHandler}
            fetchCommentUsersAndTeams={searchUserAndTeamsHandler}
            commentUsersList={usersAndTeamsComments.commentUsersList}
            commentTeamsList={usersAndTeamsComments.commentTeamsList}
            commentUsersAndTeamsLoading={commentUsersAndTeamsLoading}
            user={user}
            addCommentPermission={PERMISSIONS[PERMISSION_TYPES.observations].addComment.name}
          />
        </Tab>
        {FEATURES.modulesHistory?.visible && (
          <Tab title={toolbarItems[tabNames.history].label} tabKey={tabNames.history}>
            <DefectHistory defectId={defect[formConstants.fields.id]} project_id={projectID} inspection_id={inspectionID} defaultComponent={inspectionDetails.DefaultComponent} />
          </Tab>
        )}
      </Tabs>
      <Modal {...modalData} />
      <Modal {...deleteFileModalData} />
      <Modal
        {...assigneesModal}
        collaborators={collaborators}
        assignees={assignees}
        selectedAssignee={isEmpty(contributors.Assignees) ? null : contributors.Assignees[0]}
        addedCollaborators={contributors.Collaborators || []}
        searchContributors={handleSearchContributors}
        addContributor={handleAddObservationContributorDebounce}
        removeContributor={handleRemoveObservationContributorDebounce}
      />
      <Modal {...modalRelatedImagesData} />
      <Modal {...deleteDefectModalData} />
    </div>
  );
};

const mapStateToProps = state => ({
  deleteDefectModalData: state.inspectionReducer.delDefectModalData,
  componentsVisible: state.inspectionReducer.componentsVisible,
  observationNotifications: state.inspectionReducer.observationNotifications,
  observationWorkOrders: state.inspectionReducer.observationWorkOrders,
  observationTypes: state.inspectionReducer.observationTypes,
  inspectionDetails: state.inspectionReducer.inspectionDetails,
  defectFiles: state.uploadReducer.defectFiles,
  defectDetailsLoading: state.inspectionReducer.defectDetailsLoading,
  user: state.userReducer,
  inspectionModalData: state.inspectionReducer.inspectionModalData,
  activeTab: state.inspectionReducer.activeLeftSidebar,
  components: state.pdfTagReducer.pdfComponents,
  contributors: state.inspectionReducer.observationContributors,
  pdfPageNumber: state.pdfTagReducer.pdfPageNumber,
  observationFormState: state.inspectionReducer.observationFormState,
  observationNotificationsLoading: state.inspectionReducer.observationNotificationsLoading,
  defectsClustered: state.inspectionReducer.observationsClustered,
});

const mapDispatchToProps = dispatch => ({
  updateDefect: (defect, needsUpdate = true, callback) => dispatch(updateDefect(defect, needsUpdate, callback)),
  getObservationDMSFilesUploaded: defectId => dispatch(getObservationDMSFilesUploaded(defectId)),
  getInspectionElementsByType: (inspectionID, elementType, searchText, setDataToLocalState) => dispatch(getInspectionElementsByType(inspectionID, elementType, searchText, setDataToLocalState)),
  changeLinkedImagesColor: (defectFiles, severity) => dispatch(changeLinkedImagesColor(defectFiles, severity)),
  handleEditDrawing: (projectId, inspectionID, file, defectId) => dispatch(handleEditDrawing(projectId, inspectionID, file, defectId)),
  handleDefectRelatedComponentsVisible: isVisible => dispatch(handleDefectRelatedComponentsVisible(isVisible)),
  changeField: (fieldName, value) => dispatch(change(FORMS.defect, fieldName, value)),
  notificationModalCustom: (isOpen, errorMessage, title) => dispatch(notificationModalCustom(isOpen, errorMessage, title)),
  getDefectRelatedImages: (inspectionID, coords, onSuccess, type) => dispatch(getDefectRelatedImages(inspectionID, coords, onSuccess, type)),
  savePointRelatedImages: (defect, currentImage, files, callback) => dispatch(savePointRelatedImages(defect, currentImage, files, callback)),
  getObservationTypes: () => dispatch(getObservationTypes()),
  getDefectDetails: (ID, callback, includeLoader) => dispatch(getDefectDetails({ ID, [formConstants.fields.systemType]: measurementTypes.defect }, callback, {}, includeLoader, true)),
  setElementDetails: defect => dispatch(setElementDetails(defect)),
  createNotification: (inspectionId, defectId, configuration, sucessCallback, errorCallback) =>
    dispatch(createNotificationFromObservation(inspectionId, defectId, configuration, sucessCallback, errorCallback)),
  getObservationNotifications: defectId => dispatch(getObservationNotifications(defectId)),
  updateObservationComponent: (id, componentID) => dispatch(updateObservationComponent(id, componentID)),
  fetchObservationComments: (params, callback) => dispatch(fetchObservationComments(params, callback)),
  addObservationComment: (params, dataCallback, loadingCallback) => dispatch(addObservationComment(params, dataCallback, loadingCallback)),
  deleteObservationComment: (params, dataCallback, loadingCallback) => dispatch(deleteObservationComment(params, dataCallback, loadingCallback)),
  fetchCommentUsersAndTeams: (searchTerm, dataCallback, loadingCallback) => dispatch(fetchCommentUsersAndTeams(searchTerm, dataCallback, loadingCallback)),
  setDefectLocationEditing: val => dispatch(setDefectLocationEditing(val)),
  toggleInspectionModal: data => dispatch(toggleInspectionModal(data)),
  closeInspectionModal: () => dispatch(closeInspectionModal()),
  minimizeInspectionModal: () => dispatch(minimizeInspectionModal()),
  maximizeInspectionModal: () => dispatch(maximizeInspectionModal()),
  changePDFPage: (newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback) =>
    dispatch(changePDFPage(newPDFPageNumber, inspectionId, fileId, filter, selectedComponent, showOnlyConfirmedDrawings, callback)),
  openAsSeparatePageInspectionModal: () => dispatch(openAsSeparatePageInspectionModal()),
  getPdfComponentsForPopup: (inspectionID, fileId, filter, pageNumber, compId, callback) => dispatch(getPdfComponentsForPopup(inspectionID, fileId, filter, pageNumber, compId, callback)),
  setPdfComponents: selectedModuleItemDrawings => dispatch(setPdfComponents(selectedModuleItemDrawings)),
  getObservationContributors: (id, callback) => dispatch(getObservationContributors(id, callback)),
  searchObservationContributors: (params, successCallback) => dispatch(searchObservationContributors(params, successCallback)),
  addObservationContributor: (defectId, projectId, inspectionId, data) => dispatch(addObservationContributor(defectId, projectId, inspectionId, data)),
  deleteObservationContributor: (defectId, projectId, inspectionId, data, callback) => dispatch(deleteObservationContributor(defectId, projectId, inspectionId, data, callback)),
  unlinkObservationComponent: defectID => dispatch(unlinkObservationComponent(defectID)),
  unlinkDMSFileFromObservation: (sourceId, defectId, categoryID, callback, modalCallback) => dispatch(unlinkDMSFileFromObservation(sourceId, defectId, categoryID, callback, modalCallback)),
  fetchDetailsForCreationOfNotification: (projectID, defectID, callback) => dispatch(fetchDetailsForCreationOfNotification(projectID, defectID, callback)),
  toggleDeleteModal: data => dispatch(toggleDeleteDefectModal(data)),
  deleteDefect: (data, callback) => dispatch(deleteDefect(data, callback)),
  setObservationFormState: data => dispatch(setObservationFormState(data)),
  updateObservationProperties: (data, callback) => {
    dispatch(updateObservationProperties(data, callback));
  },
  setCreateNotificationFormSubmitting: data => dispatch(setCreateNotificationFormSubmitting(data)),
  fetchObservationWorkOrders: id => dispatch(fetchObservationWorkOrders(id)),
  setUnsavedChangesDirty: data => dispatch(setUnsavedChangesDirty(data)),
  // TODO: add saveObservationDrawing, updateObservationDrawing, linkDMSFileFromObservation, unlinkDMSFileFromObservation
});

DefectDetails.propTypes = {
  // Prop types...
};

DefectDetails.contextTypes = {
  t: PropTypes.func.isRequired,
};

DefectDetails.defaultProps = {
  isPdfScreen: false,
};

export default connect(mapStateToProps, mapDispatchToProps)(DefectDetails);
