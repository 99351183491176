import { cloneDeep, filter, find, findIndex, groupBy, isEmpty, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Field, getFormSyncErrors, getFormValues, reduxForm } from 'redux-form';

import ExpandableSection from '../../../../common/expandable-section/components/expandable-section';
import RenderIf from '../../../../common/render-if/components/render-if';

import Helpers from '../../../../common/helpers';
import Icon from '../../../../common/icon/components/icon';
import { additionalFields, questionTypes } from '../../../../common/question-components/constants/question-constants';
import ReducerHelpers from '../../../../common/reducer-helpers';
import { millisecondsToTime } from '../../../../common/section/helpers/section-helpers';
import { getIconForFileType } from '../../../document-management/actions/table-formatting';
import { moduleSectionQuestionFields } from '../../constants/constants';
import { componentHasAllCoordinatesZero } from '../../helpers/inspection-helper';
import { fields } from './constants/work-order-right-side-constants';
import { mandatoryQuestionAnswered, questionAnswered } from './helpers/checklist-procedure-helpers';
import { validate } from './validators/work-order-questions-form-validator';
import WorkOrderSectionComponents from './work-order-section-components';

const WorkOrderSectionChecklistQuestionsForm = (
  { questions = [], moduleId, handleFieldChange, disabled, change, handle3DTagLocationClick, formErrors, formValues, handleFileOpen, filesPropName, categoryPropName },
  { t }
) => {
  const [stateQuestions, setStateQuestions] = useState([]);

  useEffect(() => {
    setStateQuestions(questions.map(q => ({ ...q, isExpanded: false })));
  }, [questions]);

  const getFieldValue = (question, value) => {
    let name = '';
    let fieldValue = '';

    switch (question[moduleSectionQuestionFields.type]) {
      case questionTypes.DATE.valueId:
        fieldValue = Helpers.isDateValid(value) ? Helpers.getUnixDate(new Date(value).getTime()) : value;
        return { value: fieldValue, question };
      case questionTypes.NUMBER.valueId:
        fieldValue = value ? Number(value) : value;
        return { value: fieldValue, question };
      case questionTypes.MULTI_ANSWER.valueId:
        const questionCopy = cloneDeep(question);
        name = `${questionTypes.MULTI_ANSWER.fieldName}-${questionCopy[moduleSectionQuestionFields.id]}`;
        const questionOptions = Object.assign([], questionCopy[moduleSectionQuestionFields.questionOptions]);

        const selectedOptions = filter(questionOptions, questionOption => {
          return formValues[`${name}-${questionOption[moduleSectionQuestionFields.id]}`];
        }).map(option => option[moduleSectionQuestionFields.id]);

        const index = selectedOptions.indexOf(value);
        const questionOptionsIndex = findIndex(questionOptions, { [moduleSectionQuestionFields.id]: value });

        if (questionCopy[moduleSectionQuestionFields.allowMultiple]) {
          const option = questionOptions[questionOptionsIndex];
          option[moduleSectionQuestionFields.isAnswer] = index === -1;
          questionOptions.splice(questionOptionsIndex, 1, option);
          questionCopy[moduleSectionQuestionFields.questionOptions] = questionOptions;

          if (index > -1) {
            selectedOptions.splice(index, 1);
          } else {
            selectedOptions.push(value);
          }

          return { value: selectedOptions, questionCopy };
        } else {
          for (let i = 0; i < questionOptions.length; i++) {
            const option = questionOptions[i];
            if (option[moduleSectionQuestionFields.id] === value && !option[moduleSectionQuestionFields.isAnswer]) {
              option[moduleSectionQuestionFields.isAnswer] = true;
            } else {
              option[moduleSectionQuestionFields.isAnswer] = false;
            }

            questionOptions.splice(i, 1, option);
            questionCopy[moduleSectionQuestionFields.questionOptions] = questionOptions;

            change(`${name}-${questionOptions[i][moduleSectionQuestionFields.id]}`, questionOptions[i][moduleSectionQuestionFields.id] === questionCopy[moduleSectionQuestionFields.id] ? value : null);
          }

          fieldValue = index > -1 ? [] : [value];
          return { value: fieldValue, questionCopy };
        }
      case questionTypes.CHECKBOX.valueId:
        name = `${questionTypes.CHECKBOX.fieldName}-${question[moduleSectionQuestionFields.id]}`;
        change(`${name}-yes`, value ? 'true' : null);
        change(`${name}-no`, value ? null : 'true');

        return { value, question };
      default:
        return { value, question };
    }
  };

  const onFieldChange = (field, question, newValue, oldValue) => {
    if (typeof handleFieldChange === 'function') {
      const fieldValue = getFieldValue(question, newValue);
      const isCheckboxSameValue = field === questionTypes.CHECKBOX.fieldName && oldValue === newValue;
      const isNewValueEmptyString = (field === questionTypes.TEXT.fieldName || field === questionTypes.NUMBER.fieldName) && typeof newValue === 'string' && newValue === '';

      const values = {
        [moduleSectionQuestionFields.moduleId]: moduleId,
        [moduleSectionQuestionFields.questionId]: question[moduleSectionQuestionFields.id],
        [moduleSectionQuestionFields.type]: question[moduleSectionQuestionFields.type],
        [field]: isCheckboxSameValue || isNewValueEmptyString ? null : fieldValue?.value,
        question: fieldValue?.question,
      };

      handleFieldChange(values);
    }
  };

  const getField = question => {
    const types = values(questionTypes);
    const type = find(types, { valueId: question[moduleSectionQuestionFields.type] });
    const name = type ? `${type.fieldName}-${question[moduleSectionQuestionFields.id]}` : '';
    const id = type ? `question-field-${question[moduleSectionQuestionFields.sectionId]}-${question[moduleSectionQuestionFields.id]}` : '';

    switch (question[moduleSectionQuestionFields.type]) {
      case questionTypes.TEXT.valueId:
        return (
          <Field
            id={id}
            name={name}
            component={questionTypes.TEXT_LONG.component}
            onChange={(_e, value) => onFieldChange(questionTypes.TEXT.fieldName, question, value)}
            size="lg"
            enableAutoResize={true}
            disabled={disabled}
            withHiddenError
            maxChars={questionTypes.TEXT_LONG.maxChars}
            max={questionTypes.TEXT_LONG.maxChars}
            maxLength={questionTypes.TEXT_LONG.maxChars}
            placeholder={t('TEXT')}
          />
        );
      case questionTypes.NUMBER.valueId:
        return (
          <Field
            id={id}
            name={name}
            component={questionTypes.NUMBER.component}
            type="number"
            onChange={(_e, value) => onFieldChange(questionTypes.NUMBER.fieldName, question, value)}
            size="lg"
            withHiddenError
            disabled={disabled}
            placeholder={t('NUMBER')}
          />
        );
      case questionTypes.CHECKBOX.valueId: {
        const prevCheckboxValue = question.Answers && question.Answers[0] && question.Answers[0].AnswerCheckbox;
        return (
          <div className="checkboxes-question">
            <Field
              id={`${id}-yes`}
              name={`${name}-yes`}
              component={questionTypes.CHECKBOX.component}
              checkboxText={t('MODULES_SECTIONS.QUESTION_CHECKBOX_YES')}
              value={true}
              onChange={() => onFieldChange(questionTypes.CHECKBOX.fieldName, question, true, prevCheckboxValue)}
              restprops={{ name: `${name}-yes` }}
              disabled={disabled}
            />
            <Field
              id={`${id}-no`}
              name={`${name}-no`}
              component={questionTypes.CHECKBOX.component}
              checkboxText={t('MODULES_SECTIONS.QUESTION_CHECKBOX_NO')}
              value={false}
              onChange={() => onFieldChange(questionTypes.CHECKBOX.fieldName, question, false, prevCheckboxValue)}
              restprops={{ name: `${name}-no` }}
              disabled={disabled}
            />
          </div>
        );
      }
      case questionTypes.MULTI_ANSWER.valueId:
        if (question[moduleSectionQuestionFields.questionOptions] && question[moduleSectionQuestionFields.questionOptions].length > 0) {
          return (
            <div className="multi-answers-container">
              {question[moduleSectionQuestionFields.questionOptions].map((option, index) => (
                <div key={`question-option-${question[moduleSectionQuestionFields.id]}-${option[moduleSectionQuestionFields.id]}`} className={'question-option'}>
                  <Field
                    id={`${id}-${option[moduleSectionQuestionFields.id]}`}
                    name={`${name}-${option[moduleSectionQuestionFields.id]}`}
                    component={questionTypes.CHECKBOX.component}
                    value={option[moduleSectionQuestionFields.id]}
                    onChange={() => onFieldChange(questionTypes.MULTI_ANSWER.fieldName, question, option[moduleSectionQuestionFields.id])}
                    checkboxText={option[moduleSectionQuestionFields.name]}
                    shortenText
                    restprops={{ name: `${name}-${option[moduleSectionQuestionFields.id]}` }}
                    disabled={disabled}
                  />
                </div>
              ))}
            </div>
          );
        }

        return null;
      case questionTypes.DATE.valueId:
        return (
          <Field
            id={id}
            name={name}
            component={questionTypes.DATE.component}
            placeholder={t('MODULES_SECTIONS.DATE_PLACEHOLDER')}
            disabledKeyboardNavigation
            dateFormat="MMMM d, yyyy h:mm aa"
            timeFormat="h:mm aa"
            showTimeSelect
            size="lg"
            popperPlacement="top"
            withHiddenError
            onChange={value => onFieldChange(questionTypes.DATE.fieldName, question, value)}
            disabled={disabled}
          />
        );
      case questionTypes.NO_ANSWER.valueId:
        return <p className="f-primary">{question[moduleSectionQuestionFields.name]}</p>;
      default:
        return null;
    }
  };

  const getQuestionTitle = (question, index) => {
    const { type, questionOptions, name } = moduleSectionQuestionFields;

    // TODO: this can be moved to reducer and reused in section-title
    const isQuestionAnswered = questionAnswered(question, formValues);
    const isQuestionMultipleAnswersAnswered = question[type] === questionTypes.MULTI_ANSWER.valueId && question[questionOptions]?.some(qo => questionAnswered(qo, formValues));
    const isMandatoryQuestionAnswered = mandatoryQuestionAnswered(question, formErrors);

    if (question[type] === questionTypes.NO_ANSWER.valueId) {
      return (
        <>
          <span className="section-question-state section-question-state__completed">{index + 1}.</span>
          <span className="section-question-state__question-label">{t('MODULES_SECTIONS.PROCEDURE_TITLE')}</span>
        </>
      );
    }

    const questionStateClass = !isMandatoryQuestionAnswered
      ? 'section-question-state section-question-state__error'
      : isQuestionAnswered || isQuestionMultipleAnswersAnswered
      ? 'section-question-state section-question-state__completed'
      : 'section-question-state';

    return (
      <>
        <span className={questionStateClass}>{index + 1}.</span>
        <span className="section-question-state__question-label">{`${question[name]}`}</span>
      </>
    );
  };

  const toggleQuestionDetails = question => {
    const newQuestions = ReducerHelpers.updateItemInListByProp(stateQuestions, { ...question, isExpanded: !question.isExpanded }, moduleSectionQuestionFields.id);
    setStateQuestions(newQuestions);
  };

  const renderFilesSection = files => {
    const groupedByCategory = groupBy(files, categoryPropName) || {};
    return Object.keys(groupedByCategory).map(categoryKey => {
      if (!categoryKey) return null;
      const categoryFiles = groupedByCategory[categoryKey];

      return (
        <div className="category-wrapper" key={categoryKey}>
          <h3 className="title">{t(categoryKey)}</h3>
          {categoryFiles?.length > 0 && (
            <div className="category-files-wrapper">
              {categoryFiles.map(file => {
                file.isPDF = Helpers.isPDF(file[fields.fileName], file[fields.fileType]);
                file.isImage = Helpers.isImage(file[fields.fileName], file[fields.fileType]);

                return (
                  <div className="row" key={file[fields.fileName]} onClick={() => handleFileOpen(file)}>
                    <Icon name={getIconForFileType(file[fields.fileName])} size="sm" active />
                    <p title={Helpers.decodeIfStringEncoded(file[fields.fileName])} className="title">
                      {Helpers.decodeIfStringEncoded(file[fields.fileName])}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      );
    });
  };

  const getCoordinatesText = geometry => {
    const coordinates = geometry?.coordinates;
    if (!isEmpty(coordinates) && !componentHasAllCoordinatesZero(coordinates)) {
      const coordinatesLongText = (coordinates[0] || []).join(' / '),
        coordinatesShortText = (coordinates[0] || []).map(cord => Number(cord).toFixed(2)).join(' / ');
      return { shortText: coordinatesShortText, longText: coordinatesLongText };
    } else {
      return null;
    }
  };

  return (
    <form className="custom-section-form" onSubmit={e => e.preventDefault()}>
      {stateQuestions &&
        stateQuestions.length > 0 &&
        stateQuestions.map((question, index) => {
          const coordinatesText = getCoordinatesText(question[additionalFields.geometry.name]);
          const questionTitle = getQuestionTitle(question, index);
          const isQuestionMandatory = question[moduleSectionQuestionFields.isMandatory];

          return (
            <div className="question-field">
              <div className="question-field-container" key={`section-question-field-${question[moduleSectionQuestionFields.id]}`}>
                <div className="question-field-name-container">
                  <h6 className="f-primary light-bold question-label">
                    <div>{questionTitle}</div>
                    {isQuestionMandatory && <span className="f-secondary-red">{isQuestionMandatory ? '*' : ''}</span>}
                  </h6>
                  <div className="question-field-details-container">
                    <ExpandableSection
                      CustomComponent={({ isExpanded }) => <p className="f-secondary-dark light-bold">{isExpanded ? 'Less Details' : 'More Details'}</p>}
                      key={`filter-${question[moduleSectionQuestionFields.id]}`}
                      handlePreventClick={() => toggleQuestionDetails(question)}
                      customExpanded={question.isExpanded}
                    ></ExpandableSection>
                  </div>
                </div>
                <div className="question-input-field">{getField(question)}</div>
              </div>
              <RenderIf if={question.isExpanded}>
                <div className="question-field-details-wrapper">
                  <div className="inline-row">
                    <div className="item">
                      <p className="f-secondary-dark item__label">{t(additionalFields.description.label)}</p>
                      <p className="f-primary item__value">{question[additionalFields.description.name] ? question[additionalFields.description.name] : '-'}</p>
                    </div>
                    <div className="item">
                      <p className="f-secondary-dark item__label">{t(additionalFields.estimatedTime.label)}</p>
                      <p className="f-primary item__value">
                        {question[additionalFields.estimatedTime.name] && typeof question[additionalFields.estimatedTime.name] === 'number'
                          ? millisecondsToTime(question[additionalFields.estimatedTime.name] * 1000)
                          : '-'}
                      </p>
                    </div>
                  </div>
                  <div className="inline-row">
                    <div>
                      <p className="f-secondary-dark light-bold">{t(additionalFields.components.label)}</p>
                      {!isEmpty(question[additionalFields.components.name]) ? (
                        <WorkOrderSectionComponents module={{ workOrderComponents: Helpers.formatComponents(question[additionalFields.components.name]) }} disabled={true} listWithDeeplink={false} />
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                  <div className="inline-row">
                    <div>
                      <p className="f-secondary-dark light-bold">{t(additionalFields.geometry.label)}</p>
                      {!isEmpty(coordinatesText) ? (
                        <p className="f-secondary-green link" onClick={() => handle3DTagLocationClick(question)} title={coordinatesText.longText}>
                          {coordinatesText.shortText}
                        </p>
                      ) : (
                        '-'
                      )}
                    </div>
                  </div>
                  <div className="inline-row">{renderFilesSection(question[filesPropName])}</div>
                </div>
              </RenderIf>
            </div>
          );
        })}
    </form>
  );
};

WorkOrderSectionChecklistQuestionsForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

WorkOrderSectionChecklistQuestionsForm.propTypes = {
  moduleId: PropTypes.number.isRequired,
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      Name: PropTypes.string.isRequired,
      QuestionType: PropTypes.number,
      QuestionOrder: PropTypes.number,
      IsMandatory: PropTypes.bool,
      AllowMultipleAnswers: PropTypes.bool,
      EstimatedTime: PropTypes.number,
      CreatedAt: PropTypes.number,
      SectionID: PropTypes.number,
    })
  ),
  handleFieldChange: PropTypes.func,
  disabled: PropTypes.bool,
  change: PropTypes.func,
  formErrors: PropTypes.object,
  formValues: PropTypes.object,
  handle3DTagLocationClick: PropTypes.func,
  categoryPropName: PropTypes.string,
  filesPropName: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
  formErrors: getFormSyncErrors(props.form)(state),
  formValues: getFormValues(props.form)(state),
});

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    enableReinitialize: true,
    touchOnChange: true,
    destroyOnUnmount: true,
    validate,
  })(WorkOrderSectionChecklistQuestionsForm)
);
