import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { debounce, isEmpty } from 'lodash';

import InspectionView from '../../../app/inspections/potree/components/main-view';
import LocationSection from '../../../app/inspections/components/right-toolbar/location-section';
import Modal from '../../modal/components/modal';
import InspectionRenderer from '../../../app/inspections/components/left-toolbar/inspection-renderer';

import { getInspectionDetails } from '../../../app/inspections/actions/inspection-actions';

import Helpers from '../../helpers';
import { measurementTypes } from '../../../app/inspections/constants/constants';
import { measurementDropdownActions } from '../../../app/inspections/constants/measurement-constants';
import { setInspectionDetails } from '../../../app/inspections/actions/action-creators';

import '../styles/question-tag-location.scss';

const QuestionTagLocation = (props, { t }) => {
  const { getInspectionDetails, inspectionId, handleUpdateLocation, inspectionDetails, question, viewOnly } = props;

  const [viewerInstance, setViewerInstance] = useState(null);
  const [currentInsertion, setCurrentInsertion] = useState(null);
  const [modalData, setModalData] = useState({ isOpen: false });
  const [locationAdding, setLocationAdding] = useState(false);
  const [locationEditing, setLocationEditing] = useState(false);

  const parentRef = useRef(null);
  const updateTagLocationDebounced = useRef(debounce(data => updateTagLocation(data), 300)).current;

  useEffect(() => {
    getInspectionDetails(inspectionId);

    return () => {
      updateTagLocationDebounced.cancel();
    };
  }, [getInspectionDetails, inspectionId, updateTagLocationDebounced]);

  useEffect(() => {
    if (!viewerInstance) {
      return;
    }

    const cameraPosition = question?.CameraPosition?.coordinates;
    const geometry = question?.Geometry?.coordinates;
    if (cameraPosition && geometry) {
      viewerInstance.zoomToPosition(
        {
          x: cameraPosition[0],
          y: cameraPosition[1],
          z: cameraPosition[2],
        },
        geometry,
        1000
      );
    }
  }, [question, viewerInstance]);

  const updateTagLocation = data => {
    handleUpdateLocation(question.ID, data);
  };

  const createViewerInstance = newInstance => {
    setViewerInstance(newInstance);
  };

  const getCameraPosition = () => {
    const defaultCameraPosition =
      inspectionDetails && inspectionDetails.CameraPosition && !isEmpty(inspectionDetails.CameraPosition.coordinates) ? inspectionDetails.CameraPosition.coordinates : undefined;

    const cameraPosition = Helpers.getCameraPosition(viewerInstance, defaultCameraPosition);
    return cameraPosition;
  };

  const startTagLocationInsertion = (action, onFinish) => {
    if (viewerInstance) {
      const measuringTool = new window.Potree.MeasuringTool(viewerInstance);

      if (currentInsertion !== null) {
        currentInsertion.removeEventListener('finish_inserting_measurement', e => onTagLocationInsertionFinish(e, onFinish));
      }

      if (action === measurementDropdownActions.point) {
        let measurement = measuringTool.startInsertion({
          showDistances: false,
          showAngles: false,
          showCoordinates: true,
          showArea: false,
          closed: true,
          maxMarkers: 1,
          coordinatesText: 'New Area',
          systemType: measurementTypes.measurement,
          name: 'Point',
        });

        measuringTool.addEventListener('finish_inserting_measurement', e => onTagLocationInsertionFinish(e, onFinish));
        measurement.addEventListener('finish_inserting_measurement', e => onTagLocationInsertionFinish(e, onFinish));

        setCurrentInsertion(measurement);
      }
    }
  };

  const onTagLocationInsertionFinish = (e, callback) => {
    setCurrentInsertion(null);

    if (isEmpty(e.measure.points)) {
      e.target.removeEventListener('finish_inserting_measurement', e => onTagLocationInsertionFinish(e, callback));
      e.measure.removeEventListener('finish_inserting_measurement', e => onTagLocationInsertionFinish(e, callback));
      viewerInstance.scene.removeMeasurement(e.measure);
      return;
    }

    const camPos = getCameraPosition();
    const type = Helpers.getDefectType(e.measure.name);

    let body = {
      InspectionID: parseInt(inspectionId),
      CameraPosition: {
        coordinates: [camPos.x, camPos.y, camPos.z],
      },
      Geometry: {
        type: type,
        coordinates: [...e.measure.points.map(point => [point.position.x, point.position.y, point.position.z])],
      },
    };

    callback(body);

    viewerInstance.scene.removeMeasurement(e.measure);
    e.target.removeEventListener('finish_inserting_measurement', e => onTagLocationInsertionFinish(e, callback));
    e.measure.removeEventListener('finish_inserting_measurement', e => onTagLocationInsertionFinish(e, callback));
  };

  const toggleConfirmationModal = (isOpen, title, content, confirmAction) => {
    if (isOpen) {
      const handleConfirmAction = () => {
        if (typeof confirmAction === 'function') {
          confirmAction();
        }
      };

      setModalData({
        isOpen: true,
        content: t(content),
        title: t(title),
        type: 'cancel-confirm',
        customClassName: 'component-confirmation-modal',
        confirmAction: handleConfirmAction,
        closeAction: () => setModalData({ isOpen: false }),
      });
    } else {
      setModalData({ isOpen: false });
    }
  };

  const handleDeleteLocation = coordinates => {
    handleUpdateLocation(question.ID, { Geometry: { ...question?.Geometry, coordinates } });
    toggleConfirmationModal(false);
  };

  const coordinates = !isEmpty(question?.Geometry) && question.Geometry.coordinates ? question.Geometry.coordinates : [];
  return (
    <div className="question-tag-location">
      <InspectionView
        parentRef={parentRef}
        isWorkflow={false}
        potreeId={'question-location-tag-potree'}
        createViewerInstance={createViewerInstance}
        viewer={viewerInstance}
        showCameras={false}
        showScreenToolbox
      />
      <InspectionRenderer
        deselectAll={() => null}
        selectAll={() => null}
        toggleElement={() => null}
        deselectAllTemp={() => null}
        selectAllTemp={() => null}
        toggleElementTemp={() => null}
        updateElementGeometry={updateTagLocationDebounced}
        selectElement={() => null}
        elements={[{ ...question, visible: true, enableMove: locationEditing }]}
        selectedDefect={null}
        viewer={viewerInstance}
        disableMove={viewOnly}
      />
      <div className="sidebar">
        <LocationSection
          viewer={viewerInstance}
          coordinates={coordinates}
          invalid={viewOnly}
          readonlyCamPos={false}
          locationIsAdding={locationAdding}
          locationIsEditing={locationEditing}
          setLocationIsAdding={setLocationAdding}
          setLocationIsEditing={setLocationEditing}
          handleAddLocation={() => {
            if (!question) return;
            setLocationAdding(true);
            startTagLocationInsertion(measurementDropdownActions.point, newBody => {
              const data = { ...newBody };
              handleUpdateLocation(question.ID, data, () => {
                // small delay wait for update geometry
                setTimeout(() => {
                  setLocationAdding(false);
                }, 300);
              });
            });
          }}
          handleCameraChange={newValue => {
            const camPos = getCameraPosition();
            let body = {
              InspectionID: parseInt(inspectionId),
              ...question,
              CameraPosition: {
                coordinates: [camPos.x, camPos.y, camPos.z],
              },
            };

            updateTagLocationDebounced(body);
          }}
          handleDeleteLocation={newCoordinates => {
            toggleConfirmationModal(true, 'SECTIONS.ADDITIONAL_FIELD.DELETE_LOCATION_MODAL.TITLE', 'SECTIONS.ADDITIONAL_FIELD.DELETE_LOCATION_MODAL.DESC', () => handleDeleteLocation(newCoordinates));
          }}
          addingLocationDescription="SECTIONS.ADDITIONAL_FIELD.MARK_ON_3D.IN_PROGRESS.TEXT"
          missingLocationDescription="SECTIONS.ADDITIONAL_FIELD.WARNING.MISSING_PIN.DESCRIPTION"
          missingLocationErrorMessage="SECTIONS.ADDITIONAL_FIELD.MISSING_PIN.FORM_INVALID.ERROR_MESSAGE"
          editLocationDescription="SECTIONS.ADDITIONAL_FIELD.MARK_ON_3D.EDIT.TEXT"
          showSetCamera={!viewOnly}
        />
      </div>
      <Modal {...modalData} />
    </div>
  );
};

QuestionTagLocation.propTypes = {
  inspectionId: PropTypes.number.isRequired,
  getInspectionDetails: PropTypes.func.isRequired,
  handleUpdateLocation: PropTypes.func.isRequired,
  question: PropTypes.object,
  viewOnly: PropTypes.bool,
};

QuestionTagLocation.defaultProps = {
  viewOnly: false,
};

const mapStateToProps = state => {
  return {
    inspectionDetails: state.inspectionReducer.inspectionDetails,
  };
};

const mapDispatchToProps = dispatch => ({
  getInspectionDetails: inspectionId => dispatch(getInspectionDetails(inspectionId)),
  setInspectionDetails: data => dispatch(setInspectionDetails(data)),
});

QuestionTagLocation.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(QuestionTagLocation);
