export const fields = {
  id: 'ID',
  name: 'Name',
  status: 'Status',
  questions: 'Questions',
  createdAt: 'CreatedAt',
  createdBy: 'CreatedBy',
  createdByName: 'CreatedByName',
  actionMenu: 'ActionMenu',
  properties: 'Properties',
  description: 'Description',
  checklistItemTypeID: 'ChecklistItemTypeID',
  isMandatory: 'IsMandatory',
  allowMultipleAnswers: 'AllowMultipleAnswers',
  estimatedTime: 'EstimatedTime',
  questionType: 'QuestionType',
  questionOptions: 'QuestionOptions',
};

export const tableConfig = [
  {
    title: 'CHECKLISTS_PROCEDURES_LIST.ID',
    key: fields.id,
    enableSort: true,
    class: 'id-column',
  },
  {
    title: 'CHECKLISTS_PROCEDURES_LIST.NAME',
    key: fields.name,
    enableSort: true,
    class: 'name-column',
  },
  {
    title: 'CHECKLISTS_PROCEDURES_LIST.STATUS',
    key: fields.status,
    enableSort: true,
    type: fields.status,
  },
  {
    title: 'CHECKLISTS_PROCEDURES_LIST.QUESTIONS',
    key: fields.questions,
    enableSort: true,
  },
  {
    title: 'CHECKLISTS_PROCEDURES_LIST.CREATED_DATE',
    key: fields.createdAt,
    enableSort: true,
    type: 'date',
  },
  {
    title: 'CHECKLISTS_PROCEDURES_LIST.CREATOR',
    key: fields.createdByName,
    sortKey: fields.createdBy,
    enableSort: true,
  },
  {
    title: '',
    type: fields.actionMenu,
    enableSort: false,
    class: 'checklists__table-wrapper__table__inline-right',
    className: 'checklists__table-wrapper__table__inline-flex-right',
  },
];

export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const filterProps = {
  sortByColumn: 'SortByColumn',
  sortDirection: 'SortDirection',
  perPage: 'PerPage',
  lastSeen: 'LastSeen',
  searchText: 'SearchText',
  hasNext: 'HasNext',
  totalItems: 'TotalItems',
  createdByFilter: 'CreatedByFilter',
  properties: 'Properties',
};

export const defaultFilters = {
  [filterProps.sortByColumn]: fields.createdAt,
  [filterProps.sortDirection]: sortDirection.desc,
  [filterProps.perPage]: 20,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
};

export const defaultPagingObj = {
  [filterProps.lastSeen]: 0,
  [filterProps.perPage]: 20,
  [filterProps.totalItems]: 0,
  [filterProps.hasNext]: false,
};

export const defaultSortingFilter = {
  [filterProps.sortByColumn]: fields.id,
  [filterProps.sortDirection]: sortDirection.desc,
};

export const steps = {
  firstStep: '1',
  secondStep: '2',
  thirdStep: '3',
};

export const stepperData = [
  {
    name: 'CHECKLISTS_PROCEDURES_MANAGEMENT.FIRST_STEP',
    stepValue: steps.firstStep,
  },
  {
    name: 'CHECKLISTS_PROCEDURES_MANAGEMENT.SECOND_STEP',
    stepValue: steps.secondStep,
  },
  {
    name: 'CHECKLISTS_PROCEDURES_MANAGEMENT.THIRD_STEP',
    stepValue: steps.thirdStep,
  },
];

export const firstStepFields = {
  name: {
    name: 'Name',
    label: 'CHECKLISTS_PROCEDURES_MANAGEMENT.NAME_LABEL',
    placeholder: 'CHECKLISTS_PROCEDURES_MANAGEMENT.NAME_PLACEHOLDER',
    errorRequired: 'CHECKLISTS_PROCEDURES_MANAGEMENT.NAME_ERROR_REQUIRED',
    errorMinChars: 'CHECKLISTS_PROCEDURES_MANAGEMENT.NAME_ERROR_MIN_CHARS',
    errorMaxChars: 'CHECKLISTS_PROCEDURES_MANAGEMENT.NAME_ERROR_MAX_CHARS',
    minChars: 3,
    maxChars: 50,
  },
  description: {
    name: 'Description',
    label: 'CHECKLISTS_PROCEDURES_MANAGEMENT.DESCRIPTION_LABEL',
    placeholder: 'CHECKLISTS_PROCEDURES_MANAGEMENT.DESCRIPTION_PLACEHOLDER',
    maxChars: 2000,
    errorMaxChars: 'CHECKLISTS_PROCEDURES_MANAGEMENT.DESCRIPTION_ERROR_MAX_CHARS',
  },
};

export const statuses = {
  draft: {
    value: 'DRAFT',
    title: 'CHECKLISTS_PROCEDURES_LIST.DRAFT_STATUS',
    icon: 'clock',
  },
  raised: {
    value: 'RAISED',
    title: 'CHECKLISTS_PROCEDURES_LIST.RAISED_STATUS',
    icon: 'clock-filled',
    iconClass: 'checklists__table-wrapper__table__icon-grey',
  },
  live: {
    value: 'LIVE',
    title: 'CHECKLISTS_PROCEDURES_LIST.LIVE_STATUS',
    icon: 'checkmark',
    iconProps: {
      active: true,
    },
    iconClass: 'checklists__table-wrapper__table__icon-green',
  },
};

export const confirmationModalTypes = {
  changeStatus: 'change-status',
  duplicate: 'duplicate',
  delete: 'delete',
};

export const confirmationModalContent = {
  [confirmationModalTypes.changeStatus]: {
    content: 'CHECKLISTS_PROCEDURES_LIST.CHANGE_STATUS_ACTION',
  },
  [confirmationModalTypes.duplicate]: {
    content: 'CHECKLISTS_PROCEDURES_LIST.DUPLICATE_ACTION',
  },
  [confirmationModalTypes.delete]: {
    content: 'CHECKLISTS_PROCEDURES_LIST.DELETE_ACTION',
  },
};

export const componentSearchParams = {
  PerPage: Number.MAX_SAFE_INTEGER,
  LastSeen: 0,
  SearchText: '',
  SortByColumn: fields.id,
  SortDirection: 'ASC',
};
