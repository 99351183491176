import { isEmpty } from 'lodash';
import { FEATURES } from '../../../../../common/constants';

export const fields = {
  icon: 'icon',
  name: 'Name',
  createdAt: 'CreatedAt',
  status: 'Status',
  id: 'ID',
  externalNotificationID: 'ExternalNotificationID',
  type: 'Type',
  source: 'Source',
  severity: 'Severity',
  createdBy: 'CreatedBy',
  actionedAt: 'ActionedAt',
  actionedBy: 'ActionedBy',
  closedAt: 'ClosedAt',
  closedBy: 'ClosedBy',
  workOrders: 'WorkOrdersNumber',
  toggle: 'toggle',
  geometry: 'Geometry',
  camPosition: 'CameraPosition',
  hasDependencies: 'HasDependencies',
  fieldType: 'FieldType',
  fieldValues: 'FieldValues',
  properties: 'Properties',
  value: 'Value',
  notificationID: 'NotificationID',
  critical: 'Critical',
  updatedCustomProperties: 'UpdatedCustomProperties',
};

//TODO: Adjust it to support full screen in future
export const getTableConfig = (isTab, isFullScreen) => {
  const showExtID = FEATURES.notifications.externalID.visible;
  const tableConfig = [
    {
      title: '',
      key: fields.status,
      type: fields.icon,
      class: 'icon-column',
    },
    {
      title: isTab ? 'NOTIFICATIONS.TITLE' : 'NOTIFICATIONS.NAME',
      key: fields.name,
      type: fields.name,
      class: isTab ? 'name-column__notification_wo' : 'name-column',
      isSort: true,
    },
    {
      title: isTab ? 'NOTIFICATIONS.REPORTED_DATE' : 'NOTIFICATIONS.CREATED_AT',
      key: fields.createdAt,
      type: fields.createdAt,
      class: 'created-at-column',
      isSort: true,
      format: 'D MMM, YYYY',
    },
  ];

  !isTab &&
    tableConfig.splice(1, 0, {
      title: showExtID ? 'EXTERNAL_ID_SHORT' : 'ID',
      key: showExtID ? fields.externalNotificationID : fields.id,
      type: fields.id,
      class: 'id-column',
      isSort: true,
    });

  !isTab &&
    !isFullScreen &&
    tableConfig.push({
      title: '',
      key: fields.toggle,
      type: fields.toggle,
      class: 'toggle-column',
    });

  if (isFullScreen) {
    tableConfig.splice(3, 1);
    tableConfig.shift();
    tableConfig.push(
      {
        title: 'NOTIFICATIONS.STATUS',
        key: fields.status,
        type: fields.status,
        class: 'status-column',
        isSort: true,
      },
      {
        title: 'NOTIFICATIONS.TYPE',
        key: fields.type,
        type: fields.type,
        class: 'type-column',
        isSort: true,
      },
      {
        title: 'NOTIFICATIONS.SOURCE',
        key: fields.source,
        type: fields.source,
        class: 'source-column',
        isSort: true,
      },
      {
        title: 'NOTIFICATIONS.SEVERITY',
        key: fields.severity,
        type: fields.severity,
        class: 'severity-column',
        isSort: true,
      },
      {
        title: 'NOTIFICATIONS.CREATED_AT',
        key: fields.createdAt,
        type: fields.createdAt,
        class: 'created-at-column',
        isSort: true,
      },
      {
        title: 'NOTIFICATIONS.CREATED_BY',
        key: fields.createdBy,
        type: fields.createdBy,
        class: 'created-by-column',
        isSort: true,
      },
      {
        title: 'NOTIFICATIONS.ACTIONED_AT',
        key: fields.actionedAt,
        type: fields.actionedAt,
        class: 'actioned-at-column',
        isSort: true,
      },
      {
        title: 'NOTIFICATIONS.ACTIONED_BY',
        key: fields.actionedBy,
        type: fields.actionedBy,
        class: 'actioned-by-column',
        isSort: true,
      },
      {
        title: 'NOTIFICATIONS.CLOSED_AT',
        key: fields.closedAt,
        type: fields.closedAt,
        class: 'closed-at-column',
        isSort: true,
      },
      {
        title: 'NOTIFICATIONS.CLOSED_BY',
        key: fields.closedBy,
        type: fields.closedBy,
        class: 'closed-by-column',
        isSort: true,
      },
      {
        title: 'NOTIFICATIONS.RELATED_WORK_ORDERS',
        key: fields.workOrders,
        type: fields.workOrders,
        class: 'work-orders-column',
        isSort: true,
      }
    );
  }

  return tableConfig;
};
export const tableConfig = [];

export const filterProps = {
  componentID: 'ComponentID',
  searchText: 'SearchText',
  sortByColumn: 'SortByColumn',
  sortBy: 'SortBy',
  sortDirection: 'SortDirection',
  severityFilter: 'SeverityFilter',
  statusFilter: 'StatusFilter',
  dateFrom: 'DateFrom',
  dateTo: 'DateTo',
  typeFilter: 'TypeFilter',
  sourceFilter: 'SourceFilter',
  createdByFilter: 'CreatedByFilter',
  lastSeen: 'LastSeen',
  perPage: 'PerPage',
  hasNext: 'HasNext',
  limit: 'Limit',
  lastSevenDays: 'CreatedInLastWeekFilter',
  lastThirtyDays: 'CreatedInLastThirtyDaysFilter',
  totalNumber: 'TotalNumber',
  searchByExternalID: 'SearchByExternalNotificationID',
  includedIDs: 'IncludedIDs',
  componentsSearchFilter: 'ComponentsSearchFilter',
  createdInLastXDaysFilter: 'CreatedInLastXDaysFilter',
  hasWorkOrdersFilter: 'HasWorkOrdersFilter',
  hasLinkedEquipment: 'HasLinkedEquipment',
  hasFiles: 'HasFilesFilter',
  has3DLocationFilter: 'Has3DLocationFilter',
  properties: 'Properties',
};

export const statuses = {
  open: {
    value: 'OPEN',
    title: 'OBSERVATION_STATUS.OPEN',
    icon: 'exclamation-mark',
    iconProps: {
      danger: true,
      handleHover: false,
    },
    skipPermissionCheck: true,
    isRegularStatus: true,
    modalTitle: 'ISOLATION_CERTIFICATE_STATUS.DRAFT_TITLE',
  },
  actioned: {
    value: 'ACTIONED',
    title: 'OBSERVATION_STATUS.ACTIONED',
    icon: 'exclamation-mark',
    iconProps: {
      warning: true,
      handleHover: false,
    },
    skipPermissionCheck: true,
    isRegularStatus: true,
  },
  closed: {
    value: 'CLOSED',
    title: 'OBSERVATION_STATUS.CLOSED',
    icon: 'checkmark',
    iconProps: {
      active: true,
    },
    isRegularStatus: true,
    skipPermissionCheck: true,
  },
  lastSevenDays: {
    value: filterProps.lastSevenDays,
    title: 'OBSERVATION_STATUS.LAST_SEVEN_DAYS',
    iconFilledWhenActive: true,
    isRegularStatus: true,
    isSingleSelect: true,
    includeInStatuses: true,
  },
  lastThirtyDays: {
    value: filterProps.lastThirtyDays,
    title: 'OBSERVATION_STATUS.LAST_THIRTY_DAYS',
    iconFilledWhenActive: true,
    isRegularStatus: true,
    isSingleSelect: true,
    includeInStatuses: true,
  },
};

// In future we should just have one sortDirection in main consts
// TODO: this is repetitive across the app
export const sortDirection = {
  asc: 'ASC',
  desc: 'DESC',
};

export const defaultFilters = {
  [filterProps.sortByColumn]: fields.createdAt,
  [filterProps.sortDirection]: sortDirection.desc,
  [filterProps.perPage]: 10,
  [filterProps.lastSeen]: 0,
  [filterProps.searchText]: '',
  [filterProps.hasNext]: false,
  [filterProps.typeFilter]: [],
  [filterProps.createdByFilter]: [],
  [filterProps.dateFrom]: null,
  [filterProps.dateTo]: null,
  // set open and actioned by default
  [filterProps.statusFilter]: [`${statuses.open.value}`, `${statuses.actioned.value}`],
  // set created in last 7 days by default
  [filterProps.lastSevenDays]: true,
  [filterProps.lastThirtyDays]: false,
  [filterProps.searchByExternalID]: FEATURES.notifications.externalID.visible,
  // BE doesn't support per page for the notification module route
  [filterProps.limit]: 20,
  // new advanced filters
  [filterProps.sourceFilter]: '',
  [filterProps.componentsSearchFilter]: '',
  [filterProps.severityFilter]: [],
  [filterProps.createdInLastXDaysFilter]: 0,
  [filterProps.hasLinkedEquipment]: null,
  [filterProps.has3DLocationFilter]: null,
  [filterProps.hasWorkOrdersFilter]: null,
  [filterProps.properties]: [],
};

export const updateNotificationPropertiesShouldTrigger = data => {
  return !isEmpty(data);
};
