import actionTypes from '../constants/action-types.js';
import { defaultFilter, formConstants } from '../constants/measurement-location-constants.js';

const measurementLocationState = {
  measurementLocations: [],
  measurementLocationsClustered: [],
  measurementLocationsFilter: defaultFilter,
  measurementLocationsLoading: false,
  measurementLocationFormState: {
    hasUnsavedCustomProps: false,
    unsavedCustomProps: [],
    requestInProgress: false,
  },
  measurementLocationObjectEditing: false,
  measurementLocationObjectAdding: false,
  areAllMeasurementLocationsHidden: false,
  measurementLocationDetailsLoading: false,
  selectedMeasurementLocation: null,
  measurementLocationDetailsErrorModal: {
    isOpen: false,
  },
};

export const measurementLocationReducer = (state = measurementLocationState, payload) => {
  switch (payload.type) {
    case actionTypes.SELECT_ALL_MEASUREMENT_LOCATIONS:
      return {
        ...state,
        areAllMeasurementLocationsHidden: false,
        measurementLocations: state.measurementLocations.map(ml => {
          return { ...ml, visible: true };
        }),
        measurementLocationsClustered: state.measurementLocationsClustered.map(ml => {
          return { ...ml, visible: true };
        }),
      };
    case actionTypes.DESELECT_ALL_MEASUREMENT_LOCATIONS:
      return {
        ...state,
        areAllMeasurementLocationsHidden: true,
        measurementLocations: state.measurementLocations.map(ml => {
          return { ...ml, visible: false };
        }),
        measurementLocationsClustered: state.measurementLocationsClustered.map(ml => {
          return { ...ml, visible: false };
        }),
      };
    case actionTypes.TOGGLE_MEASUREMENT_LOCATION:
      return {
        ...state,
        measurementLocations: state.measurementLocations.map(location => {
          return { ...location, visible: location[formConstants.fields.id] === payload.data ? !location.visible : location.visible };
        }),
      };
    case actionTypes.SET_MEASUREMENT_LOCATIONS:
      return {
        ...state,
        measurementLocations: payload.data.map(location => ({ ...location, visible: !state.areAllMeasurementLocationsHidden })),
      };
    case actionTypes.AMEND_MEASUREMENT_LOCATIONS:
      return {
        ...state,
        measurementLocations: [...state.measurementLocations, ...payload.data.map(location => ({ ...location, visible: !state.areAllMeasurementLocationsHidden }))],
      };
    case actionTypes.SET_MEASUREMENT_LOCATIONS_FILTER:
      return { ...state, measurementLocationsFilter: payload.data };
    case actionTypes.SET_MEASUREMENT_LOCATIONS_LOADING:
      return {
        ...state,
        measurementLocationsLoading: payload.data,
      };
    case actionTypes.SET_MEASUREMENT_LOCATION_FORM_STATE:
      return {
        ...state,
        measurementLocationFormState: {
          ...state.measurementLocationFormState,
          ...payload.data,
        },
      };
    case actionTypes.SET_MEASUREMENT_LOCATIONS_CLUSTERED:
      return {
        ...state,
        measurementLocationsClustered: payload.data.map(ml => ({ ...ml, visible: true })),
      };
    case actionTypes.SET_MEASUREMENT_LOCATION_OBJECT_EDITING:
      return { ...state, measurementLocationObjectEditing: payload.data };
    case actionTypes.SET_MEASUREMENT_LOCATION_OBJECT_ADDING:
      return { ...state, measurementLocationObjectAdding: payload.data };
    case actionTypes.UPDATE_MEASUREMENT_LOCATION_SUCCESS:
      return {
        ...state,
        measurementLocations: payload.data,
        measurementLocationsClustered: payload.clusters,
      };
    case actionTypes.SET_MEASUREMENT_LOCATION_DETAILS_LOADING:
      return {
        ...state,
        measurementLocationDetailsLoading: payload.data,
      };
    case actionTypes.SET_SELECTED_MEASUREMENT_LOCATION:
      return {
        ...state,
        selectedMeasurementLocation: payload.data,
      };
    case actionTypes.SET_MEASUREMENT_LOCATION_DETAILS_ERROR_MODAL:
      return {
        ...state,
        measurementLocationDetailsErrorModal: payload.data,
      };
    default:
      return state;
  }
};
