import { debounce, findIndex, get, isEmpty, map, pick, remove, toInteger, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { change, getFormValues } from 'redux-form';
import { ReactComponent as PDFIcon } from '../../../common/assets/pdf-icon.svg';
import { setCollapsibleInfo, setCollapsibleInfoContentProps, setCollapsibleInfoIsExpanded, setCollapsibleInfoTitle } from '../../../common/collapsible-info/actions/action-creators';
import { FORMS } from '../../../common/constants';
import DoubleTable from '../../../common/double-table/components/double-table';
import Helpers from '../../../common/helpers';
import Modal from '../../../common/modal/components/modal';
import { setGenericNotification } from '../../../common/notification/actions/action-creators';
import PageNavigation from '../../../common/page-navigation/components/page-navigation';
import PathViewer from '../../../common/path-viewer/components/path-viewer';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';
import routesConstants, { routes } from '../../../common/routes-constants';
import { ComponentHierarchyUnassignedLevelID } from '../../component-hierarchy/constants/constants';
import { setUnsavedChangesDirty } from '../../inspections/actions/action-creators';
import { singleItemUploadDone } from '../../inspections/actions/work-order-actions';
import Toolbar from '../../inspections/components/collapsible-toolbar';
import ComponentPDF from '../../inspections/components/right-toolbar/component-pdf';
import { modules, placements } from '../../inspections/constants/constants';
import { setPdfComponents } from '../../pdf-tag/actions/action-creators';
import { componentFields, componentFilterValues } from '../../pdf-tag/constants/constants';
import { params } from '../../profile/constants/profile-constants';
import { fetchDMSCategories } from '../../project/actions/project-actions';
import LinkedImagesSliderSlider from '../../start-workflow/components/linked-images-slider';
import orientationConstants from '../../start-workflow/constants/orientation-constants';
import { setUploadBatchItems, setUploadItems, uploadBatchItems } from '../../upload/actions/upload-actions';
import { uploadType } from '../../upload/constants/constants';
import { createNewBatch } from '../../upload/helpers/upload-helpers';
import {
  addLabelToDMSFile,
  confirmDMSFileUploadIsDone,
  createDMSFolder,
  deleteDMFile,
  deleteDMFolder,
  deleteDMSFileVersion,
  fetchDMFileDetails,
  fetchDMFiles,
  fetchDMFolderDetails,
  fetchDMFolders,
  fetchDMLabels,
  fetchDMSStatuses,
  fetchVersionHistoryForDocument,
  getPdfComponentDrawings,
  linkComponentsToFile,
  linkNotificationToFile,
  linkObservationToFile,
  linkWorkorderToFile,
  moveDMSFile,
  removeLabelFromDMSFile,
  updateDMFolderDetails,
  updateDMSFile,
  updateDocumentVersion,
} from '../actions/dm-api-calls';
import { formatFileTableCells, formatFolderTableCells } from '../actions/table-formatting';
import { addVersionHistoryItem, updateVersionDetails } from '../constants/action-creators';
import {
  DMSCategoriesModules,
  actionButtonProps,
  actionModalContent,
  defaultFilesFilters,
  defaultFolderFilters,
  fields,
  fileDetailsSections,
  fileTableConfig,
  fileTypesOptionsValues,
  filterProps,
  filterSections,
  folderTableConfig,
  itemActionTypes,
  quickFiltersDefault,
  secondActionButtonProps,
  sortDirection,
  supportedUploadFileMimeTypes,
} from '../constants/constants';
import DMSHelpers from '../helpers/dms-helpers';
import '../styles/document-management.scss';
import DMSFileComponentPickerModal from './dms-component-picker/dms-component-picker';
import DMSRightSide from './dms-right-side/dms-right-side';
import DMSUploadDrawer from './dms-upload-drawer/dms-upload-drawer';
import DMSFilters from './filters/components/dms-filters';
import ActionModal from './modals/action-modal/action-modal';
import ChangeDMSLocation from './modals/change-dms-location/change-dms-location';
import CreateFolderForm from './modals/create-folder-modal/create-folder-modal';
import EditLabelsModal from './modals/edit-labels-modal/edit-labels-modal';
import VersionModal from './modals/upload-file-modal/components/version-modal/version-modal';
import UploadFileModal from './modals/upload-file-modal/upload-file-modal';

const DocumentManagement = (props, context) => {
  // props are extracted this way since there will be multiple props and this is cleaner than to have it deconstructed in the args pass
  const {
    location,
    user,
    fetchDMFolders,
    createDMSFolder,
    fetchDMFiles,
    fetchDMFolderDetails,
    fetchDMFileDetails,
    updateDMFolderDetails,
    deleteDMFolder,
    deleteDMFile,
    setGenericNotification,
    setUploadItems,
    fetchDMSStatuses,
    updateDMSFile,
    linkComponentsToFile,
    linkObservationToFile,
    linkNotificationToFile,
    linkWorkorderToFile,
    moveDMSFile,
    fetchDMLabels,
    addLabelToDMSFile,
    removeLabelFromDMSFile,
    getPdfComponentDrawings,
    setCollapsibleInfo,
    setCollapsibleInfoIsExpanded,
    setUploadBatchItems,
    uploadFiles,
    uploadBatchItems,
    defaultUnassignedDMSFolderID,
    confirmFileUploadIsDone,
    fetchDMSCategories,
    fetchVersionHistoryForDocument,
    addVersionHistoryItem,
    updateVersionDocument,
    updateVersionDetails,
    deleteDMSFileVersion,
    changeField,
    formValues,
    setUnsavedChangesDirty,
  } = props;
  const { search, query } = location;

  const projectID = toInteger(get(query, 'project_id'));
  const inspectionID = toInteger(get(query, 'inspection_id'));
  //to be implemented once BE supports deep linking
  //const folderID = toInteger(get(query, 'folder_id')) || null;

  //context deconstruction
  const { t } = context;

  const backButtonPath = `${user[params.fullScreenEnabled] ? routesConstants.routes.protectedRoutes.fullScreen.fullPath : routesConstants.routes.protectedRoutes.inspections.fullPath}${search}`;

  // hierarchy level details state
  const [hierarchyLevelDetails, setHierarchyLevelDetails] = useState({
    selectedItem: null,
    persistedSelectedItem: null,
    selectedItemData: {},
    selectedItemDataProperties: [],
    toolbarCollapsed: true,
    isRightSideLoading: false,
    isRightSideDisabled: true,
  });

  //path
  const [path, setPath] = useState([{ Name: t('DOCUMENT_MANAGEMENT.ALL_DOCUMENTS'), ID: null }]);

  //filters
  const [foldersFilters, setFolderFilters] = useState(defaultFolderFilters);
  const [filesFilters, setFilesFilters] = useState(defaultFilesFilters);
  //eslint-disable-next-line
  const [filesQuickFilters, setFilesQuickFilters] = useState(quickFiltersDefault);

  //data
  const [currentLevel, setCurrentLevel] = useState(null);
  const [folderData, setFolderData] = useState([]);
  const [isFoldersDataLoading, setIsFoldersDataLoading] = useState(false);
  const [foldersSearchText, setFoldersSearchText] = useState('');

  const [fileData, setFileData] = useState([]);
  const [isFilesDataLoading, setIsFilesDataLoading] = useState(false);
  const [filesSearchText, setFilesSearchText] = useState('');
  const [isFolderSelected, setIsFolderSelected] = useState(false);

  const folderRef = useRef(null);
  // versionHistoryModalRef indicates if version history modal is open
  const versionHistoryModalRef = useRef(null);

  //modal
  const [modalData, setModalData] = useState({ isOpen: false });
  const [fileUploadModalData, setFileUploadModalData] = useState({ isOpen: false });

  const [statusesData, setStatusesData] = useState([]);
  const [filtersLabels, setFiltersLabels] = useState([]);
  const [filtersLabelsLoading, setFiltersLabelsLoading] = useState(false);

  // form state
  const [formHasUnsavedChanges, setFormHasUnsavedChanges] = useState(false);
  // introduced so the change can be tracked in router callback routerWillLeave
  const formIsDirty = useRef(false);

  // hooks & lifecycles
  useEffect(() => {
    getFolders(defaultFolderFilters);
    fetchDMSStatuses(setStatusesData);
    fetchDMSCategories(projectID, DMSCategoriesModules.components);
    // fetchDMSCategories(projectID, 'DEFECTS');
    // fetchDMSCategories(projectID, 'NOTIFICATIONS');
    // fetchDMSCategories(projectID, 'WORKORDERS');
    fetchDMLabels(projectID, '', setFiltersLabels, setFiltersLabelsLoading);
    //
    /**
     * TO DISCUSS: two options:
     * 1. fetch projectDetails if the projectDetails reducer is empty we use DefaultDMSCategoryID from reducer in component picker (Linked Equipment)
     * 2. since we fetchDMSCategories, we can map to NO_CATEGORY to defaultDMSCategoryID  but it is heavily dependent on API (we will hardcode it)
     *  2.1. this way we reduce the API call but heavily dependent on API to not change NO_CATEGORY naming
     */
    // adds router hook to track route leave with routerWillLeave
    props.router.setRouteLeaveHook(props.route, routerWillLeave);

    //eslint-disable-next-line
  }, []);

  // shows browser popup when exiting or refreshing tab for unsaved form changes
  useEffect(() => {
    // the handler for actually showing the prompt
    // https://developer.mozilla.org/en-US/docs/Web/API/WindowEventHandlers/onbeforeunload
    const handler = event => {
      event.preventDefault();
      event.returnValue = '';
    };

    // if the form is changed, then set the onbeforeunload
    if (formHasUnsavedChanges) {
      window.addEventListener('beforeunload', handler);
      // clean it up, if the dirty state changes
      return () => {
        window.removeEventListener('beforeunload', handler);
      };
    }
    // since this is not dirty, don't do anything
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHasUnsavedChanges]);

  // shows validation popup when leaving DMS module (back browser click, etc.)
  const routerWillLeave = nextLocation => {
    // https://github.com/remix-run/react-router/blob/v3.2.6/docs/guides/ConfirmingNavigation.md
    // return false to prevent a transition w/o prompting the user,
    // or return a string to allow the user to decide:
    // return `null` or nothing to let other hooks to be executed
    //
    // NOTE: if you return true, other hooks will not be executed!
    if (formIsDirty.current) return 'You have unsaved changes. Are you sure you want to leave?';
  };

  const handleFolderSearchInput = e => {
    setFoldersSearchText(e.target.value);
    foldersSearchTextChanged(e.target.value);
  };

  const clearUnsavedFormChanges = () => {
    setFormHasUnsavedChanges(false);
    setUnsavedChangesDirty(false);
    formIsDirty.current = false;
  };

  const submitForm = values => {
    clearUnsavedFormChanges();

    if (isFolderSelected) {
      updateDMFolderDetails({ ProjectID: projectID, [filterProps.parentID]: currentLevel, ...values }, folderData, onSuccessFolderFetch, onSuccessFetch);
    } else {
      updateDMSFile({ ...values, [filterProps.documentHierarchyID]: hierarchyLevelDetails.selectedItemData[filterProps.documentHierarchyID] }, onSuccessFileUpdate);
    }
  };

  const formSubmitDebounce = debounce(submitForm, 250);

  //folder related logic

  const handleFolderSearch = SearchText => DMSHelpers.onSearch(SearchText, foldersFilters, getFolders);

  const foldersSearchTextChanged = debounce(handleFolderSearch, 300);

  const handleFolderSort = SortByColumn => DMSHelpers.onSort(SortByColumn, foldersFilters, getFolders);

  const handleOnFolderRowClick = row => {
    if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      folderRef.current = row.ID;
      DMSHelpers.onFolderRowClick(row, path, foldersFilters, defaultFolderFilters, getFolders, setPath, setFoldersSearchText, setCurrentLevel);
      DMSHelpers.onFolderChangeFetchFiles(row, path, filesFilters, defaultFilesFilters, getFiles, setFilesSearchText);
      clearUnsavedFormChanges();
    }
  };

  const getFolders = (filters, loadMore = false, resetSelectedItem = false) => {
    const handleGetFolders = (data, newFilters) => {
      const newData = loadMore ? [...folderData, ...data] : data;

      setFolderData(uniqBy(newData, fields.id));
      setFolderFilters({ ...foldersFilters, ...newFilters });
      setIsFoldersDataLoading(false);
      if (loadMore) {
        Helpers.scrollIntoView('folders-table', `row-${filters[filterProps.lastSeen] - 1}`, -250);
      }
      if (resetSelectedItem && !loadMore) {
        // reset selectedItem so that row it is not sticky anymore
        setHierarchyLevelDetails({ ...hierarchyLevelDetails, selectedItem: null, selectedItemData: {}, toolbarCollapsed: true });
      }
    };
    fetchDMFolders({ ...filters, ProjectID: projectID }, handleGetFolders, setIsFoldersDataLoading);
  };

  const createNewFolder = values => {
    const handleCreateFolder = Data => {
      setFolderData([...(folderData || []), Data]);
      setFolderFilters({ ...foldersFilters, [filterProps.totalItems]: foldersFilters[filterProps.totalItems] + 1 });
    };
    createDMSFolder({ ...values, [filterProps.parentID]: currentLevel, ProjectID: projectID }, handleCreateFolder, closeModal);
  };

  const loadMoreFolders = () => {
    if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      getFolders(foldersFilters, true);
      clearUnsavedFormChanges();
    }
  };

  //files related logic

  const onSuccessFileUpdate = data => {
    const newFileData = Object.assign([], fileData, {
      [findIndex(fileData, { ID: data[filterProps.sourceID] })]: {
        ...hierarchyLevelDetails.selectedItemData,
        ...{ ...data, ID: data[filterProps.sourceID], [fields.fileSize]: data[fields.fileSizeKB], [fields.createdByUser]: data[fields.createdByName] },
      },
    });
    setFileData(newFileData);
    setHierarchyLevelDetails({
      ...hierarchyLevelDetails,
      selectedItemData: { ...hierarchyLevelDetails.selectedItemData, ...data },
    });

    getFiles({ ...filesFilters, [filterProps.lastSeen]: defaultFilesFilters[filterProps.lastSeen] }, false);
  };

  const handleFileStatusChange = status => {
    const newFileObj = { ...hierarchyLevelDetails.selectedItemData, Status: status.value };
    submitForm({ ...newFileObj });
  };

  const handleFileSearchInput = e => {
    setFilesSearchText(e.target.value);
    filesSearchTextChanged(e.target.value);
  };
  const handleFileSearch = SearchText => DMSHelpers.onSearch(SearchText, filesFilters, getFiles);

  const filesSearchTextChanged = debounce(handleFileSearch, 300);

  const handleFilesQuickFiltersChange = filters => {
    const isFileTypeFiltersEachFalse = isEmpty(filters[filterSections.fileTypes.valueKey]) ? false : Object.values(filters[filterSections.fileTypes.valueKey]).every(v => v === false);
    const newFilesQuickFilters = { ...filesQuickFilters, ...filters };
    if (isFileTypeFiltersEachFalse) {
      newFilesQuickFilters[filterSections.fileTypes.valueKey] = {};
    }
    setFilesQuickFilters(newFilesQuickFilters);
    let quickFilterFileType = [];
    Object.values(fileTypesOptionsValues).forEach(item => {
      if (newFilesQuickFilters[filterSections.fileTypes.valueKey][item.name]) {
        quickFilterFileType = [...quickFilterFileType, ...item.value];
      }
    });

    getFiles({ ...filesFilters, [filterProps.lastSeen]: defaultFilesFilters[filterProps.lastSeen], ...newFilesQuickFilters, [filterSections.fileTypes.valueKey]: quickFilterFileType });
  };

  const handleFilesSort = SortByColumn => DMSHelpers.onSort(SortByColumn, filesFilters, getFiles);

  const loadMoreFiles = () => {
    if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      getFiles(filesFilters, true);
      clearUnsavedFormChanges();
    }
  };

  const getFiles = (filters, loadMore = false) => {
    //this whole section was just to check if I can turn true and false to
    const handleGetFolders = (data, newFilters) => {
      const newData = loadMore ? [...fileData, ...data] : data;
      setFileData(newData);
      setFilesFilters({ ...foldersFilters, ...newFilters });
      setIsFilesDataLoading(false);
      if (loadMore) {
        Helpers.scrollIntoView('files-table', `row-${filters[filterProps.lastSeen] - 1}`, -250);
      }
    };

    fetchDMFiles({ ...filters, ProjectID: projectID }, handleGetFolders, setIsFilesDataLoading);
  };

  // path related logic
  const handleOnPathBackClick = () => {
    if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      DMSHelpers.onPathBackClick(path, foldersFilters, defaultFolderFilters, getFolders, setPath, setFoldersSearchText, setCurrentLevel, folderRef);
      DMSHelpers.onPathBackFetchFiles(path, filesFilters, defaultFilesFilters, getFiles, setFilesSearchText);
      clearUnsavedFormChanges();
    }
  };

  const handleOnPathClick = ID => {
    if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      folderRef.current = ID;
      DMSHelpers.onPathClick(ID, path, foldersFilters, defaultFolderFilters, getFolders, setPath, setFoldersSearchText, setCurrentLevel);
      DMSHelpers.onPathClickFetchFiles(ID, filesFilters, defaultFilesFilters, getFiles, setFilesSearchText);
      clearUnsavedFormChanges();
    }
  };

  // modal related actions
  const closeModal = () => setModalData({ isOpen: false });

  const onCreateFolderClick = () => {
    setModalData({
      type: '',
      title: t('DOCUMENT_MANAGEMENT.NEW_FOLDER'),
      isOpen: true,
      closeAction: closeModal,
      customCloseAction: closeModal,
      customClassName: 'modal-no-max-height modal-medium',
      CustomContent: dynamicProps => <CreateFolderForm {...dynamicProps} />,
      onSubmit: createNewFolder,
    });
  };

  const handleSelectedItemURLUpdate = file => {
    setHierarchyLevelDetails({
      ...hierarchyLevelDetails,
      selectedItemData: {
        ...hierarchyLevelDetails.selectedItemData,
        ...pick(file, [
          fields.thumbnailUrl,
          fields.url,
          fields.documentUrl,
          fields.numberOfFileVersions,
          fields.versionCreatedAt,
          fields.versionCreatedBy,
          fields.versionDate,
          fields.fileVersion,
          fields.fileType,
          fields.versionDescription,
          fields.versionNumber,
          fields.fileSizeKB,
        ]),
        [fields.isPdf]: Helpers.isPDF(file[fields.fileName], file[fields.fileType]),
        [fields.isImage]: Helpers.isImage(file[fields.fileName], file[fields.fileType]),
        [fields.src]: Helpers.getUploadImageSource(file[fields.fileName], file[fields.url], null, file[fields.fileType]),
        [fields.documentSizeUrl]: file[fields.documentUrl],
        [fields.thumbnailUrlLower]: file[fields.thumbnailUrl],
      },
    });
    // updates file data and sets the type and size of new active version of selected item
    const updatedFileData = fileData.map(file => {
      if (file[fields.id] === hierarchyLevelDetails.selectedItem) {
        return {
          ...file,
          [fields.fileSize]: file[fields.fileSize],
          [fields.fileType]: file[fields.fileType],
        };
      }
      return file;
    });
    setFileData(updatedFileData);
  };

  // If the user is still in same folder fetch the list of files
  const batchDoneCallback = (batch, isVersionUpload = false) => {
    if (!isVersionUpload && folderRef.current === batch.folderID)
      getFiles({
        ...filesFilters,
        [filterProps.lastSeen]: defaultFilesFilters[filterProps.lastSeen],
        [filterProps.sortByColumn]: fields.createdAt,
        [filterProps.sortDirection]: sortDirection.desc,
        [filterProps.parentID]: batch.folderID,
      });
    if (isVersionUpload && versionHistoryModalRef.current?.isOpen && hierarchyLevelDetails.selectedItemData[fields.sourceID] === versionHistoryModalRef.current.sourceID) {
      fetchVersionHistoryForDocument({ [fields.sourceID]: hierarchyLevelDetails.selectedItemData[fields.sourceID] });
    }
    if (isVersionUpload && hierarchyLevelDetails && hierarchyLevelDetails.selectedItem && !hierarchyLevelDetails.toolbarCollapsed) {
      if ((!batch.files || batch.files[0]) && !batch.batchCompleted && !batch.files[0][fields.isActive]) return;
      // file data will be made form batch.files[0] since in version upload only one file can be uploaded
      fetchDMFileDetails(hierarchyLevelDetails.selectedItem, handleSelectedItemURLUpdate, null, false, true);
    }
  };

  const confirmFileUpload = (fileID, batch, isVersionUpload, isActive) => {
    if (!fileID || fileID < 0 || !batch) return;
    confirmFileUploadIsDone(fileID, isActive, () => batchDoneCallback(batch, isVersionUpload));
  };

  // reuse this as it is redundant code same as in edit-module-item-files-modal.js?
  const fileUploadHandler = async (files, UploadGroup, isVersionUpload) => {
    const dmFileUploadType = uploadType.dmFiles;
    const newBatch = createNewBatch(files, currentLevel || defaultUnassignedDMSFolderID);

    const customGetIDs = file => ({
      ProjectID: projectID,
      SourceID: file.SourceID || 0,
      UploadGroup,
      DocumentHierarchyID: file.DocumentHierarchyID || currentLevel || defaultUnassignedDMSFolderID,
      [fields.status]: file[fields.status],
      [fields.fileSizeKB]: file.size,
      [fields.fileType]: file[fields.fileType],
      [fields.smartDrawing]: file[fields.smartDrawing],
      UploadType: dmFileUploadType,
      [fields.versionNumber]: file[fields.versionNumber],
      [fields.versionDescription]: file[fields.versionDescription],
      [fields.versionDate]: file[fields.versionDate],
      [fields.isActive]: file[fields.isActive],
    });

    // set batch to upload
    setUploadBatchItems(newBatch);
    // Show files in the notification drawer
    setCollapsibleInfo({
      customContent: () => <DMSUploadDrawer />,
      isDisplayed: true,
      isExpanded: true,
      title: t('DOCUMENT_MANAGEMENT.FILE_UPLOADING_TITLE', { totalItems: files.length + uploadFiles.length }),
    });
    // if the version modal is open add one item to the table
    if (versionHistoryModalRef.current?.isOpen) {
      addVersionHistoryItem({ [fields.fileName]: t('UPLOADING'), ID: null, loading: true });
    }
    // Start uploading the batch
    uploadBatchItems(newBatch, customGetIDs, confirmFileUpload, false, isVersionUpload);

    // close upload modal
    setFileUploadModalData({ isOpen: false });
  };

  const onUploadFileClick = (isVersionModal = false) => {
    if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave DMS?')) {
      const closeUploadModalAction = () => {
        setFileUploadModalData({ isOpen: false });
        setUploadItems([]);
      };
      setCollapsibleInfoIsExpanded(false);
      const modalTitle = isVersionModal ? 'DMS_UPLOAD_NEW_VERSION_MODAL.TITLE' : 'DOCUMENT_MANAGEMENT_FILES_UPLOAD_MODAL.TITLE';
      setFileUploadModalData({
        title: t(modalTitle),
        customClassName: 'project-assets-modal modal-small dm-upload-file-modal modal-no-max-height ',
        CustomContent: () => (
          <UploadFileModal
            className="project-dropzone"
            labelWhite={t('DOCUMENT_MANAGEMENT.UPLOAD_MODAL_DRAG_AND_DROP_UPLOAD')}
            labelGreen={t('DOCUMENT_MANAGEMENT.UPLOAD_MODAL_CHOOSE_FILE')}
            labelWhiteRest={t('DOCUMENT_MANAGEMENT.UPLOAD_MODAL_UPLOAD')}
            supportedMimeTypes={supportedUploadFileMimeTypes}
            fileUploadHandler={fileUploadHandler}
            closeAction={closeUploadModalAction}
            user={user}
            setUploadItems={setUploadItems}
            statusData={statusesData}
            isVersionModal={isVersionModal}
            documentDetails={hierarchyLevelDetails.selectedItemData}
          />
        ),
        isOpen: true,
        type: 'ok',
        confirmAction: () => null,
        closeAction: closeUploadModalAction,
      });
    }
  };

  //dropdown related logic

  const onSuccessFolderFetch = () => getFolders({ ...foldersFilters, [filterProps.lastSeen]: defaultFolderFilters[filterProps.lastSeen] });
  const onSuccessFetch = newState => setHierarchyLevelDetails({ ...newState });

  const handleOnDropdownClick = (row, type) => {
    if (!row) return;

    if (type === itemActionTypes.file.delete || type === itemActionTypes.folder.delete || type === itemActionTypes.fileVersion.delete) {
      const { selectedItem } = hierarchyLevelDetails;
      const isSameFileOrFolder = selectedItem === row[fields.id];
      const fileName = row[fields.name] || row[fields.fileName];
      const fileId = row[fields.id] || row[fields.fileId];
      const decodedFileName = Helpers.decodeIfStringEncoded(fileName);
      // shows unsaved changes popup only if active file/folder !== selected file/folder
      if (!isSameFileOrFolder) {
        // TODO: setModalData code snippet seems redundant, requires improvement and refactoring
        if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
          setModalData({
            type: '',
            isOpen: true,
            closeAction: closeModal,
            customCloseAction: closeModal,
            className: 'modal-no-max-height modal-medium',
            CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
            customConfirmAction: () => confirmDeleteActionModal(fileId, type),
            firstParagraphProps: { name: decodedFileName },
            ...actionModalContent[type],
            title: t(actionModalContent[type].title),
          });
        }
      } else {
        setModalData({
          type: '',
          isOpen: true,
          closeAction: closeModal,
          customCloseAction: closeModal,
          className: 'modal-no-max-height modal-medium',
          CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
          customConfirmAction: () => confirmDeleteActionModal(fileId, type),
          firstParagraphProps: { name: decodedFileName },
          ...actionModalContent[type],
          title: t(actionModalContent[type].title),
        });
      }
    } else if (type === itemActionTypes.folder.edit) {
      if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        fetchDMFolderDetails(row[fields.id], onSuccessFetch, type);
        setIsFolderSelected(true);
      }
    } else if (type === itemActionTypes.file.edit) {
      if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
        fetchDMFileDetails(row[fields.id], onSuccessFetch, type);
        setIsFolderSelected(false);
      }
    }
  };

  const handleOnFileRowClick = row => {
    if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      if (!row) return;

      fetchDMFileDetails(row[fields.id], onSuccessFetch, itemActionTypes.file.edit);
      setIsFolderSelected(false);
      clearUnsavedFormChanges();
    }
  };

  const confirmDeleteActionModal = (ID, type) => {
    setModalData({
      type: '',
      className: 'modal-no-max-height modal-medium',
      isOpen: true,
      closeAction: closeModal,
      customCloseAction: closeModal,
      CustomContent: dynamicProps => <ActionModal {...dynamicProps} />,
      customConfirmAction: () => handleDeleteItem(ID, type),
      ...actionModalContent[itemActionTypes.confirm.delete],
      title: t(actionModalContent[itemActionTypes.confirm.delete].title),
    });
  };

  //shared logic between files and folders

  const handleDeleteItem = (ID, type) => {
    setModalData({ isOpen: false });
    if (type === itemActionTypes.file.delete) {
      const successCallback = () => {
        const newFilesData = Object.assign([], fileData);
        remove(newFilesData, { ID });
        setFileData(newFilesData);
        setFilesFilters({ ...filesFilters, [filterProps.totalItems]: filesFilters[filterProps.totalItems] - 1 });
        setGenericNotification({ isDisplayed: true, type: 'error', text: t('DOCUMENT_MANAGEMENT.FILE_DELETE_SUCCESS'), icon: 'trash' });
        setHierarchyLevelDetails({
          selectedItem: null,
          selectedItemData: {},
          toolbarCollapsed: true,
          isRightSideLoading: false,
          isRightSideDisabled: true,
        });
        getFiles({ ...filesFilters, [filterProps.lastSeen]: defaultFilesFilters[filterProps.lastSeen] });
        clearUnsavedFormChanges();
      };
      deleteDMFile({ [filterProps.sourceID]: ID }, successCallback);
    } else if (type === itemActionTypes.folder.delete) {
      const successCallback = () => {
        const newFolderData = Object.assign([], folderData);
        remove(newFolderData, { ID });
        setFolderData(newFolderData);
        setFolderFilters({ ...foldersFilters, [filterProps.totalItems]: foldersFilters[filterProps.totalItems] - 1 });
        setGenericNotification({ isDisplayed: true, type: 'error', text: t('DOCUMENT_MANAGEMENT.FOLDER_DELETE_SUCCESS'), icon: 'trash' });
        getFolders({ ...foldersFilters, [filterProps.lastSeen]: defaultFolderFilters[filterProps.lastSeen] }, false, true);
        clearUnsavedFormChanges();
      };
      deleteDMFolder({ ID, ProjectID: projectID }, successCallback);
    } else if (type === itemActionTypes.fileVersion.delete) {
      const successCallback = () => {
        fetchVersionHistoryForDocument({ [fields.sourceID]: hierarchyLevelDetails.selectedItemData[fields.sourceID] });
        clearUnsavedFormChanges();
      };

      deleteDMSFileVersion({ VersionID: ID }, successCallback);
    }
  };

  const handleToolbarClick = (_, e) => {
    e?.stopPropagation();
    if (!hierarchyLevelDetails.selectedItem) return;
    setHierarchyLevelDetails({ ...hierarchyLevelDetails, toolbarCollapsed: !hierarchyLevelDetails.toolbarCollapsed });
  };

  const handleDeselectItem = e => {
    e?.stopPropagation();
  };

  const openChangeLocationModal = () => {
    if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      setModalData({
        isOpen: true,
        type: '',
        customClassName: 'modal-no-max-height modal-large',
        closeAction: closeModal,
        customCloseAction: closeModal,
        CustomContent: dynamicProps => <ChangeDMSLocation {...dynamicProps} />,
        title: t('DOCUMENT_HIERARCHY_CHANGE.MODAL_TITLE'),
        fileInformation: hierarchyLevelDetails.selectedItemData,
        customConfirmAction: handleChangeLocation,
        isChooseDocument: false,
        fetchDMFolders: fetchDMFolders,
        fetchDMFiles: fetchDMFiles,
        projectID: projectID,
      });
    }
  };

  const handleAddLabelToDMSFile = (newAddedLabels, label) => {
    const { SourceID } = hierarchyLevelDetails.selectedItemData;
    addLabelToDMSFile(newAddedLabels, SourceID, label[fields.id]);
  };

  const handleRemoveLabelFromDMSFile = (newAddedLabels, label) => {
    const { SourceID } = hierarchyLevelDetails.selectedItemData;
    removeLabelFromDMSFile(newAddedLabels, SourceID, label[fields.id]);
  };

  const handleSearchDMSFileLabels = debounce(searchText => {
    fetchDMLabels(projectID, searchText);
  }, 300);

  const openEditLabelsModal = e => {
    e.stopPropagation();
    setModalData({
      isOpen: true,
      type: '',
      customClassName: 'modal-no-max-height modal-large file-details-labels-modal',
      closeAction: closeModal,
      customCloseAction: closeModal,
      CustomContent: dynamicProps => <EditLabelsModal {...dynamicProps} />,
      title: t('DOCUMENT_MANAGEMENT_FILTERS.LABELS'),
      handleAddLabelToDMSFile: handleAddLabelToDMSFile,
      handleRemoveLabelFromDMSFile: handleRemoveLabelFromDMSFile,
      handleSearchDMSFileLabels: handleSearchDMSFileLabels,
    });
  };

  const handleChangeLocation = (data, modalPath) => {
    const newFiles = Object.assign([], fileData);

    const isSameFolder = modalPath[modalPath.length - 1][fields.id] === path[path.length - 1][fields.id];
    const newFileInformation = Object.assign({}, hierarchyLevelDetails.selectedItemData);

    const onSuccessChange = () => {
      if (isSameFolder) {
        newFiles.push({
          ...newFileInformation,
          [fields.fileSize]: newFileInformation[fields.fileSizeKB],
          [fields.createdByUser]: newFileInformation[fields.createdByName],
          [fields.createdAt]: newFileInformation[fields.createdAt],
        });
      } else {
        remove(newFiles, { [fields.id]: data[filterProps.sourceID] });
      }
      setFileData(newFiles);
      setFilesFilters({ ...filesFilters, [filterProps.totalItems]: filesFilters[filterProps.totalItems] + (isSameFolder ? 1 : -1) });
      setHierarchyLevelDetails({
        ...hierarchyLevelDetails,
        selectedItemData: {
          ...hierarchyLevelDetails.selectedItemData,
          Hierarchy: Helpers.getShortenedPath(modalPath, fields.name),
          [filterProps.documentHierarchyID]: data[filterProps.documentHierarchyID],
        },
      });
      setGenericNotification({
        isDisplayed: true,
        type: 'success',
        text: t('DOCUMENT_MANAGEMENT.FILE_LOCATION_CHANGE_SUCCESS', {
          fileName: Helpers.decodeIfStringEncoded(hierarchyLevelDetails.selectedItemData[fields.fileName]),
          folderName: modalPath[modalPath.length - 1][fields.name],
        }),
        icon: 'checkmark-outline',
      });
      getFiles({ ...filesFilters, [filterProps.lastSeen]: defaultFilesFilters[filterProps.lastSeen] });
      closeModal();
    };
    moveDMSFile(data, onSuccessChange);
  };

  // eslint-disable-next-line no-unused-vars
  const linkNotificationsToCurrentFile = data => {
    const onSuccessfulLinking = () => {
      const dataToSave = data.map(item => ({ ...item, [fields.id]: item[fields.id], [fields.name]: item[fields.name] }));
      setHierarchyLevelDetails(prevState => ({ ...prevState, selectedItemData: { ...prevState.selectedItemData, Notifications: dataToSave } }));
      closeModal();
    };
    const dataToSend = { [filterProps.sourceID]: hierarchyLevelDetails.selectedItem, Notifications: data.map(item => ({ ...item, [fields.id]: item[fields.id] })) };
    linkNotificationToFile(dataToSend, onSuccessfulLinking);
  };

  // eslint-disable-next-line no-unused-vars
  const linkObservationsToCurrentFile = data => {
    const onSuccessfulLinking = () => {
      const dataToSave = data.map(item => ({ ...item, [fields.id]: item[fields.id], [fields.name]: item[fields.name] }));
      setHierarchyLevelDetails(prevState => ({ ...prevState, selectedItemData: { ...prevState.selectedItemData, Defects: dataToSave } }));
      closeModal();
    };
    const dataToSend = { [filterProps.sourceID]: hierarchyLevelDetails.selectedItem, Defects: data.map(item => ({ ...item, [fields.id]: item[fields.id] })) };
    linkObservationToFile(dataToSend, onSuccessfulLinking);
  };

  // eslint-disable-next-line no-unused-vars
  const linkWorkorderToCurrentFile = data => {
    const onSuccessfulLinking = () => {
      const dataToSave = data.map(item => ({ ...item, [fields.id]: item[fields.id], [fields.name]: item[fields.name] }));
      setHierarchyLevelDetails(prevState => ({ ...prevState, selectedItemData: { ...prevState.selectedItemData, Workorders: dataToSave } }));
      closeModal();
    };
    const dataToSend = { [filterProps.sourceID]: hierarchyLevelDetails.selectedItem, Workorders: data.map(item => ({ ...item, [fields.id]: item[fields.id] })) };
    linkWorkorderToFile(dataToSend, onSuccessfulLinking);
  };

  const linkComponentsToCurrentFile = data => {
    const onSuccessfulLinking = () => {
      const dataToSave = data.map(item => ({
        ...item,
        [fields.componentType]: item[fields.componentTypeShort],
        [fields.componentCode]: item[fields.componentCodeShort],
        [fields.critical]: item[fields.critical],
      }));

      setHierarchyLevelDetails(prevState => ({ ...prevState, selectedItemData: { ...prevState.selectedItemData, Components: dataToSave } }));

      /**
       * The code below is checking for dirty form fields using the `getDirtyFormFields`
       * function from the `dmsHelpers` object. If there are dirty form fields (fields that have been
       * modified), it iterates over each dirty field and calls the `changeField` function with the
       * form name, field name, field value, and a boolean parameter set to true. This code is
       * used to update specific form fields based on user input or changes. This will ensure that the unsaved changes
       * are preserved and the Save button is enabled.
       */

      const dirtyFormFields = DMSHelpers.getDirtyFormFields(formValues, hierarchyLevelDetails.selectedItemData);

      if (dirtyFormFields?.length > 0) {
        dirtyFormFields.forEach(field => {
          changeField(FORMS.DMSFileHierarchyLevel, field, formValues[field], true);
        });
      }

      closeModal();

      // display a generic notification after link or unlink or category change
      setGenericNotification({
        isDisplayed: true,
        type: 'success',
        text: t('LINKED_COMPONENT_SUCCESSFULLY_EDITED'),
        icon: 'checkmark-outline',
      });
    };

    const dataToSend = { [filterProps.sourceID]: hierarchyLevelDetails.selectedItem, Components: data.map(item => ({ ...item, [fields.componentID]: item[fields.id] })) };

    linkComponentsToFile(dataToSend, onSuccessfulLinking);
  };

  const openEditComponentsModal = e => {
    e?.stopPropagation();

    setModalData({
      isOpen: true,
      type: '',
      customClassName: 'link-components-modal modal-large',
      customClassWrapperName: 'link-components-modal__picker',
      closeAction: closeModal,
      customCloseAction: closeModal,
      CustomContent: dynamicProps => <DMSFileComponentPickerModal {...dynamicProps} />,
      title: t('DOCUMENT_MANAGEMENT.LINKED_EQUIPMENT'),
      fileInformation: hierarchyLevelDetails.selectedItemData,
      customConfirmAction: linkComponentsToCurrentFile,
      fileID: hierarchyLevelDetails.selectedItem,
      initialComponents: hierarchyLevelDetails.selectedItemData.Components,
    });
  };

  const openEdit = {
    [fileDetailsSections.components.valueKey]: openEditComponentsModal,
    // TODO: implement openEditObservationsModal, openEditNotificationsModal, openEditWorkordersModal!
    // [fileDetailsSections.defects.valueKey]: openEditObservationsModal,
    // [fileDetailsSections.notifications.valueKey]: openEditNotificationsModal,
    // [fileDetailsSections.workorders.valueKey]: openEditWorkordersModal,
    [fileDetailsSections.labels.valueKey]: openEditLabelsModal,
  };

  const openImagesModal = (selectedImage, linkedImages) => {
    let index = 0;
    linkedImages = map(linkedImages, (item, i) => {
      if (selectedImage.FileID === item.FileID) {
        index = i;
      }
      return item;
    });

    setModalData({
      ...modalData,
      customClassName: 'linked-images-slider',
      CustomContent: () => (
        <LinkedImagesSliderSlider
          linkedImages={linkedImages}
          currentSlideIndex={index}
          setOrientation={() => null}
          disabledOrientations={[orientationConstants.orientation.chart]}
          isPreviewModal={true}
        />
      ),
      isOpen: true,
      type: 'none',
      closeAction: closeModal,
    });
  };

  const openFile = (file, imageFiles) => {
    if (file.isPDF) {
      getPdfComponentDrawings(
        inspectionID,
        file.FileID, // sets default filters and pageNumber to 1
        {
          SearchText: '',
          ComponentFilter: componentFilterValues.all,
        },
        1,
        null,
        d => {
          const confirmedDrawings = (d || []).filter(cDrawing => cDrawing[componentFields.confirmed]);
          setPdfComponents(confirmedDrawings);

          setModalData({
            ...modalData,
            isOpen: true,
            isMinimized: false,
            FileIcon: props => <PDFIcon height={18} {...props} />,
            fileName: file.name,
            title: t('PDF_VIEWER.MODAL.TITLE'),
            customClassName: 'dms-pdf-viewer',
            type: '',
            closeAction: () => {
              setModalData({ isOpen: false });
              setPdfComponents([]);
            },
            CustomContent: props => (
              <ComponentPDF
                file={file}
                rightCollapsed={true}
                inspectionId={inspectionID}
                // when PDF opened in popup from module details or DMS, we show only confirmed tags/drawings
                showOnlyConfirmedDrawings={true}
                {...props}
              />
            ),
          });
        }
      );
    } else if (file.isImage) {
      const images = isEmpty(imageFiles) ? [file] : imageFiles;
      openImagesModal(file, images);
    } else {
      Helpers.getFileExtensionAndDownload(file);
    }
  };

  const downloadFile = file => {
    Helpers.getFileExtensionAndDownload(file);
  };

  const onModuleItemDeepLinkHandler = (moduleItem, moduleKey) => {
    // TODO: disable deep-link to default component?
    switch (moduleKey) {
      case fileDetailsSections.checklistQuestions.valueKey.toLowerCase():
        // checklist view first step
        Helpers.goTo(`${routes.protectedRoutes.checklistsProceduresManagement.fullPath}/view/1/${moduleItem[fields.id]}`, [{ project_id: projectID }, { inspection_id: inspectionID }]);
        break;
      default:
        Helpers.goTo(user[params.fullScreenEnabled] ? routes.protectedRoutes.fullScreen.fullPath : routes.protectedRoutes.inspections.fullPath, [
          { project_id: projectID },
          { inspection_id: inspectionID },
          { type: modules[moduleKey] },
          { selected_item: moduleItem[fields.id] },
        ]);
        break;
    }
  };

  const updateSelectedHierarchyDetailsData = data => {
    const newSelectedItemData = {
      ...hierarchyLevelDetails.selectedItemData,
      ...data,
      // set URLs for instant display of new file for the document
      [fields.src]: Helpers.getUploadImageSource(data[fields.fileName], data[fields.url], null, data[fields.fileType]),
      [fields.thumbnailUrl]: data[fields.url],
      [fields.documentSizeUrl]: '',
    };
    setHierarchyLevelDetails({
      ...hierarchyLevelDetails,
      selectedItemData: newSelectedItemData,
    });
    // updates file data and sets the type and size of new active version of selected item
    const updatedFileData = fileData.map(file => {
      if (file[fields.id] === hierarchyLevelDetails.selectedItem) {
        return {
          ...file,
          [fields.fileSize]: data[fields.fileSizeKB],
          [fields.fileType]: data[fields.fileType],
        };
      }
      return file;
    });
    setFileData(updatedFileData);
  };

  const handleEditAction = (file, selectedDocument) => updateVersionDocument(file, selectedDocument, updateSelectedItemDataVersionDetails);

  const updateSelectedItemDataVersionDetails = (file, selectedDocument) => {
    if (file[fields.fileVersion] === selectedDocument[fields.fileVersion]) {
      setHierarchyLevelDetails({
        ...hierarchyLevelDetails,
        selectedItemData: {
          ...hierarchyLevelDetails.selectedItemData,
          ...file,
        },
      });
      /**
       * The code below is checking for dirty form fields using the `getDirtyFormFields`
       * function from the `dmsHelpers` object. If there are dirty form fields (fields that have been
       * modified), it iterates over each dirty field and calls the `changeField` function with the
       * form name, field name, field value, and a boolean parameter set to true. This code is
       * used to update specific form fields based on user input or changes. This will ensure that the unsaved changes
       * are preserved and the Save button is enabled.
       */

      const dirtyFormFields = DMSHelpers.getDirtyFormFields(formValues, hierarchyLevelDetails.selectedItemData);

      if (dirtyFormFields?.length > 0) {
        dirtyFormFields.forEach(field => {
          changeField(FORMS.DMSFileHierarchyLevel, field, formValues[field], true);
        });
      }
    }
    updateVersionDetails(file);
    closeModal();
  };

  const openEditVersionModal = (versionFile, documentDetails) => {
    if (!formHasUnsavedChanges || window.confirm('You have unsaved changes. Are you sure you want to leave?')) {
      setModalData({
        isOpen: true,
        title: t('DMS_FILE.EDIT_VERSION'),
        customClassName: 'modal-no-max-height modal-large',
        CustomContent: dynamicProps => <VersionModal isEditMode={true} {...dynamicProps} />,
        type: '',
        versionFile,
        handleEditAction,
        closeAction: closeModal,
        customCloseAction: closeModal,
        documentDetails,
      });
    }
  };

  const areButtonsDisabled = isFoldersDataLoading || isFilesDataLoading;
  const currentFolder = path[path.length - 1];
  const isCurrentFolderParentUnassigned = () => {
    // we check if the path.length is 3, because that is the maximum number of levels if the user is currently in the module-unassigned subfolder
    if (path.length === 3) {
      const parentFolder = path[path.length - 2]; // we get the second to last in the list, which should be Unassigned folder
      return parentFolder[fields.id] === defaultUnassignedDMSFolderID; // check if the parent folder of the current folder is Unassigned folder and return
    }
    return false;
  };

  const onFormChangeHandler = formChange => {
    setFormHasUnsavedChanges(formChange);
    setUnsavedChangesDirty(true); // only added so it can be handled in the logout action click, for now
    formIsDirty.current = true;
  };

  return (
    <div className={`document-management default-background ${hierarchyLevelDetails.toolbarCollapsed ? '' : 'toolbar-opened'}`}>
      <PageNavigation
        title="DOCUMENT_MANAGEMENT"
        icon="documents"
        backButtonPath={backButtonPath}
        withBottomBorder
        secondActionButtonText="DOCUMENT_MANAGEMENT.UPLOAD_FILE"
        actionButtontext="DOCUMENT_MANAGEMENT.NEW_FOLDER"
        handleActionButtonClick={onCreateFolderClick}
        handleSecondActionButtonClick={onUploadFileClick}
        actionButtonProps={{
          ...actionButtonProps,
          // disabled New Folder btn if User is inside the Unassigned folder (ComponentHierarchyUnassignedLevelID === 'Unassigned') or inside module Unassigned subfolders
          // TODO: should we ditch ComponentHierarchyUnassignedLevelID which is checking string, and use defaultUnassignedDMSFolderID?
          disabled: areButtonsDisabled || currentFolder[fields.name] === ComponentHierarchyUnassignedLevelID || isCurrentFolderParentUnassigned(),
        }}
        // disabled Upload File btn if files are being uploaded
        secondActionButtonProps={{ ...secondActionButtonProps, disabled: areButtonsDisabled }}
      />
      <div className="document-management__container" onClick={handleDeselectItem}>
        <div className="document-management__container__filters">
          <DMSFilters quickFiltersChange={handleFilesQuickFiltersChange} value={filesQuickFilters} labels={filtersLabels} labelsLoading={filtersLabelsLoading} />
        </div>
        <div className="document-management__container__documents">
          <div className="document-management__container__documents__path">
            <PathViewer levels={path} propInPath="Name" onBackClick={handleOnPathBackClick} onPathClick={handleOnPathClick} />
          </div>
          <div className="document-management__container__documents__tables">
            <DoubleTable
              firstTableProps={{
                data: folderData || [],
                tableConfig: folderTableConfig,
                filters: foldersFilters,
                sortDirection: filterProps.sortDirection,
                sortByColumn: filterProps.sortByColumn,
                handleFormatCells: formatFolderTableCells,
                TotalItems: foldersFilters[filterProps.totalItems],
                searchPlaceholder: t('DOCUMENT_MANAGEMENT_TABLE.SEARCH_FOLDER', { currentFolder: path[path.length - 1][fields.name] }),
                noDataText: t('DOCUMENT_MANAGEMENT_TABLE.FOLDER_TABLE_NO_DATA'),
                onRowClick: handleOnFolderRowClick,
                onSortClick: handleFolderSort,
                isLoading: isFoldersDataLoading,
                hasNext: foldersFilters[filterProps.hasNext],
                loadMoreOnClick: loadMoreFolders,
                customTableClass: 'folders-table',
                searchInputValue: foldersSearchText,
                searchInputOnChange: handleFolderSearchInput,
                onDropdownClick: handleOnDropdownClick,
                selectedItem: hierarchyLevelDetails.selectedItem,
                loadForDMS: true,
                defaultUnassignedDMSFolderID,
              }}
              secondTableProps={{
                data: fileData || [],
                tableConfig: fileTableConfig,
                filters: filesFilters,
                sortDirection: filterProps.sortDirection,
                sortByColumn: filterProps.sortByColumn,
                handleFormatCells: formatFileTableCells,
                TotalItems: filesFilters[filterProps.totalItems],
                searchPlaceholder: t('DOCUMENT_MANAGEMENT_TABLE.SEARCH_FILE', { currentFolder: path[path.length - 1][fields.name] }),
                noDataText: t('DOCUMENT_MANAGEMENT_TABLE.FILE_TABLE_NO_DATA'),
                onRowClick: handleOnFileRowClick,
                onSortClick: handleFilesSort,
                isLoading: isFilesDataLoading,
                hasNext: filesFilters[filterProps.hasNext],
                loadMoreOnClick: loadMoreFiles,
                customTableClass: 'files-table',
                searchInputValue: filesSearchText,
                searchInputOnChange: handleFileSearchInput,
                onDropdownClick: handleOnDropdownClick,
                selectedItem: hierarchyLevelDetails.selectedItem,
                loadForDMS: true,
              }}
            />
          </div>
        </div>
      </div>
      <Toolbar
        collapsed={hierarchyLevelDetails.toolbarCollapsed}
        placement={placements.right}
        resizable={false}
        toggleToolbar={handleToolbarClick}
        children={
          <DMSRightSide
            isLoading={hierarchyLevelDetails.isRightSideLoading || isEmpty(hierarchyLevelDetails.selectedItemData)}
            initialValues={hierarchyLevelDetails.selectedItemData}
            handleFormSubmit={formSubmitDebounce}
            isDisabled={
              hierarchyLevelDetails.isRightSideDisabled ||
              !Helpers.hasAccess({
                user,
                visibleFor: [PERMISSIONS[PERMISSION_TYPES.documentManagement].edit.name],
                id: hierarchyLevelDetails.selectedItemData && hierarchyLevelDetails.selectedItemData[fields.createdByID],
                ownerRequiredPermission: PERMISSIONS[PERMISSION_TYPES.documentManagement].create.name,
              })
            }
            isFolderSelected={isFolderSelected}
            user={user}
            openChangeLocationModal={openChangeLocationModal}
            statusData={statusesData}
            onFileStatusChange={handleFileStatusChange}
            openEditFunction={openEdit}
            openFile={openFile}
            downloadFile={downloadFile}
            onModuleItemDeepLinkHandler={onModuleItemDeepLinkHandler}
            formHasUnsavedChanges={formHasUnsavedChanges}
            onFormChange={onFormChangeHandler}
            onUploadFileClick={onUploadFileClick}
            versionHistoryModalRef={versionHistoryModalRef}
            updateSelectedHierarchyDetailsData={updateSelectedHierarchyDetailsData}
            openEditVersionModal={openEditVersionModal}
            handleOnDropdownClick={handleOnDropdownClick}
          />
        }
        stopProp={true}
      />
      <Modal {...modalData} />
      <Modal {...fileUploadModalData} />
    </div>
  );
};

DocumentManagement.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  user: state.userReducer,
  uploadFiles: state.uploadBatchesReducer.uploadFiles,
  defaultUnassignedDMSFolderID: state.projectDetailsReducer.DefaultUnassignedDMSFolderID,
  formValues: getFormValues(FORMS.DMSFileHierarchyLevel)(state),
});

const mapDispatchToProps = dispatch => ({
  fetchDMFolders: (filters, dataCallback, loadingCallback) => dispatch(fetchDMFolders(filters, dataCallback, loadingCallback)),
  createDMSFolder: (data, dataCallback, modalCallback) => dispatch(createDMSFolder(data, dataCallback, modalCallback)),
  fetchDMFiles: (filters, dataCallback, loadingCallback) => dispatch(fetchDMFiles(filters, dataCallback, loadingCallback)),
  fetchDMFolderDetails: (data, hierarchyId, dataCallback, loadingCallback) => dispatch(fetchDMFolderDetails(data, hierarchyId, dataCallback, loadingCallback)),
  fetchDMFileDetails: (fileId, dataCallback, action, isPropertyAdd, isVersionRefresh) => dispatch(fetchDMFileDetails(fileId, dataCallback, action, isPropertyAdd, isVersionRefresh)),
  deleteDMSFolder: (data, callback) => dispatch(deleteDMFolder(data, callback)),
  deleteDMSFile: (data, callback) => dispatch(deleteDMFile(data, callback)),
  moveDMSFile: (data, callback) => dispatch(moveDMSFile(data, callback)),
  fetchDMSStatuses: callback => dispatch(fetchDMSStatuses(callback)),
  updateDMSFile: (data, callback) => dispatch(updateDMSFile(data, callback)),
  updateDMFolderDetails: (data, documentLevels, hierarchyCallback, detailsCallback) => dispatch(updateDMFolderDetails(data, documentLevels, hierarchyCallback, detailsCallback)),
  deleteDMFolder: (data, callback) => dispatch(deleteDMFolder(data, callback)),
  deleteDMFile: (data, callback) => dispatch(deleteDMFile(data, callback)),
  setUploadItems: assets => dispatch(setUploadItems(assets)),
  singleItemUploadDone: (url, callback) => dispatch(singleItemUploadDone(url, callback)),
  setGenericNotification: data => dispatch(setGenericNotification(data)),
  linkComponentsToFile: (data, callback) => dispatch(linkComponentsToFile(data, callback)),
  linkObservationToFile: (data, callback) => dispatch(linkObservationToFile(data, callback)),
  linkNotificationToFile: (data, callback) => dispatch(linkNotificationToFile(data, callback)),
  linkWorkorderToFile: (data, callback) => dispatch(linkWorkorderToFile(data, callback)),
  fetchDMLabels: (projectID, searchText, dataCallback, loadingCallback) => dispatch(fetchDMLabels(projectID, searchText, dataCallback, loadingCallback)),
  addLabelToDMSFile: (newAddedLabels, sourceId, labelId, callback) => dispatch(addLabelToDMSFile(newAddedLabels, sourceId, labelId, callback)),
  removeLabelFromDMSFile: (newAddedLabels, sourceId, labelId, callback) => dispatch(removeLabelFromDMSFile(newAddedLabels, sourceId, labelId, callback)),
  getPdfComponentDrawings: (inspectionID, fileID, filter, pdfPageNumber, componentId, callback) =>
    dispatch(getPdfComponentDrawings(inspectionID, fileID, filter, pdfPageNumber, componentId, callback)),
  setCollapsibleInfo: data => dispatch(setCollapsibleInfo(data)),
  setCollapsibleInfoTitle: data => dispatch(setCollapsibleInfoTitle(data)),
  setCollapsibleInfoIsExpanded: data => dispatch(setCollapsibleInfoIsExpanded(data)),
  setCollapsibleInfoContentProps: data => dispatch(setCollapsibleInfoContentProps(data)),
  setUploadBatchItems: data => dispatch(setUploadBatchItems(data)),
  uploadBatchItems: (batch, getIDs, customDoneFunction, needLoader, isVersionUpload) => dispatch(uploadBatchItems(batch, getIDs, customDoneFunction, needLoader, isVersionUpload)),
  confirmFileUploadIsDone: (id, isActiveVersion, callback) => dispatch(confirmDMSFileUploadIsDone(id, isActiveVersion, callback)),
  fetchDMSCategories: (projectId, moduleName, callback) => dispatch(fetchDMSCategories(projectId, moduleName, callback)),
  setPdfComponents: selectedModuleItemDrawings => dispatch(setPdfComponents(selectedModuleItemDrawings)),
  fetchVersionHistoryForDocument: data => dispatch(fetchVersionHistoryForDocument(data)),
  addVersionHistoryItem: data => dispatch(addVersionHistoryItem(data)),
  updateVersionDocument: (data, selectedDocument, callback) => dispatch(updateDocumentVersion(data, selectedDocument, callback)),
  updateVersionDetails: data => dispatch(updateVersionDetails(data)),
  deleteDMSFileVersion: (data, callback) => dispatch(deleteDMSFileVersion(data, callback)),
  changeField: (formName, field, value, touch) => dispatch(change(formName, field, value, touch)),
  setUnsavedChangesDirty: data => dispatch(setUnsavedChangesDirty(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DocumentManagement));
