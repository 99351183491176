import React from 'react';
import PropTypes from 'prop-types';

import AddButton from '../../../../../common/add-button/components/add-button';
import Section from '../../../../../common/section/components/section';
import Button from '../../../../../common/form/components/button';
import { FORMS } from '../../../../../common/constants';
import { defaultQuestionValidators } from '../../../../../common/question-components/constants/question-constants';
import { defaultSectionQuestionsName } from '../../../../../common/section/constants/section-constants';

const IsolationTemplateSections = (
  {
    sections,
    questionTypes,
    handleUpdateSection,
    handleAddQuestion,
    handleFieldDelete,
    handleFieldSort,
    handleDeleteSection,
    handleFieldChange,
    handleAddQuestionOption,
    handleUpdateQuestionOption,
    handleDeleteQuestionOption,
    handleSectionSort,
    isLoading,
    handleAddSection,
    handleBackButtonClick,
    handleFinishButtonClick,
    toggleUploadModal,
    handleDeleteSectionFile,
    sectionUpdateDebounce,
    questionUpdateDebounce,
    questionOptionUpdateDebounce,
    viewOnly,
  },
  { t }
) => {
  return (
    <div className="isolation-template-modal__sections">
      {sections.map((section, index) => {
        return (
          <Section
            {...section}
            form={`${FORMS.sectionsForm}-isolation-template-${section.ID}`}
            key={`isolation-template-section-${section.ID}`}
            initialValues={section}
            sectionIndex={index}
            sectionsLength={sections.length}
            questions={section.SectionQuestions}
            sectionQuestionsName={defaultSectionQuestionsName}
            questionValidators={defaultQuestionValidators}
            questionTypes={questionTypes}
            handleSectionChange={handleUpdateSection}
            handleAddQuestion={handleAddQuestion}
            handleFieldDelete={handleFieldDelete}
            handleFieldSort={handleFieldSort}
            handleUpdateSection={handleUpdateSection}
            handleDeleteSection={handleDeleteSection}
            handleFieldChange={handleFieldChange}
            isExpandable={
              (section.SectionQuestions && section.SectionQuestions.length > 0) || (section.lockedQuestions && section.lockedQuestions.length > 0) || section.isFilesUpload || section.Editable
                ? true
                : false
            }
            deleteDisabled={sections.length <= 1}
            sectionsChanged={section.changed}
            handleAddQuestionOption={handleAddQuestionOption}
            handleUpdateQuestionOption={handleUpdateQuestionOption}
            handleDeleteQuestionOption={handleDeleteQuestionOption}
            handleSort={handleSectionSort}
            isLoading={isLoading}
            hideAddQuestionButton={section.Locked && !section.Editable}
            hideAdditionalFields
            stickActions
            showQuestionDescription
            toggleUploadModal={toggleUploadModal}
            handleDeleteSectionFile={handleDeleteSectionFile}
            isLocked={section.Locked}
            sections={sections}
            sectionUpdateDebounce={sectionUpdateDebounce}
            questionUpdateDebounce={questionUpdateDebounce}
            questionOptionUpdateDebounce={questionOptionUpdateDebounce}
            isDisabled={viewOnly}
            hideMandatoryCheckbox
          />
        );
      })}
      <div className="checklists-procedures-management__container__second-step__add-button-wrapper">
        <AddButton disabled={viewOnly} onClick={handleAddSection}>
          {'SECTIONS.ADD_SECTION'}
        </AddButton>
      </div>
      <div className="checklists-procedures-management__container__second-step__buttons">
        <div className="checklists-procedures-management__container__second-step__buttons__wrapper">
          <Button
            variant="gray-outline"
            type="button"
            text={t('BACK')}
            width="sm"
            height="md"
            className="checklists-procedures-management__container__second-step__buttons__wrapper__btn"
            onClick={handleBackButtonClick}
          />
          <Button type="button" text={t('FINISH')} width="sm" height="md" onClick={handleFinishButtonClick} />
        </div>
      </div>
    </div>
  );
};

IsolationTemplateSections.contextTypes = {
  t: PropTypes.func.isRequired,
};

IsolationTemplateSections.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number.isRequired,
      SectionOrder: PropTypes.number.isRequired,
      Name: PropTypes.string.isRequired,
      SectionQuestions: PropTypes.arrayOf(
        PropTypes.shape({
          ID: PropTypes.number,
          Name: PropTypes.string.isRequired,
          QuestionType: PropTypes.number,
          QuestionOrder: PropTypes.number,
          IsMandatory: PropTypes.bool,
          AllowMultipleAnswers: PropTypes.bool,
          EstimatedTime: PropTypes.number,
          CreatedAt: PropTypes.number,
        })
      ),
    })
  ),
  questionTypes: PropTypes.arrayOf(
    PropTypes.shape({
      ID: PropTypes.number,
      ChecklistItemType: PropTypes.string,
      ChecklistItemTypeName: PropTypes.string,
    })
  ),
  sectionsChanged: PropTypes.bool,
  handleUpdateSection: PropTypes.func.isRequired,
  handleAddQuestion: PropTypes.func.isRequired,
  handleFieldDelete: PropTypes.func.isRequired,
  handleFieldSort: PropTypes.func.isRequired,
  handleDeleteSection: PropTypes.func.isRequired,
  handleFieldChange: PropTypes.func.isRequired,
  handleAddQuestionOption: PropTypes.func.isRequired,
  handleUpdateQuestionOption: PropTypes.func.isRequired,
  handleDeleteQuestionOption: PropTypes.func.isRequired,
  handleSectionSort: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  handleAddSection: PropTypes.func.isRequired,
  handleBackButtonClick: PropTypes.func.isRequired,
  handleFinishButtonClick: PropTypes.func.isRequired,
  toggleUploadModal: PropTypes.func.isRequired,
  handleDeleteSectionFile: PropTypes.func.isRequired,
  viewOnly: PropTypes.bool,
};

export default IsolationTemplateSections;
