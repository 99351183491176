import React from 'react';
import PropTypes from 'prop-types';
import { find } from 'lodash';

import Icon from '../../icon/components/icon';
import '../styles/display-status.scss';

const DisplayStatus = ({ statuses, status, valueKey, iconKey, titleKey, nonTranslatable, displayTitle = true, ...rest }, { t }) => {
  const selected = find(statuses, item => item[valueKey] === status);

  if (!selected) {
    return '-';
  }

  return (
    <div className="display-status" {...rest}>
      {selected[iconKey] ? <Icon className={`display-status__icon ${selected.iconClass || ''}`} name={selected[iconKey]} size="sm" {...(selected?.iconProps || {})} /> : null}
      {displayTitle && selected[titleKey] ? <p className="f-primary display-status__text">{nonTranslatable ? selected[titleKey] : t(selected[titleKey])}</p> : null}
    </div>
  );
};

DisplayStatus.contextTypes = {
  t: PropTypes.func.isRequired,
};

DisplayStatus.propTypes = {
  statuses: PropTypes.array.isRequired,
  status: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  valueKey: PropTypes.string,
  iconKey: PropTypes.string,
  titleKey: PropTypes.string,
  nonTranslatable: PropTypes.bool,
};

DisplayStatus.defaultProps = {
  valueKey: 'value',
  iconKey: 'icon',
  titleKey: 'title',
  nonTranslatable: false,
};

export default DisplayStatus;
