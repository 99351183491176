import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { get, values, debounce, isArray, keys, reduce, map } from 'lodash';

import TableDropdown from './table-dropdown';
import DisplayStatus from '../../../common/display-status/components/display-status';
import LoadMore from '../../../common/load-more/components/load-more';
import Modal from '../../../common/modal/components/modal';
import PageNavigation from '../../../common/page-navigation/components/page-navigation';
import SearchInput from '../../../common/input/components/search-input';
import TableComponent from '../../../common/table/components/table-component';
import ChecklistsProceduresFilter from './checklists-procedures-filter';

import Helpers from '../../../common/helpers';
import { routes } from '../../../common/routes-constants';
import { params } from '../../profile/constants/profile-constants';
import {
  tableConfig,
  steps,
  defaultFilters,
  fields,
  statuses,
  filterProps,
  sortDirection,
  confirmationModalContent,
  confirmationModalTypes,
  defaultPagingObj,
} from '../constants/checklists-procedures-constants';
import { openCreateChecklistsForm } from '../helpers/checklists-procedures-management-helpers';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../common/permissions-constants';
import {
  fetchChecklistsProcedures,
  deleteChecklistProcedure,
  updateChecklistProcedure,
  changeChecklistProcedureStatus,
  copyChecklistProcedure,
  generatePDF,
} from '../actions/checklists-procedures-management-actions';

import '../styles/checklists-procedures-list.scss';

class ChecklistsProceduresList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      actionInProgress: false,
      checklists: [],
      filters: defaultFilters,
      advancedFilter: {},
      modalData: {
        isOpen: false,
      },
      isDownloadInProgress: false,
    };
    this.searchChangeDebounced = debounce(this.handleSearch, 500);
  }

  componentDidMount = () => {
    const { location } = this.props;
    const { query } = location;
    const projectId = get(query, 'project_id');

    if (!projectId) {
      Helpers.goTo(routes.protectedRoutes.project.fullPath);
    }

    this.getContractorsProcedures(defaultFilters);
  };

  getContractorsProcedures = (params, loadMore) => {
    const { checklists, advancedFilter } = this.state;
    const { location, fetchChecklistsProcedures } = this.props;
    const { query } = location;
    const projectId = get(query, 'project_id');
    params = {
      ...params,
      ProjectId: parseInt(projectId, 10),
    };

    //normalize advanced filter
    const newAdvancedFilter = reduce(
      advancedFilter,
      (obj, it, key) => {
        if ([filterProps.createdByFilter].indexOf(key) > -1) {
          obj[key] = map(advancedFilter[key], ({ ID, UserID }) => ID || UserID);
        } else {
          obj[key] = advancedFilter[key];
        }
        return obj;
      },
      {}
    );

    const onSuccessFetch = newState => {
      this.setState(newState);

      if (loadMore && newState.checklists) {
        Helpers.scrollIntoView('checklists__container', `row-${newState.checklists - 1}`);
      }
    };

    fetchChecklistsProcedures(params, checklists, onSuccessFetch, loadMore, newAdvancedFilter);
  };

  onRowClick = () => {};

  onColumnSort = SortByColumn => {
    const { filters } = this.state;
    const newParams = {
      ...filters,
      [filterProps.sortByColumn]: SortByColumn,
      [filterProps.sortDirection]: filters[filterProps.sortDirection] === sortDirection.asc ? sortDirection.desc : sortDirection.asc,
      ...defaultPagingObj,
    };

    this.getContractorsProcedures(newParams);
  };

  loadMore = () => {
    const { filters } = this.state;
    const newParams = { ...filters };

    this.getContractorsProcedures(newParams, true);
  };

  handleSearch = SearchText => {
    const { filters } = this.state;
    const newParams = {
      ...filters,
      [filterProps.lastSeen]: defaultFilters[filterProps.lastSeen],
      SearchText,
    };

    this.getContractorsProcedures(newParams);
  };

  handleInputChange = e => {
    this.searchChangeDebounced(e.target.value);
  };

  handleSuccessConfirmModalAction = (actionInProgress, isSuccess, _id, checklists, isDelete) => {
    const newState = { actionInProgress };

    if (isSuccess) {
      if (checklists && isArray(checklists)) {
        newState.checklists = checklists;
      }

      if (isDelete) {
        const { filters } = this.state;
        newState.filters = {
          ...filters,
          [filterProps.totalItems]: filters[filterProps.totalItems] - 1,
          [filterProps.lastSeen]: filters[filterProps.lastSeen] - 1,
        };
      }
    }

    this.setState(newState);
    this.toggleConfirmationModal(false);
  };

  handleConfirmModalAction = (type, data, isChangeToLiveStatus) => {
    const { deleteChecklistProcedure, location } = this.props;
    const { checklists } = this.state;

    switch (type) {
      case confirmationModalTypes.changeStatus:
        const { changeChecklistProcedureStatus } = this.props;
        changeChecklistProcedureStatus(data, this.handleSuccessConfirmModalAction, Object.assign([], checklists), isChangeToLiveStatus);
        break;
      case confirmationModalTypes.duplicate:
        // if the C&P is in status "LIVE", prevent the action
        if (data[fields.status] === statuses.live.value) {
          break;
        }

        const { copyChecklistProcedure } = this.props;
        copyChecklistProcedure(data.ID, templateId => openCreateChecklistsForm('edit', steps.firstStep, templateId, location?.search));
        break;
      case confirmationModalTypes.delete:
        deleteChecklistProcedure(data, Object.assign([], checklists), this.handleSuccessConfirmModalAction);
        break;
      default:
        break;
    }
  };

  toggleConfirmationModal = (isOpen, type, data, translationData, isChangeToLiveStatus) => {
    const { t } = this.context;
    const modalData = isOpen
      ? {
          isOpen,
          content: t(confirmationModalContent[type]?.content, translationData),
          type: 'yes-no',
          customClassName: 'checklist-procedures-list-confirmation-modal',
          confirmAction: () => this.handleConfirmModalAction(type, data, isChangeToLiveStatus),
          closeAction: () => this.toggleConfirmationModal(false),
        }
      : { isOpen: false };

    this.setState({ modalData });
  };

  openAdvancedFilter = () => {
    const { t } = this.context;
    const { location } = this.props;
    const { query } = location;
    const { advancedFilter, filters } = this.state;
    const closeAction = () => this.setState({ modalData: { isOpen: false } });
    let projectId = get(query, 'project_id');
    projectId = parseInt(projectId, 10);

    const modalData = {
      isOpen: true,
      type: 'none',
      title: t('ADVANCED_FILTERS.TITLE'),
      CustomContent: () => (
        <ChecklistsProceduresFilter
          submitForm={values => {
            const newParams = {
              ...filters,
              ...defaultPagingObj,
            };

            this.setState(
              {
                advancedFilter: values,
              },
              () => {
                closeAction();
                this.getContractorsProcedures(newParams);
              }
            );
          }}
          resetFilter={() => {
            const newParams = { ...filters, ...defaultPagingObj };
            this.setState(
              {
                advancedFilter: {},
              },
              () => {
                closeAction();
                this.getContractorsProcedures(newParams);
              }
            );
          }}
          projectId={projectId}
          initialValues={advancedFilter}
          closeAction={closeAction}
        />
      ),
      customClassName: 'modal-large defects-filter',
      closeAction,
    };
    this.setState({ modalData });
  };

  handleDownloadPDF = templateId => {
    const { isDownloadInProgress } = this.state;
    const { generatePDF } = this.props;

    if (isDownloadInProgress) {
      return;
    }

    this.setState({ isDownloadInProgress: true });

    generatePDF(templateId, () => {
      this.setState({ isDownloadInProgress: false });
    });
  };

  render() {
    const { user, location } = this.props;
    const { checklists, filters, isLoading, modalData, actionInProgress, advancedFilter, isDownloadInProgress } = this.state;
    const { t } = this.context;
    const { search } = location;
    const backButtonPath = user[params.fullScreenEnabled] ? routes.protectedRoutes.fullScreen.fullPath : routes.protectedRoutes.inspections.fullPath;

    return (
      <div className="checklists">
        <PageNavigation
          backButtonPath={`${backButtonPath}${search}`}
          title="CHECKLISTS_PROCEDURES_LIST.TITLE"
          icon="list"
          withBottomBorder
          actionButtontext="CREATE_BUTTON_TEXT.NEW"
          handleActionButtonClick={() => openCreateChecklistsForm('edit', steps.firstStep, null, search)}
          actionButtonProps={{
            visibleFor: [PERMISSIONS[PERMISSION_TYPES.checklists].create.name],
          }}
        />
        <div className="checklists__search-input-wrapper">
          <SearchInput onChange={this.handleInputChange} placeholder={t('PROJECT.SEARCH.PLACEHOLDER')} stripped />
          <p className="f-secondary-green noselect pointer link" onClick={() => this.openAdvancedFilter()}>
            {t('ADVANCED_FILTER', { active: keys(advancedFilter).length })}
          </p>
        </div>
        <div className="checklists__container">
          <div className="checklists__container__table-wrapper">
            <TableComponent
              tableConfig={tableConfig}
              data={checklists || []}
              tableCustomClass="checklists__container__table-wrapper__table"
              onRowClick={this.onRowClick}
              onColumnSort={this.onColumnSort}
              sortingObj={filters}
              translationModule={t}
              formatCell={(value, type, index, item) => {
                if (type === fields.status) {
                  return <DisplayStatus statuses={values(statuses)} status={value} />;
                } else if (type === fields.actionMenu) {
                  return (
                    <TableDropdown
                      item={item}
                      queryParams={search}
                      toggleConfirmationModal={this.toggleConfirmationModal}
                      handleDownloadPDF={this.handleDownloadPDF}
                      isDownloadInProgress={isDownloadInProgress}
                    />
                  );
                } else {
                  return Helpers.formatCell(value, type, index, item);
                }
              }}
              stickyHeader={true}
            />
          </div>
        </div>
        <div className="checklists__load-more-wrapper">
          <LoadMore
            disabled={!filters[filterProps.hasNext] || isLoading}
            loaded={checklists.length}
            total={filters[filterProps.totalItems]}
            totalPosition="center"
            isLoading={isLoading}
            label="COMPONENT_HISTORY.LOAD_MORE"
            showButton
            showTotalUp
            buttonVariant="success-outline"
            resultsText="CHECKLISTS_PROCEDURES_LIST.SHOWING_TOTAL_ITEMS"
            onClick={this.loadMore}
          />
        </div>
        <Modal {...modalData} modalDisabled={actionInProgress} confirmButtonDisabled={actionInProgress} />
      </div>
    );
  }
}

ChecklistsProceduresList.contextTypes = {
  t: PropTypes.func.isRequired,
};

const mapStateToProps = state => {
  return {
    user: state.userReducer,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchChecklistsProcedures: (filters, checklists, callback, loadMore, advancedFilters) => dispatch(fetchChecklistsProcedures(filters, checklists, callback, loadMore, advancedFilters)),
  deleteChecklistProcedure: (data, checklists, callback) => dispatch(deleteChecklistProcedure(data, checklists, callback)),
  updateChecklistProcedure: (data, callback, checklists) => dispatch(updateChecklistProcedure(data, callback, checklists)),
  changeChecklistProcedureStatus: (data, callback, checklists, isChangeToLiveStatus) => dispatch(changeChecklistProcedureStatus(data, callback, checklists, isChangeToLiveStatus)),
  copyChecklistProcedure: (templateId, callback) => dispatch(copyChecklistProcedure(templateId, callback)),
  generatePDF: (templateId, callback) => dispatch(generatePDF(templateId, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistsProceduresList);
