import { cloneDeep, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Field, reduxForm } from 'redux-form';
import AccessRenderer from '../../../../../../common/access-renderer/components/access-renderer';
import { FORMS } from '../../../../../../common/constants';
import ExpandableSection from '../../../../../../common/expandable-section/components/expandable-section';
import Button from '../../../../../../common/form/components/button';
import ColorPalette from '../../../../../../common/form/components/color-pallete';
import CustomInput from '../../../../../../common/form/components/input';
import Textarea from '../../../../../../common/form/components/text-area';
import UneditableInfo from '../../../../../../common/form/components/uneditable-info';
import Helpers from '../../../../../../common/helpers';
import Icon from '../../../../../../common/icon/components/icon';
import LocationExpandableSection from '../../../../../../common/location-expandable-section/components/location-expandable-section';
import Modal from '../../../../../../common/modal/components/modal';
import { PERMISSIONS, PERMISSION_TYPES } from '../../../../../../common/permissions-constants';
import RenderIf from '../../../../../../common/render-if/components/render-if';
import { formConstants as componentConstants } from '../../../../constants/component-constants';
import { modules, objectTools } from '../../../../constants/constants';
import { expandableSectionInfoTypes } from '../../../../constants/notification-constants';
import '../../../../styles/measurement-point-form.scss';
import TimeSeriesGraphModal from '../../../readings-and-gauges/components/time-series-graph-modal/time-series-graph-modal';
import { alarmColorPalette } from '../../../readings-and-gauges/constants/alarm-constants';
import { tabNames, toolbarItems } from '../../../readings-and-gauges/constants/time-series-graph-modal-constants';
import { measurementPointDetailsValidate } from '../../../readings-and-gauges/validators/create-measurement-point-validator';
import DefectComponentPicker from '../../defect-details/components/defect-component-picker';
import LocationSection from '../../location-section';
import ExpandableSectionInfo from '../../notification-details/expandable-section-info';
import { formConstants, textAreaMaxChars } from '../constants/measurement-point-constants';
import TimeSeriesGraphSmallPreview from './time-series-graph-small-preview/time-series-graph-small-preview';

const MeasurementPointForm = (props, { t }) => {
  const {
    handleSubmit,
    selectedMeasurementPoint,
    showGeometryWarning,
    formHasUnsavedChanges,
    viewer,
    locationObject,
    updateGeometry,
    measurementPointLocationObjectAdding,
    measurementPointLocationObjectEditing,
    setMeasurementPointLocationObjectAdding,
    setMeasurementPointLocationObjectEditing,
    images360Ref,
    objectToolClick,
    toggleConfirmationModal,
    defaultComponent,
    componentDetails,
    handleActivePage,
    handleComponentChange,
    setGenericNotification,
    setMeasurementPointFormState,
    handleAddMeasurementReading,
    initialValues,
    onChange,
    toggleMeasurementPoint,
    graphDetailsIsLoading,
    graphLoading,
    graphData,
    graphDetails,
    timePeriodsList,
    refetchGraphDetails,
    copyEquipmentFromParentMeasurementLocation,
  } = props;

  const [modalData, setModalData] = useState({ isOpen: false });
  const ownerID = selectedMeasurementPoint?.[formConstants.fields.createdBy];

  const handleDeleteLocation = newCoordinates => {
    const element = cloneDeep({ ...selectedMeasurementPoint, ...locationObject });
    element.Geometry.coordinates = newCoordinates;

    updateGeometry(element);
    toggleConfirmationModal(false);
  };

  const formatDate = input => {
    if (!input) return;

    return Helpers.getDateFromUnix(input);
  };

  const handleComponentClick = component => {
    if (component[componentConstants.fields.id] === defaultComponent[componentConstants.fields.id]) return;
    handleActivePage(modules.components, component[componentConstants.fields.id]);
  };

  const closeModal = () => {
    refetchGraphDetails();
    setModalData({ isOpen: false });
  };

  const handleChangedComponent = components => {
    const idToBeSent = components?.[0] ? components?.[0]?.[componentConstants.fields.id] : null;
    handleComponentChange(idToBeSent);
    closeModal();
  };

  const componentPickerAdditionalOptions = [
    {
      onClickHandler: copyEquipmentFromParentMeasurementLocation,
      text: `${t('COPY_FROM')} ${t('READINGS_AND_GAUGES.MEASUREMENT_LOCATION')}`,
    },
  ];

  const openComponentPickerModal = e => {
    e?.stopPropagation();
    setModalData({
      isOpen: true,
      type: '',
      customClassName: 'pick-and-link-components-modal modal-large',
      customClassWrapperName: 'pick-and-link-components-modal__picker',
      closeAction: closeModal,
      customCloseAction: closeModal,
      CustomContent: dynamicProps => <DefectComponentPicker {...dynamicProps} />,
      title: t('MEASUREMENT_POINT_DETAILS.EDIT_COMPONENTS_MODAL.TITLE'),
      setGenericNotification: setGenericNotification,
      additionalOptions: componentPickerAdditionalOptions,
      component: {
        ...componentDetails,
        [formConstants.fields.name]: componentDetails?.[componentConstants.fields.name] || defaultComponent?.[componentConstants.fields.name],
        [formConstants.fields.code]: componentDetails?.[componentConstants.fields.code] || null,
        [formConstants.fields.id]: componentDetails?.[componentConstants.fields.id] || defaultComponent?.[componentConstants.fields.id],
      },
      customConfirmAction: handleChangedComponent,
    });
  };

  const handleOpenTimeSeriesGraphModal = (e, tabKey) => {
    e.stopPropagation();
    setModalData({
      isOpen: true,
      customClassName: 'alarms-edit-modal modal-large',
      customClassWrapperName: 'alarms-edit-modal__picker',
      type: '',
      CustomContent: dynamicProps => <TimeSeriesGraphModal {...dynamicProps} />,
      closeAction: closeModal,
      title: t('MP.TIME_SERIES_GRAPH_MODAL.TITLE'),
      customCloseAction: closeModal,
      defaultTabKey: tabKey,
      measurementPointID: selectedMeasurementPoint[formConstants.fields.id],
      createdBy: ownerID,
    });
  };

  const triggeredAlarms = initialValues && initialValues[formConstants.fields.measurementPointTriggeredAlarms];

  return (
    <AccessRenderer
      visibleFor={[PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].pointEdit.name]}
      id={ownerID}
      ownerRequiredPermission={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].pointCreate.name}
    >
      {({ hasAccess }) => {
        const readonly = !hasAccess;

        return (
          <>
            <form className="defect-form measurement-point-form overflow-inherit" onSubmit={handleSubmit} noValidate onChange={onChange}>
              <div className="measurement-point-form__fields-container">
                <div className="measurement-point-form__fields-container--wrapper">
                  <ExpandableSection hideEditAction={true} expanded={true} title={t('SECTION_TITLE.TIME_SERIES_GRAPH')}>
                    <TimeSeriesGraphSmallPreview
                      readonly={readonly}
                      handleViewMore={e => handleOpenTimeSeriesGraphModal(e, toolbarItems[tabNames.graph].name)}
                      showGraphingGroup={false}
                      graphDetailsIsLoading={graphDetailsIsLoading}
                      graphLoading={graphLoading}
                      graphData={graphData}
                      graphDetails={graphDetails}
                      timePeriodsList={timePeriodsList}
                    />
                  </ExpandableSection>
                  <ExpandableSection title={t('MEASUREMENT_POINT_DETAILS.MEASUREMENT_READINGS')} hideEditAction={true} expanded={true}>
                    <Field
                      id={formConstants.fields.lastReadingValue}
                      name={formConstants.fields.lastReadingValue}
                      disabled={readonly}
                      component={UneditableInfo}
                      placeholder={t('MEASUREMENT_POINT_DETAILS.MEASUREMENT_READINGS.LAST_MEASUREMENT_READING')}
                      label={t('MEASUREMENT_POINT_DETAILS.MEASUREMENT_READINGS.LAST_MEASUREMENT_READING')}
                      labelClass="defect-form__label"
                      type="text"
                      size="lg"
                    />
                    <Field
                      id={formConstants.fields.lastReadingDate}
                      name={formConstants.fields.lastReadingDate}
                      disabled={readonly}
                      component={UneditableInfo}
                      placeholder={t('MEASUREMENT_POINT_DETAILS.MEASUREMENT_READINGS.LAST_MEASUREMENT_DATE')}
                      label={t('MEASUREMENT_POINT_DETAILS.MEASUREMENT_READINGS.LAST_MEASUREMENT_DATE')}
                      labelClass="defect-form__label"
                      type="text"
                      size="lg"
                      isRequired
                      format={formatDate}
                    />
                    <div className="measurement-point-form-measurement-readings__actions">
                      <AccessRenderer visibleFor={PERMISSIONS[PERMISSION_TYPES.readingsAndGauges].readingCreate.name}>
                        {({ hasAccess: canCreate }) => (
                          <Button
                            disabled={!canCreate}
                            onClick={handleAddMeasurementReading}
                            text={t('MEASUREMENT_POINT_DETAILS.MEASUREMENT_READINGS.ADD_MEASUREMENT_READING')}
                            height="md"
                            icon={'plus-circle'}
                            type="button"
                          />
                        )}
                      </AccessRenderer>
                      <Button
                        onClick={e => handleOpenTimeSeriesGraphModal(e, toolbarItems[tabNames.measurementReadings].name)}
                        text={t('MEASUREMENT_POINT_DETAILS.MEASUREMENT_READINGS.VIEW_ALL_MEASUREMENT_READINGS')}
                        height="md"
                        variant="success-outline"
                        type="button"
                      />
                    </div>
                  </ExpandableSection>
                  <RenderIf if={showGeometryWarning}>
                    <LocationExpandableSection
                      customExpanded={locationObject?.visible}
                      title={t('SECTION_TITLE.COMPONENT_LOCATION')}
                      onToggleLocationSection={() => toggleMeasurementPoint(selectedMeasurementPoint[formConstants.fields.id])}
                    >
                      <LocationSection
                        viewer={viewer}
                        coordinates={locationObject?.Geometry?.coordinates || []}
                        invalid={readonly}
                        readonlyCamPos={readonly}
                        locationIsAdding={measurementPointLocationObjectAdding}
                        locationIsEditing={measurementPointLocationObjectEditing}
                        setLocationIsAdding={setMeasurementPointLocationObjectAdding}
                        setLocationIsEditing={isEditing => {
                          setMeasurementPointLocationObjectEditing(isEditing);
                          if (images360Ref) {
                            // Show/Hide all footprints
                            images360Ref.footprintsVisible = isEditing ? false : true;
                          }
                        }}
                        handleAddLocation={() => {
                          setMeasurementPointLocationObjectAdding(true);
                          objectToolClick(objectTools.rgMeasurementPoint, null, false, () => {
                            setMeasurementPointLocationObjectAdding(false);
                            setMeasurementPointFormState({ hasUnsavedChanges: false });
                          });
                        }}
                        handleCameraChange={newValue => {
                          const element = cloneDeep({ ...selectedMeasurementPoint, ...locationObject });
                          element.CameraPosition.coordinates = newValue;

                          updateGeometry(element);
                        }}
                        handleDeleteLocation={newCoordinates => {
                          toggleConfirmationModal(true, t('MEASUREMENT_POINT_DETAILS.DELETE_LOCATION_MODAL.TITLE'), t('MEASUREMENT_POINT_DETAILS.DELETE_LOCATION_MODAL.DESC'), () =>
                            handleDeleteLocation(newCoordinates)
                          );
                        }}
                        addingLocationDescription="MEASUREMENT_POINT_DETAILS.MARK_ON_3D.IN_PROGRESS.TEXT"
                        missingLocationDescription="MEASUREMENT_POINT_DETAILS.WARNING.MISSING_PIN.DESCRIPTION"
                        missingLocationErrorMessage="MEASUREMENT_POINT_DETAILS.MISSING_PIN.FORM_INVALID.ERROR_MESSAGE"
                        editLocationDescription="MEASUREMENT_POINT_DETAILS.MARK_ON_3D.EDIT.TEXT"
                      />
                    </LocationExpandableSection>
                  </RenderIf>
                  <ExpandableSection expanded={true} title={t('DETAILS')}>
                    <div className="status-alarms-triggered">
                      <label>{t('MEASUREMENT_POINT_DETAILS.STATUS_ALARMS_TRIGGERED')}</label>
                      {isEmpty(triggeredAlarms) ? (
                        <div className="alarm-status">
                          <p className="input f-primary">-</p>
                        </div>
                      ) : (
                        triggeredAlarms.map(alarm => (
                          <div key={`${alarm[formConstants.fields.name]}`} className={`alarm-icon ${alarmColorPalette[alarm[formConstants.fields.color]].name}`}>
                            <Icon size="sm" name="alarm-bell-icon" handleHover={false} disabled={true} />
                            <p className="input f-primary">{alarm[formConstants.fields.name]}</p>
                          </div>
                        ))
                      )}
                    </div>
                    <Field
                      id={formConstants.fields.name}
                      name={formConstants.fields.name}
                      disabled={readonly}
                      component={CustomInput}
                      placeholder={t('MEASUREMENT_POINT_DETAILS.FORM_NAME')}
                      label={t('MEASUREMENT_POINT_DETAILS.FORM_NAME')}
                      labelClass="defect-form__label"
                      type="text"
                      size="lg"
                      isRequired
                    />
                    <Field
                      id={formConstants.fields.unit}
                      name={formConstants.fields.unit}
                      disabled={readonly}
                      component={CustomInput}
                      placeholder={t('MEASUREMENT_POINT_DETAILS.FORM_UNIT')}
                      label={t('MEASUREMENT_POINT_DETAILS.FORM_UNIT')}
                      labelClass="defect-form__label"
                      type="text"
                      size="lg"
                    />
                    <Field
                      id={formConstants.fields.expectedRange}
                      name={formConstants.fields.expectedRange}
                      disabled={readonly}
                      component={CustomInput}
                      placeholder={t('MEASUREMENT_POINT_DETAILS.FORM_EXPECTED_RANGE')}
                      label={t('MEASUREMENT_POINT_DETAILS.FORM_EXPECTED_RANGE')}
                      labelClass="defect-form__label"
                      type="text"
                      size="lg"
                    />
                    <Field
                      id={formConstants.fields.description}
                      name={formConstants.fields.description}
                      disabled={readonly}
                      component={Textarea}
                      placeholder={'MEASUREMENT_POINT_DETAILS.FORM_DESCRIPTION'}
                      label={'MEASUREMENT_POINT_DETAILS.FORM_DESCRIPTION'}
                      labelClass="defect-form__label"
                      maxChars={textAreaMaxChars}
                      enableAutoResize={true}
                      wrapperClassName={''}
                    />
                    <Field id={formConstants.fields.color} name={formConstants.fields.color} disabled={readonly} component={ColorPalette} label={t('MEASUREMENT_POINT_DETAILS.COLOR_TAG')} />
                    <Field
                      id={formConstants.fields.createdDate}
                      disabled={true}
                      name={formConstants.fields.createdDate}
                      component={UneditableInfo}
                      format={formatDate}
                      label={t('MEASUREMENT_POINT_DETAILS.FORM_DATE')}
                      labelClass=" defect-form__label"
                      type="text"
                    />
                    <Field
                      id={formConstants.fields.createdByUser}
                      disabled={true}
                      name={formConstants.fields.createdByUser}
                      component={UneditableInfo}
                      placeholder={t('MEASUREMENT_POINT_DETAILS.FORM_CREATED_BY')}
                      label={t('MEASUREMENT_POINT_DETAILS.FORM_CREATED_BY')}
                      labelClass="defect-form__label"
                      type="text"
                    />
                  </ExpandableSection>
                  <ExpandableSection title={t('INSPECTION_COMPONENTS')} className="source-section" hideEditAction={readonly} onEditClick={openComponentPickerModal} expanded={true}>
                    <ExpandableSectionInfo
                      className={'expandable-section-equipment'}
                      data={componentDetails}
                      type={expandableSectionInfoTypes.components}
                      onClick={handleComponentClick}
                      defaultComponent={defaultComponent}
                      emptyStateProps={{
                        emptyStateText: t('NO_EQUIPMENT_EMPTY_STATE'),
                        buttonAction: openComponentPickerModal,
                        buttonText: t('WORK_ORDER.ADD_COMPONENTS'),
                        showButton: !readonly,
                      }}
                    />
                  </ExpandableSection>
                </div>
              </div>
              <div className="defect-form__save-cta-wrapper default-background">
                <Button type="submit" width="sm" height="md" text={t('SAVE')} disabled={!formHasUnsavedChanges} />
              </div>
            </form>
            <Modal {...modalData} />
          </>
        );
      }}
    </AccessRenderer>
  );
};

MeasurementPointForm.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default reduxForm({
  form: FORMS.measurementPointForm,
  validate: measurementPointDetailsValidate,
  enableReinitialize: true,
})(MeasurementPointForm);
